import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LayersDataService, OperationsService } from '@bg-front/core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import { MapBaseService } from '@bg-front/map/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BaseLayerControlComponent } from '../base-layer-control/base-layer-control.component';
import { MapChemicalMonitoringObjectsFilterComponent } from '../map-chemical-monitoring-objects-filter/map-chemical-monitoring-objects-filter.component';
import { ControlsOf } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { IBaseMapFilterForm } from '../../models/interfaces';
import { MapControlsService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'bg-chemical-monitoring-objects-layer-control',
  templateUrl: './chemical-monitoring-objects-layer-control.component.html',
  styleUrls: ['./chemical-monitoring-objects-layer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChemicalMonitoringObjectsLayerControlComponent extends BaseLayerControlComponent implements OnInit {
  public override mapLayerFilters: ValuesOf<ControlsOf<IBaseMapFilterForm>> = this.accessService.accessMap[
    'viewAllMunicipals'
  ]?.visible
    ? {}
    : { moId: this.settings.currentUser.organizationId.mo };
  constructor(
    mapService: MapBaseService,
    cdr: ChangeDetectorRef,
    gisService: LayersDataService,
    settings: Settings2Service,
    router: Router,
    route: ActivatedRoute,
    operationsService: OperationsService,
    mapControlsService: MapControlsService,
    private readonly accessService: AccessService,
    private readonly modalService: NzModalService,
  ) {
    super(mapService, cdr, gisService, settings, router, route, operationsService, mapControlsService);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
  }

  /** Открытие модального окна фильтрации */
  public override openFilterDialog(): void {
    this.modalService
      .create({
        nzTitle: 'Фильтрация объектов на карте',
        nzContent: MapChemicalMonitoringObjectsFilterComponent,
        nzComponentParams: {
          mapLayerFilters: this.mapLayerFilters,
        },
        nzFooter: null,
      })
      .afterClose.pipe(untilDestroyed(this))
      .subscribe((value: ValuesOf<ControlsOf<IBaseMapFilterForm>>) => {
        if (value) {
          this.mapLayerFilters = value;
          this.getLayerData(value, true, true);
        }
      });
  }
}
