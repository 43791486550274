<nw-header [options]="headerOptions" class="no-margin" (clickButton)="clickHeaderButton($event)"></nw-header>
<div class="building-route-container">
  <form nz-form [formGroup]="form" nzLayout="vertical" *ngIf="form" (ngSubmit)="finishBuilding()">
    <ng-container formArrayName="points">
      <ng-container *ngFor="let el of pointsArray?.controls; let i = index">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-label nzRequired nzFor="name">Координаты фактического адреса</nz-form-label>
              <nz-form-control>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                  <input
                    type="text"
                    nz-input
                    placeholder="Координаты фактического адреса"
                    [formControlName]="i"
                    maxlength="100"
                  />
                </nz-input-group>
                <ng-template #suffixIconButton>
                  <button nz-button nzType="primary"><i nz-icon nzType="delete" nzTheme="outline"></i></button>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <nz-divider></nz-divider>
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="24">
        <div class="start-forecasting-button d-flex d-flex-column d-flex-center">
          <button nz-button nzType="primary" type="button" *ngIf="!isBuildingRoute" (click)="startBuilding()">
            Задать новый маршрут
          </button>
          <button
            nz-button
            nzType="primary"
            type="submit"
            [disabled]="!form?.valid"
            *ngIf="isBuildingRoute"
            [disabled]="!(pointsArray.controls.length >= 2)"
          >
            Построить маршрут
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
