<form nz-form [formGroup]="form" nzLayout="vertical">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Муниципальное образование</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="moId" nzSize="large" nzAllowClear nzShowSearch>
            <nz-option
              [nzValue]="municipal.value"
              [nzLabel]="municipal.text"
              *ngFor="let municipal of municipals"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Тип КСиП</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="incidentTypeId" nzSize="large" nzAllowClear nzShowSearch>
            <nz-option [nzValue]="type.id" [nzLabel]="type.name" *ngFor="let type of ksipTypes$ | async"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label>Состояние</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="state" nzSize="large" nzAllowClear>
            <nz-option nzValue="new" nzLabel="Новый"></nz-option>
            <nz-option nzValue="inWork" nzLabel="В работе"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label>Время регистрации с</nz-form-label>
        <nz-form-control>
          <nz-date-picker
            [nzDisabledDate]="disabledStartDate"
            nzFormat="dd.MM.yyyy HH:mm:ss"
            formControlName="timeCreateFrom"
            nzPlaceHolder="с"
            nzShowTime
            nzSize="large"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label>Время регистрации по</nz-form-label>
        <nz-form-control>
          <nz-date-picker
            [nzDisabledDate]="disabledEndDate"
            nzFormat="dd.MM.yyyy HH:mm:ss"
            formControlName="timeCreateTo"
            nzPlaceHolder="по"
            nzShowTime
            nzSize="large"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
<div *nzModalFooter>
  <div nz-row>
    <div nz-col [nzSpan]="12" class="d-flex">
      <button nz-button nzType="default" class="m-r" (click)="clear()">Очистить</button>
    </div>
    <div nz-col [nzSpan]="12">
      <button nz-button nzType="default" class="m-r" (click)="close()">Отмена</button>
      <button nz-button nzType="primary" [disabled]="!form.valid" (click)="filtrate()">Фильтровать</button>
    </div>
  </div>
</div>
