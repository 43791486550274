import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { IPolygonDto } from '@bg-front/core/models/interfaces';
import { BgMapService } from '@bg-front/core/services';
import { IScrollableContainerComponent } from '@smart-city/core/common';
import { takeUntil } from 'rxjs/operators';

import { PolygonsService } from '../../services';

/**
 * Компонент для отображения информации о пользовательском полигоне
 * Реализует интерфейс IScrollableContainerComponent,
 * что дает возможность использовать его в динамическом
 * скроллируемом контейнере (ScrollableContainer)
 */
@Component({
  selector: 'bg-user-polygon-mini-card',
  templateUrl: './user-polygon-mini-card.component.html',
  styleUrls: ['./user-polygon-mini-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPolygonMiniCardComponent
  extends BaseComponent
  implements OnInit, OnDestroy, IScrollableContainerComponent
{
  /** Информация о полигоне */
  public userPolygon: IPolygonDto;

  /** Активная карточка */
  public isActive: boolean = false;

  /** Входной параметр для инициализации полей */
  @Input()
  public set data(userPolygon: IPolygonDto) {
    this.userPolygon = userPolygon;
    this.cdr.detectChanges();
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly gisService: BgMapService,
    private readonly polygonsService: PolygonsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    /** Подписка на выбор карточки пользовательского полигона в реестре */
    this.polygonsService.userPolygonSelectItem$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userPolygonId: string) => {
        this.isActive = userPolygonId === this.userPolygon.id;

        if (this.isActive) {
          this.router.navigate([{ outlets: { leftPopup: ['userPolygon', this.userPolygon.id] } }], {
            relativeTo: this.route.parent,
            queryParamsHandling: 'merge',
          });
        }
      });
  }

  /**
   * Обработчик, вызываемый при клике на кнопке показать на карте.
   * @param event - информация о событии
   */
  public showOnMap(event: Event): void {
    event.preventDefault();
    this.polygonsService
      .getById(this.userPolygon.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((polygon: IPolygonDto): void => {
        const polygonCenter: string = this.gisService.getCenter(polygon.coordinates);
        this.gisService.drawPolygon(polygon.coordinates);
        this.gisService.setPositionMapOnCoordinates(polygonCenter);
      });
  }

  /** Обработчик, вызываемый при клике на карточке полигона */
  public onClickUserPolygon($event): void {
    if (!$event.defaultPrevented) {
      this.polygonsService.userPolygonObject(this.isActive ? undefined : this.userPolygon.id);
    }
  }
}
