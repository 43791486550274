import { Injectable } from '@angular/core';
import { RestService } from '@smart-city/core/services';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IEmergencyDto } from 'smart-city-types';
import { IBgRegimeDto } from '../../../bg/modules/consolidated-registries/modules/regimes/models/bg-regime-dto.interface';
import { MultiFileService } from '@bg-front/core/services';

/**
 * Сервис для взаимодействия с Режимами функционирования
 */
@Injectable({
  providedIn: 'root',
})
export class RegimesService {
  constructor(private readonly rest: RestService, private readonly multiFileService: MultiFileService) {}

  /**
   * Позволяет следить за выбором одной из карточек реестра режимов функционирования
   */
  private regimeSelectItem = new Subject<string>();
  public regimeSelectItem$ = this.regimeSelectItem.asObservable();

  /** Запрос списка Режимов функционирования */
  public getAllRegimes(): Observable<IBgRegimeDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Regimes',
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items as IBgRegimeDto[];
        }),
      );
  }

  /** Запрос Режима функционирования по Id */
  public getRegimeById(id: string): Observable<IBgRegimeDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Regimes',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || [])[0] as IBgRegimeDto;
        }),
        switchMap((regime: IBgRegimeDto) => {
          if (!regime) {
            return of(undefined);
          }
          return forkJoin([
            this.multiFileService.getFilesFromSfs(regime.introducingReason),
            this.multiFileService.getFilesFromSfs(regime.withdrawalReason),
          ]).pipe(
            map((files) => {
              regime.introducingReason = files[0];
              regime.withdrawalReason = files[1];
              return regime;
            }),
          );
        }),
      );
  }

  /** Запрос Режимов функционирования для реестра */
  public getRegimeForRegistryPanel(): Observable<IBgRegimeDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Regimes',
          attributes: [
            'id',
            'introducingTime',
            'type.name',
            'description',
            'introducingReason',
            'municipalityIds',
            'districtIds',
          ],
          sort: {
            field: 'introducingTime',
            direction: 'desc',
          },
          query: {
            'state.sysname': 'introduced',
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items as IBgRegimeDto[];
        }),
      );
  }

  public getRegimeForInformationCard(id: string): Observable<IBgRegimeDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Regimes',
          attributes: [
            'type.name',
            'introducingTime',
            'introducingReason',
            'description',
            'municipalityIds',
            'districtIds',
            'responseLevelsId',
            'objectLevelTypeId',
            'comment',
            'organizationId',
            'significantObjectId',
            'forestryFacilitiesId',
          ],
          query: { id },
        },
      })
      .pipe(map((regime: IAbstractServiceData) => (regime.data?.items || [])[0]));
  }

  /** Запрос связанных инцидентов по режиму функционирования */
  public getEmergenciesByRegimeId(id: string): Observable<IEmergencyDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          attributes: ['number', 'lifeCycleStepId.status.name', 'organization.name', 'timeCreate'],
          name: 'Emergency',
          query: {
            $expr: {
              $eq: ['$regimeId', id],
            },
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items as IEmergencyDto[];
        }),
      );
  }

  /**
   * Удаление записи
   */
  public deleteRegime(id: string): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: 'Emergency' },
      entity: {
        name: 'Regimes',
        query: {
          id,
        },
      },
    });
  }

  /**
   * Сохранение модели
   * @param model модель
   */
  public save(model: IBgRegimeDto): Observable<IBgRegimeDto> {
    return forkJoin([
      this.multiFileService.saveFilesToSfs(model.introducingReason),
      this.multiFileService.saveFilesToSfs(model.withdrawalReason),
    ]).pipe(
      switchMap((documents) => {
        let obs: Observable<IAbstractServiceData>;

        model.introducingReason = documents[0];
        model.withdrawalReason = documents[1];
        if (model.id) {
          obs = this.rest.serviceRequest({
            action: 'update',
            service: { name: 'Emergency' },
            entity: {
              name: 'Regimes',
              query: {
                id: model.id,
              },
            },
            data: model,
          });
        } else {
          obs = this.rest.serviceRequest({
            action: 'insert',
            service: { name: 'Emergency' },
            entity: {
              name: 'Regimes',
            },
            data: model,
          });
        }
        return obs;
      }),
      map((response: IAbstractServiceData) => {
        model.id = response?.data?.id;
        return model;
      }),
    );
  }

  /**
   * Сохранение новой модели
   * @param model модель
   */
  public insertRegime(model: IBgRegimeDto): Observable<IBgRegimeDto> {
    return forkJoin([
      this.multiFileService.saveFilesToSfs(model.introducingReason),
      this.multiFileService.saveFilesToSfs(model.withdrawalReason),
    ]).pipe(
      switchMap((documents) => {
        model.introducingReason = documents[0];
        model.withdrawalReason = documents[1];
        return this.rest.serviceRequest({
          action: 'insert',
          service: { name: 'Emergency' },
          entity: {
            name: 'Regimes',
          },
          data: model,
        });
      }),
      map((response: IAbstractServiceData) => {
        model.id = response?.data?.id;
        return model;
      }),
    );
  }

  /**
   * Сохранение модели
   * @param model модель
   */
  public updateRegime(model: IBgRegimeDto): Observable<IBgRegimeDto> {
    return forkJoin([
      this.multiFileService.saveFilesToSfs(model.introducingReason),
      this.multiFileService.saveFilesToSfs(model.withdrawalReason),
    ]).pipe(
      switchMap((documents) => {
        model.introducingReason = documents[0];
        model.withdrawalReason = documents[1];
        return this.rest.serviceRequest({
          action: 'update',
          service: { name: 'Emergency' },
          entity: {
            name: 'Regimes',
            query: {
              id: model.id,
            },
          },
          data: model,
        });
      }),
      map((response: IAbstractServiceData) => {
        model.id = response?.data?.id;
        return model;
      }),
    );
  }

  /**
   * Привязка инцидентов к режиму функционирования
   * @param incidentIds id инцидентов
   * @param regimeId id режима
   */
  public linkIncidentsToRegime(incidentIds: string[], regimeId: string): Observable<IAbstractServiceData[]> {
    return incidentIds?.length
      ? forkJoin(
          incidentIds.map((incidentId) => {
            return this.rest.serviceRequest({
              action: 'update',
              service: { name: 'Emergency' },
              entity: {
                name: 'Emergency',
                query: {
                  id: incidentId,
                },
              },
              data: { regimeId },
            });
          }),
        )
      : of([]);
  }

  /**
   * Отвязка инцидентов от режима функционирования
   * @param incidentIds id инцидентов
   * @param regimeId id режима
   */
  public unlinkIncidentsFromRegime(incidentIds: string[], regimeId: string): Observable<IAbstractServiceData[]> {
    return incidentIds?.length
      ? forkJoin(
          incidentIds.map((incidentId) => {
            return this.rest.serviceRequest({
              action: 'update',
              service: { name: 'Emergency' },
              entity: {
                name: 'Emergency',
                query: {
                  id: incidentId,
                },
              },
              data: { regimeId: null },
            });
          }),
        )
      : of([]);
  }

  /**
   * Кидаем событие, какой элемент выбрали в реестре режимов функционирования
   */
  public selectRegime(event: string): void {
    this.regimeSelectItem.next(event);
  }
}
