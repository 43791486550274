<form nz-form [formGroup]="form" nzLayout="vertical">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Наименование</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="id" nzSize="large" nzAllowClear nzShowSearch>
            <nz-option
              [nzValue]="device.value"
              [nzLabel]="device.text"
              *ngFor="let device of devices$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Тип видеокамеры</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="videoCameraSignId" nzSize="large" nzAllowClear nzShowSearch>
            <nz-option
              [nzValue]="videoCameraSign.value"
              [nzLabel]="videoCameraSign.text"
              *ngFor="let videoCameraSign of videoCameraSignIds"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Категория</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="categoryIds" nzSize="large" nzAllowClear nzMode="multiple" nzShowSearch>
            <nz-option
              [nzValue]="category.value"
              [nzLabel]="category.text"
              *ngFor="let category of categoryIds$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Без категории</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="isNoCategory" nzSize="large" nzAllowClear>
            <nz-option [nzValue]="true" nzLabel="Да"></nz-option>
            <nz-option [nzValue]="false" nzLabel="Нет"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Состояние</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="status" nzSize="large" nzAllowClear nzShowSearch>
            <nz-option
              [nzValue]="status.value"
              [nzLabel]="status.text"
              *ngFor="let status of statuses"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
<div *nzModalFooter>
  <div nz-row>
    <div nz-col [nzSpan]="12" class="d-flex">
      <button nz-button nzType="default" class="m-r" (click)="clear()">Очистить</button>
    </div>
    <div nz-col [nzSpan]="12">
      <button nz-button nzType="default" class="m-r" (click)="close()">Отмена</button>
      <button nz-button nzType="primary" [disabled]="!form.valid" (click)="filtrate()">Фильтровать</button>
    </div>
  </div>
</div>
