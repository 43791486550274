import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Coordinates } from '@bg-front/core/models/classes';
import { ICON_BIG_DOT_SVG, ICON_POINT_SVG } from '@bg-front/core/models/constants';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import { IMapLayer } from '@bg-front/core/models/interfaces';
import { BgMapService, LayersDataService } from '@bg-front/core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import {
  IMapBaseIcon,
  IMapBaseObjectEvent,
  IMapObjectIconOptions,
  IconShapeType,
  MapBaseCoordinatesType,
  MapBaseModel,
  MapBaseService,
  TMapBaseCoordinates,
} from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { IAdminMunicipalSchemaDto, IAnyObject } from 'smart-city-types';
import { IVehicle } from '../../../bg/modules/external-interactions/modules/vehicles/models/interfaces';
import { IRnisExternalMessage, IRnisResponse, IRnisState } from '../../models/interfaces';
import { RnisExternalService, RnisHelpersService, RnisService, RnisTelematicsService } from '../../services';

/**
 * Компонент отображения ТС на базовой карте.
 * Осуществляет подключение к РНИС (см. {@link RnisService}) и управляет отображением маркеров на карте.
 * В текущей реализации вызов компонента происходит из панели фильтров карты {@link BgMapExtFilterComponent}
 */
@UntilDestroy()
@Component({
  selector: 'bg-rnis-control-layer',
  template: '',
})
export class RnisControlLayerComponent implements OnInit, OnDestroy {
  /** Данные о слое "Транспортные средства" */
  @Input()
  public layer!: IMapLayer;

  /** Модель карты для инициализации */
  public mapModel: MapBaseModel;

  /** Опции иконки маркера */
  private markerIcon!: IMapObjectIconOptions;

  /** Текущий пользователь, авторизованный в системе */
  private get user() {
    return (this.settings || <any>{}).currentUser;
  }

  /** Флаг отображения маршрута в реальном времени */
  private showRealTimeTrack: boolean = false;

  /** Массив координат для построения маршрута реального времени */
  private coordinatesRealTimeTrack: TMapBaseCoordinates[] = [];

  /** Выбранное устройство АТТ */
  private attIdSelected: string | undefined = undefined;

  constructor(
    private readonly rnis: RnisService,
    private readonly rnisTelematics: RnisTelematicsService,
    private readonly rnisHelpers: RnisHelpersService,
    private readonly settings: Settings2Service,
    private readonly mapService: MapBaseService,
    private readonly layersDataService: LayersDataService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly rnisExternal: RnisExternalService,
    private readonly gisService: BgMapService,
  ) {}

  public ngOnInit(): void {
    this.mapModel = new MapBaseModel('baseMapWorkspace', this.mapService);
    /**
     * Подписка на сообщения от РНИС
     */
    this.rnis.onRnisMessage$.pipe(untilDestroyed(this)).subscribe((message: IRnisResponse) => {
      this.onRnisMessage(message);
    });

    this.markerIcon = {
      iconSVG: this.layer.icon as string,
      iconSize: 17,
      iconColor: this.layer.iconColor ?? '#ffffff',
      shapeType: ((this.layer.markerShape as IDictionaryInfo)?.name as IconShapeType) || 'Нет',
      shapeColor: this.layer.markerColor,
      shapeSize: 30,
      strokeWidth: 3,
      strokeColor: this.layer.borderColor,
      shapeLineRadius: null,
    };

    /**
     * Открытие WS соединения с РНИС и последующая загрузка предварительных данных.
     * В подписке следует вызов инициализирующий основную БЛ.
     */
    this.rnis
      .connect()
      .pipe(
        /** Данные слоя Транспортный средств */
        switchMap(() => this.rnisHelpers.getAttByType(this.user?.vehicleTypeIds)),
        /*
      // Оставить пока. Может понадобится при доработках
      switchMap(() => this.rnis.getDevice(this.user.id).pipe(
        switchMap((response: IRnisResponse) => {
          if (response.status) return of(response);
          // Если пользователя не существует в РНИС и он при этом админ,
          // завести его по-тихому.
          // В противном случае выплюнуть ошибку.
          if (response?.error === 'User not exists' && this.isAdmin()) {
            return this.rnis.addUser({ user: this.user.id, isAdmin: true })
            .pipe(
              switchMap((response: IRnisResponse) => {
                if (response.status) return this.rnis.getDevice(this.user.id);
                return throwError(new Error(response?.error ?? 'Rnis.addUser() ERROR: Ошибка добавления пользователя'));
              }),
            );
          }

          return throwError(new Error(response?.error ?? 'Rnis.getDevice() ERROR: Ошибка при получении устройств пользователя'));
        }),
      )),
      */
        tap((device: string[]) => {
          /** Получить текущее состояние доступных устройств */
          this.rnis.sendRequest({ command: 'current', user: this.user.id, filter: [...device] });
        }),
        catchError((err: Error) => {
          console.error(err.message);
          return of(null);
        }),
        untilDestroyed(this),
      )
      .subscribe((device: string[]) => {
        /**
         * Подписка на получение обновленных состояний от доступных устройств
         */
        this.rnis.sendRequest({ command: 'subscribe', user: this.user.id, filter: [...device] });
      });

    /**
     * Подписка на клик по маркеру ТС
     * Открытие формы просмотра ТС
     */
    this.mapService
      .getObservableSubjectEvent()
      .pipe(
        filter(
          (event: IMapBaseObjectEvent) => event.typeEvent === 'click' && event.layerId === 'Транспортные средства',
        ),
        switchMap((event: IMapBaseObjectEvent) => {
          const originalAtt = event.objectId as string;
          return this.rnisHelpers.getVehiclesByAtt(originalAtt);
        }),
        untilDestroyed(this),
      )
      .subscribe((vehicle: IVehicle) => {
        this.router.navigate(['vehicle', vehicle.id], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
      });

    /**
     * Подписка на сообщения от внешних компонент
     */
    this.rnisExternal.onMessage$
      .pipe(
        switchMap((message: IRnisExternalMessage) => {
          switch (message.action) {
            case 'onRealTimeTracking':
              this.onRealTimeTracking(message.data);
              break;
            case 'onIntervalTracking':
              this.onIntervalTracking(message.data);
              break;
            case 'onRemoveTracking':
              this.removeAllTrackingLayers();
              this.rnis.sendRequest({ command: 'unsubscribeRoute' });
              break;
            case 'onSetFilters': {
              const { filters = [] } = message.data;
              /** Преобразование типов операций в значения запроса */
              const queryOperations: IAnyObject[] = filters
                .map((filter: { property: string; value: string | string[]; operation: FilterOperationEnum }) => {
                  /**
                   * Проверка наличия доступности пользователю типов ТС
                   * TODO: По хорошему надо доработать компонент фильтров карты,
                   * чтобы не было выбора не доступных пользователю типов ТС
                   */
                  if (filter.property === 'typeId') {
                    filter.value = (filter.value as string[]).filter((item: string) =>
                      ((this.user?.vehicleTypeIds as string[]) || []).includes(item),
                    );
                    if (!filter.value.length) {
                      return null;
                    }
                  }

                  return this.rnisHelpers.createValueByOperation(filter.property, filter.value, filter.operation);
                })
                .filter((item: IAnyObject) => !!item);
              /** Итоговый объект запроса */
              const query: IAnyObject = queryOperations.length ? { $and: queryOperations } : undefined;

              return of(query).pipe(
                switchMap((query: IAnyObject) => {
                  if (!query) {
                    return this.rnisHelpers.getAttByType(this.user?.vehicleTypeIds);
                  }
                  return this.rnisHelpers.getAttByQuery(query);
                }),
                map((devices: string[]) => {
                  const device = devices.filter((item: string) => !!item);

                  if (device.length === 1) {
                    const naviObject = <IRnisState>this.rnisTelematics.getNaviObjects(device[0]);
                    const coordinates = new Coordinates(
                      naviObject.correctLatitude || naviObject.latitude,
                      naviObject.correctLongitude || naviObject.longitude,
                    );
                    if (coordinates.isValid()) {
                      this.gisService.setPositionMapOnCoordinates(coordinates.toString());
                    }
                  }

                  this.rnisTelematics.clearNaviObjects();
                  this.mapModel.removeLayer(this.layer.name);

                  this.rnis.sendRequest({ command: 'current', user: this.user.id, filter: [...device] });
                  return { message, device };
                }),
                map((data: { message: IRnisExternalMessage; device: string[] }) => {
                  this.rnis.sendRequest({ command: 'subscribe', user: this.user.id, filter: [...data.device] });
                  return data.message;
                }),
              );
            }
          }
          return of(message);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  /**
   * Обработчик сообщений от РНИС
   * @param message сообщение от РНИС {@link IRnisResponse}
   */
  private onRnisMessage(message: IRnisResponse): void {
    // Ловим ошибки построения маршрута, чтобы не удалился слой
    if (message.command === 'find' && message.error) {
      console.warn(`[Rnis] Ошибка построения маршрута:\n${message.error}`);
      return;
    }
    if (!message.status && message.error) {
      console.warn(`[Rnis] Ошибка: ${message.error}`);

      this.mapModel.removeLayer(this.layer.name);
      this.rnisTelematics.clearNaviObjects();
    }
    switch (message.command) {
      /**
       * Получение текущего состояния всх доступных устройств, с последующим отображением их на карте
       */
      case 'current': {
        const results = message?.result;
        /** Наполнение реестра первичными данными от устройств */
        this.rnisTelematics.clearNaviObjects();
        this.rnisTelematics.register(results?.states);
        const naviObjects = this.rnisTelematics.getNaviObjects();
        if (naviObjects) {
          this.renderTransportLayer(this.layer.name, Array.isArray(naviObjects) ? naviObjects : [naviObjects]);
        }
        break;
      }
      /**
       * Обновление маркеров на карте по каждому устройству, изменившему состояние
       */
      case 'subscribe': {
        const result: IRnisState = message?.result as IRnisState;
        /** Обновление реестра устройств с АТТ */
        this.rnisTelematics.register(result);
        /** Получение данных (координат) от устройств */
        const vehicle = this.rnisTelematics.findNaviObjects(result?.id);

        if (vehicle?.id) {
          this.mapModel.hideObject(this.layer.nameOnMap, vehicle.id);
          this.mapModel.removeObject(this.layer.nameOnMap, vehicle.id);
          this.renderTransportLayer(this.layer.name, vehicle);
        }
        break;
      }
      /** Построение маршрута в реальном времени */
      case 'subscribeRoute': {
        const routeData: IRnisResponse['result'] = message?.result as IAnyObject;

        if (this.showRealTimeTrack && routeData?.sid === this.attIdSelected) {
          this.renderRealTimeTrackLayer(routeData);
        }
        break;
      }
      case 'find': {
        const resultFind = message.result;
        this.renderIntervalTrackLayer(resultFind);
        break;
      }
      default:
        console.log('[Rnis] onRnisMessage$ =>', message);
    }
  }

  /**
   * Обработка запроса на построение маршрута в реальном времени
   * @param data объект данных сообщения от внешнего компонента
   */
  private onRealTimeTracking(data: IAnyObject): void {
    /**
     * Построение маршрута в реальном времени будет производиться в подписке 'subscribe' WS соединение с РНИС
     */
    this.removeAllTrackingLayers();

    /** Выход, если АТТ не определено */
    this.attIdSelected = (data?.vehicle as IVehicle)?.originalAtt;
    if (!this.attIdSelected) return;

    /** Подписка на данные для построения маршрута */
    this.rnis.sendRequest({
      command: 'subscribeRoute',
      user: this.user.id,
      device: data?.vehicle?.originalAtt,
    });

    this.showRealTimeTrack = true;
  }

  /**
   * Обработка запроса на построение маршрута по временному интервалу
   * @param data объект данных сообщения от внешнего компонента
   */
  private onIntervalTracking(data: IAnyObject): void {
    /**
     * Построение маршрута по интервалу будет производиться в подписке 'find' WS соединение с РНИС
     */
    const utcOffset: number = dayjs().utcOffset();
    this.removeAllTrackingLayers();

    /** Выход, если АТТ не определено */
    this.attIdSelected = (data?.vehicle as IVehicle)?.originalAtt;
    if (!this.attIdSelected) return;

    /** Выход, если интервал не определен */
    const { dateTimeTo, dateTimeFrom } = data;
    if (!(dateTimeTo && dateTimeFrom)) return;

    this.rnis.sendRequest({
      command: 'find',
      user: this.user.id,
      naviTimeStart: dayjs(dateTimeFrom).add(-utcOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
      naviTimeEnd: dayjs(dateTimeTo).add(-utcOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
      filter: [this.attIdSelected],
    });
  }

  /**
   * Отрисовка маршрута ТС за период
   * @param resultFind IRnisState
   * @private
   */
  private renderIntervalTrackLayer(resultFind): void {
    /** Общая дистанция */
    let totalDistance: number = 0;
    const matches = resultFind?.matches ?? {};
    const deviceId: string | undefined = Object.keys(matches).find((attId: string) =>
      attId?.includes(this.attIdSelected!),
    );

    if (!deviceId || !matches[deviceId]) {
      console.error('deviceId not find');
      return;
    }

    /** Данные по маршруту по идентификатору АТТ */
    const findData = matches[deviceId]!;
    /** Участки маршрута */
    const flights = findData.flights ?? [];
    const firstFlight: IAnyObject = { totalDistance: 0 };
    const lastFlight: IAnyObject = { totalDistance: 0 };
    const fitBoundsCoordinates: MapBaseCoordinatesType[] = [];
    const addMarker = (
      coords: MapBaseCoordinatesType,
      iconType: 'bigDotStart' | 'bigDotEnd' | 'bigDot',
      index: number,
    ): void => {
      let title = '';
      let text = '';

      switch (iconType) {
        case 'bigDotStart':
          title = 'Начало маршрута';
          text = `<p>Протяженность маршрута: ${Math.round(totalDistance / 1000)} км.</p>`;
          break;
        case 'bigDotEnd':
          title = 'Окончание маршрута';
          text = `<p>Протяженность маршрута: ${Math.round(totalDistance / 1000)} км.</p>`;
          break;
        case 'bigDot':
          title = 'Остановка на маршруте';
          break;
      }
      this.mapModel.addMarkerToLayer(
        'tracking-on-interval',
        `${iconType}-${index}`,
        coords as MapBaseCoordinatesType,
        this.getSpecificIcon(iconType),
        undefined,
        {
          text: `<h4>${title}</h4>${text}`,
          autoClose: true,
          closeButton: true,
        },
        undefined,
      );
    };
    const markers: MapBaseCoordinatesType[] = [];

    if (flights.length) {
      // Количество участков маршрута
      const flightsLength: number = flights.length;
      flights.forEach((flight, indexFlights: number) => {
        // Координаты для каждого участка маршрута
        const flightCoords: Coordinates[] = flight.coordinates.map(
          (item: [number, number]) => new Coordinates(item[0], item[1]),
        );
        totalDistance = Math.round(totalDistance + flight.totalDistance);
        fitBoundsCoordinates.push(
          ...(flightCoords
            .map((coordinates: Coordinates) => coordinates.toArray())
            .filter((item) => !!item) as MapBaseCoordinatesType[]),
        );
        // Добавление участка на слой карты
        this.mapModel.addPolylineToLayer(
          'tracking-on-interval',
          `polyline_${indexFlights}`,
          flightCoords.map((coordinates: Coordinates) => coordinates.toArray()) as MapBaseCoordinatesType[],
          {
            color: flight.averageSpeed <= 10 ? this.getColorShape(10) : this.getColorShape(flight.averageSpeed),
            weight: 4,
            smoothFactor: 0.2,
            className: 'tracking-polyline',
          },
          undefined,
          {
            sticky: true,
            text: `Дистанция: ${Math.round(flight.totalDistance / 1000)} км. Скорость: ${Math.round(
              flight.averageSpeed,
            )} км/ч.`,
            // text: `Протяженность отрезка маршрута: ${Math.round(flight.totalDistance / 1000)} км.`,
          },
        );

        // Точка начала пути
        if (indexFlights === 0 && flightCoords[0]) {
          firstFlight.totalDistance = flight.totalDistance;
          firstFlight.coords = flightCoords[0].toArray();
        }
        // Точка окончания пути
        if (flightsLength === indexFlights + 1) {
          const lastIndex = flightCoords.length - 1;
          lastFlight.totalDistance = flight.totalDistance;
          if (flightCoords[lastIndex]) {
            lastFlight.coords = flightCoords[lastIndex]!.toArray();
          }
        }
        // Точки остановки
        if (flightsLength !== indexFlights + 1) {
          const lastIndex = flightCoords.length - 1;
          const coords = flightCoords[lastIndex]!.toArray();
          markers.push(coords!);
        }
      });
    }
    // вписываем геометрию всей дистанции в карту
    // TODO: не отработал, надо разбираться
    // this.mapModel.lMap.fitBounds(fitBoundsCoordinates);

    if (firstFlight.coords) markers.unshift(firstFlight.coords);
    if (lastFlight.coords) markers.push(lastFlight.coords);

    markers.forEach((markerCoords: MapBaseCoordinatesType, index: number) => {
      if (index === 0) {
        addMarker(markerCoords, 'bigDotStart', index);
      } else if (index === markers.length - 1) {
        addMarker(markerCoords, 'bigDotEnd', index);
      } else {
        addMarker(markerCoords, 'bigDot', index);
      }
    });

    this.mapModel.viewLayer('tracking-on-interval', false);
  }

  /**
   * Отрисовка слоя "Маршрута ТС в реальном времени" на карте
   * @param data - данные маршрута наблюдаемого ТС
   */
  private renderRealTimeTrackLayer(data: IRnisResponse['result']): void {
    if (!data) return;

    const layerId = 'tracking-on-realtime';
    this.mapModel.removeLayer(layerId);

    const naviObject = this.rnisTelematics.findNaviObjects(data.sid);

    if (data.route?.length) {
      this.coordinatesRealTimeTrack.push(...data.route);
    }

    this.mapModel.addPolylineToLayer(
      layerId,
      'polyline_realTime',
      this.coordinatesRealTimeTrack,
      {
        color: naviObject.speed <= 10 ? this.getColorShape(10) : this.getColorShape(naviObject.speed),
        weight: 4,
        smoothFactor: 0.2,
      },
      undefined,
      undefined,
    );
    this.mapModel.viewLayer(layerId, false);
  }

  /**
   * Отрисовка слоя "Транспорт" на карте
   * @param layerName имя слоя в котором показывать транспорт
   * @param data массив данных наблюдаемых ТС
   */
  private renderTransportLayer(layerName: string, data: IRnisState | IRnisState[]): void {
    if (!data) return;
    /** Параметры иконки */
    const icon = this.markerIcon;
    /** Добавление маркера на слой */
    const addMarker = (layerName: string, naviState: IRnisState, icon: IMapObjectIconOptions) => {
      icon.shapeColor = this.getColorShape(naviState.speed || 0);
      this.mapModel.addMarkerToLayer(
        layerName,
        naviState.id,
        [naviState.correctLatitude, naviState.correctLongitude],
        this.mapService.makeIcon(icon),
        undefined,
        undefined,
        undefined,
      );
    };

    if (Array.isArray(data)) {
      data.forEach((naviState: IRnisState) => addMarker(layerName, naviState, icon));
    } else {
      addMarker(layerName, data, icon);
    }
    /** Отрисовка обновленного слоя */
    this.mapModel.viewLayer(layerName, false);
  }

  /**
   * Метод проверки пользователя на права администратора
   * @return флаг является ли пользователь администратором
   */
  private isAdmin(): boolean {
    const moId = this.user.organizationId?.mo ?? this.settings.currentUser.mo?.id;
    const userMo = this.settings.allMo.find((mo: IAdminMunicipalSchemaDto) => {
      return mo.id === moId;
    });

    if (!userMo) {
      return false;
    }
    return !userMo.municipal;
  }

  /**
   * Цвет иконки в зависимости от скорости
   * @param speed скорость автомобиля
   * @returns цвет строкой
   */
  private getColorShape(speed: number): string {
    if (!speed) return '#5c5c5c';
    if (speed >= 60) return '#da1e28';
    if (speed >= 40) return '#2960ff';
    if (speed > 10) return '#4777ff';
    if (speed <= 10) return '#668eff';
    return '#5c5c5c';
  }

  /** Удалить все слои отображаемых маршрутов */
  private removeAllTrackingLayers(): void {
    this.attIdSelected = undefined;
    this.showRealTimeTrack = false;
    this.coordinatesRealTimeTrack = [];
    this.mapModel.removeLayer('tracking-on-interval');
    this.mapModel.removeLayer('tracking-on-realtime');
  }

  /**
   * Получить иконку по пресету
   * @param preset название пресета
   * @param data данные объекта
   */
  private getSpecificIcon(preset: string, data?: IAnyObject): IMapBaseIcon | undefined {
    const iconSettingsDefault: IMapObjectIconOptions = {
      iconSize: 17,
      iconColor: '#ffffff',
      shapeType: 'Прямоугольник',
      shapeSize: 30,
      shapeColor: '#2960ff',
    };
    let iconSettings: IAnyObject = {};
    switch (preset) {
      case 'bigDotStart':
        iconSettings = {
          iconSVG: ICON_POINT_SVG,
          iconSize: 0,
          iconColor: '#ffffff',
          shapeType: 'Круг',
          shapeColor: '#2960ff',
          shapeSize: 20,
        };
        break;
      case 'bigDotEnd':
        iconSettings = {
          iconSVG: ICON_BIG_DOT_SVG,
          iconSize: 0,
          iconColor: '#ffffff',
          shapeType: 'Круг',
          shapeColor: '#2960ff',
          shapeSize: 20,
        };
        break;
      case 'bigDot':
        iconSettings = {
          iconSVG: ICON_BIG_DOT_SVG,
          iconSize: 0,
          iconColor: '#ffffff',
          shapeType: 'Круг',
          shapeColor: '#2960ff',
          shapeSize: 20,
        };
        break;
    }
    return this.mapService.makeIcon({ ...iconSettingsDefault, ...iconSettings });
  }

  /**
   * Метод срабатывает при смерти компонента.
   * Очищает данные и подписки за собой.
   */
  public ngOnDestroy(): void {
    setTimeout(() => {
      this.mapModel?.removeLayer(this.layer.name);
      this.rnis?.close();
      this.rnisTelematics?.clearNaviObjects();
      this.removeAllTrackingLayers();
    }, 0);
  }
}
