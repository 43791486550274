import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import { Uuid } from '@smart-city/core/utils';

import { Coordinates } from '@bg-front/core/models/classes';
import {
  IMapBaseAddObjectOptions,
  IMapBaseEvent,
  IMapBaseIcon,
  IMapBaseMarkerOptions,
  IMapBasePolylineOptions,
} from '@bg-front/map/models/interfaces';
import { MapBaseCoordinatesType } from '@bg-front/map/models/types';
import { PATH_POINT_MARKER_SVG } from '@bg-front/map/models/constants';
import { MarkerIconFactory } from '@bg-front/map/models/classes';
import { MapBaseService } from '@bg-front/map/services';
import { IControlEvent, IGraphHopperPathDto } from '../../models/interfaces';
import { BuildRouteService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'bg-building-route-control',
  templateUrl: './building-route-control.component.html',
  styleUrls: ['./building-route-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingRouteControlComponent implements OnInit, OnChanges {
  /** Состояние кнопки */
  public active: boolean = false;
  @Input() public name: string;
  /** Блокирование кнопки */
  @Input() disabled: boolean = false;
  /** Имя карты для работы */
  @Input() mapId: string;
  /** Информируем о текущем состоянии */
  @Output() changeActive: EventEmitter<IControlEvent> = new EventEmitter<IControlEvent>(true);

  /** Имя слоя */
  private layerName: string = 'building-route';

  constructor(
    private readonly mapService: MapBaseService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly buildRoute: BuildRouteService,
  ) {}

  public ngOnInit(): void {
    this.router.navigate([{ outlets: { leftPopup: null } }], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });

    this.changeActive.emit({
      name: this.name,
      active: false,
    });

    /** Иконка для маркера точки пути */
    const icon = MarkerIconFactory.makeIcon({
      iconSize: 8,
      iconColor: 'black',
      iconSVG: PATH_POINT_MARKER_SVG,
      shapeType: 'Круг',
      shapeSize: 10,
      shapeColor: 'black',
    });

    /** Иконка для обозначения крайних точек маршрута */
    const pathIcon: IMapBaseIcon = {
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      popupAnchor: [0, -41],
      tooltipAnchor: [0, -41],
      iconUrl: '/assets/icons/marker-icon.png',
      shadowUrl: '/assets/icons/marker-shadow.png',
    };

    /** Инициализируем модель карты для отображения результатов прогнозирования */
    this.mapService
      .getObservableMapEvents(this.mapId, 'click')
      .pipe(
        filter(() => this.active === true),
        untilDestroyed(this),
      )
      .subscribe((event: IMapBaseEvent) => {
        this.buildRoute.addRoutePoint(event.coordinates.join(', '));
        this.mapService.addMarker(this.mapId, <IMapBaseAddObjectOptions<IMapBaseMarkerOptions>>{
          layerId: this.layerName,
          objectId: `${this.layerName}_${Uuid.newUuid()}`,
          coordinates: event.coordinates,
          objectOptions: {
            icon,
          },
        });
      });

    this.buildRoute.updateRoutePointsEvent$.pipe(untilDestroyed(this)).subscribe((points: string[]) => {
      this.mapService.removeLayer(this.mapId, this.layerName);
      points.forEach((point: string) => {
        this.mapService.addMarker(this.mapId, <IMapBaseAddObjectOptions<IMapBaseMarkerOptions>>{
          layerId: this.layerName,
          objectId: `${this.layerName}_${Uuid.newUuid()}`,
          coordinates: new Coordinates(point).toArray(),
          objectOptions: {
            icon,
          },
        });
      });
    });

    this.buildRoute.createRouteEvent$
      .pipe(untilDestroyed(this))
      .subscribe((paths: IGraphHopperPathDto[]) => {
        this.mapService.removeLayer(this.mapId, this.layerName);
        paths.forEach((el: IGraphHopperPathDto) => {
          this.mapService.addMarker(this.mapId, <IMapBaseAddObjectOptions<IMapBaseMarkerOptions>>{
            layerId: this.layerName,
            objectId: 'start',
            coordinates: [el.points.coordinates[0][1], el.points.coordinates[0][0]],
            objectOptions: {
              icon: pathIcon,
            },
          });

          this.mapService.addPolyline(this.mapId, <IMapBaseAddObjectOptions<IMapBasePolylineOptions>>{
            layerId: this.layerName,
            objectId: 'building-route_path_1',
            coordinates: el.points.coordinates.map((coord: [number, number]) => [coord[1], coord[0]]),
            objectOptions: {
              color: '#3dd598',
              opacity: 1,
              weight: 5,
            },
          });

          const endCoordinate = <MapBaseCoordinatesType>[
            el.points.coordinates[el.points.coordinates.length - 1][1],
            el.points.coordinates[el.points.coordinates.length - 1][0],
          ];

          this.mapService.addMarker(this.mapId, <IMapBaseAddObjectOptions<IMapBaseMarkerOptions>>{
            layerId: this.layerName,
            objectId: 'end',
            coordinates: endCoordinate,
            tooltip: {
              text: el.distance > 1000 ? `${(el.distance / 1000).toFixed(2)} км.` : `${el.distance.toFixed(0)} м.`,
              permanent: true,
              className: 'end-tooltip',
            },
            objectOptions: {
              icon: pathIcon,
            },
          });
        });
    });
  }

  public ngOnChanges(): void {
    if (this.disabled) {
      this.active = false;
      this.mapService.removeLayer(this.mapId, this.layerName);
    }
  }

  /** Открываем маршрут построения маршрута */
  public openMapBuildingPath(): void {
    if (!this.disabled) {
      this.active = !this.active;

      if (this.active) {
        this.router.navigate([{ outlets: { leftPopup: ['building-route'] } }], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigate([{ outlets: { leftPopup: null } }], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        this.mapService.removeLayer(this.mapId, this.layerName);
      }

      this.changeActive.emit({
        name: this.name,
        active: this.active,
      });
    }
  }

  /** Закрытие формы построения маршрута */
  public close(): void {
    if (this.active) {
      this.openMapBuildingPath();
    }
  }
}
