import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { MapLayersState } from '../../../models/states';
import { MapLayersStore } from '../store/map-layers.store';

@Injectable()
export class MapLayersQuery extends QueryEntity<MapLayersState> {
  constructor(protected override store: MapLayersStore) {
    super(store);
  }
}
