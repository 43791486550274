import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import { IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { LayersDataService, OperationsService } from '@bg-front/core/services';
import { IMapBaseObjectBaseEvent, IMapBaseObjectSelectEvent } from '@bg-front/map/models/interfaces';
import { MapBaseService } from '@bg-front/map/services';
import { ControlsOf, ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import { NzModalService } from 'ng-zorro-antd/modal';

import { IOrganizationMapFilterForm } from '../../models/interfaces';
import { BaseLayerControlComponent } from '../base-layer-control/base-layer-control.component';
import { MapOrganizationFilterComponent } from '../map-organization-filter/map-organization-filter.component';
import { MapControlsService } from '../../services';

/** Слой для Экстренные оперативные службы */
@UntilDestroy()
@Component({
  selector: 'bg-eos-layer-control',
  templateUrl: './eos-layer-control.component.html',
  styleUrls: ['./eos-layer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EosLayerControlComponent extends BaseLayerControlComponent implements OnInit {
  public override mapLayerFilters: ValuesOf<ControlsOf<IOrganizationMapFilterForm>> = this.accessService.accessMap[
    'viewAllMunicipals'
  ]?.visible
    ? {}
    : { moId: this.settings.currentUser.organizationId.mo };
  constructor(
    mapService: MapBaseService,
    cdr: ChangeDetectorRef,
    gisService: LayersDataService,
    settings: Settings2Service,
    router: Router,
    route: ActivatedRoute,
    operationsService: OperationsService,
    mapControlsService: MapControlsService,
    private readonly accessService: AccessService,
    private readonly modalService: NzModalService,
  ) {
    super(mapService, cdr, gisService, settings, router, route, operationsService, mapControlsService);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    /** Подписка на Клик по объекту */
    this.mapService
      .getObservableObjectEvent<IMapBaseObjectSelectEvent>(this.mapId, 'selectObject', this.layer.nameOnMap)
      .pipe(untilDestroyed(this))
      .subscribe((event: IMapBaseObjectSelectEvent) => {
        if (event.selected) {
          this.showObjectInfo(event);
        }
      });
  }

  /** Обработка нажатия и вызов соответствующей навигации */
  private showObjectInfo(event: IMapBaseObjectBaseEvent): void {
    this.router.navigate(['eos', event?.objectId], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }

  /** Открытие диалога фильтрации на карте */
  public override openFilterDialog(): void {
    this.modalService
      .create({
        nzTitle: 'Фильтрация объектов на карте',
        nzContent: MapOrganizationFilterComponent,
        nzComponentParams: {
          mapLayerFilters: this.mapLayerFilters,
        },
        nzFooter: null,
      })
      .afterClose.pipe(untilDestroyed(this))
      .subscribe((value: ValuesOf<ControlsOf<IOrganizationMapFilterForm>>): void => {
        if (value) {
          this.mapLayerFilters = value;
          this.getLayerData(this.mapLayerFilters, true, true);
        }
      });
  }

  public override getFilterQuery(
    value: ValuesOf<ControlsOf<IOrganizationMapFilterForm>> | undefined,
  ): IMapLayerEntityFilter[] {
    if (!value) {
      return [];
    }
    const result: IMapLayerEntityFilter[] = [];
    if (value.moId) {
      result.push({
        property: 'mo',
        value: value.moId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.orgTypeParamId) {
      result.push({
        property: 'orgTypeParam',
        value: value.orgTypeParamId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.organizationId || value?.ids) {
      if (value.organizationId) {
        result.push({
          property: 'id',
          value: value.organizationId,
          operation: FilterOperationEnum.equal,
        });
      } else {
        if (value.ids?.length) {
          result.push({
            property: 'id',
            value: value.ids,
            operation: FilterOperationEnum.in,
          });
        }
      }
    }
    // Запросить только данные по обновленным записям
    return result;
  }
}
