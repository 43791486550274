import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ISelectItem } from '@bg-front/core/models/interfaces';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { IAdminMunicipalSchemaDto } from 'smart-city-types';

import { IAqiMapFilterForm } from '../../models/interfaces';
import { MapControlsService } from '../../services';

@Component({
  selector: 'bg-map-aqi-filter',
  templateUrl: './map-aqi-filter.component.html',
  styleUrls: ['./map-aqi-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapAqiFilterComponent implements OnInit {
  /** Значения фильтров */
  public mapLayerFilters: ValuesOf<ControlsOf<IAqiMapFilterForm>>;
  /** Настройка компоненты Муниципальное образование */
  public municipals!: ISelectItem[];

  /** Объекты мониторинга АХОВ */
  public objects$: Observable<ISelectItem[]> = this.mapControlsService.getAQIObjects();

  /** Форма */
  public form: FormGroup<ControlsOf<IAqiMapFilterForm>>;

  /** Просмотр всех МО */
  private viewAllMunicipals!: boolean;

  constructor(
    private readonly accessService: AccessService,
    private readonly settings: Settings2Service,
    private readonly modalRef: NzModalRef,
    private readonly mapControlsService: MapControlsService,
  ) {
  }

  public ngOnInit(): void {
    this.viewAllMunicipals = this.accessService.accessMap['viewAllMunicipals']?.visible;
    this.form = new FormGroup<ControlsOf<IAqiMapFilterForm>>({
      moId: new FormControl({
        value: this.viewAllMunicipals ? this.mapLayerFilters?.moId : this.settings.currentUser.organizationId.mo,
        disabled: !this.viewAllMunicipals,
      }),
      id: new FormControl(this.mapLayerFilters?.id),
    });
    this.municipals = this.settings.allMo
      .map((item: IAdminMunicipalSchemaDto) => {
        return <ISelectItem>{
          text: item.name,
          value: item.id,
        };
      })
      .filter((item: ISelectItem) => this.viewAllMunicipals
        || item.value === this.settings.currentUser.organizationId.mo);
  }

  /** Очистка формы */
  public clear(): void {
    this.form.reset();
    if (!this.viewAllMunicipals) {
      this.form.controls.moId.patchValue(this.settings.currentUser.organizationId.mo);
    }
  }

  /** Закрытие формы */
  public close(): void {
    this.modalRef.destroy();
  }

  /** Фильтрация */
  public filtrate(): void {
    this.modalRef.destroy(this.form.getRawValue());
  }
}
