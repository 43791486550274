import { Injectable } from '@angular/core';
import { Coordinates } from '@bg-front/core/models/classes';
import { ILimit, ISort, RestService } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, ITreeSpeciesDto } from 'smart-city-types';

import { IForestryFacilityDto } from '../../models/interfaces';
import { ISelectItem } from '@bg-front/core/models/interfaces';

/**
 * Сервис для работы со справочником Лесохозяйственных объектов
 */
@Injectable({ providedIn: 'root' })
export class ForestryFacilitiesService {
  /** Позволяет следить за выбором карточки в оперативном реестре лесохозяйственных объектов */
  private forestryFacilitiesSelectItem = new Subject<string>();
  public forestryFacilitiesSelectItem$ = this.forestryFacilitiesSelectItem.asObservable();

  /** @ignore */
  constructor(private readonly rest: RestService) {}

  /**
   * Получение значений справочника видов древесных пород
   */
  public getTreeSpecies(): Observable<ITreeSpeciesDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: { name: 'TreeSpecies' },
      })
      .pipe(map((treeSpecies: IAbstractServiceData) => treeSpecies.data.items));
  }

  /** Получение Лесохозяйственного объекта */
  public getById(id: string): Observable<IForestryFacilityDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: { name: 'ForestryFacilities', query: { id } },
      })
      .pipe(
        map((data: IAbstractServiceData) => {
          return data?.data?.items[0];
        }),
      );
  }

  /** Сохранение Лесохозяйственного объекта */
  public save(model: IForestryFacilityDto): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'upsert',
      service: { name: 'Forecasting' },
      entity: {
        name: 'ForestryFacilities',
        query: { id: model.id || Uuid.newUuid() },
      },
      data: model,
    });
  }

  /** Удаление Лесохозяйственного объекта */
  public delete(id: string): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: 'Forecasting' },
      entity: { name: 'ForestryFacilities', query: { id } },
    });
  }

  /**
   * Получить лесохозяйственные объекты с требуемыми полями.
   * @param query фильтр для запроса
   * @param sort сортировка записей запроса
   * @param limit ограничения запрашиваемых данных
   */
  public getForestryFacilities(query?: IAnyObject, limit?: ILimit, sort?: ISort): Observable<IForestryFacilityDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: {
          query,
          name: 'ForestryFacilities',
          attributes: [
            'name',
            'forestTypeId.id',
            'forestTypeId.name',
            'treeSpeciesId',
            'flammabilityClass',
            'trunkDiameterId',
            'stateId.name',
            'area',
            'responsible',
            'phone',
            'polygonId.coordinates',
            'projectedRiskIds',
          ],
        },
        data: {
          limit,
          sort,
        },
      })
      .pipe(
        map(({ data: { items: forestryFacilities } }) =>
          forestryFacilities.map(
            (forestryFacility: IAnyObject) =>
              <IForestryFacilityDto>{
                id: forestryFacility.id,
                name: forestryFacility.name,
                forestTypeId: forestryFacility.forestTypeId,
                treeSpeciesId: forestryFacility.treeSpeciesId,
                flammabilityClass: forestryFacility.flammabilityClass,
                trunkDiameterId: forestryFacility.trunkDiameterId,
                stateId: forestryFacility.stateId,
                area: forestryFacility.area,
                responsible: forestryFacility.responsible,
                phone: forestryFacility.phone,
                polygonId: {
                  coordinates: forestryFacility.polygonId?.coordinates.map((item: string) => new Coordinates(item)),
                },
                projectedRiskIds: forestryFacility.projectedRiskIds,
              },
          ),
        ),
      );
  }

  /**
   * Кидаем событие, какой элемент выбрали в в оперативном реестре лесохозяйственных объектов
   */
  public forestryFacilitiesObject(event: string) {
    this.forestryFacilitiesSelectItem.next(event);
  }

  /**
   * Поиск ЛХО, в полигоне которого находятся координаты инцидента
   * @param id id инцидента
   */
  // TODO заменить на метод поиска полигона из платформы, когда тот будет готов
  public getForestryFacilityForEmergency(id: string): Observable<IForestryFacilityDto> {
    const forestryFacilities: IForestryFacilityDto[] = [];
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: {
          name: 'ForestryFacilities',
          attributes: ['id', 'name', 'polygonId.coordinates', 'forestTypeId', 'flammabilityClass', 'trunkDiameterId'],
        },
      })
      .pipe(
        map((data: IAbstractServiceData) => data?.data?.items),
        switchMap((res: IForestryFacilityDto[]) => {
          // сохраняем все ЛХО. Из этого массива потом будем возвращать результат
          forestryFacilities.push(...res);

          /* ищем инцидент, который входит в полигон */
          const req = [];
          res.forEach((el: IForestryFacilityDto) => {
            if (el['polygonId.coordinates']) {
              const coordinates = el['polygonId.coordinates'];
              coordinates.push(coordinates[0]);
              req.push(
                this.rest.serviceRequest({
                  action: 'select',
                  service: { name: 'Emergency' },
                  entity: {
                    name: 'Emergency',
                    query: {
                      id,
                      point: {
                        $polygon: {
                          $points: [...coordinates.map((coord) => new Coordinates(coord).toArray())],
                        },
                      },
                    },
                  },
                }),
              );
            } else {
              req.push(of(null));
            }
          });
          return forkJoin([...req]);
        }),
        switchMap((res: any[]) => {
          for (let i = 0; i < res.length; i++) {
            if (res[i]?.data?.items[0]) {
              return of(forestryFacilities[i]);
            }
          }
          return of({ name: 'Лесохозяйственный объект не найден' });
        }),
      );
  }

  /**
   * Получить лесохозяйственные объекты с требуемыми полями.
   * @param query фильтр для запроса
   */
  public getForestryFacilitiesForForecasting(query?: IAnyObject): Observable<ISelectItem[]> {
    return this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: 'Forecasting' },
          entity: {
            query,
            name: 'ForestryFacilities',
            attributes: ['id', 'name'],
          },
          data: {
            sort: <ISort>{
              direction: 'asc',
              field: 'name',
            },
          },
        },
        'http',
      )
      .pipe(
        map((response: IAbstractServiceData) => {
          return ((response.data.items as []) || []).map((el: IAnyObject) => {
            return <ISelectItem>{
              text: el.name,
              value: el.id,
            };
          });
        }),
      );
  }

  /**
   * Получить ЛХО, в полигон которого попадают указанные координаты
   * @param pointCoordinates - точка координат для поиска вхождения в полигон ЛХО
   **/
  public getForestryFacilityByCoordinates(pointCoordinates: [number, number]): Observable<IForestryFacilityDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: {
          name: 'ForestryFacilities',
          attributes: ['id', 'name', 'polygonId.coordinates'],
          query: {
            polygonId: {
              polygon: {
                $polygon: { $points: [pointCoordinates] },
              },
            },
          },
        },
      })
      .pipe(map((response: IAbstractServiceData) => response?.data?.items[0]));
  }
}
