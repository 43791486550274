<div *ngIf="showTitle" class="upload">
  <div class="bg-row space-between-container bg-row-align-content-center">
    <div class="bg-col">
      <div class="title">Загрузить новый файл</div>
    </div>
    <div class="bg-col-min">
      <div class="actions">
        <sc-button *ngIf="mode === 'edit'" #fileButton [options]="addButton" (onFilesChange)="onFileChange($event)">
        </sc-button>
      </div>
    </div>
  </div>
</div>
<div *ngFor="let item of activeFiles">
  <div class="file-item">
    <div
      *ngIf="!($any(item.file)).uuid || $any(item.file).isActive"
      class="bg-row space-between-container bg-row-align-content-center"
    >
      <div class="bg-col">
        <div class="title">
          {{ $any(item.file)?.fileName || $any(item.file)?.name }}
        </div>
        <div class="meta-info">
          {{ getMetaInfo($any(item).userId?.fio, $any(item).file?.createdAt, $any(item).file?.size) }}
        </div>
      </div>
      <div class="bg-col-min">
        <div class="actions">
          <sc-button
            *ngIf="$any(item.file).uuid || $any(item.file).id"
            [options]="downloadButton"
            (clickButton)="downloadFile(item)"
          ></sc-button>
          <sc-button [options]="deleteButton" (clickButton)="deleteFile(item)"></sc-button>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-row d-flex-vertical-center pb-20" *ngIf="showFileSigning">
    <div class="bg-col align-right">
      {{getSignatureStatus(item)}}
    </div>
    <div class="bg-col-min">
      <sc-button *ngIf="!item.signature?.id" [options]="signButton" (clickButton)="signFile(item)"></sc-button>
      <sc-button
        *ngIf="item.signature?.id"
        [options]="showSignatureButton"
        (clickButton)="showSignature(item)"
      ></sc-button>
    </div>
  </div>
</div>
