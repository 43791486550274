import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayersDataService, OperationsService } from '@bg-front/core/services';
import { IMapBaseObjectBaseEvent, IMapBaseObjectSelectEvent } from '@bg-front/map/models/interfaces';
import { MapBaseService } from '@bg-front/map/services';
import { untilDestroyed } from '@ngneat/until-destroy';
import { AccessService, Settings2Service } from '@smart-city/core/services';

import { BaseLayerControlComponent } from '../base-layer-control/base-layer-control.component';
import { MapControlsService } from '../../services';

@Component({
  selector: 'bg-kseon-layer-control',
  templateUrl: './kseon-layer-control.component.html',
  styleUrls: ['./kseon-layer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KseonLayerControlComponent extends BaseLayerControlComponent implements OnInit {
  public override mapLayerFilters = this.accessService.accessMap['viewAllMunicipals']?.visible
    ? {}
    : { moId: this.settings.currentUser.organizationId.mo };
  constructor(
    mapService: MapBaseService,
    cdr: ChangeDetectorRef,
    gisService: LayersDataService,
    settings: Settings2Service,
    router: Router,
    route: ActivatedRoute,
    operationsService: OperationsService,
    mapControlsService: MapControlsService,
    private readonly accessService: AccessService,
  ) {
    super(mapService, cdr, gisService, settings, router, route, operationsService, mapControlsService);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    /** Подписка на Клик по объекту */
    this.mapService
      .getObservableObjectEvent<IMapBaseObjectSelectEvent>(this.mapId, 'selectObject', this.layer.nameOnMap)
      .pipe(untilDestroyed(this))
      .subscribe((event: IMapBaseObjectSelectEvent) => {
        if (event.selected) {
          this.showObjectInfo(event);
        }
      });
  }

  /** Обработка нажатия и вызов соответствующей навигации */
  private showObjectInfo(event: IMapBaseObjectBaseEvent): void {
    this.router.navigate(['kseon', event?.objectId], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }
}
