import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { IPolygonDto } from '@bg-front/core/models/interfaces';
import { IMapBaseAddObjectOptions, IMapBasePolygonOptions } from '@bg-front/map/models/interfaces';
import { TMapBaseCoordinates } from '@bg-front/map/models/types';
import { MapBaseService } from '@bg-front/map/services';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { AccessService, IAccessAction, Settings2Service } from '@smart-city/core/services';
import { takeUntil } from 'rxjs/operators';
import { ISystemPolygon } from '../../models/interfaces';
import { PolygonsService } from '../../services';

/**
 * Компонента отвечающая за отображение списка доступных полигонов
 */
@Component({
  selector: 'bg-map-polygons-legend',
  templateUrl: './map-polygons-legend.component.html',
  styleUrls: ['./map-polygons-legend.component.scss'],
})
export class MapPolygonsLegendComponent extends BaseComponent implements OnInit {
  /** Флаг: показываем какой либо полигон или нет */
  public showPolygon: string = undefined;
  public systemPolygons: ISystemPolygon[] = this.settings.getDictionaryByTypeSysName('polygonTypes').map(
    (polygon: IDictionaryInfo): ISystemPolygon => ({
      name: polygon.name,
      type: polygon.sysname,
      selected: false,
    }),
  );

  /** Идентификатор карты */
  @Input()
  public mapId: string;

  /** Текущий зум */
  @Input()
  public zoom: number;

  /** Текущий центр */
  @Input()
  public center: TMapBaseCoordinates;

  private SHOW_LEGEND = false;
  /** Мышка на элементе или нет */
  private mouseOnCurrentElement = false;

  constructor(
    private readonly polygonsService: PolygonsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly accessService: AccessService,
    private readonly settings: Settings2Service,
    private readonly mapBaseService: MapBaseService,
  ) {
    super();
  }

  /** Показать легенду */
  public get showLegend(): boolean {
    return this.SHOW_LEGEND;
  }

  public set showLegend(value: boolean) {
    this.SHOW_LEGEND = value;
    /** Открываем список полигонов и если был выбран хоть один ранее, сбрасываем выбор */
    if (value) {
      if (this.showPolygon) {
        this.showPolygon = undefined;
        this.clearPolygons();
      }
    } else {
      this.drawPolygons();
    }
  }

  /** Доступность фильтра "Муниципальное образование" */
  public globalMoFilter: IAccessAction = { name: 'globalMoFilter' };

  /**
   * Следим, что мышка на элементе
   */
  @HostListener('mouseenter') onMouseenter() {
    this.mouseOnCurrentElement = true;
  }

  /**
   * Следим, что мышка покинула элемент
   */
  @HostListener('mouseleave') onMouseleave() {
    this.mouseOnCurrentElement = false;
  }

  /**
   * Обрабатываем событие, что кликнули другой элемент
   */
  @HostListener('document:click') clickedOutside() {
    if (!this.mouseOnCurrentElement && this.showLegend) {
      this.showLegend = false;
    }
  }

  public ngOnInit(): void {
    this.globalMoFilter = this.accessService.accessMap[this.globalMoFilter.name];
    /** скрываем выбор полигонов МО если клэйм выключен */
    if (!this.globalMoFilter?.visible) {
      this.systemPolygons = this.systemPolygons.filter((item) => item.type !== '01-municipalPolygons');
    }
  }

  /** Обработчик, вызываемый при клике на шеврон. Открыть легенду. */
  public onShowLegend(): void {
    this.showLegend = !this.showLegend;
  }

  /** Обработка факта выбора полигона */
  public changeRadioButtonHandler($event): void {
    this.showPolygon = $event.value;
  }

  /** Добавляем новый пользовательский полигон */
  public newPolygon(): void {
    this.router.navigate([{ outlets: { editForm: ['new-polygon'] } }], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      state: { zoom: this.zoom, center: this.center },
    });
  }

  /** Очистить полигоны */
  private clearPolygons() {
    if (this.mapId) {
      this.mapBaseService.removeLayer(this.mapId, 'polygon');
    }
  }

  /** Нарисовать полигоны */
  private drawPolygons() {
    if (this.mapId && this.showPolygon) {
      this.polygonsService
        .getPolygonsBySysname(this.showPolygon)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((polygons: IPolygonDto[]) => {
          polygons.forEach((polygon: IPolygonDto) => {
            this.mapBaseService.addPolygon(this.mapId, <IMapBaseAddObjectOptions<IMapBasePolygonOptions>>{
              layerId: 'polygon',
              objectId: `p-${polygon.id}`,
              coordinates: polygon.coordinates.map((coordinate: Coordinates) => coordinate.toArray()),
              objectOptions: { color: '#0072C3', weight: 2 },
            });
          });
        });
    }
  }
}
