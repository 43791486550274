<button
  nz-button
  nzType="default"
  nzGhost
  [nzTooltipTitle]="layer.name"
  nzTooltipPlacement="top"
  nz-tooltip
  (click)="changeState(!active)"
  [nzLoading]="isLoading"
  [class.layer-loading]="isLoading"
  [attr.nameForTest]="layer.name"
  [attr.selectedForTest]="!!active"
>
  <i nz-icon>
    <img [src]="active ? activeIcon : disabledIcon" [alt]="layer.name" />
  </i>
</button>
