import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import { IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { LayersDataService, OperationsService } from '@bg-front/core/services';
import { IMapBaseObjectBaseEvent, IMapBaseObjectSelectEvent } from '@bg-front/map/models/interfaces';
import { MapBaseService } from '@bg-front/map/services';
import { ControlsOf } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IModifiedData } from '@smart-city/core/interfaces';
import { AccessService, Settings2Service, SubscriberService } from '@smart-city/core/services';
import * as clone from 'clone';
import { NzModalService } from 'ng-zorro-antd/modal';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';

import { IVideoDeviceBg } from '../../../app-common/models/interfaces';
import { IVideoDeviceMapFilterForm } from '../../models/interfaces';
import { BaseLayerControlComponent } from '../base-layer-control/base-layer-control.component';
import { MapVideoDeviceFilterComponent } from '../map-video-device-filter/map-video-device-filter.component';
import { MapControlsService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'bg-video-devices-layer-control',
  templateUrl: './video-devices-layer-control.component.html',
  styleUrls: ['./video-devices-layer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoDevicesLayerControlComponent extends BaseLayerControlComponent implements OnInit {
  public override attributes: string[] = ['id', 'name', 'coordinates', 'active', 'mo'];

  public override mapLayerFilters: ValuesOf<ControlsOf<IVideoDeviceMapFilterForm>> = this.accessService.accessMap[
    'viewAllMunicipals'
  ]?.visible
    ? {}
    : { mo: this.settings.currentUser.organizationId.mo };

  constructor(
    mapService: MapBaseService,
    cdr: ChangeDetectorRef,
    gisService: LayersDataService,
    settings: Settings2Service,
    router: Router,
    route: ActivatedRoute,
    operationsService: OperationsService,
    mapControlsService: MapControlsService,
    private readonly accessService: AccessService,
    private readonly subs: SubscriberService,
    private readonly modalService: NzModalService,
  ) {
    super(mapService, cdr, gisService, settings, router, route, operationsService, mapControlsService);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.initSubscriptionOnChanges();

    /** Подписка на Клик по объекту */
    this.mapService
      .getObservableObjectEvent<IMapBaseObjectSelectEvent>(this.mapId, 'selectObject', this.layer.nameOnMap)
      .pipe(untilDestroyed(this))
      .subscribe((event: IMapBaseObjectSelectEvent) => {
        if (event.selected) {
          this.showObjectInfo(event);
        }
      });
  }

  /** Обработка нажатия и вызов соответствующей навигации */
  private showObjectInfo(event: IMapBaseObjectBaseEvent): void {
    this.router.navigate(['web-camera', event?.objectId], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Инициализация подписки на изменения сущностей на карте
   */
  private initSubscriptionOnChanges(): void {
    // Массив изменившихся id записей
    let ids: string[] = [];
    // Подписка на поток событий по видеоустройствам
    this.subs
      .onTableChange<IVideoDeviceBg>('Admin', 'VideoDevices')
      .pipe(
        tap((response: IModifiedData<IVideoDeviceBg | { deletedItems: IVideoDeviceBg[]; ids: string[] }>) => {
          // TODO:
          // 1) что делать, если просматриваемая камера удалена?
          // 2) что делать, если камера изменила угол обзора, координаты, другую подобную информацию,
          // которая влияет на отображение на карте
          // 3) что делать, если включен фильтр на карте и просматриваемая камера изменила поля и в результате этого
          // больше не должна попадать в фильтр?
          // 4) учесть, что при создании камеры не приходит id (в ids добавляется единственный элемент - null)
          if (response.action === 'update') {
            ids.push((<IModifiedData<IVideoDeviceBg>>response).data.id);
          }
        }),
        debounceTime(1000),
        filter(() => ids.length > 0),
        untilDestroyed(this),
      )
      .subscribe(() => {
        const mapLayerFilters = clone(this.mapLayerFilters, { includeNonEnumerable: true });
        // Если для слоя есть фильтр по МО, то учесть его при получении объектов
        if (ids?.length) {
          mapLayerFilters.ids = [...ids];
        }
        // Очистка массива изменившихся id
        ids = [];

        this.getLayerData(mapLayerFilters, false);
      });
  }

  /** Открытие диалогово окна фильтрации */
  public override openFilterDialog(): void {
    this.modalService
      .create({
        nzTitle: 'Фильтрация объектов на карте',
        nzContent: MapVideoDeviceFilterComponent,
        nzComponentParams: {
          mapLayerFilters: this.mapLayerFilters,
        },
        nzFooter: null,
      })
      .afterClose.pipe(untilDestroyed(this))
      .subscribe((value: ValuesOf<ControlsOf<IVideoDeviceMapFilterForm>>) => {
        if (value) {
          this.mapLayerFilters = {
            ...value,
            ...(this.accessService.accessMap['viewAllMunicipals']?.visible
              ? {}
              : { mo: this.settings.currentUser.organizationId.mo }),
          };
          this.getLayerData(this.mapLayerFilters, false, true);
        }
      });
  }

  /** Формирование запроса */
  public override getLayerLoadDataQuery(
    mapLayerFilters: ValuesOf<ControlsOf<IVideoDeviceMapFilterForm>> | undefined,
  ): IAnyObject[] {
    // Доступ к камере на основании фильтров связанных с категориями
    const filterByVideoDeviceCategories: IAnyObject = { $or: [] };
    // Доступ к камере на основании фильтра по наличию/отсутствию категории
    if ((mapLayerFilters || {}).isNoCategory !== undefined && (mapLayerFilters || {}).isNoCategory !== null) {
      filterByVideoDeviceCategories.$or.push({
        $expr: mapLayerFilters.isNoCategory
          ? { $eq: ['$categories.categoryId', null] }
          : { $ne: ['$categories.categoryId', null] },
      });
    }
    // Доступ к камере на основании фильтра по категориям
    if ((mapLayerFilters || {}).categoryIds) {
      filterByVideoDeviceCategories.$or.push({
        $expr: {
          $in: [`["${mapLayerFilters.categoryIds.join('","')}"]`, '$categories.categoryId'],
        },
      });
    }

    // Доступ к камере на основании данных о категории.
    // Отображаются камеры либо с разрешенной категорией, либо без категории
    const filterByVideoDeviceCategoriesAccess: IAnyObject = {};
    if (this.settings.getConfig().cameraAccessByCategories === 'TRUE') {
      filterByVideoDeviceCategoriesAccess.$or = [
        { $expr: { $eq: ['$categories.categoryId', null] } },
        {
          $and: [
            { $expr: { $eq: ['$accessRights.cameraAccess', true] } },
            { $expr: { $eq: ['$accessRights.userId', this.settings.currentUser.id] } },
          ],
        },
      ];
    }

    const query = {
      coordinates: { $ne: null },
      active: true,
      $and: [filterByVideoDeviceCategories, filterByVideoDeviceCategoriesAccess],
    };

    return [
      query,
      ...this.gisService.getEntityFilters(this.layer.entityFilters.filters),
      ...this.gisService.getEntityFilters(this.getFilterQuery(mapLayerFilters)),
    ];
  }

  /** Получаем дополнительные таблицы */
  public override getJoinedTables(
    mapLayerFilters: ValuesOf<ControlsOf<IVideoDeviceMapFilterForm>> | undefined,
  ): IAnyObject {
    const joinedTables = {};

    if (
      (mapLayerFilters?.isNoCategory !== undefined && mapLayerFilters?.isNoCategory !== null) ||
      (mapLayerFilters || {}).categoryIds?.length ||
      this.settings.getConfig().cameraAccessByCategories === 'TRUE'
    ) {
      joinedTables['categories'] = {
        $join: {
          service: 'Admin',
          entity: 'VideoDevicesCategoriesLinks',
          attributes: ['videoDeviceId', 'categoryId'],
          query: { $expr: { $eq: ['$id', '$categories.videoDeviceId'] } },
        },
      };
    }

    // Доступ к камере на основании данных о категории.
    // Отображаются камеры либо с разрешенной категорией, либо без категории
    if (this.settings.getConfig().cameraAccessByCategories === 'TRUE') {
      joinedTables['accessRights'] = {
        $join: {
          service: 'Admin',
          entity: 'VideoDeviceCategoryAccessRights',
          attributes: ['id', 'cameraAccess', 'userId', 'videoDeviceCategoryId'],
          query: { $expr: { $eq: ['$categories.categoryId', '$accessRights.videoDeviceCategoryId'] } },
        },
      };
    }

    return joinedTables;
  }

  public override getFilterQuery(
    value: ValuesOf<ControlsOf<IVideoDeviceMapFilterForm>> | undefined,
  ): IMapLayerEntityFilter[] {
    if (!value) {
      return [];
    }
    const result: IMapLayerEntityFilter[] = [];

    if (value.id) {
      result.push({
        property: 'id',
        value: value.id,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.ids) {
      result.push({
        property: 'id',
        value: value.ids,
        operation: FilterOperationEnum.in,
      });
    }
    if (value.videoCameraSignId) {
      result.push({
        property: 'videoCameraSignId',
        value: value.videoCameraSignId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.categoryIds) {
      result.push({
        property: 'categoryIds',
        value: value.categoryIds,
        operation: FilterOperationEnum.in,
      });
    }
    if (value.isNoCategory) {
      result.push({
        property: 'isNoCategory',
        value: value.isNoCategory,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.status) {
      result.push({
        property: 'status',
        // Т.к. в таблице хранится sysname записи из справочника,
        // то нужно в фильтр отдавать значение системного наименования
        value: this.settings.getDictionaryById(value.status).sysname.toString(),
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.mo) {
      result.push({
        property: 'mo',
        value: value.mo,
        operation: FilterOperationEnum.equal,
      });
    }

    return result;
  }
}
