import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import { IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { LayersDataService, OperationsService } from '@bg-front/core/services';
import { IMapBaseObjectBaseEvent, IMapBaseObjectSelectEvent } from '@bg-front/map/models/interfaces';
import { MapBaseService } from '@bg-front/map/services';
import { ControlsOf } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { untilDestroyed } from '@ngneat/until-destroy';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { IFireMonitoringObjectMapFilterForm } from '../../models/interfaces';
import { BaseLayerControlComponent } from '../base-layer-control/base-layer-control.component';
import { MapFireMonitoringObjectFilterComponent } from '../map-fire-monitoring-object-filter/map-fire-monitoring-object-filter.component';
import { MapControlsService } from '../../services';

@Component({
  selector: 'bg-fire-monitoring-objects-layer-control',
  templateUrl: './fire-monitoring-objects-layer-control.component.html',
  styleUrls: ['./fire-monitoring-objects-layer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FireMonitoringObjectsLayerControlComponent extends BaseLayerControlComponent implements OnInit {
  public override mapLayerFilters: ValuesOf<ControlsOf<IFireMonitoringObjectMapFilterForm>> = this.accessService
    .accessMap['viewAllMunicipals']?.visible
    ? {}
    : { municipalId: this.settings.currentUser.organizationId.mo };
  constructor(
    mapService: MapBaseService,
    cdr: ChangeDetectorRef,
    gisService: LayersDataService,
    settings: Settings2Service,
    router: Router,
    route: ActivatedRoute,
    operationsService: OperationsService,
    mapControlsService: MapControlsService,
    private readonly accessService: AccessService,
    private readonly modalService: NzModalService,
  ) {
    super(mapService, cdr, gisService, settings, router, route, operationsService, mapControlsService);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    /** Подписка на Клик по объекту */
    this.mapService
      .getObservableObjectEvent<IMapBaseObjectSelectEvent>(this.mapId, 'selectObject', this.layer.nameOnMap)
      .pipe(untilDestroyed(this))
      .subscribe((event: IMapBaseObjectSelectEvent) => {
        if (event.selected) {
          this.showObjectInfo(event);
        }
      });
  }

  /** Обработка нажатия и вызов соответствующей навигации */
  private showObjectInfo(event: IMapBaseObjectBaseEvent): void {
    this.router.navigate(['fireMonitoringObject', event?.objectId], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }

  /** Открытие модального окна фильтрации */
  public override openFilterDialog(): void {
    this.modalService
      .create({
        nzTitle: 'Фильтрация объектов на карте',
        nzContent: MapFireMonitoringObjectFilterComponent,
        nzComponentParams: {
          mapLayerFilters: this.mapLayerFilters,
        },
        nzFooter: null,
      })
      .afterClose.pipe(untilDestroyed(this))
      .subscribe((value: ValuesOf<ControlsOf<IFireMonitoringObjectMapFilterForm>>) => {
        if (value) {
          this.mapLayerFilters = value;
          this.getLayerData(value, true, true);
        }
      });
  }

  /** Формирование объекта запроса */
  public override getFilterQuery(
    value: ValuesOf<ControlsOf<IFireMonitoringObjectMapFilterForm>> | undefined,
  ): IMapLayerEntityFilter[] {
    if (!value) {
      return [];
    }
    const result: IMapLayerEntityFilter[] = [];
    if (value.systemId) {
      result.push({
        property: 'systemId',
        value: value.systemId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.hasOwnProperty('hasOfflineSources') && value.hasOfflineSources !== null) {
      result.push({
        property: 'hasOfflineSources',
        value: value.hasOfflineSources ? 'true' : 'false',
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.hasOwnProperty('onFire') && value.onFire !== null) {
      result.push({
        property: 'onFire',
        value: value.onFire ? 'true' : 'false',
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.municipalId) {
      result.push({
        property: 'municipalId',
        value: value.municipalId,
        operation: FilterOperationEnum.equal,
      });
    }

    return result;
  }
}
