<div
  *ngIf="layersControl"
  [ngStyle]="mapStyle"
  leaflet
  [leafletOptions]="options"
  [leafletLayersControl]="layersControl"
  [leafletPanOptions]="panOptions"
  [leafletZoomOptions]="zoomOptions"
  [leafletFitBoundsOptions]="fitBoundsOptions"
  [leafletZoom]="zoom"
  [leafletMaxZoom]="maxZoom"
  [leafletMinZoom]="minZoom"
  [leafletCenter]="center"
  [leafletFitBounds]="$any(fitBounds)"
  (leafletMapReady)="onMapReady($event)"
  (leafletClick)="onMapMouseEvent($event)"
  (leafletDoubleClick)="onMapMouseEvent($event)"
  (leafletMouseDown)="onMapMouseEvent($event)"
  (leafletMouseUp)="onMapMouseEvent($event)"
  (leafletMouseMove)="onMapMouseEvent($event)"
  (leafletMouseOut)="onMapMouseEvent($event)"
  (leafletZoomChange)="onMapEvent('zoomchange', $event)"
  (leafletCenterChange)="onMapEvent('centerchange', $event)"
>
  <!-- <ng-container *ngIf="clusterObjects.length > 0" [mapMarkerCluster]="clusterObjects"></ng-container>
  <ng-container *ngFor="let id of notClusteredIds; trackBy: trackObject">
    <ng-container [leafletLayer]="mapObjects[id]"></ng-container>
  </ng-container> -->
  <ng-container *ngFor="let layer of renderLayers">
    <ng-container
      *ngIf="layer.clustered.length > 0"
      [mapMarkerClusterOptions]="layer.clusterOptions"
      [mapMarkerCluster]="layer.clustered"
    ></ng-container>
    <!-- <ng-container *ngFor="let el of layer.nonClustered">
      <ng-container [leafletLayer]="el"></ng-container>
    </ng-container> -->
    <ng-container [leafletLayers]="layer.nonClustered"></ng-container>
  </ng-container>
</div>
<div
  *ngIf="!layersControl"
  [ngStyle]="mapStyle"
  leaflet
  [leafletOptions]="options"
  [leafletPanOptions]="panOptions"
  [leafletZoomOptions]="zoomOptions"
  [leafletFitBoundsOptions]="fitBoundsOptions"
  [leafletZoom]="zoom"
  [leafletMaxZoom]="maxZoom"
  [leafletMinZoom]="minZoom"
  [leafletCenter]="center"
  [leafletFitBounds]="$any(fitBounds)"
  (leafletMapReady)="onMapReady($event)"
  (leafletClick)="onMapMouseEvent($event)"
  (leafletDoubleClick)="onMapMouseEvent($event)"
  (leafletMouseDown)="onMapMouseEvent($event)"
  (leafletMouseUp)="onMapMouseEvent($event)"
  (leafletMouseMove)="onMapMouseEvent($event)"
  (leafletMouseOut)="onMapMouseEvent($event)"
  (leafletZoomChange)="onMapEvent('zoomchange', $event)"
  (leafletCenterChange)="onMapEvent('centerchange', $event)"
>
  <!-- <ng-container *ngIf="clusterObjects.length > 0" [mapMarkerCluster]="clusterObjects"></ng-container>
  <ng-container *ngFor="let id of notClusteredIds; trackBy: trackObject">
    <ng-container [leafletLayer]="mapObjects[id]"></ng-container>
  </ng-container> -->
  <ng-container *ngFor="let layer of renderLayers">
    <ng-container
      *ngIf="layer.clustered.length > 0"
      [mapMarkerClusterOptions]="layer.clusterOptions"
      [mapMarkerCluster]="layer.clustered"
    ></ng-container>
    <ng-container [leafletLayers]="layer.nonClustered"></ng-container>
  </ng-container>
</div>
