<div nz-row nzJustify="space-between">
  <div nz-col nzFlex="none">
    <b>{{ title }}</b>
  </div>
</div>
<div class="map-container" [ngClass]="{ 'map-cursor': editingPolygon }">
  <map-base *ngIf="mapOptions" [mapOptions]="mapOptions"></map-base>
  <nz-space class="map-container-buttons" *ngIf="canEdit">
    <ng-container *ngIf="!editingPolygon; else polygonEdit">
      <button *nzSpaceItem type="button" nz-button nzType="primary" (click)="editPolygon()">Редактировать</button>
    </ng-container>

    <ng-template #polygonEdit>
      <button *nzSpaceItem type="button" nz-button nzType="default" (click)="cancelEditPolygon()">Отмена</button>
      <button *nzSpaceItem nz-button nzType="primary" type="button" nzDanger (click)="clearPolygon()">Удалить</button>
      <button *nzSpaceItem nz-button type="button" nzType="primary" [nzLoading]="savingPolygon" (click)="savePolygon()">
        Сохранить
      </button>
    </ng-template>
  </nz-space>
</div>
<div *ngIf="showCoordinates">
  <nz-form-item>
    <nz-form-label>Координаты границ</nz-form-label>
    <nz-form-control>
      <input *ngIf="canEdit" nz-input [(ngModel)]="coordinates" (ngModelChange)="onCoordinatesChange($event)" />
      <nz-view-control *ngIf="!canEdit" [data]="coordinates"></nz-view-control>
    </nz-form-control>
  </nz-form-item>
</div>
