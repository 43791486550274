import { Injectable } from '@angular/core';
import { Coordinates, OperationFilterHelper } from '@bg-front/core/models/classes';
import { IBasePolygonDto, IMapLayer, IMapLayerEntityFilter, ISelectItem } from '@bg-front/core/models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IModifiedData } from '@smart-city/core/interfaces';
import { ILimit, ISort, RestService, SubscriberService } from '@smart-city/core/services';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, ISignificantObjectDto } from 'smart-city-types';

import { ISignificantObjectData } from '../../../models';
import { SignificantObjectsStore } from '../store/significant-objects.store';

@UntilDestroy()
@Injectable()
export class SignificantObjectsService {
  /** Флаг инициализации хранилища */
  private isInit = false;
  /**
   * Позволяет следить за обновлением важного объекта
   */
  private significantObjectUpdated = new Subject<string>();
  public significantObjectUpdated$ = this.significantObjectUpdated.asObservable();
  /**
   * Позволяет следить за добавлением важного объекта в реестр
   */
  private significantObjectAdded = new Subject<string>();
  public significantObjectAdded$ = this.significantObjectAdded.asObservable();
  /**
   * Позволяет следить за выбором одной из карточек реестра происшествий
   */
  private significantObjectSelectItem = new Subject<string>();
  public significantObjectSelectItem$ = this.significantObjectSelectItem.asObservable();

  constructor(
    private readonly rest: RestService,
    private readonly store: SignificantObjectsStore,
    private readonly subs: SubscriberService,
  ) {}

  /**
   * Загружаем список Типов инцидентов
   */
  public initStore(): Observable<boolean> {
    this.store.setLoading(true);

    if (!this.isInit) {
      this.isInit = true;
      this.subs
        .onTableChange<ISignificantObjectDto>('Admin', 'SignificantObjects')
        .pipe(untilDestroyed(this))
        .subscribe((result: IModifiedData<ISignificantObjectDto>) => {
          this.store.setLoading(true);
          if (result.action !== 'delete') {
            this.store.upsert(
              result.data.id,
              (oldState: Partial<ISignificantObjectDto>) => ({ ...oldState, ...result.data }),
              (id: string, newState: ISignificantObjectDto) => ({ id, ...newState }),
            );
          } else {
            this.store.remove(result.data.id);
          }
          this.store.setLoading(false);
        });
    }

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'SignificantObjects',
          sort: { field: 'name', direction: 'asc' },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          this.store.add(response?.data?.items as ISignificantObjectDto[]);
          return true;
        }),
        catchError(() => {
          return of(false);
        }),
        finalize(() => {
          this.store.setLoading(false);
        }),
      );
  }

  /**
   * Получение важных объектов расположенных рядом с заданной координатой и не дальше указанного радиуса
   * @param coord класс координат
   * @param radius расстояние не далее которого производится поиск
   * @param needTotal запрашивать подсчёт количества или нет
   */
  public getCloseObject(
    coord: Coordinates,
    radius: number,
    needTotal: boolean = true,
  ): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      data: needTotal
        ? {
            isNeedTotal: true,
          }
        : undefined,
      entity: {
        name: 'SignificantObjects',
        attributes: [
          'id',
          'category.name',
          'type.name',
          'type.sysname',
          'name',
          'state.name',
          'responsible',
          'phone',
          'projectedRiskIds',
          'coordinates',
        ],
        query: {
          point: {
            $radius: {
              $lat: coord.lat,
              $lon: coord.lon,
              $meters: radius,
            },
          },
        },
      },
    });
  }

  /** TODO Переработать getSignificantObjects и getAllSignificantObjects. Использовать один метод
   * Получить важные объекты с требуемыми полями.
   * @param query фильтр для запроса
   * @param sort сортировка записей запроса
   * @param limit ограничения запрашиваемых данных
   */
  public getSignificantObjects(query?: IAnyObject, limit?: ILimit, sort?: ISort): Observable<ISignificantObjectData[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query,
          name: 'SignificantObjects',
          attributes: [
            'name',
            'shortName',
            'state.name',
            'category.name',
            'type.name',
            'type.sysname',
            'coordinates',
            'address',
            'phone',
            'responsible',
            'dutyServices',
            'documents',
            'projectedRiskIds',
            'organizationId.id',
            'organizationId.name',
          ],
        },
        data: {
          limit,
          sort,
        },
      })
      .pipe(map((significantObjects: IAbstractServiceData) => significantObjects.data.items));
  }

  /** TODO Переработать getSignificantObjects и getAllSignificantObjects. Использовать один метод
   * Получить важные объекты с требуемыми полями.
   * @param id ID объекта
   */
  public getSignificantObjectById(id: string): Observable<ISignificantObjectData> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'SignificantObjects',
          attributes: [
            'name',
            'shortName',
            'state.name',
            'category.name',
            'type.name',
            'type.sysname',
            'coordinates',
            'address',
            'phone',
            'responsible',
          ],
          query: {
            id,
          },
        },
      })
      .pipe(map((significantObjects: IAbstractServiceData) => significantObjects.data.items[0]));
  }

  /**
   * Получение важных объектов для режима функционирования
   * @param polygon полигон или полигоны для поиска
   */
  public getSignificantObjectsForRegime(
    polygon: IBasePolygonDto | IBasePolygonDto[],
  ): Observable<ISignificantObjectData[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Gis' },
        entity: {
          name: 'MapLayers',
          query: {
            claim: 'SignificantObjectsLayer',
          },
          attributes: ['entityFilters'],
        },
      })
      .pipe(
        switchMap((result: IAbstractServiceData) => {
          const filters = ((<IMapLayer>(result.data?.items || [])[0])?.entityFilters?.filters || []).map(
            (filter: IMapLayerEntityFilter) =>
              OperationFilterHelper.createValueByOperation(filter.property, filter.value, filter.operation),
          );
          return forkJoin(
            (Array.isArray(polygon) ? polygon : [polygon]).map((item: IBasePolygonDto) =>
              this.rest.serviceRequest({
                action: 'select',
                service: { name: 'Admin' },
                entity: {
                  name: 'SignificantObjects',
                  attributes: [
                    'id',
                    'name',
                    'shortName',
                    'state.name',
                    'category.name',
                    'type.name',
                    'type.sysname',
                    'coordinates',
                    'address',
                    'phone',
                    'responsible',
                    'dutyServices',
                    'documents',
                  ],
                  query: {
                    point: {
                      $polygon: {
                        $points: [
                          ...item.coordinates.map((coordinates) => [coordinates.lat, coordinates.lon]),
                          ...[[item.coordinates[0].lat, item.coordinates[0].lon]],
                        ],
                      },
                    },
                    $and: [...filters],
                  },
                },
              }),
            ),
          );
        }),
        map((result: IAbstractServiceData[]) =>
          // Уплощение полученного массива массивов, дополнительно выполняется исключение возможных повторений
          result.reduce(
            (acc: ISignificantObjectData[], val: IAbstractServiceData) =>
              acc.concat(
                (val?.data.items || []).filter(
                  (item: ISignificantObjectData) =>
                    !acc.some((accItem: ISignificantObjectData) => accItem.id === item.id),
                ),
              ),
            [],
          ),
        ),
      );
  }

  /** TODO Переработать getSignificantObjects и getAllSignificantObjects. Использовать один метод
   * Запрос списка важных объектов.
   */
  public getAllSignificantObjects(): Observable<ISignificantObjectDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'SignificantObjects',
          query: {},
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items as ISignificantObjectDto[];
        }),
      );
  }

  /**
   * Получить важные объекты с требуемыми полями.
   * @param query фильтр для запроса
   * @param sort сортировка записей запроса
   * @param limit ограничения запрашиваемых данных
   */
  public getSignificantObjectsForForecasting(query?: IAnyObject): Observable<ISelectItem[]> {
    return this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: 'Admin' },
          entity: {
            query,
            name: 'SignificantObjects',
            attributes: ['id', 'shortName'],
          },
          data: {
            sort: <ISort>{
              direction: 'asc',
              field: 'shortName',
            },
          },
        },
        'http',
      )
      .pipe(
        map((response: IAbstractServiceData) => {
          return ((response.data.items as []) || []).map((el: IAnyObject) => {
            return <ISelectItem>{
              text: el.shortName,
              value: el.id,
            };
          });
        }),
      );
  }

  /**
   * Получить Observable, возвращающий все инциденты с требуемыми полями.
   * @param query фильтр для запроса
   * @param sort сортировка записей запроса
   * @param limit ограничения запрашиваемых данных
   */
  public getIncidents(query?: any, limit?: ILimit, sort?: ISort): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Emergency' },
      entity: {
        query,
        name: 'Emergency',
        attributes: [
          'id',
          'organization.mo.id',
          'number',
          'lifeCycleStepId.status.sysname',
          'lifeCycleStepId.name',
          'lifeCycleStepId.endOfCycle',
          'incidentTypeId.id',
          'incidentTypeId.name',
          'addressFact.fullText',
          'addressFact.latitude',
          'addressFact.longitude',
          'addressFact.manual',
          'timeCreate',
          'docType.id',
          'docType.sysname',
          'organization.organizationType.sysname',
          'coordinates',
        ],
      },
      data: {
        limit,
        sort,
      },
    });
  }

  /**
   * Кидаем событие, когда важный объект обновился
   * @param significantObjectId - ID важного объекта
   */
  public updateSignificantObject(significantObjectId: string): void {
    this.significantObjectUpdated.next(significantObjectId);
  }

  /**
   * Кидаем событие, когда важный объект добавляется в реестр
   * @param significantObject - ID важного объекта
   */
  public addNewSignificantObject(significantObject: string): void {
    this.significantObjectAdded.next(significantObject);
  }

  /**
   * Кидаем событие, какой элемент выбрали в реестре важных объектов
   */
  public selectSignificantObject(event: string): void {
    this.significantObjectSelectItem.next(event);
  }
}
