import { Injectable } from '@angular/core';
import { Coordinates } from '@bg-front/core/models/classes';
import { RestService } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';
import { IPolygonDto, ISelectItem } from '../../models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PolygonsService {
  /** Позволяет следить за выбором карточки в оперативном реестре пользовательских полигонов */
  private userPolygonSelectItem = new Subject<string>();
  public userPolygonSelectItem$ = this.userPolygonSelectItem.asObservable();

  /** Позволяет следить за удалением/добавлением полигонов */
  private polygonListUpdate = new Subject<string>();
  public polygonListUpdate$ = this.polygonListUpdate.asObservable();

  constructor(private readonly rest: RestService) {}

  /** Возвращает координаты полигона */
  public getCoordinates(id: string): Observable<Coordinates[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Polygons',
          query: { id },
          attributes: ['coordinates'],
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => res.data?.items[0]?.coordinates.map((el: string) => new Coordinates(el))),
      );
  }

  /** Получаем полигон по Id */
  public getById(id: string): Observable<IPolygonDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Polygons',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          (<IPolygonDto[]>res.data?.items)?.forEach(
            (polygon: IPolygonDto, index: number) =>
              (res.data.items[index] = {
                ...res.data.items[index],
                coordinates: res.data.items[index].coordinates?.map((el: string) => new Coordinates(el)),
              }),
          );
          return <IPolygonDto>(res.data?.items || [])[0];
        }),
      );
  }

  /** Получаем полигоны по Id */
  public getByIds(ids: string[]): Observable<IPolygonDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Polygons',
          query: {
            id: { $in: ids },
          },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          (<IPolygonDto[]>res.data?.items)?.forEach(
            (polygon: IPolygonDto, index: number) =>
              (res.data.items[index] = {
                ...res.data.items[index],
                coordinates: res.data.items[index].coordinates?.map((el: string) => new Coordinates(el)),
              }),
          );
          return <IPolygonDto[]>res.data.items;
        }),
      );
  }

  /** Сохраняем полигон */
  public save(model: IPolygonDto): Observable<string> {
    // Добавление первой координаты в конец полигона чтобы его замкнуть
    model.coordinates.push(model.coordinates[0]);
    return this.rest
      .serviceRequest({
        action: 'upsert',
        service: { name: 'Admin' },
        entity: {
          name: 'Polygons',
          query: { id: model.id ?? Uuid.newUuid() },
        },
        data: {
          ...model,
          coordinates: model.coordinates.map((coordinate: Coordinates) => coordinate.toString()),
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          if (model.id) {
            return model.id;
          }
          this.polygonListUpdate.next(res.data.id);
          return res.data.id;
        }),
      );
  }

  /** Удаляем полигон */
  public delete(id: string): Observable<IAbstractServiceData> {
    return this.rest
      .serviceRequest({
        action: 'delete',
        service: { name: 'Admin' },
        entity: {
          name: 'Polygons',
          query: { id },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          const deletedPolygonId = res?.data?.items[0]?.id;

          if (deletedPolygonId) {
            this.polygonListUpdate.next(deletedPolygonId);
          }
          return res;
        }),
      );
  }

  /** Получаем полигоны по sysnameId */
  public getPolygonsBySysname(sysname: string): Observable<IPolygonDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Polygons',
          query: {
            polygonTypeId: {
              sysname,
            },
          },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return res.data?.items?.map((item) => {
            return <IPolygonDto>{
              ...item,
              coordinates: item.coordinates?.map((el: string) => new Coordinates(el)),
            };
          });
        }),
      );
  }

  /** Получаем полигоны по sysnameId */
  public getPolygonsBySysnameForSelect(sysname: string): Observable<ISelectItem[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Polygons',
          query: {
            polygonTypeId: {
              sysname,
            },
          },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return res.data?.items?.map((item) => {
            return <ISelectItem>{
              text: item['name'],
              value: item['id'],
            };
          });
        }),
      );
  }

  /**
   * Кидаем событие, какой элемент выбрали в в оперативном реестре пользовательских полигонов
   */
  public userPolygonObject(event: string): void {
    this.userPolygonSelectItem.next(event);
  }

  /** Возвращает координаты полигона */
  public getMultiplePolygonsByIds(polygonIds: string[]): Observable<IPolygonDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Polygons',
          query: { id: { $in: polygonIds } },
          attributes: ['coordinates'],
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => res.data?.items),
        map((res: IAnyObject[]) =>
          res.map((el: IAnyObject) => {
            return {
              id: el.id,
              coordinates: (el.coordinates as string[]).map((c: string) => new Coordinates(c)),
            };
          }),
        ),
      );
  }
}
