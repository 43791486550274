import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import { IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { LayersDataService, OperationsService } from '@bg-front/core/services';
import { IMapBaseObjectBaseEvent, IMapBaseObjectSelectEvent } from '@bg-front/map/models/interfaces';
import { MapBaseService } from '@bg-front/map/services';
import { ControlsOf } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { untilDestroyed } from '@ngneat/until-destroy';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { AccessService, Settings2Service, SubscriberService } from '@smart-city/core/services';
import * as clone from 'clone';
import { NzModalService } from 'ng-zorro-antd/modal';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { IAnyObject, IEmergencyDto } from 'smart-city-types';

import { IEmergencyMapFilterForm, IPlannedWorkMapFilterForm } from '../../models/interfaces';
import { BaseLayerControlComponent } from '../base-layer-control/base-layer-control.component';
import { MapEmergencyFilterComponent } from '../map-emergency-filter/map-emergency-filter.component';
import { MapControlsService } from '../../services';

@Component({
  selector: 'bg-planned-works-layer-control',
  templateUrl: './planned-works-layer-control.component.html',
  styleUrls: ['./planned-works-layer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlannedWorksLayerControlComponent extends BaseLayerControlComponent implements OnInit {
  public override mapLayerFilters: ValuesOf<ControlsOf<IEmergencyMapFilterForm>> = this.accessService.accessMap[
    'viewAllMunicipals'
  ]?.visible
    ? {}
    : { moId: this.settings.currentUser.organizationId.mo };

  constructor(
    mapService: MapBaseService,
    cdr: ChangeDetectorRef,
    gisService: LayersDataService,
    settings: Settings2Service,
    router: Router,
    route: ActivatedRoute,
    operationsService: OperationsService,
    mapControlsService: MapControlsService,
    private readonly accessService: AccessService,
    private readonly subs: SubscriberService,
    private readonly modalService: NzModalService,
  ) {
    super(mapService, cdr, gisService, settings, router, route, operationsService, mapControlsService);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    /** Подписка на Клик по объекту */
    this.mapService
      .getObservableObjectEvent<IMapBaseObjectSelectEvent>(this.mapId, 'selectObject', this.layer.nameOnMap)
      .pipe(untilDestroyed(this))
      .subscribe((event: IMapBaseObjectSelectEvent) => {
        if (event.selected) {
          this.showObjectInfo(event);
        }
      });

    this.initSubscribeOnChanges();
  }

  /**
   * Инициализация подписки на изменения сущностей на карте
   */
  private initSubscribeOnChanges(): void {
    // Массив для накопления ID записей которые изменились за секунду
    let ids = [];
    /** Подписка на поток изменений в таблице происшествий */
    this.subs
      .onTableChange<IEmergencyDto>('Emergency', 'Emergency')
      .pipe(
        tap((response) => {
          const id: string | IAnyObject = response.data.id;
          if (typeof id === 'string') {
            ids.push(id);
          } else if (((id as Record<string, unknown>)?.['$in'] as [])?.length) {
            ((id as Record<string, unknown>)?.['$in'] as []).forEach((el: string) => ids.push(el));
          }
        }),
        debounceTime(1000),
        filter(() => ids.length > 0),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.mapService.removeObject(this.mapId, this.layer.nameOnMap, ids);

        const mapLayerFilters = clone(this.mapLayerFilters, { includeNonEnumerable: true });
        if (ids?.length) {
          mapLayerFilters.ids = [...ids];
        }
        // Очистка массива изменявшихся ID
        ids = [];

        this.getLayerData(mapLayerFilters, false);
      });
  }

  /** Обработка нажатия и вызов соответствующей навигации */
  private showObjectInfo(event: IMapBaseObjectBaseEvent): void {
    this.router.navigate([{ outlets: { leftPopup: ['emergency', event?.objectId] } }], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }

  /** Открытие модального окна фильтрации */
  public override openFilterDialog(): void {
    this.modalService
      .create({
        nzTitle: 'Фильтрация объектов на карте',
        nzContent: MapEmergencyFilterComponent,
        nzComponentParams: {
          mapLayerFilters: this.mapLayerFilters,
        },
        nzFooter: null,
      })
      .afterClose.pipe(untilDestroyed(this))
      .subscribe((value: ValuesOf<ControlsOf<IEmergencyMapFilterForm>>) => {
        if (value) {
          this.mapLayerFilters = value;
          this.getLayerData(value, true, true);
        }
      });
  }

  public override getLayerLoadDataQuery(
    mapFilter: ValuesOf<ControlsOf<IPlannedWorkMapFilterForm>> | undefined,
  ): IAnyObject[] {
    const statusIds: string[] = this.settings
      .getDictionaryByTypeSysName('statusLifeCycleStep')
      .filter((item: IDictionaryInfo) => {
        return ['new', 'inWork'].includes(item.sysname);
      })
      .map((item: IDictionaryInfo) => item.id);

    const query = {
      coordinates: { $ne: null },
      lifeCycleStepId: { status: { id: { $in: statusIds } } },
    };

    return [
      query,
      ...this.gisService.getEntityFilters(this.layer.entityFilters.filters),
      ...this.gisService.getEntityFilters(this.getFilterQuery(mapFilter)),
    ];
  }

  public override getFilterQuery(
    value: ValuesOf<ControlsOf<IPlannedWorkMapFilterForm>> | undefined,
  ): IMapLayerEntityFilter[] {
    if (!value) {
      return [];
    }
    const result: IMapLayerEntityFilter[] = [];
    if (value.moId) {
      result.push({
        property: 'moId',
        value: value.moId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.incidentTypeId) {
      result.push({
        property: 'incidentTypeId',
        value: value.incidentTypeId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.state) {
      result.push({
        property: 'lifeCycleStepId.status.sysname',
        value: value.state,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.timeCreateFrom) {
      result.push({
        property: 'timeCreate',
        value: value.timeCreateFrom,
        operation: FilterOperationEnum.greatThenOrEqual,
      });
    }
    if (value.timeCreateTo) {
      result.push({
        property: 'timeCreate',
        value: value.timeCreateTo,
        operation: FilterOperationEnum.lessThenOrEqual,
      });
    }
    if (value.ids) {
      result.push({
        property: 'id',
        value: value.ids,
        operation: FilterOperationEnum.in,
      });
    }
    // Запросить только данные по обновленным записям
    return result;
  }
}
