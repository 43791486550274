<form nz-form [formGroup]="form" nzLayout="vertical">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Государственный номер</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="stateNumber" nzSize="large" nzAllowClear nzShowSearch nzMode="multiple">
            <nz-option
              [nzValue]="stateNumber.id"
              [nzLabel]="stateNumber.stateNumber"
              *ngFor="let stateNumber of stateNumbers$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Тип транспортного средства</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="typeId" nzSize="large" nzAllowClear nzShowSearch nzMode="multiple">
            <nz-option
              [nzValue]="typeId.id"
              [nzLabel]="typeId.name"
              *ngFor="let typeId of typeIds$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Номер происшествия</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="emergencyId" nzSize="large" nzAllowClear nzShowSearch nzMode="multiple">
            <nz-option
              [nzValue]="emergency.id"
              [nzLabel]="emergency.number"
              *ngFor="let emergency of emergencies$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Организация</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="organizationId" nzSize="large" nzAllowClear nzShowSearch nzMode="multiple">
            <nz-option
              [nzValue]="organization.id"
              [nzLabel]="organization.name"
              *ngFor="let organization of organizations$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
<div *nzModalFooter>
  <div nz-row>
    <div nz-col [nzSpan]="12" class="d-flex">
      <button nz-button nzType="default" class="m-r" (click)="clear()">Очистить</button>
    </div>
    <div nz-col [nzSpan]="12">
      <button nz-button nzType="default" class="m-r" (click)="close()">Отмена</button>
      <button nz-button nzType="primary" [disabled]="!form.valid" (click)="filtrate()">Фильтровать</button>
    </div>
  </div>
</div>
