<!-- <div class="container">
  <nw-header [options]="headerOptions" (clickButton)="delete()"></nw-header>
  <form [formGroup]="form">
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select formControlName="type" [options]="typeOptions"></sc-select>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select formControlName="name" [options]="nameOptions"></sc-select>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select formControlName="state" [options]="stateOptions"></sc-select>
        </div>
      </div>
    </div>
  </form>
</div> -->

<form nz-form [formGroup]="form" nzLayout="vertical">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Муниципальное образование</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="moId" nzSize="large" nzAllowClear nzShowSearch>
            <nz-option
              [nzValue]="municipal.value"
              [nzLabel]="municipal.text"
              *ngFor="let municipal of municipals"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Тип</nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="typeId"
            nzSize="large"
            nzAllowClear
            nzShowSearch
          >
            <nz-option
            [nzValue]="type.value"
            [nzLabel]="type.text"
            *ngFor="let type of types"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Наименование</nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="id"
            nzSize="large"
            nzAllowClear
            nzSearch
            nzShowSearch
          >
            <nz-option
              [nzValue]="obj.value"
              [nzLabel]="obj.text"
              *ngFor="let obj of significantObjects$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Состояние</nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="stateId"
            nzSize="large"
            nzAllowClear
            nzShowSearch
          >
            <nz-option
              [nzValue]="status.value"
              [nzLabel]="status.text"
              *ngFor="let status of statuses"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
<div *nzModalFooter>
  <div nz-row>
    <div nz-col [nzSpan]="12" class="d-flex">
      <button nz-button nzType="default" class="m-r" (click)="clear()">Очистить</button>
    </div>
    <div nz-col [nzSpan]="12">
      <button nz-button nzType="default" class="m-r" (click)="close()">Отмена</button>
      <button nz-button nzType="primary" [disabled]="!form.valid" (click)="filtrate()">Фильтровать</button>
    </div>
  </div>
</div>
