import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import { IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { LayersDataService, OperationsService } from '@bg-front/core/services';
import { MapBaseService } from '@bg-front/map/services';
import { ControlsOf, ValuesOf } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Settings2Service } from '@smart-city/core/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { IVehicleMapFilterForm } from '../../models/interfaces';
import { BaseLayerControlComponent } from '../base-layer-control/base-layer-control.component';
import { MapVehicleFilterComponent } from '../map-vehicle-filter/map-vehicle-filter.component';
import { IAnyObject } from 'smart-city-types';
import { RnisExternalService } from '../../../rnis/services';
import { MapControlsService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'bg-rnis-layer-control-container.component.ts',
  templateUrl: './rnis-layer-control-container.component.html',
  styleUrls: ['./rnis-layer-control-container.component.scss'],
})
export class RnisLayerControlContainerComponent extends BaseLayerControlComponent implements OnInit {
  public override mapLayerFilters: ValuesOf<ControlsOf<IVehicleMapFilterForm>>;

  /** @ignore */
  constructor(
    mapService: MapBaseService,
    cdr: ChangeDetectorRef,
    gisService: LayersDataService,
    settings: Settings2Service,
    router: Router,
    route: ActivatedRoute,
    operationsService: OperationsService,
    mapControlsService: MapControlsService,
    private readonly modalService: NzModalService,
    private readonly rnisExternal: RnisExternalService,
  ) {
    super(mapService, cdr, gisService, settings, router, route, operationsService, mapControlsService);
  }

  /** @ignore */
  public override ngOnInit(): void {
    super.ngOnInit();
    /** Отправление сигнала готовности компонента */
    // this.rnisExternal.layerControlReady.next(true);
    /** Подписка на сигнал отображения слоя ТС */
    // this.rnisExternal.showVehicleLayer
    //   .asObservable()
    //   .pipe(untilDestroyed(this))
    //   .subscribe(() => {
    //     this.changeState(true);
    //   });
  }

  public override getLayerData() {
    /** Т.к. работа со слоем ТС происходит в {@link RnisControlLayerComponent}, здесь только
     снимаем флаг загрузки */
    this.isLoading = false;
  }

  /** Открытие модального окна фильтрации */
  public override openFilterDialog(): void {
    this.modalService
      .create({
        nzTitle: 'Фильтрация объектов на карте',
        nzContent: MapVehicleFilterComponent,
        nzComponentParams: {
          mapLayerFilters: this.mapLayerFilters,
        },
        nzFooter: null,
      })
      .afterClose.pipe(untilDestroyed(this))
      .subscribe((value: ValuesOf<ControlsOf<IVehicleMapFilterForm>>) => {
        if (value) {
          this.mapLayerFilters = value;
          const message = {
            action: 'onSetFilters',
            data: {
              filters: this.getFilterQuery(this.mapLayerFilters),
              joinedTables: this.getJoinedTables(this.mapLayerFilters),
            },
          };
          this.rnisExternal.sendMessage(message);
          this.getLayerData();
        }
      });
  }

  /** Получаем дополнительные таблицы */
  public override getJoinedTables(
    mapLayerFilters: ValuesOf<ControlsOf<IVehicleMapFilterForm>> | undefined,
  ): IAnyObject {
    const joinedTables = {};

    if ((mapLayerFilters || {}).emergencyId && mapLayerFilters.emergencyId.length) {
      joinedTables['emergency'] = {
        $join: {
          service: 'Emergency',
          entity: 'Emergency',
          attributes: ['id', 'involvedVehicles'],
          query: { $expr: { $in: ['$emergency.involvedVehicles', '$id'] } },
        },
      };
    }
    return joinedTables;
  }

  public override getFilterQuery(
    value: ValuesOf<ControlsOf<IVehicleMapFilterForm>> | undefined,
  ): IMapLayerEntityFilter[] {
    if (!value) {
      return [];
    }
    const result: IMapLayerEntityFilter[] = [];
    if (value.stateNumber && value.stateNumber.length) {
      result.push({
        property: 'id',
        value: value.stateNumber,
        operation: FilterOperationEnum.in,
      });
    }
    if (value.typeId && value.typeId.length) {
      result.push({
        property: 'typeId',
        value: value.typeId,
        operation: FilterOperationEnum.in,
      });
    } else {
      result.push({
        property: 'typeId',
        value: this.settings.currentUser.vehicleTypeIds ?? [],
        operation: FilterOperationEnum.in,
      });
    }
    if (value.emergencyId && value.emergencyId.length) {
      result.push({
        property: '$emergency.id',
        value: value.emergencyId,
        operation: FilterOperationEnum.in,
      });
    }
    if (value.organizationId && value.organizationId.length) {
      result.push({
        property: 'organizationId',
        value: value.organizationId,
        operation: FilterOperationEnum.in,
      });
    }
    return result;
  }
}
