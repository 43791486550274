import { Component, OnInit } from '@angular/core';
import { ISelectItem } from '@bg-front/core/models/interfaces';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { IAdminMunicipalSchemaDto } from 'smart-city-types';

import { IEmergencyMapFilterForm } from '../../models/interfaces';

dayjs.extend(isSameOrBefore);

/**
 * Общая компонента для фильтров разных происшествий
 */
@UntilDestroy()
@Component({
  selector: 'map-emergency-filter',
  templateUrl: './map-emergency-filter.component.html',
  styleUrls: ['./map-emergency-filter.component.scss'],
})
export class MapEmergencyFilterComponent implements OnInit {
  /** Значения фильтров */

  public mapLayerFilters: ValuesOf<ControlsOf<IEmergencyMapFilterForm>>;

  /** Форма */
  public form: FormGroup<ControlsOf<IEmergencyMapFilterForm>>;

  /** Настройка компоненты Муниципальное образование */
  public municipals!: ISelectItem[];

  /** Настройка компоненты Тип КСиП */
  public ksipTypes$ = this.ksipTypesQuery.getAllKsipTypes();
  /** Видимость контрола "срочность" */
  public urgentlyVisible: boolean = false;

  /** Просмотр всех МО */
  private viewAllMunicipals!: boolean;

  constructor(
    private readonly access: AccessService,
    private readonly ksipTypesQuery: KsipTypesQuery,
    private readonly settings: Settings2Service,
    private readonly modalRef: NzModalRef,
  ) {}

  /** @ignore */
  public ngOnInit(): void {
    this.urgentlyVisible = this.access.accessMap['UrgentlyAvailable']?.visible ?? true;
    this.viewAllMunicipals = this.access.accessMap['viewAllMunicipals']?.visible;
    this.form = new FormGroup<ControlsOf<IEmergencyMapFilterForm>>({
      moId: new FormControl({
        value: this.viewAllMunicipals ? this.mapLayerFilters?.moId : this.settings.currentUser.organizationId.mo,
        disabled: !this.viewAllMunicipals,
      }),
      incidentTypeId: new FormControl(this.mapLayerFilters?.incidentTypeId),
      state: new FormControl(this.mapLayerFilters?.state),
      urgently: new FormControl(this.mapLayerFilters?.urgently),
      timeCreateFrom: new FormControl(this.mapLayerFilters?.timeCreateFrom),
      timeCreateTo: new FormControl(this.mapLayerFilters?.timeCreateTo),
    });
    this.municipals = this.settings.allMo
      .map((item: IAdminMunicipalSchemaDto) => {
        return <ISelectItem>{
          text: item.name,
          value: item.id,
        };
      })
      .filter((item: ISelectItem) => this.viewAllMunicipals
        || item.value === this.settings.currentUser.organizationId.mo);
  }

  /** Блокируем даты */
  public disabledStartDate = (): boolean => {
    const start = this.form.get('timeCreateFrom')?.value;
    const end = this.form.get('timeCreateTo')?.value;
    if (!start || !end) {
      return false;
    }
    return dayjs(start).isAfter(end);
  };

  /** Блокируем даты */
  public disabledEndDate = (endValue: Date): boolean => {
    const start = this.form.get('timeCreateFrom')?.value;
    if (!start) {
      return false;
    }
    if (dayjs(endValue).isSame(start, 'd')) {
      return false;
    }
    return dayjs(endValue).isSameOrBefore(start);
  };

  /** Очистка формы */
  public clear(): void {
    this.form.reset();
    if (!this.viewAllMunicipals) {
      this.form.controls.moId.patchValue(this.settings.currentUser.organizationId.mo);
    }
  }

  /** Закрытие формы */
  public close(): void {
    this.modalRef.destroy();
  }

  /** Фильтрация */
  public filtrate(): void {
    this.modalRef.destroy(this.form.getRawValue());
  }
}
