import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ISelectItem } from '@bg-front/core/models/interfaces';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { IAdminMunicipalSchemaDto } from 'smart-city-types';

import { IBaseMapFilterForm } from '../../models/interfaces';

@Component({
  selector: 'bg-map-chemical-monitoring-objects-filter',
  templateUrl: './map-chemical-monitoring-objects-filter.component.html',
  styleUrls: ['./map-chemical-monitoring-objects-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapChemicalMonitoringObjectsFilterComponent implements OnInit {
  /** Форма */
  public form: FormGroup<ControlsOf<IBaseMapFilterForm>>;
  /** Значения фильтров */
  public mapLayerFilters: ValuesOf<ControlsOf<IBaseMapFilterForm>>;

  /** Настройка компоненты Муниципальное образование */
  public municipals!: ISelectItem[];

  /** Просмотр всех МО */
  private viewAllMunicipals!: boolean;

  constructor(
    private readonly accessService: AccessService,
    private readonly settings: Settings2Service,
    private readonly modalRef: NzModalRef,
  ) {}

  public ngOnInit(): void {
    this.viewAllMunicipals = this.accessService.accessMap['viewAllMunicipals']?.visible;
    this.form = new FormGroup<ControlsOf<IBaseMapFilterForm>>({
      moId: new FormControl({
        value: this.viewAllMunicipals ? this.mapLayerFilters?.moId : this.settings.currentUser.organizationId.mo,
        disabled: !this.viewAllMunicipals,
      }),
    });
    this.municipals = this.settings.allMo
      .map((item: IAdminMunicipalSchemaDto) => {
        return <ISelectItem>{
          text: item.name,
          value: item.id,
        };
      })
      .filter((item: ISelectItem) => this.viewAllMunicipals
        || item.value === this.settings.currentUser.organizationId.mo);
  }

  /** Очистка формы */
  public clear(): void {
    this.form.reset();
    if (!this.viewAllMunicipals) {
      this.form.controls.moId.patchValue(this.settings.currentUser.organizationId.mo);
    }
  }

  /** Закрытие формы */
  public close(): void {
    this.modalRef.destroy();
  }

  /** Фильтрация */
  public filtrate(): void {
    this.modalRef.destroy(this.form.getRawValue());
  }
}
