import { Injectable } from '@angular/core';
import { IDictionaryInfo, IFormFieldData } from '@smart-city/core/interfaces';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, pluck, switchMap } from 'rxjs/operators';
import {
  IAbstractServiceData,
  IAnyObject,
  IAppealDetailDto,
  IDds01AdditionalInfoDto,
  IEmergencyDto,
  IEmergencyEventDto,
  IEmergencyTotalCars,
  IExecuteActionEventDto,
  IIntegrationEventDto,
  ILifeCycleStepActionDto,
  IEmergencyVaDetailDto,
} from 'smart-city-types';
import { ScConsole } from '@smart-city/core/utils';
import { BaseEmergencyService, MultiFileService } from '@bg-front/core/services';
import { EmergencyDto } from '../../models/classes';
import * as dayjs from 'dayjs';
import { Coordinates } from '@bg-front/core/models/classes';
import {
  IAddressDetailsDto,
  IEmergencyInfo,
  IEventMainInfo,
  IEventViewFormDataDto,
  IEcoMonitoringIntegrationEventDto,
  IMeasurementPointDto,
} from '../../models/interfaces';
import { retryWithDelay } from '@bg-front/core/models/helpers';
import { IHcsJournalDto } from '@bg-front/interaction-hcs-journal/models/interfaces';
import { GarFindFlatResponseElement } from '@bg-front/core';
import { IMiniCardIncidentSelectionEvent } from '@bg-front/core/models/interfaces';

/**
 * Сервис, служащий способом общения компонента EmergencyRegistryComponent с внешним миром (другие сервисы, бэкенд).
 */
@Injectable({
  providedIn: 'root',
})
export class EmergencyService extends BaseEmergencyService {
  /**
   * Позволяет следить за изменением поля «Тип КСиП» в информационной карте.
   */
  private callCardIncidentTypeChangedSource = new Subject<string>();
  public callCardIncidentTypeChanged$ = this.callCardIncidentTypeChangedSource.asObservable();

  /**
   * Позволяет следить за осуществлением привязки события к инциденту.
   */
  private incidentBoundSource = new Subject<string>();
  public incidentBound$ = this.incidentBoundSource.asObservable();

  /**
   * Позволяет следить за осуществлением отвязки события от инцидента.
   */
  private incidentUnboundSource = new Subject<string>();
  public incidentUnbound$ = this.incidentUnboundSource.asObservable();

  /**
   * Позволяет следить за выбором одной из карточек реестра событий
   */
  private eventSelectItem = new Subject<string>();
  public eventSelectItem$ = this.eventSelectItem.asObservable();

  /**
   * Позволяет следить за обновлением одной из карточек реестра событий
   */
  private eventUpdateItem = new Subject<string>();
  public eventUpdateItem$ = this.eventUpdateItem.asObservable();

  /**
   * Позволяет следить за добавлением события в реестр
   */
  private eventAdded = new Subject<string>();
  public eventAdded$ = this.eventAdded.asObservable();

  /**
   * Позволяет следить за выбором одной из карточек реестра происшествий
   */
  private incidentSelectItem = new Subject<IMiniCardIncidentSelectionEvent>();
  public incidentSelectItem$ = this.incidentSelectItem.asObservable();

  /**
   * Следит за кликом на ссылку адреса для перехода/центрирования по координатам.
   * Событие клика приходит из incidentMiniCard.
   */
  private incidentAddressLinkClicked = new Subject<Coordinates>();
  public incidentAddressLinkClicked$ = this.incidentAddressLinkClicked.asObservable();
  /**
   * Позволяет следить за обновлением одной из карточек реестра происшествий
   */
  private incidentUpdateItem = new Subject<string>();
  public incidentUpdateItem$ = this.incidentUpdateItem.asObservable();
  /**
   * Позволяет следить за добавлением происшествия в реестр
   */
  private incidentAdded = new Subject<string>();
  public incidentAdded$ = this.incidentAdded.asObservable();
  /**
   * Событие по которому следует закрыть любую форму
   */
  private closeItemForm = new Subject<string>();
  public closeItemForm$ = this.closeItemForm.asObservable();
  /**
   * Событие по которому следует закрыть форму события
   */
  private closeSomeEventForm = new Subject<string>();
  public closeEventForm$ = this.closeSomeEventForm.asObservable();

  /**
   * Позволяет следить за раскрытием блока "Зона действия в форме происшествия"
   */
  private coverageAreaOpened = new Subject<EmergencyDto>();
  public coverageAreaOpened$ = this.coverageAreaOpened.asObservable();

  constructor(
    rest: RestService,
    private readonly settings2: Settings2Service,
    private readonly multiFileService: MultiFileService,
  ) {
    super(rest);
  }

  /**
   * Кидаем событие, какой элемент выбрали в реестре событий
   */
  public selectEvent(eventId: string): void {
    this.eventSelectItem.next(eventId);
  }

  /**
   * Кидаем событие, какой элемент выбрали в реестре инцидентов
   */
  public selectIncident(event: IMiniCardIncidentSelectionEvent): void {
    this.incidentSelectItem.next(event);
  }

  /**
   * Кидаем событие при клике на адрес элемента
   * @param coordinates координаты для центровки карты формата "55.0, 36.0"
   */
  public incidentAddressLinkClick(coordinates: Coordinates): void {
    this.incidentAddressLinkClicked.next(coordinates);
  }

  /**
   * Кидаем событие, какой элемент обновился в реестре событий
   * @param eventId - ID события
   */
  public updateEvent(eventId: string): void {
    this.eventUpdateItem.next(eventId);
  }

  /**
   * Кидаем событие, какой элемент обновился в реестре инцидентов
   * @param eventId - ID инцидента
   */
  public updateIncident(eventId: string): void {
    this.incidentUpdateItem.next(eventId);
  }

  /**
   * Кидаем событие, когда элемент добавляется в реестр событий
   * @param event - события
   */
  public addNewEvent(event: string): void {
    this.eventAdded.next(event);
  }

  /**
   * Кидаем событие, когда элемент добавляется в реестр инцидентов
   * @param incident - ID инцидента
   */
  public addNewIncident(incident: any): void {
    this.incidentAdded.next(incident);
  }

  /**
   * Кидаем событие, когда раскрыт блок зона действия
   * @param incident - инцидент
   */
  public openCoverageArea(incident: EmergencyDto): void {
    this.coverageAreaOpened.next(incident);
  }

  /**
   * Получение инцидентов по Query
   * @param query - запрос
   * @param attributes - аттрибуты
   **/
  public getIncidentByQuery(query: IAnyObject, attributes?: string[]): Observable<IEmergencyDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          ...(attributes ? { attributes } : {}),
          query,
        },
      })
      .pipe(pluck('data', 'items'));
  }

  /**
   * Получение инцидента по Id
   * @param incidentId ID инцидента
   */
  public getIncidentById(incidentId: string): Observable<IEmergencyDto> {
    return this.rest
      .serviceRequest({
        action: 'getEmergencyById',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          linksMode: 'raw',
        },
        data: {
          id: incidentId,
        },
      })
      .pipe(
        map((data: IAbstractServiceData) => data.data as IEmergencyDto),
        switchMap((emergency: IEmergencyDto) => {
          if (emergency?.parentEventId && emergency.parentEventId['appealDetail']) {
            return this.getAppealDetailById(emergency.parentEventId['appealDetail']).pipe(
              map((appealDetail: IAppealDetailDto) => {
                if (appealDetail) {
                  emergency.parentEventId['appealDetail'] = appealDetail;
                }
                return emergency;
              }),
            ) as Observable<IEmergencyDto>;
          }
          return of(emergency);
        }),
      );
  }

  /**
   * Получение инцидента по Id для отображения внутри ScrollableContainer
   * @param incidentId ID инцидента
   */
  public getIncidentByIdForMiniCard(incidentId: string): Observable<IAnyObject> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          attributes: [
            'id',
            'organization.mo.id',
            'number',
            'lifeCycleStepId.status.sysname',
            'lifeCycleStepId.name',
            'incidentTypeId.id',
            'incidentTypeId.name',
            'addressFact.fullText',
            'addressFact.manual',
            'lifeCycleStepId.endOfCycle',
            'timeCreate',
            'docType.id',
            'docType.sysname',
            'organization.organizationType.sysname',
            'coordinates',
          ],
          query: {
            id: incidentId,
          },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return res.data.items[0];
        }),
      );
  }

  /**
   * Получение информации об инциденте для отображения на рабочем столе
   * @param emergencyId - ID происшествия
   */
  public getEmergencyForInfoComponent(emergencyId: string): Observable<IEmergencyInfo> {
    const emergencyInfo: IEmergencyInfo = {};
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          query: { id: emergencyId },
          attributes: [
            'id',
            'number',
            'coordinates',
            'docType.sysname',
            'docType.name',
            'incidentTypeId.name',
            'ksipDetailsId.name',
            'organization.name',
            'lifeCycleStepId.name',
            'addressFact',
            'timeCreate',
            'workTermFrom',
            'workTermTo',
            'parentId',
            'deadline',
          ],
        },
      })
      .pipe(
        switchMap((result: IAbstractServiceData) => {
          const emergency = result.data.items[0];
          emergencyInfo.id = emergency.id;
          emergencyInfo.number = emergency.number;
          emergencyInfo.coordinates = emergency.coordinates;
          emergencyInfo.docTypeSysName = emergency.docType.sysname;
          emergencyInfo.docTypeName = emergency.docType.name;
          emergencyInfo.incidentType = emergency.incidentTypeId?.name;
          emergencyInfo.ksipDetails = emergency.ksipDetailsId?.name;
          emergencyInfo.organization = emergency.organization?.name;
          emergencyInfo.lifeCycleStep = emergency.lifeCycleStepId.name;
          emergencyInfo.address = emergency.addressFact?.fullText;
          emergencyInfo.parentId = emergency.parentId;
          emergencyInfo.deadline = emergency.deadline && dayjs(emergency.deadline).format('DD.MM.YYYY HH:mm:ss');
          emergencyInfo.timeCreate = dayjs(emergency.timeCreate).format('DD.MM.YYYY HH:mm:ss');
          emergencyInfo.workTerms =
            emergency.workTermFrom &&
            emergency.workTermTo &&
            `${dayjs(emergency.workTermFrom).format('DD.MM.YYYY HH:mm')} - ${dayjs(emergency.workTermTo).format(
              'DD.MM.YYYY HH:mm',
            )}`;
          return this.rest.serviceRequest({
            action: 'select',
            service: { name: 'Emergency' },
            entity: {
              name: 'AddressDetails',
              query: { emergencyId: emergencyInfo.parentId || emergencyInfo.id },
              attributes: ['address'],
            },
          });
        }),
        map((coverageArea: IAbstractServiceData) => {
          emergencyInfo.coverageArea = coverageArea.data.items.map(
            (point: IAddressDetailsDto) => `${point.address.latitude}, ${point.address.longitude}`,
          );
          return emergencyInfo;
        }),
      );
  }

  /**
   * Получение события по Id
   * @param eventId ID события
   */
  public getEventById(eventId: string): Observable<IEmergencyEventDto> {
    return this.rest
      .serviceRequest({
        action: 'getEventById',
        service: { name: 'Emergency' },
        entity: {
          name: 'Events',
          linksMode: 'raw',
        },
        data: {
          id: eventId,
        },
      })
      .pipe(
        map((data: IAbstractServiceData) => data.data as IEmergencyEventDto),
        switchMap((event: IEmergencyEventDto) => {
          return this.multiFileService.getFilesFromSfs(event.documents).pipe(
            map((documents) => {
              event.documents = documents;
              return event;
            }),
          );
        }),
      );
  }

  /**
   * Получения события по ExtId
   * @param id - string
   * @return
   */
  public getEventByExtId(extId: string): Observable<IEmergencyEventDto> {
    return this.rest
      .serviceRequest({
        action: 'getEventByExtId',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
        },
        data: {
          extId,
        },
      })
      .pipe(
        map((result: IAbstractServiceData) => {
          return result.data;
        }),
      );
  }

  /**
   * Получение инцидента по Id
   * @param id ID инцидента
   */
  public getEddsEventViewById(id: string): Observable<IEventViewFormDataDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          linksMode: 'raw',
          name: 'Events',
          attributes: [
            'address.fullText',
            'address.latitude',
            'address.longitude',
            'moId.name',
            'districtId.name',
            'number',
            'incidentId',
            'incidentId.number',
            'incidentId.id',
            'details',
            'description',
            'responsibleId.fio',
            'ksipTypeId.name',
            'ksipDetailsId',
            'ksipDetailsId.name',
            'ksipInfo',
            'ksipTime',
            'comment',
            'parentId.sfsId',
            'parentId.extnum',
            'urgently',
            'threatPopulation',
            'threatOrganization',
            'isHandled',
            'attractionServices',
            'attractionOrganizations',
            'declarerId.fio',
            'declarerId.phone',
            'declarerId.contactPhone',
            'declarerId.building',
            'declarerId.address',
            'exactCoordinates',
            'documents',
            'workTermFrom',
            'workTermTo',
            'isSendingToJkhReform',
            'resourceConstraintType',
            'isNestedRestrictions',
            'jkhDirection',
            'jkhObject',
            'sourceId',
            'incidentId.lifeCycleStepId.lifeCycleId',
            'organizationId',
            'viewed',
            'isTest',
            'vaDetail.camera1Id',
            'vaDetail.file1Id',
            'vaDetail.frame',
            'byCoordinates',
          ],
          query: {
            id,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          const item = response.data.items[0] as IAnyObject;
          return <IEventViewFormDataDto>{
            id,
            number: item['number'],
            sfsId: item['parentId.sfsId'],
            incidentId: item['incidentId'],
            declarerId: item['declarerId'],
            incidentNumber: item['incidentId.number'],
            isHandled: item['isHandled'],
            extnum: item['parentId.extnum'],
            mainInfo: {
              address: item['address.fullText'],
              moId: item['moId.name'],
              districtId: item['districtId.name'],
              details: item['details'],
              ksipTypeName: item['ksipTypeId.name'],
              ksipDetailsName: item['ksipDetailsId.name'],
              description: item['description'],
              ksipTime: item['ksipTime'],
              ksipInfo: item['ksipInfo'],
              comment: item['comment'],
              urgently: item['urgently'],
              threatPopulation: item['threatPopulation'],
              threatOrganization: item['threatOrganization'],
              responsibleUser: item['responsibleId.fio'],
              coordinates: new Coordinates(item['address.latitude'], item['address.longitude']),
              exactCoordinates: new Coordinates(item['exactCoordinates']),
              workTermFrom: item['workTermFrom'],
              workTermTo: item['workTermTo'],
              isSendingToJkhReform: item['isSendingToJkhReform'],
              resourceConstraintType: item['resourceConstraintType'],
              isNestedRestrictions: item['isNestedRestrictions'],
              viewed: item['viewed'],
              organizationId: item['organizationId'],
              jkhDirection: item['jkhDirection'],
              jkhObject: item['jkhObject'],
              lifeCycleId: item['incidentId.lifeCycleStepId.lifeCycleId'],
              sourceId: item['sourceId'],
              byCoordinates: item['byCoordinates'],
            },
            isTest: item['isTest'],
            attractionServices: item['attractionServices'],
            attractionOrganizations: item['attractionOrganizations'],
            documents: item['documents'],
            vaDetail: item['vaDetail'],
          };
        }),
        switchMap((event: IEventViewFormDataDto) => {
          return this.multiFileService.getFilesFromSfs(event.documents).pipe(
            map((documents) => {
              event.documents = documents;
              return event;
            }),
          );
        }),
      );
  }

  /**
   * Получение инцидента по Id
   * @param eventId ID инцидента
   */
  public getEventMainById(eventId: string): Observable<IEventMainInfo> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Events',
          attributes: ['id', 'sourceId.sysname', 'isHandled'],
          query: {
            id: eventId,
          },
        },
      })
      .pipe(
        map((data: IAbstractServiceData) => data.data.items[0]),
        map((data: IAnyObject) => {
          let info: IEventMainInfo = null;
          if (data) {
            info = {
              id: data['id'],
              isHandled: data['isHandled'],
              sourceSysName: data['sourceId.sysname'],
            };
          }
          return info;
        }),
      );
  }

  /**
   * Получение события по Id для отображения внутри ScrollableContainer
   * @param eventId ID события
   */
  public getEventByIdForMiniCard(eventId: string): Observable<IAnyObject> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          attributes: [
            'state',
            'timeCreate',
            'closeReasonId.name',
            'comment',
            'direction',
            'number',
            'isHandled',
            'comment',
            'id',
            'sourceId.name',
          ],
          name: 'Events',
          query: {
            id: eventId,
          },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return res.data.items[0];
        }),
      );
  }

  /**
   * Обработка действий с инцидентом (Сохранить, Передать и т.д)
   * Если ицидент ещё не существует, то после выполнения действия, обновим мо
   * @param data
   */
  public executeIncidentAction(data: IExecuteActionEventDto<IEmergencyDto>): Observable<IEmergencyDto> {
    return this.rest
      .serviceRequest({
        data,
        action: 'actionsIncident',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
        },
      })
      .pipe(
        switchMap((response: IAbstractServiceData) => {
          return this.getIncidentById(response.data.id);
        }),
      );
  }

  /**
   * Проверка на наличие открытых поручений привязанных к происшествию
   * @param parentId - ID происшествия
   */
  public isExistOpenOrders(parentId: string): Observable<boolean> {
    return this.rest
      .serviceRequest({
        action: 'count',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          query: {
            parentId,
            'docType.sysname': 'order',
            'lifeCycleStepId.endOfCycle': { $ne: true },
          },
        },
      })
      .pipe(map((orders: IAbstractServiceData) => !!+orders.data.totalCount));
  }

  /**
   * Обработка действий с событием (Сохранить, Передать и т.д)
   * Если событие ещё не существует, то после выполнения действия, обновим мо
   * @param data
   */
  public executeEventAction(data: IExecuteActionEventDto<IEmergencyEventDto>): Observable<IEmergencyEventDto> {
    return this.rest
      .serviceRequest({
        data,
        action: 'actionsEvent',
        service: { name: 'Emergency' },
        entity: {
          name: 'Events',
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          const data = { ...response.data };
          data.attractedServices = data.attractionServices;
          return data as IEmergencyEventDto;
        }),
      );
  }

  /**
   * Функция для тестового создания инцидентов
   * @param sourceTypeName Название источника события
   */
  public getLifeCycleButtons(sourceTypeName: string): Observable<IAbstractServiceData> {
    const incidentDocType = this.settings2.getDictionaryByType('docType').find((item: IFormFieldData) => {
      if (sourceTypeName === 'ДДС 01' && item.text === 'Поручение') return item;
      if (item.text === 'Инцидент') return item;
    });

    const incidentSourceType = this.settings2
      .getDictionaryByTypeSysName('sourceTypes')
      .find((item: IDictionaryInfo) => item.name === sourceTypeName);

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'LifeCycleStep',
          linksMode: 'object',
        },
      })
      .pipe(
        /**
         * Это как временное решение, до исправления ситуации с бэком
         */
        map((data) => {
          data.data.items = data.data.items.filter((item: any) => {
            return (
              (item.lifeCycleId || {}).docType === incidentDocType.id &&
              (item.lifeCycleId || {}).sourceType === incidentSourceType.id &&
              item.status.sysname === 'new'
            );
          });
          return data;
        }),
      );
  }

  /**
   * Получение списка actions по Id шага ЖЦ
   * @param lifeCycleStepId
   */
  public getLifeCycleButtonsById(lifeCycleStepId: string): Observable<ILifeCycleStepActionDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'LifeCycleStep',
          linksMode: 'object',
          query: {
            id: lifeCycleStepId,
          },
        },
      })
      .pipe(
        map<IAbstractServiceData, ILifeCycleStepActionDto[]>((data: IAbstractServiceData) => {
          return data?.data?.items[0].actions;
        }),
      );
  }

  /**
   * Метод запроса суммы задействованных машин
   * @param id
   */
  public getEmergencyTotalCars(id: string): Observable<IEmergencyTotalCars> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          attributes: ['dds01AdditionalInfo', 'dds01AdditionalInfo.id', 'dds01AdditionalInfo.totalCars'],
          query: {
            parentId: id,
          },
        },
      })
      .pipe(
        map((data: IAbstractServiceData) => {
          const emergencies = data?.data?.items;
          const totalCars: IEmergencyTotalCars = {
            totalPoliceCars: 0,
            totalFireCars: 0,
            totalAmbulanceCars: 0,
          };

          emergencies.forEach((item: IAnyObject) => {
            const ddsInfo = <IDds01AdditionalInfoDto>item.dds01AdditionalInfo || <IDds01AdditionalInfoDto>{};
            if (ddsInfo.totalCars) {
              const fire = ddsInfo.totalCars.toString();
              totalCars.totalFireCars += parseInt(fire, 10);
            }
          });
          return totalCars;
        }),
      );
  }

  /** Сохранение эвента */
  public saveEvent(model: IEmergencyEventDto): Observable<IEmergencyEventDto> {
    if (model.id) {
      return this.rest
        .serviceRequest({
          action: 'eventUpdate',
          service: { name: 'Emergency' },
          entity: {
            name: 'Events',
            query: {
              id: model.id,
            },
          },
          data: model,
        })
        .pipe(
          map((response: IAbstractServiceData) => {
            return model;
          }),
        );
    }
    return this.rest
      .serviceRequest({
        action: 'eventCreate',
        service: { name: 'Emergency' },
        entity: {
          name: 'Events',
        },
        data: model,
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          model.id = (response.data || {}).id;
          return model;
        }),
      );
  }

  /** Сохранение integration Event */
  public saveIntegrationEvent(model: IIntegrationEventDto): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'update',
      service: { name: 'Integration' },
      entity: {
        name: 'Events',
        query: { extId: model.extId },
      },
      data: model,
    });
  }

  /** Установка значения параметра На контроле ЦУКС
   * @param id - ID обновляемой записи
   * @param supervisedByCuks - значение На контроле ЦУКС
   */
  public setSupervision(id: string, supervisedByCuks: boolean): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'update',
      service: {
        name: 'Emergency',
      },
      entity: {
        name: 'Emergency',
        query: {
          id,
        },
      },
      data: {
        supervisedByCuks,
      },
    });
  }

  /** Установка значения параметра На контроле ЦУКС
   * @param id - ID обновляемой записи
   * @param data - данные для обновления инцидента
   */
  public updateEmergency(id: string, data: IAnyObject): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      data,
      action: 'update',
      service: {
        name: 'Emergency',
      },
      entity: {
        name: 'Emergency',
        query: {
          id,
        },
      },
    });
  }

  /** Проверка на дубль инцидента */
  public hasDoubleEmergencies(coordinates: Coordinates, radius: number, incidentTypeId: string): Observable<boolean> {
    const incidentDocType = this.settings2
      .getDictionaryByType('docType')
      .find((item: IFormFieldData) => item.text === 'Инцидент');

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          attributes: ['id'],
          query: {
            incidentTypeId,
            point: {
              $radius: {
                $lat: coordinates.lat,
                $lon: coordinates.lon,
                $meters: radius,
              },
            },
            lifeCycleStepId: {
              endOfCycle: { $ne: true },
            },
            docType: incidentDocType.id,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (((response || {}).data || {}).items || []).length > 0;
        }),
      );
  }

  public selectEmergencyByQuery(query: IAnyObject, attributes?: string[]): Observable<IEmergencyDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          query,
          attributes,
          name: 'Emergency',
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response.data.items;
        }),
      );
  }

  /** TODO: Комментарий */
  public closeForm(): void {
    this.closeItemForm.next();
  }

  /** TODO: Комментарий */
  public closeEventForm(): void {
    this.closeSomeEventForm.next();
  }

  /** Сохранение данных отчета */
  public saveReport(model) {
    if (model.id) {
      return this.rest
        .serviceRequest({
          action: 'update',
          service: { name: 'Report' },
          entity: {
            name: 'ReportEmergency',
            query: {
              id: model.id,
            },
          },
          data: model,
        })
        .pipe(
          map((response: IAbstractServiceData) => {
            return model;
          }),
        );
    }
    return this.rest
      .serviceRequest({
        action: 'insert',
        service: { name: 'Report' },
        entity: {
          name: 'ReportEmergency',
        },
        data: model,
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          model.id = (response.data || {}).id;
          return model;
        }),
      );
  }

  /** Получение информации для блока "Прогнозирование"
   * @param id id идентификатор инцидента
   * */
  public getEmergencyProjectedRiskId(id: string): Observable<string | undefined> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          attributes: ['incidentTypeId.projectedRisk'],
          query: { id },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return (res.data.items[0] || {})['incidentTypeId.projectedRisk'];
        }),
      );
  }

  /** Получение  Integration Event по parentId события */
  public getIntegrationEventById(id: string): Observable<IIntegrationEventDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Integration' },
        entity: {
          name: 'Events',
          query: { id },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || [])[0] as IIntegrationEventDto;
        }),
      );
  }

  /** Получение  Integration Event по ExtId */
  public getIntegrationEventByExtId(extId: string): Observable<IIntegrationEventDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Integration' },
        entity: {
          name: 'Events',
          query: { extId },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || [])[0] as IIntegrationEventDto;
        }),
      );
  }

  /**
   * Многократный поиск происшествия по globalId.
   * Возвращает данные по запросу к базе, содержащие либо найденное происшествие,
   * либо 5 актуальных (созданные последними) содержащие какой-то globalId.
   * @param globalId - идентификатор карточки УКИО от системы 112
   * @param options - опции для многократного поиска, где delay - задержка в мс, maxRetryAttempts - кол-во попыток
   * @return результат поиска происшествия {@link IAbstractServiceData}
   */
  public getIncidentByGlobalId(
    globalId: string,
    options?: { delay: number; maxRetryAttempts: number },
  ): Observable<IAbstractServiceData> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          query: { globalId },
        },
      })
      .pipe(
        switchMap((res: IAbstractServiceData) => {
          if (!res.data.items.length) {
            return throwError(`Происшествие с globalId "${globalId}" не найдено`);
          }
          return of(res);
        }),
        retryWithDelay({
          delay: options?.delay || 250,
          maxRetryAttempts: options?.maxRetryAttempts || 10,
          onRetry: (attempt, delay, error) => {
            // Событие срабатывающее перед каждой новой попыткой
          },
        }),
        catchError((err: Error) => {
          ScConsole.warning(`${err}`);
          ScConsole.warning('Поиск последних происшествий содержащих globalId');
          return this.rest.serviceRequest({
            action: 'select',
            service: { name: 'Emergency' },
            entity: {
              name: 'Emergency',
              query: {
                globalId: { $ne: null },
                docType: { sysname: 'incident' },
              },
              sort: {
                field: 'number',
                direction: 'desc',
              },
              limit: 5,
            },
          });
        }),
      );
  }

  /**
   * Получение обращения по Id
   * @param id ID обращения
   */
  public getAppealDetailById(id: string): Observable<IAppealDetailDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'AppealDetail',
          query: { id },
        },
      })
      .pipe(
        map((data: IAbstractServiceData) => {
          return data.data?.items ? data.data?.items[0] : (null as IAppealDetailDto);
        }),
      );
  }

  /** Сброс видимости инцидентов на Интернет портале, если КСиП запрещён к экспорту */
  public rejectShowIncidentOnInternetPortal(id: string): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'update',
      service: { name: 'Emergency' },
      entity: {
        name: 'Emergency',
        query: {
          incidentTypeId: id,
        },
      },
      data: {
        showOnPortal: false,
      },
    });
  }

  /**
   * Обновляю поле просмотрено
   * @param id
   * @param entity
   * @return
   */
  public updateViewedField(id: string, entity: 'Emergency' | 'Events'): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'update',
      service: { name: 'Emergency' },
      entity: {
        name: entity,
        query: {
          id,
        },
      },
      data: {
        viewed: new Date().getTime(),
      },
    });
  }

  /**
   * Получение происшествия на основании родительского происшествия и ответственной организации.
   * Используется при создании донесений.
   * @param parentId - ID родительского происшествия
   * @param organization - ответственная организация по происшествию
   */
  public getIncidentByParentIdAndOrganization(parentId: string, organization: string): Observable<string> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          attributes: ['id'],
          query: { parentId, organization },
        },
      })
      .pipe(map((res: IAbstractServiceData) => res.data.items[0]?.id));
  }

  /** Получение детализации по адресам для происшествий */
  public getAddressDetails(query: IAnyObject): Observable<IAnyObject[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'AddressDetails',
          query,
          sort: {
            field: 'number',
            direction: 'asc',
          },
          attributes: [
            'id',
            'emergencyId',
            'address',
            'coordinates',
            'point',
            'numberOfRegistered',
            'numberOfResidents',
            'buildingKindId.id',
            'buildingKindId.name',
            'byCoordinates',
            'number',
          ],
        },
      })
      .pipe(
        map((data: IAbstractServiceData) => {
          return data.data?.items ? data.data?.items : ([] as IAnyObject[]);
        }),
      );
  }

  /** Сохранение записи в таблице детализации по адресам */
  public saveAddressDetails(data: IAddressDetailsDto): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'insert',
      service: { name: 'Emergency' },
      entity: {
        name: 'AddressDetails',
      },
      data,
    });
  }

  /** Обновление записи в таблице детализации по адресам */
  public updateAddressDetails(data: IAddressDetailsDto): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'update',
      service: { name: 'Emergency' },
      entity: {
        name: 'AddressDetails',
        query: { id: data.id },
      },
      data,
    });
  }

  /** Обновление записи таблицы Emergency_VaDetail */
  public updateVaDetail(data: IEmergencyVaDetailDto): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'update',
      service: { name: 'Emergency' },
      entity: {
        name: 'VaDetail',
        query: { id: data.id },
      },
      data,
    });
  }

  /** Получение точки измерения */
  public getMeasurementPoint(eventId: string): Observable<IMeasurementPointDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'EcoMonitoringIntegration' },
        entity: {
          name: 'IntegrationEvents',
          query: { eventId },
          attributes: ['measurementPointId'],
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => res.data.items[0] as IEcoMonitoringIntegrationEventDto),
        switchMap((event: IEcoMonitoringIntegrationEventDto) => {
          return this.rest.serviceRequest({
            action: 'select',
            service: { name: 'EcoMonitoringIntegration' },
            entity: {
              name: 'MeasurementPoints',
              query: { id: event.measurementPointId },
              attributes: ['name'],
            },
          });
        }),
        map((res: IAbstractServiceData) => res.data.items[0] as IMeasurementPointDto),
      );
  }

  /**
   * Запрос результатов отправки информации в реформу ЖКХ
   * @param emergencyId - ID происшествия по которому отправлялась информация
   * @private
   */
  public getLastHcsInteractionResult(emergencyId: string): Observable<IHcsJournalDto> {
    return this.rest
      .serviceRequest({
        action: 'getLastHcsInteractionResult',
        service: { name: 'HcsReformIntegration' },
        data: { emergencyId },
      })
      .pipe(
        pluck('data'),
        map((hcsInteractionResult: IHcsJournalDto) => ({
          ...hcsInteractionResult,
          result: this.settings2.getDictionaryById(<string>hcsInteractionResult.result),
          reformHcsResponse: JSON.parse(<string>hcsInteractionResult.reformHcsResponse),
        })),
      );
  }

  /** Отправка инцидента в АИУС РСЧС */
  public sendEmergencyToAtlas(id: string): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'sendEmergency',
      service: { name: 'RiskAtlasIntegration' },
      data: id,
    });
  }

  /**
   * Проверка возможности отправки информации о событии/происшествии в Реформу ЖКХ
   * @param incidentTypeId - тип КСиП
   * @param jkhObjectId - Объект ЖКХ
   * @param ksipDetailsId - детализация КСиП
   * @param addressFact - адрес инцидента
   * @param incidentId - ID инцидента
   */
  public checkHcsReformSendAbility(
    incidentTypeId: string,
    jkhObjectId: string,
    ksipDetailsId: string,
    addressFact?: GarFindFlatResponseElement,
    incidentId?: string,
  ): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'checkHcsReformSendAbility',
      service: { name: 'Emergency' },
      data: { incidentTypeId, jkhObjectId, ksipDetailsId, incidentId, addressFact },
    });
  }
}
