import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@bg-front/core/components';
import { ISubstrateDto } from '@bg-front/core/models/interfaces';
import { SubstratesQuery } from '@bg-front/core/services';
import { IMapBaseEvent } from '@bg-front/map/models/interfaces';
import { MapBaseService } from '@bg-front/map/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IMapBaseInitOptions, MapBaseCoordinatesType } from '@smart-city/maps/sc';

import { IMiniMapMarkerPositionEvent } from '../../models/interfaces';

/**
 * Компонент отвечает за мини-карту в форме редактирования происшествия
 */
@UntilDestroy()
@Component({
  selector: 'bg-mini-map',
  templateUrl: './mini-map.component.html',
  styleUrls: ['./mini-map.component.scss'],
})
export class MiniMapComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Идентификатор карты */
  @Input()
  public mapId: string = 'mini-map';
  /** флаг отсутствия важных объектов */
  @Input()
  public withoutSignificantObjects: boolean = false;
  /**
   * Центр карты
   */
  @Input()
  public center: MapBaseCoordinatesType = undefined;

  /**
   * Зум карты
   */
  @Input()
  public zoom: number = undefined;

  /**
   * Запрещаем изменение позиции маркера
   */
  @Input()
  public disableChangePosition = false;
  /**
   *  Координаты по умолчанию для карты
   */
  @Input()
  public defaultMarkerCoordinate: [number, number];

  /**
   * Извещаем о изменении позиции маркера
   */
  @Output()
  public changeMarkerPosition: EventEmitter<IMiniMapMarkerPositionEvent> =
    new EventEmitter<IMiniMapMarkerPositionEvent>(true);
  /**
   * Настройка карты
   */
  public mapOptions = <IMapBaseInitOptions>{};

  /**
   * @ignore
   * @param mapService
   * @param miniMapService
   * @param settings
   * @param gisService
   */
  constructor(private readonly substratesQuery: SubstratesQuery, private readonly mapBaseService: MapBaseService) {
    super();
  }

  /**
   * @ignore
   */
  public ngOnInit(): void {
    const substrate = this.substratesQuery.getActive() as ISubstrateDto;

    const url = substrate.link.startsWith('/', 0)
      ? `//${window.location.host}${substrate.link}`
      : `//${substrate.link}`;

    this.mapOptions.mapId = this.mapId;
    this.mapOptions.url = `${window.location.protocol}${url}`;
    this.mapOptions.zoom = this.zoom || 16;
    this.mapOptions.center = this.center;
    this.mapOptions.maxZoom = 18;
    this.mapOptions.mapStyle = { width: '100%', height: '100%' };
    this.mapOptions['attribution'] = substrate.attribution;

    this.mapBaseService
      .getObservableMapEvents(this.mapId, 'mapClick')
      .pipe(untilDestroyed(this))
      .subscribe((event: IMapBaseEvent) => {
        if (!this.disableChangePosition) {
          this.changeMarkerPosition.emit(<IMiniMapMarkerPositionEvent>{
            coordinates: event.coordinates,
          });
        }
      });

    // this.mapModel
    //   .getObservableMapEvents()
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((event: IMapBaseEvent) => {
    //     switch (event.typeEvent) {
    //       case 'mapClick': {
    //         if (!this.disableChangePosition) {
    // this.changeMarkerPosition.emit({
    //   coordinates: event.coordinates,
    // });
    //         }
    //         break;
    //       }
    //       case 'mapReady':
    //         this.isReady = true;
    //         this.miniMapService.significantObjects$
    //           .pipe(takeUntil(this.ngUnsubscribe))
    //           .subscribe((data: IAnyObject) => {
    //             if (this.defaultMarkerCoordinate) {
    //               this.markers.set('addCirclesMarker', {
    //                 objectId: 'addCirclesMarkerByClick',
    //                 coordinates: this.defaultMarkerCoordinate,
    //               });
    //               this.refresh();
    //             }
    //             if (data) {
    //               this.mapModel.removeLayer('miniMapVewObjects');
    //               this.addSignificantObjectLayerToMap(data);
    //               this.mapModel.viewLayer('Важные объекты', true);
    //             }
    //           });
    //         this.mapReady.emit();
    //         break;
    //       default:
    //         break;
    //     }
    //   });
  }
}
