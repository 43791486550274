import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { IMiniCardIncidentSelectionEvent } from '@bg-front/core/models/interfaces';
import { IScrollableContainerComponent } from '@smart-city/core/common';
import * as moment from 'moment';
import { catchError, takeUntil } from 'rxjs/operators';
import { IAbstractServiceData } from 'smart-city-types';
import { IAnyObject } from 'smart-city-types/base';
import { EmergencyService } from '../../services';

/**
 * Компонент для отображения минимальной информации о инциденте
 * Реализует интерфейс IScrollableContainerComponent,
 * что дает возможность использовать его в динамическом
 * скроллируемом контейнере (ScrollableContainer)
 */
@Component({
  selector: 'bg-incident-mini-card',
  templateUrl: './incident-mini-card.component.html',
  styleUrls: ['./incident-mini-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentMiniCardComponent
  extends BaseComponent
  implements OnInit, OnDestroy, IScrollableContainerComponent
{
  /**
   * Данные для отображения
   */
  public incident: any;

  /**
   * Активная карточка.
   */
  public isActive: boolean = false;

  /**
   * Id Активного события.
   */
  public selectedEvent: string = undefined;

  /**
   * Показать привязанные события?
   */
  public showEvents: boolean = false;

  /** Время создания Инцидента */
  public incidentTimeCreate: string;

  /** Флаг карточки с типом поручения, определяет иконку для карточки */
  public isOrderCard: boolean = false;

  /** Время создания Инцидента */
  public eventTimeCreate: string;
  /**
   * ID связанного интерфейса
   */
  public boundIncidentId: string;
  /**
   * Связанные события
   */
  public events: IAnyObject[];
  /**
   * Флаг обозначающий, что мышь находится на текущем элементе
   */
  private mouseOnCurrentElement = false;

  /**
   * Свойство реализуемое интерфейсом. Используется для инициализации
   */
  @Input()
  public set data(val: any) {
    this.incident = val;
    this.isOrderCard = this.incident['docType.sysname'] === 'order';
    this.incidentTimeCreate = moment(this.incident['timeCreate']).format('DD.MM.YYYY HH:mm:ss');
    this.cdr.markForCheck();
  }

  /**
   * Следим, что мышка на элементе
   */
  @HostListener('mouseenter') onMouseenter(): void {
    this.mouseOnCurrentElement = true;
  }

  /**
   * Следим, что мышка покинула элемент
   */
  @HostListener('mouseleave') onMouseleave(): void {
    this.mouseOnCurrentElement = false;
  }

  /**
   *
   * @param cdr детектор изменений
   * @param emergencyRegistry Сервис инцидентов
   */
  constructor(
    private cdr: ChangeDetectorRef,
    private emergencyRegistry: EmergencyService,
    private renderer: Renderer2
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    /** Подписка на привязку события к инциденту. */
    this.emergencyRegistry.incidentBound$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((incidentId: string) => {
      this.boundIncidentId = incidentId;
      this.cdr.markForCheck();
    });

    /** Подписка на отвязку события от инцидента. */
    this.emergencyRegistry.incidentUnbound$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.boundIncidentId = null;
      this.cdr.markForCheck();
    });

    /** Подписка на выбор карточки происшествия в реестре */
    this.emergencyRegistry.incidentSelectItem$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((incident: IMiniCardIncidentSelectionEvent) => {
        if (incident.id === this.incident.id) {
          this.isActive = true;
        } else {
          this.isActive = false;
          this.showEvents = false;
        }
        this.cdr.markForCheck();
      });

    /** Подписка на выбор карточки события в реестре */
    this.emergencyRegistry.eventSelectItem$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((eventId: string) => {
      const event = (this.events || []).find((item: IAnyObject) => item.id === eventId);
      if (event) {
        this.selectedEvent = event.id;
      } else {
        this.selectedEvent = undefined;
      }
      this.cdr.markForCheck();
    });

    /** Подписка на обновление происшествия в реестре */
    this.emergencyRegistry.incidentUpdateItem$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: string) => {
      if (event === this.incident.id) {
        this.emergencyRegistry
          .getIncidentByIdForMiniCard(event)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            catchError((err: Error) =>
              this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка при загрузке информации карточки происшествия'),
            ),
          )
          .subscribe((res: IAnyObject) => {
            this.incident = res;
            this.cdr.markForCheck();
          });
      }
      this.cdr.markForCheck();
    });
  }

  /** Форматирует дату создания происшествия в необходимый для отображения вид */
  public formatDate(date: number): string {
    return moment(date).format('DD.MM.YYYY HH:mm:ss');
  }

  /**
   * Обработчик, вызываемый при клике на адрес происшествия.
   * @param event - информация о событии
   */
  public onClickAddress(event: Event): void {
    event.preventDefault();
    if (!this.incident['coordinates']) return;
    const coordinates = new Coordinates(this.incident['coordinates']);
    this.emergencyRegistry.incidentAddressLinkClick(coordinates);
  }

  /** Обработчик, вызываемый при клике в любое место карточки, кроме шеврона и строки адреса. */
  public onClickIncident($event): void {
    if (!$event.defaultPrevented) {
      if (!this.isActive) {
        this.emergencyRegistry.selectIncident({ id: this.incident.id, docType: this.incident['docType.sysname'] });
      } else {
        this.emergencyRegistry.closeForm();
      }
    }
  }

  /**
   * Обработчик, вызываемый при клике на шеврон. Открыть привязанные события.
   * @param $event - информация о событии.
   */
  public onEventsClick($event: Event): void {
    // Ставим флаг обработки события
    $event.preventDefault();
    if (!this.showEvents) {
      this.emergencyRegistry
        .getEvents({ incidentId: this.incident['id'] }, undefined, { field: 'timeCreate', direction: 'desc' })
        .pipe(
          takeUntil(this.ngUnsubscribe),
          catchError((err: Error) =>
            this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка при загрузке списка связанных событий'),
          ),
        )
        .subscribe((response: IAbstractServiceData) => {
          if (response) {
            this.events = [...response.data.items];
            this.events.forEach(
              (item: IAnyObject) => (item['eventKsipTime'] = moment(item['ksipTime']).format('DD.MM.YYYY HH:mm:ss')),
            );

            const unlisten = this.renderer.listen('document', 'click', () => {
              if (!this.mouseOnCurrentElement && !this.isActive && this.showEvents) {
                this.showEvents = false;
                unlisten();
                this.cdr.markForCheck();
              }
            })

            this.showEvents = true;
            this.cdr.markForCheck();
          }
        });
    } else {
      this.showEvents = false;
    }
  }

  /**
   * Получение цвета маркера для отображения в карточке
   * Пока заглушка в будущем будет иницилизироваться на основании инфомрации об объекте.
   */
  public getMarkerColor(): string {
    return '#fb4b53';
  }

  /**
   * Кидаем эвент с выбранным событием
   */
  public selectEvent($event: Event, event: IAnyObject): void {
    $event.preventDefault();
    if (this.selectedEvent === event.id) {
      this.selectedEvent = undefined;
      this.emergencyRegistry.closeEventForm();
    } else {
      this.emergencyRegistry.selectEvent(event.id);
    }
  }
}
