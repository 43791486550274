import { Component, Input, OnInit } from '@angular/core';
import { IMapLayerEntityFilter, IOrganization, IOrganizationType, ISelectItem } from '@bg-front/core/models/interfaces';
import { OrganizationsQuery, OrganizationTypesQuery } from '@bg-front/core/services';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IScSelectOptions } from '@smart-city/core/common';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { IAdminMunicipalSchemaDto } from 'smart-city-types';

import { IOrganizationMapFilterForm } from '../../models/interfaces';

@UntilDestroy()
/**
 * Общая компонента для фильтров организаций
 *  @example
 *  <map-organization-filter
 *    [title]="'Организации Экстренного реагирования'"
 *    [mapLayerFilters]="data.mapLayersFilters['Организации Экстренного реагирования']"
 *    [layerFilters]="data.layersFilters['Организации Экстренного реагирования']"
 *  ></map-organization-filter>
 */
@Component({
  selector: 'map-organization-filter',
  templateUrl: './map-organization-filter.component.html',
  styleUrls: ['./map-organization-filter.component.scss'],
})
export class MapOrganizationFilterComponent implements OnInit {
  /** Заголовок фильтра */
  @Input()
  public title: string;

  /** Значения фильтров */
  @Input()
  public mapLayerFilters: ValuesOf<ControlsOf<IOrganizationMapFilterForm>>;

  /** Значения фильтров из конструктора слоев */
  @Input()
  public layerFilters: IMapLayerEntityFilter[] = [];

  /** Форма */
  public form: FormGroup<ControlsOf<IOrganizationMapFilterForm>>;

  /** Настройка компоненты Муниципальное образование */
  public municipals!: ISelectItem[];

  /** Настройка компоненты Тип организации */
  public orgTypeParamOptions: IScSelectOptions = {
    title: 'Тип организации',
    clearable: true,
    service: 'Admin',
    entity: 'OrgTypeParams',
    modern: true,
    fieldName: 'shortName',
  };

  /** Настройка компоненты Тип организации */
  public organizationTypes$ = this.organizationTypesQuery.selectAll({
    filterBy: ({ active }: IOrganizationType): boolean => active,
  });

  /** Настройка компоненты Наименование организации */
  public organizations$ = this.organizationQuery.selectAll({
    filterBy: ({ active }: IOrganization): boolean => active,
  });

  /** Просмотр всех МО */
  private viewAllMunicipals!: boolean;

  constructor(
    private readonly accessService: AccessService,
    private readonly organizationQuery: OrganizationsQuery,
    private readonly organizationTypesQuery: OrganizationTypesQuery,
    private readonly settings: Settings2Service,
    private readonly modalRef: NzModalRef,
  ) {}

  /** @ignore */
  public ngOnInit(): void {
    this.viewAllMunicipals = this.accessService.accessMap['viewAllMunicipals']?.visible;
    this.form = new FormGroup<ControlsOf<IOrganizationMapFilterForm>>({
      moId: new FormControl({
        value: this.viewAllMunicipals ? this.mapLayerFilters?.moId : this.settings.currentUser.organizationId.mo,
        disabled: !this.viewAllMunicipals,
      }),
      orgTypeParamId: new FormControl(this.mapLayerFilters?.orgTypeParamId),
      organizationId: new FormControl(this.mapLayerFilters?.organizationId),
    });
    this.municipals = this.settings.allMo
      .map((item: IAdminMunicipalSchemaDto) => {
        return <ISelectItem>{
          text: item.name,
          value: item.id,
        };
      })
      .filter((item: ISelectItem) => this.viewAllMunicipals
        || item.value === this.settings.currentUser.organizationId.mo);
  }

  public clear(): void {
    this.form.reset();
    if (!this.viewAllMunicipals) {
      this.form.controls.moId.patchValue(this.settings.currentUser.organizationId.mo);
    }
  }

  public close(): void {
    this.modalRef.destroy();
  }

  public filtrate(): void {
    this.modalRef.destroy(this.form.getRawValue());
  }
}
