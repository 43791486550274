import { Component, OnInit } from '@angular/core';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { IVehicleMapFilterForm } from '../../models/interfaces';
import { MapControlsService } from '../../services';

/**
 * Компонент фильтрации слоя ТС на карте
 */
@UntilDestroy()
@Component({
  selector: 'map-vehicle-filter',
  templateUrl: './map-vehicle-filter.component.html',
  styleUrls: ['./map-vehicle-filter.component.scss'],
})
export class MapVehicleFilterComponent implements OnInit {
  /** Значения фильтров */
  public mapLayerFilters: ValuesOf<ControlsOf<IVehicleMapFilterForm>>;

  /** Форма */
  public form: FormGroup<ControlsOf<IVehicleMapFilterForm>>;

  /** Список ГРЗ */
  public stateNumbers$: Observable<{ id: string; stateNumber: string }[]> =
    this.mapControlsService.getStateNumbersForSelect();

  /** Список типов ТС */
  public typeIds$: Observable<{ id: string; name: string }[]> = this.mapControlsService.getTypeIdsForSelect();

  /** Список происшествий и плановых работ */
  public emergencies$: Observable<{ id: string; number: string }[]> = this.mapControlsService.getEmergenciesForSelect();

  /** Список организаций */
  public organizations$: Observable<{ id: string; name: string }[]> =
    this.mapControlsService.getOrganizationsForSelect();

  /** @ignore */
  constructor(private readonly modalRef: NzModalRef, private readonly mapControlsService: MapControlsService) {}

  /** @ignore */
  public ngOnInit(): void {
    this.form = new FormGroup<ControlsOf<IVehicleMapFilterForm>>({
      stateNumber: new FormControl(this.mapLayerFilters?.stateNumber),
      typeId: new FormControl(this.mapLayerFilters?.typeId),
      emergencyId: new FormControl(this.mapLayerFilters?.emergencyId),
      organizationId: new FormControl(this.mapLayerFilters?.organizationId),
    });
  }

  /** Очистка формы */
  public clear(): void {
    this.form.reset();
  }

  /** Закрытие формы */
  public close(): void {
    this.modalRef.destroy();
  }

  /** Фильтрация */
  public filtrate(): void {
    this.modalRef.destroy(this.form.getRawValue());
  }
}
