export class ColorHelper {
  public static darken(colorStr: string, percent: number): string | undefined {
    let groups: number[] = [];
    const hasHash: boolean = colorStr.includes('#');
    const str: string = hasHash ? colorStr.replace('#', '') : colorStr;
    if (str.length === 3 || str.length === 6) {
      if (str.length === 3) {
        groups = str.split('').map((el: string) => {
          return Number.parseInt(`0x${el + el}`);
        });
      }

      if (str.length === 6) {
        str.split('').forEach((el: string, idx: number, mass: string[]) => {
          if (idx % 2 === 1) groups.push(Number.parseInt(`0x${mass[idx - 1] + mass[idx]}`));
        });
      }

      return (
        (hasHash ? '#' : '') +
        groups.map((el: number) => Math.round(el * (1 - (percent > 1 ? percent / 100 : percent))).toString(16)).join('')
      );
    }

    return undefined;
  }

  public static lighter(colorStr: string, percent: number): string | undefined {
    let groups: number[] = [];
    const hasHash: boolean = colorStr.includes('#');
    const str: string = hasHash ? colorStr.replace('#', '') : colorStr;

    if (str.length === 3 || str.length === 6) {
      if (str.length === 3) {
        groups = str.split('').map((el: string) => {
          return Number.parseInt(`0x${el + el}`);
        });
      }

      if (str.length === 6) {
        str.split('').forEach((el: string, idx: number, mass: string[]) => {
          if (idx % 2 === 1) groups.push(Number.parseInt(`0x${mass[idx - 1] + mass[idx]}`));
        });
      }

      return (
        (hasHash ? '#' : '') +
        groups
          .map((el: number) => Math.round(el * (1 + (percent > 1 ? percent / 100 : percent))))
          .map((el: number) => (el > 255 ? 255 : el))
          .map((el: number) => el.toString(16))
          .join('')
      );
    }

    return undefined;
  }
}
