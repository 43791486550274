import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Coordinates } from '@bg-front/core/models/classes';
import { LayersDataService, OperationsService } from '@bg-front/core/services';
import { IMapBaseObjectBaseEvent, IMapBaseObjectSelectEvent } from '@bg-front/map/models/interfaces';
import { MapBaseService } from '@bg-front/map/services';
import { ControlsOf } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Settings2Service } from '@smart-city/core/services';
import { IAnyObject, IMapObjectDto } from 'smart-city-types';

import { IEcoMonitoringObjectsMapFilterForm } from '../../models/interfaces';
import { BaseLayerControlComponent } from '../base-layer-control/base-layer-control.component';
import { MapControlsService } from '../../services';

@Component({
  selector: 'bg-eco-monitoring-objects-layer-control',
  templateUrl: './eco-monitoring-objects-layer-control.component.html',
  styleUrls: ['./eco-monitoring-objects-layer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EcoMonitoringObjectsLayerControlComponent extends BaseLayerControlComponent implements OnInit {
  public override attributes: string[] = ['id', 'measurementPointName', 'coordinates'];

  constructor(
    mapService: MapBaseService,
    cdr: ChangeDetectorRef,
    gisService: LayersDataService,
    settings: Settings2Service,
    router: Router,
    route: ActivatedRoute,
    operationsService: OperationsService,
    mapControlsService: MapControlsService,
  ) {
    super(mapService, cdr, gisService, settings, router, route, operationsService, mapControlsService);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    /** Подписка на Клик по объекту */
    this.mapService
      .getObservableObjectEvent<IMapBaseObjectSelectEvent>(this.mapId, 'selectObject', this.layer.nameOnMap)
      .pipe(untilDestroyed(this))
      .subscribe((event: IMapBaseObjectSelectEvent) => {
        if (event.selected) {
          this.showObjectInfo(event);
        }
      });
  }

  /** Обработка нажатия и вызов соответствующей навигации */
  private showObjectInfo(event: IMapBaseObjectBaseEvent): void {
    this.router.navigate(['ecoMonitoringObject', event?.objectId], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }

  /** Формирование запроса */
  public override getLayerLoadDataQuery(
    mapFilter: ValuesOf<ControlsOf<IEcoMonitoringObjectsMapFilterForm>> | undefined,
  ): IAnyObject[] {
    const query: IAnyObject = {};
    if (mapFilter?.measurerId) {
      query.$expr = {
        $eq: ['$measurer.measurerId', mapFilter.measurerId],
      };
    }

    return [
      query,
      ...this.gisService.getEntityFilters(this.layer.entityFilters.filters),
      ...this.gisService.getEntityFilters(this.getFilterQuery(mapFilter)),
    ];
  }

  /** Получаем дополнительные таблицы */
  public override getJoinedTables(
    mapFilter: ValuesOf<ControlsOf<IEcoMonitoringObjectsMapFilterForm>> | undefined,
  ): IAnyObject {
    const joinedTables = {};

    if (mapFilter?.measurerId) {
      joinedTables['measurementPoint'] = {
        $join: {
          service: 'EcoMonitoringIntegration',
          entity: 'MeasurementPoints',
          attributes: ['id'],
          query: {
            $expr: { $eq: ['$measurementPointId', '$measurementPoint.id'] },
          },
        },
      };

      joinedTables['measurer'] = {
        $join: {
          service: 'EcoMonitoringIntegration',
          entity: 'MeasurementPointsMeasurersLinks',
          attributes: ['id', 'measurementPointId', 'measurerId'],
          query: {
            $expr: { $eq: ['$measurementPoint.id', '$measurer.measurementPointId'] },
          },
        },
      };
    }

    return joinedTables;
  }

  /** Преобразование входных данных */
  public override transformResponseData(data: IAnyObject[]): IMapObjectDto[] {
    return data.map(
      (el: IAnyObject): IMapObjectDto =>
        <IMapObjectDto>{
          ...el,
          name: el.measurementPointName,
          coordinates: new Coordinates(el.coordinates).toArray(),
        },
    );
  }
}
