<nw-header [options]="headerOptions" class="no-margin" (clickButton)="clickHeaderButton($event)"></nw-header>
<div class="building-route-container">
  <form nz-form [formGroup]="form" nzLayout="vertical" *ngIf="form" (ngSubmit)="finishBuilding()">
    <ng-container formArrayName="points">
      <ng-container *ngFor="let el of pointsArray?.controls; let i = index">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-label nzRequired nzFor="name">Координаты фактического адреса</nz-form-label>
              <nz-form-control>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                  <input
                    type="text"
                    nz-input
                    placeholder="Координаты фактического адреса"
                    [formControlName]="i"
                    maxlength="100"
                  />
                </nz-input-group>
                <ng-template #suffixIconButton>
                  <button nz-button nzType="primary" (click)="removePoint(i)">
                    <i nz-icon nzType="delete" nzTheme="outline"></i>
                  </button>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <nz-divider *ngIf="pointsArray?.controls.length"></nz-divider>

    <div nz-row nzJustify="center">
      <div nz-col nzFlex="none">
        <button
          nz-button
          nzType="primary"
          type="submit"
          [disabled]="form?.invalid || pointsArray.controls.length < 2"
          [nzLoading]="isBuildingRoute"
        >
          Построить маршрут
        </button>
      </div>
    </div>
  </form>
</div>
