import 'leaflet-semicircle';
import 'leaflet-simple-map-screenshoter';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LeafletControlLayersConfig } from '@asymmetrik/ngx-leaflet';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ILayoutEvent } from '@smart-city/core/common';
import * as L from 'leaflet';
import { Observable, Subject, UnaryFunction, merge, pipe } from 'rxjs';
import { debounceTime, delay, filter, map, tap } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';

import { Coordinates } from '@bg-front/core/models/classes';
import { ISubstrateDto } from '@bg-front/core/models/interfaces';
import { SubstratesQuery, SubstratesService } from '@bg-front/core/services';
import { MapLayer, MapObject } from '../../models/classes';
import {
  IBaseSetViewOptions,
  IMapBaseAddObjectOptions,
  IMapBaseAddObjectToLayerOptions,
  IMapBaseCircleOptions,
  IMapBaseEvent,
  IMapBaseFitBoundsOptions,
  IMapBaseGeoJSONOptions,
  IMapBaseInitOptions,
  IMapBaseLayerOptions,
  IMapBaseMarkerOptions,
  IMapBaseObjectBaseEvent,
  IMapBaseObjectEvent,
  IMapBaseObjectSelectEvent,
  IMapBaseOptions,
  IMapBasePanOptions,
  IMapBasePolygonOptions,
  IMapBasePolylineOptions,
  IMapBasePositionOnCoordinateOptions,
  IMapBaseRectangleOptions,
  IMapBaseRemoveDifferentObjectsOptions,
  IMapBaseRemoveObjectOptions,
  IMapBaseSelectObjectOptions,
  IMapBaseSemiCircleOptions,
  IMapBaseSet,
  IMapBaseShowLayerOptions,
  IMapBaseShowObjectOptions,
  IMapBaseTakeScreenOptions,
  IMapBaseZoomOptions,
  MapBaseTileSourceOptions,
} from '../../models/interfaces';
import { LayersAndObjects, MapBaseCoordinatesType, TMapBaseCoordinates, TMapBaseMethod } from '../../models/types';
import {
  MapBaseService,
  MapLayersQuery,
  MapLayersService,
  MapLayersStore,
  MapObjectsQuery,
  MapObjectsService,
  MapObjectsStore,
} from '../../services';

const DEFAULT_MAP_CENTER: TMapBaseCoordinates = [55.023083, 82.920608];
const DEFAULT_MAP_ZOOM = 7;

/**
 * Отображает базовую карту
 *
 * @example
 * <map-base [mapOptions]="options"></map-base>
 */
@UntilDestroy()
@Component({
  selector: 'bg-map-base',
  templateUrl: './map-base.component.html',
  providers: [MapLayersStore, MapObjectsStore, MapLayersService, MapLayersQuery, MapObjectsQuery, MapObjectsService],
})
export class MapBaseComponent implements OnInit {
  /** Модель данных для начальной настройки карты */
  @Input() public mapOptions!: IMapBaseInitOptions;
  /** Объект leaflet L.Map */
  protected lMap?: L.Map;
  /** идентификатор карты */
  public mapId!: string;
  /** Генерируется событие для ILayout при нажатии на объект */
  @Output() public clickButton = new EventEmitter<ILayoutEvent>();
  /** Генерируется событие готовности карты */
  @Output() public mapReady = new EventEmitter<any>();
  /** Генерируется событие готовности карты */
  @Output() public changeBaseLayer: EventEmitter<string> = new EventEmitter<string>();
  /** Массив базового слоя */
  private baseLayer: L.Layer[] = [];
  /** данные для начальные настройки карты */
  public options!: L.MapOptions;
  /** Основные слои карты leaflet */
  public layersControl: LeafletControlLayersConfig | null = null;
  /** начальный размер карты и стили */
  public mapStyle?: { width: string; height: string; cursor?: string };
  /** параметры масштаба */
  public zoomOptions?: L.ZoomOptions;
  /** параметры панорамирования карты */
  public panOptions?: L.PanOptions;
  /** параметры fitBounds карты */
  public fitBoundsOptions?: L.FitBoundsOptions;
  /** центр карты */
  @Input()
  public center!: L.LatLng;
  public centerChange: EventEmitter<TMapBaseCoordinates> = new EventEmitter<TMapBaseCoordinates>(true);
  /** элемент отображения линейного масштаба */
  public scaleDisplay!: L.Control.Scale;
  /** масштаб карты */
  public zoom!: number;
  @Output()
  public zoomChange: EventEmitter<number> = new EventEmitter<number>(true);
  /** минимальное значение масштаба карты */
  public minZoom!: number;
  /** максимальное значение масштаба карты */
  public maxZoom!: number;
  /** fitBounds карты */
  public fitBounds?: L.LatLngBoundsExpression;
  /** слои карты */
  public mapLayers: MapLayer[] = [];
  /** Необходим для хранения объектов по ключу в шаблоне */
  public readonly mapObjects: {
    [key: string]: L.Layer;
  } = {};
  /** Необходим для хранения иконок по ключу в шаблоне */
  public readonly mapObjectsIcon: {
    [key: string]: IAnyObject;
  } = {};
  /** Массив кластеризованных объектов */
  public clusterObjects: L.Layer[] = [];
  /** Массив  Id не кластеризируемых объектов */
  public notClusteredIds: string[] = [];
  /** предыдущее значение центра карты */
  private prevCenter!: L.LatLng;
  /** предыдущее значение масштаба карты */
  private prevZoom!: number;
  /** Элемент управления для screenshot карты */
  protected simpleMapScreenshot?: IAnyObject;
  private onMoveEnd: Subject<void> = new Subject<void>();
  private onMoveEnd$: Observable<void> = this.onMoveEnd.asObservable();

  public renderLayers: {
    layerId: string;
    clusterOptions?: IAnyObject;
    nonClustered: IAnyObject[];
    clustered: IAnyObject[];
  }[] = [];

  /** @ignore */
  constructor(
    private readonly mapService: MapBaseService,
    private readonly mapLayersService: MapLayersService,
    private readonly mapLayersQuery: MapLayersQuery,
    private readonly mapObjectsService: MapObjectsService,
    private readonly mapObjectsQuery: MapObjectsQuery,
    private readonly substratesQuery: SubstratesQuery,
    private readonly substratesService: SubstratesService,
  ) {}

  /** @ignore */
  /**
   * Инициализация карты.
   */
  public ngOnInit(): void {
    if (!this.mapOptions) {
      throw new Error('mapOptions is not defined');
    }

    this.mapId = this.mapOptions.mapId;

    if (this.mapOptions.urls && this.mapOptions.urls.length !== 0) {
      this.layersControl = {
        baseLayers: {},
        overlays: {},
      };
      this.mapOptions.urls.forEach((url) => {
        this.layersControl!.baseLayers[url.name] = L.tileLayer(url.url, {
          maxZoom: (this.mapOptions && this.mapOptions.maxZoom) || 18,
          attribution: url.attribution || '',
        });
        if (url.selected) {
          this.baseLayer.push(this.layersControl!.baseLayers[url.name]!);
          const entity = this.substratesQuery.getAll({
            filterBy: ({ name }: ISubstrateDto) => name === url.name,
          })[0];
          this.substratesService.setActive(entity.id);
        }
      });
    } else {
      if (this.mapOptions.url) {
        const tileLayerOptions: L.TileLayerOptions = {
          maxZoom: (this.mapOptions && this.mapOptions.maxZoom) || 18,
          attribution: this.mapOptions.attribution
            ? this.mapOptions.attribution
            : '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        };
        if (this.mapOptions.subdomains) {
          tileLayerOptions.subdomains = this.mapOptions.subdomains;
        }

        this.baseLayer.push(L.tileLayer(this.mapOptions.url, tileLayerOptions));
      }
    }
    /** инициализируем карту */
    this.options = this.mapOptions;
    this.options.layers = this.baseLayer;
    this.mapStyle = (this.mapOptions && this.mapOptions.mapStyle) || { width: '100%', height: '100%' };
    this.prevCenter = this.center;
    this.prevZoom = this.zoom;

    if (this.mapOptions?.scaleDisplayOptions) {
      this.scaleDisplay = L.control.scale(this.mapOptions.scaleDisplayOptions);
    }

    const baseFilterPipe = <T extends IMapBaseSet>(): UnaryFunction<Observable<T>, Observable<T>> =>
      pipe(
        delay(0),
        filter<T>((method: T): boolean => (<IMapBaseSet>method).mapId === this.mapId),
      );

    this.mapService.setZoomOptions$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseZoomOptions>>(),
        map((method: IMapBaseOptions<IMapBaseZoomOptions>): void => this.setZoomOptions(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.setPanOptions$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBasePanOptions>>(),
        map((method: IMapBaseOptions<IMapBasePanOptions>): void => this.setPanOptions(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.setFitBoundsOptionsSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseFitBoundsOptions>>(),
        map((method: IMapBaseOptions<IMapBaseFitBoundsOptions>): void => this.setFitBoundsOptions(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.setCenterSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<MapBaseCoordinatesType>>(),
        map((method: IMapBaseOptions<MapBaseCoordinatesType>): void => this.setCenterOptions(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.setZoom$
      .pipe(
        baseFilterPipe<IMapBaseOptions<number>>(),
        map((method: IMapBaseOptions<number>): number => method.options),
        untilDestroyed(this),
      )
      .subscribe(this.setZoom);

    this.mapService.setMaxZoomSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<number>>(),
        map((method: IMapBaseOptions<number>): void => this.setMaxZoomOptions(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.setMinZoomSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<number>>(),
        map((method: IMapBaseOptions<number>): void => this.setMinZoomOptions(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.setTileSourceSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<MapBaseTileSourceOptions>>(),
        map((method: IMapBaseOptions<MapBaseTileSourceOptions>): void => this.changeTileSource(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.setViewSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IBaseSetViewOptions>>(),
        map((method: IMapBaseOptions<IBaseSetViewOptions>): void => this.setViewOptions(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.setBackViewSub$
      .pipe(baseFilterPipe<IMapBaseOptions>(), untilDestroyed(this))
      .subscribe(() => this.setBackView());

    this.mapService.setFitBoundsSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<TMapBaseCoordinates>>(),
        map((method: IMapBaseOptions<TMapBaseCoordinates>): void => this.setFitBounds(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.flyToBoundsSub$
      .pipe(
        baseFilterPipe(),
        map((): void => this.flyToBounds()),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.addPolylineSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBasePolylineOptions>>>(),
        map((method: IMapBaseOptions<IMapBaseAddObjectOptions<IMapBasePolylineOptions>>): void =>
          this.addPolyline(method.options),
        ),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.addPolygonSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBasePolygonOptions>>>(),
        map((method: IMapBaseOptions<IMapBaseAddObjectOptions<IMapBasePolygonOptions>>): void =>
          this.addPolygon(method.options),
        ),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.addRectangleSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseRectangleOptions>>>(),
        map((method: IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseRectangleOptions>>): void =>
          this.addRectangle(method.options),
        ),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.addCircleSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseCircleOptions>>>(),
        map((method: IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseCircleOptions>>): void =>
          this.addCircle(method.options),
        ),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.addSemiCircleSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseSemiCircleOptions>>>(),
        map((method: IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseSemiCircleOptions>>): void =>
          this.addSemiCircle(method.options),
        ),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.addMarkerSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseMarkerOptions>>>(),
        tap((method: IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseMarkerOptions>>) => {
          this.addMarker(method.options);
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.addMarkersSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseMarkerOptions>[]>>(),
        tap((method: IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseMarkerOptions>[]>) => {
          this.addMarkers(method.options);
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.addLayerSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<MapLayer>>(),
        tap((method: IMapBaseOptions<MapLayer>): void => this.addLayer(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.showLayerSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseShowLayerOptions>>(),
        map((method: IMapBaseOptions<IMapBaseShowLayerOptions>): void => this.showLayer(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.removeLayerSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseLayerOptions>>(),
        map((method: IMapBaseOptions<IMapBaseLayerOptions>): void => this.removeLayer(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.showObjectSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseShowObjectOptions>>(),
        map((method: IMapBaseOptions<IMapBaseShowObjectOptions>): void => this.showObject(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.removeObjectSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseRemoveObjectOptions>>(),
        map((method: IMapBaseOptions<IMapBaseRemoveObjectOptions>): void => this.removeObject(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.removeDifferentObjectsSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseRemoveDifferentObjectsOptions>>(),
        map((method: IMapBaseOptions<IMapBaseRemoveDifferentObjectsOptions>): void =>
          this.removeDifferentObjects(method.options),
        ),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.setPositionOnCoordinateSub$
      .pipe(
        baseFilterPipe<Required<IMapBaseOptions<IMapBasePositionOnCoordinateOptions>>>(),
        map((method: Required<IMapBaseOptions<IMapBasePositionOnCoordinateOptions>>): void =>
          this.setPositionOnCoordinate(method.options),
        ),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.selectObjectSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseSelectObjectOptions>>(),
        map((method: IMapBaseOptions<IMapBaseSelectObjectOptions>): void => this.selectObject(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.deselectObjectSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseSelectObjectOptions>>(),
        map((method: IMapBaseOptions<IMapBaseSelectObjectOptions>): void => this.deselectObject(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.resetActiveObjectSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseSelectObjectOptions>>(),
        map((method: IMapBaseOptions<IMapBaseSelectObjectOptions>): void => this.resetActiveObject(method.options)),
        untilDestroyed(this),
      )
      .subscribe();

    this.mapService.addGeoJSONSub$
      .pipe(
        baseFilterPipe<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseGeoJSONOptions>>>(),
        map((method: IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseGeoJSONOptions>>): void =>
          this.addGeoJSON(method.options),
        ),
        untilDestroyed(this),
      )
      .subscribe();

    // combineLatest([
    //   this.mapLayersQuery.selectAll({
    //     filterBy: (layer: MapLayer): boolean => layer.isShow,
    //   }),
    //   this.mapObjectsQuery.selectAll({
    //     filterBy: (object: MapObject): boolean => object.isShow,
    //   }),
    // ])
    //   .pipe(
    //     debounceTime(50),
    //     map((response: [MapLayer[], MapObject[]]) => {
    //       const resultLayers: LayersAndObjects[] = response[0].map(
    //         (layer: MapLayer): MapLayer & { objects: MapObject[] } =>
    //           <LayersAndObjects>{
    //             ...layer,
    //             objects: response[1].filter((el: MapObject) => el.layerId === layer.id),
    //           },
    //       );
    //       return resultLayers;
    //     }),
    //     tap((layers: LayersAndObjects[]) => this.reDrawMapElements(layers)),
    //     untilDestroyed(this),
    //   )
    //   .subscribe();

    // this.onMoveEnd$.pipe(debounceTime(100), untilDestroyed(this)).subscribe((): void => {
    //   const layers = this.mapLayersQuery.getAll({
    //     filterBy: (layer: MapLayer): boolean => layer.isShow,
    //   });
    //   const objects = this.mapObjectsQuery.getAll({
    //     filterBy: (object: MapObject): boolean => object.isShow,
    //   });
    //   const resultLayers: LayersAndObjects[] = layers.map(
    //     (layer: MapLayer): MapLayer & { objects: MapObject[] } =>
    //       <LayersAndObjects>{
    //         ...layer,
    //         objects: objects.filter((el: MapObject) => el.layerId === layer.id),
    //       },
    //   );

    //   this.reDrawMapElements(resultLayers);
    // });
  }

  /** Отрисовка элементов на карте */
  private reDrawMapElements(layers: LayersAndObjects[]): void {
    this.clusterObjects = [];
    this.notClusteredIds = [];
    this.renderLayers = [];
    const bounds = this.lMap.getBounds();
    const pBounds = [
      [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
      [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
      [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
      [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
      [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
    ];
    layers
      .filter((layer: LayersAndObjects) => layer.objects?.length)
      .forEach((layer: MapLayer & { objects: MapObject[] }): void => {
        const renderLayer: {
          layerId: string;
          nonClustered: IAnyObject[];
          clustered: IAnyObject[];
          clusterOptions?: IAnyObject;
        } = {
          layerId: layer.id,
          clustered: [],
          nonClustered: [],
          clusterOptions: layer.clusterOptions,
        };

        layer.objects.forEach((obj: MapObject): void => {
          if (
            (this.isPointCoordinate(obj.coordinates) &&
              Coordinates.isPointInPolygon(
                [(obj.coordinates as [number, number])[1], (obj.coordinates as [number, number])[0]],
                pBounds as [number, number][],
              )) ||
            this.isPolygonCoordinates(obj.coordinates)
          ) {
            if (layer.cluster || obj.cluster) {
              renderLayer.clustered.push(this.mapObjects[obj.id]);
            } else {
              renderLayer.nonClustered.push(this.mapObjects[obj.id]);
            }
          }
        });

        this.renderLayers.push(renderLayer);
      });

    // Позиционирование на единственном объекте на карте
    if (this.clusterObjects.length === 1 && !this.notClusteredIds.length) {
      const coords = this.clusterObjects[0].getLatLng();
      this.lMap.flyTo([coords.lat, coords.lng]);
    } else if (this.notClusteredIds.length === 1 && !this.clusterObjects.length) {
      // Необходима проверка на наличие метода получения широты и долготы.
      // Например, у полигонов его нет
      if (typeof this.mapObjects[this.notClusteredIds[0]].getLatLng === 'function') {
        const coords = this.mapObjects[this.notClusteredIds[0]].getLatLng();
        this.lMap.flyTo([coords.lat, coords.lng]);
      }
    }
  }

  /**
   * Обработчик события готовности карты
   *
   */
  public onMapReady(map: L.Map): void {
    this.lMap = map;

    if (this.mapOptions?.screenshotOptions) {
      this.initScreenshot(this.mapOptions.screenshotOptions);
    }

    if (this.scaleDisplay) {
      this.scaleDisplay.addTo(this.lMap);
    }
    setTimeout(() => {
      this.mapService.mapEvent(<IMapBaseEvent>{
        mapId: this.mapId,
        eventName: 'MapBaseEvent',
        type: 'mapReady',
      });
      this.lMap?.invalidateSize();
      this.center = L.latLng((this.mapOptions && this.mapOptions.center) || DEFAULT_MAP_CENTER);
      this.zoom = (this.mapOptions && this.mapOptions.zoom) || DEFAULT_MAP_ZOOM;
      this.mapReady.emit(this.lMap);
    }, 0);

    this.lMap.on('layeradd', (event: L.LayerEvent) => {
      event.layer.fireEvent('addObject', event);
    });

    this.lMap.on('moveend', () => this.onMoveEnd.next());

    this.lMap.on('baselayerchange', (event: L.LayerEvent) => {
      this.changeBaseLayer.emit(event.name);
    });

    merge(
      this.mapLayersQuery.selectAll({
        filterBy: (layer: MapLayer): boolean => layer.isShow,
      }),
      this.mapObjectsQuery.selectNeedUpdate((obj: MapObject) => {
        const bounds = this.lMap.getBounds();
        const pBounds = [
          [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
          [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
          [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
          [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
          [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
        ];

        return (
          (this.isPointCoordinate(obj.coordinates) &&
            Coordinates.isPointInPolygon(
              [(obj.coordinates as [number, number])[1], (obj.coordinates as [number, number])[0]],
              pBounds as [number, number][],
            )) ||
          this.isPolygonCoordinates(obj.coordinates)
        );
      }),
      this.onMoveEnd$,
    )
      .pipe(debounceTime(100))
      .subscribe(() => {
        const layers = this.mapLayersQuery.getAll({
          filterBy: (layer: MapLayer): boolean => layer.isShow,
        });
        const objects = this.mapObjectsQuery.getAll({
          filterBy: (object: MapObject): boolean => object.isShow,
        });
        const resultLayers: LayersAndObjects[] = layers.map(
          (layer: MapLayer): MapLayer & { objects: MapObject[] } =>
            <LayersAndObjects>{
              ...layer,
              objects: objects.filter((el: MapObject) => el.layerId === layer.id),
            },
        );

        this.reDrawMapElements(resultLayers);
      });
  }

  /**
   * Обработчик событий мыши на карте
   */
  public onMapMouseEvent(event: L.LeafletMouseEvent): void {
    this.mapService.mapEvent(<IMapBaseEvent>{
      mapId: this.mapId,
      type: event.type,
      coordinates: [event.latlng.lat, event.latlng.lng],
    });
  }

  /**
   * Обработчик событий карты
   */
  public onMapEvent(type: 'zoomchange' | 'centerchange', event: number | L.LatLng): void {
    const eventData: IMapBaseEvent = {
      type,
      mapId: this.mapId,
    };
    switch (type) {
      case 'zoomchange':
        this.prevZoom = this.zoom;
        this.zoom = +event;
        eventData.zoom = +event;
        this.zoomChange.emit(this.zoom);
        break;
      case 'centerchange':
        if (event) {
          eventData.center = [(event as L.LatLng).lat, (event as L.LatLng).lng];
        } else {
          eventData.center = undefined;
        }
        break;
    }
    this.mapService.mapEvent(eventData);
  }

  /** Устанавливаем/Меняем настройку зума */
  private setZoomOptions(options: IMapBaseZoomOptions): void {
    this.zoomOptions = options;
  }

  /** Устанавливаем/Меняем зум */
  private setZoom(options: number): void {
    this.prevZoom = this.zoom;
    this.zoom = options;
  }

  /** Устанавливаем/Меняем настройку Pan */
  private setPanOptions(options: IMapBasePanOptions): void {
    this.panOptions = options;
  }

  /** Устанавливаем/Меняем настройку Pan */
  private setFitBoundsOptions(options: IMapBaseFitBoundsOptions): void {
    this.fitBoundsOptions = options;
  }

  /** Устанавливаем/Меняем настройку Pan */
  private setCenterOptions(center: MapBaseCoordinatesType): void {
    this.prevCenter = this.center;
    this.center = L.latLng(center);
  }

  /** Устанавливаем/Меняем настройку MaxZoom */
  private setMaxZoomOptions(zoom: number): void {
    this.maxZoom = zoom;
  }

  /** Устанавливаем/Меняем настройку MinZoom */
  private setMinZoomOptions(zoom: number): void {
    this.minZoom = zoom;
  }

  /** Устанавливаем/Меняем настройку MinZoom */
  private setViewOptions(options: IBaseSetViewOptions): void {
    this.prevCenter = this.center;
    this.prevZoom = this.zoom;
    this.center = L.latLng(options.center);
    this.zoom = options.zoom;
  }

  /** Устанавливаем/Меняем настройку MinZoom */
  private setBackView(): void {
    this.center = this.prevCenter ? this.prevCenter : this.center;
    this.zoom = this.prevZoom ? this.prevZoom : this.zoom;
  }

  /** Устанавливаем/Меняем настройку FitBounds */
  private setFitBounds(options: TMapBaseCoordinates): void {
    this.fitBounds = options as any;
  }

  /**
   * Позиционируемся на координатах, указанных в опциях
   *
   * Если в опциях задан слой, то проверяем его видимость и если слой видим, то позиционируемся
   * @param option параметры позиционирования
   */
  public setPositionOnCoordinate(option: IMapBasePositionOnCoordinateOptions): void {
    if (option.layerId) {
      if (this.mapLayersQuery.getEntity(option.layerId)?.isShow) {
        this.lMap.flyTo(option.coordinate);
      }
    } else {
      this.lMap.flyTo(option.coordinate);
    }
  }

  /** Устанавливаем/Меняем настройку FitBounds */
  private flyToBounds(): void {
    const layers = this.mapLayersQuery.getAll({
      filterBy: (layer: MapLayer): boolean => layer.isShow,
    });

    const objects = this.mapObjectsQuery.getAll({
      filterBy: (layer: MapObject): boolean => layer.isShow,
    });

    const layersAndObjects: LayersAndObjects[] = layers.map(
      (layer: MapLayer): MapLayer & { objects: MapObject[] } =>
        <LayersAndObjects>{
          ...layer,
          objects: objects.filter((el: MapObject) => el.layerId === layer.id),
        },
    );

    const allCoordinates = [];
    layersAndObjects.forEach(({ objects }: MapLayer & { objects: MapObject[] }): void =>
      objects.forEach((obj: MapObject): void => {
        if (obj.isShow) {
          allCoordinates.push(obj.coordinates);
        }
      }),
    );
    this.lMap.flyToBounds(allCoordinates);
  }

  /** Функция имитирующая выбор объекта на карте без отправки события */
  public selectObject(option: IMapBaseSelectObjectOptions): void {
    if (this.mapObjectsQuery.getActiveId() !== option.id) {
      this.mapObjects[option.id].fire('click', { latlng: this.mapObjects[option.id].getLatLng() });
    }
  }

  /** Функция имитирующая выбор объекта на карте без отправки события */
  public deselectObject(option: IMapBaseSelectObjectOptions): void {
    if (this.mapObjectsQuery.getActiveId() === option.id) {
      this.mapObjects[option.id].fire('click', { latlng: this.mapObjects[option.id].getLatLng() });
    }
  }

  /** Функция имитирующая выбор объекта на карте без отправки события */
  public resetActiveObject(option: IMapBaseSelectObjectOptions): void {
    if (this.mapObjectsQuery.getActiveId()) {
      this.mapObjectsService.setActive(null);
      this.setMainIcon(option.id, this.mapObjectsIcon[option.id]);
    }
  }

  /** Добавляем линию на карту */
  private addPolyline(options: IMapBaseAddObjectOptions<IMapBasePolylineOptions>): void {
    const obj: MapObject = this.addObjectToLayer(L.polyline(options.coordinates as any, options.objectOptions), {
      layerId: options.layerId,
      objectId: options.objectId,
      popup: options.popup,
      tooltip: options.tooltip,
      cluster: options.cluster,
      coordinates: options.coordinates,
    });

    this.mapObjectsService.add(obj);
  }

  /** Добавляем полигон на карту */
  private addPolygon(options: IMapBaseAddObjectOptions<IMapBasePolygonOptions>): void {
    const obj: MapObject = this.addObjectToLayer(L.polygon(options.coordinates as any, options.objectOptions), {
      layerId: options.layerId,
      objectId: options.objectId,
      popup: options.popup,
      tooltip: options.tooltip,
      cluster: options.cluster,
      coordinates: options.coordinates,
    });

    this.mapObjectsService.add(obj);
  }

  /** Добавляем треугольника на карту */
  private addRectangle(options: IMapBaseAddObjectOptions<IMapBaseRectangleOptions>): void {
    const obj: MapObject = this.addObjectToLayer(L.rectangle(options.coordinates as any, options.objectOptions), {
      layerId: options.layerId,
      objectId: options.objectId,
      popup: options.popup,
      tooltip: options.tooltip,
      cluster: options.cluster,
      coordinates: options.coordinates,
    });

    this.mapObjectsService.add(obj);
  }

  /** Добавляем круга на карту */
  private addCircle(options: IMapBaseAddObjectOptions<IMapBaseCircleOptions>): void {
    const obj = this.addObjectToLayer(L.circle(options.objectOptions.center, options.objectOptions), {
      layerId: options.layerId,
      objectId: options.objectId,
      popup: options.popup,
      tooltip: options.tooltip,
      cluster: options.cluster,
      coordinates: options.coordinates,
    });

    this.mapObjectsService.add(obj);
  }

  /** Добавляем круга на карту */
  private addCircleMarker(options: IMapBaseAddObjectOptions<IMapBaseCircleOptions>): void {
    const obj = this.addObjectToLayer(L.circle(options.objectOptions.center, options.objectOptions), {
      layerId: options.layerId,
      objectId: options.objectId,
      popup: options.popup,
      tooltip: options.tooltip,
      cluster: options.cluster,
      coordinates: options.coordinates,
    });

    this.mapObjectsService.add(obj);
  }

  /** Добавляем круга на карту */
  private addSemiCircle(options: IMapBaseAddObjectOptions<IMapBaseSemiCircleOptions>): void {
    const obj = this.addObjectToLayer((L as any).semiCircle(options.objectOptions.center, options.objectOptions), {
      layerId: options.layerId,
      objectId: options.objectId,
      popup: options.popup,
      tooltip: options.tooltip,
      cluster: options.cluster,
      coordinates: options.coordinates,
    });

    this.mapObjectsService.add(obj);
  }

  /** Добавляем маркера на карту */
  private addMarker(options: IMapBaseAddObjectOptions<IMapBaseMarkerOptions>): void {
    const marker = this.addObjectToLayer(
      L.marker(options.coordinates as any, {
        ...options.objectOptions,
        icon: L.icon(
          options.objectOptions?.icon ||
            <L.IconOptions>{
              iconSize: [25, 41],
              iconAnchor: [13, 41],
              popupAnchor: [0, -41],
              tooltipAnchor: [0, -41],
              iconUrl: '/assets/marker-icon.png',
              shadowUrl: '/assets/marker-shadow.png',
            },
        ),
        properties: options.properties,
        coordinates: options.coordinates,
      }),
      {
        layerId: options.layerId,
        objectId: options.objectId,
        popup: options.popup,
        tooltip: options.tooltip,
        cluster: options.cluster,
        eventsConfig: options.eventsConfig,
        icon:
          options.objectOptions?.icon ||
          <L.IconOptions>{
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            popupAnchor: [0, -41],
            tooltipAnchor: [0, -41],
            iconUrl: '/assets/marker-icon.png',
            shadowUrl: '/assets/marker-shadow.png',
          },
        coordinates: options.coordinates,
      },
    );

    this.mapObjectsService.add(marker);
  }

  /** Добавляем маркера на карту */
  private addMarkers(options: IMapBaseAddObjectOptions<IMapBaseMarkerOptions>[]): void {
    const elements = options?.map((option: IMapBaseAddObjectOptions<IMapBaseMarkerOptions>): MapObject => {
      return this.addObjectToLayer(
        L.marker(option.coordinates as any, {
          ...option.objectOptions,
          icon: L.icon(
            option.objectOptions.icon ||
              <L.IconOptions>{
                iconSize: [25, 41],
                iconAnchor: [13, 41],
                popupAnchor: [0, -41],
                tooltipAnchor: [0, -41],
                iconUrl: '/assets/icons/marker-icon.png',
                shadowUrl: '/assets/icons/marker-shadow.png',
              },
          ),
          properties: option.properties,
          coordinates: option.coordinates,
        }),
        {
          layerId: option.layerId,
          objectId: option.objectId,
          popup: option.popup,
          tooltip: option.tooltip,
          cluster: option.cluster,
          eventsConfig: option.eventsConfig,
          icon:
            option.objectOptions.icon ||
            <L.IconOptions>{
              iconSize: [25, 41],
              iconAnchor: [13, 41],
              popupAnchor: [0, -41],
              tooltipAnchor: [0, -41],
              iconUrl: '/assets/icons/marker-icon.png',
              shadowUrl: '/assets/icons/marker-shadow.png',
            },
          coordinates: option.coordinates,
        },
      );
    });

    this.mapObjectsService.add(elements);
  }

  /** Добавляем geoJSON на карту */
  private addGeoJSON(options: IMapBaseAddObjectOptions<IMapBaseGeoJSONOptions>): void {
    const obj = this.addObjectToLayer(L.geoJSON(options.coordinates as any, options.objectOptions), {
      layerId: options.layerId,
      objectId: options.objectId,
      popup: options.popup,
      tooltip: options.tooltip,
      cluster: options.cluster,
      coordinates: options.objectOptions.extraCoordinates ?? options.coordinates,
    });

    this.mapObjectsService.add(obj);
  }

  /** Добавляем маркера на карту */
  private addLayer(layer: MapLayer): void {
    if (!this.mapLayersQuery.hasEntity(layer.id)) {
      this.mapLayersService.add(layer);
    }
  }

  /** Добавляем маркера на карту */
  private showLayer({ id, isShow }: IMapBaseShowLayerOptions): void {
    this.mapLayersService.setIsShow(id, isShow);
  }

  /** Добавляем маркера на карту */
  private removeLayer({ id }: IMapBaseLayerOptions): void {
    this.mapLayersService.delete(id);
    this.removeObject({ layerId: id });
  }

  /** Добавляем маркера на карту */
  private showObject({ id, isShow }: IMapBaseShowObjectOptions): void {
    this.mapObjectsService.setIsShow(id, isShow);
  }

  /** Удаляем объекты */
  private removeObject({ layerId, id }: IMapBaseRemoveObjectOptions): void {
    if (id) {
      this.mapObjectsService.delete(id);
      delete this.mapObjects[id as string];
    } else {
      const objects = this.mapObjectsQuery.getAll({
        filterBy: (object: MapObject): boolean => object.layerId === layerId,
      });

      objects.forEach((el: MapObject) => {
        this.deleteLayerObject(el.id);
      });

      this.mapObjectsService.deleteFromLayer(layerId);
    }
  }

  /** Удаляем объекты */
  private removeDifferentObjects({ layerId, ids }: IMapBaseRemoveDifferentObjectsOptions): void {
    const objects = this.mapObjectsQuery.getAll({
      filterBy: (object: MapObject): boolean => object.layerId === layerId && !ids.includes(object.id),
    });

    objects.forEach((obj: MapObject) => {
      this.mapObjectsService.delete(obj.id);
      this.mapObjects[obj.id].off();
      delete this.mapObjects[obj.id];
    });
  }

  /**
   * Обработка методов, через вызовы ngx-leaflet
   * @param method TMapBaseMethod
   */
  private processMethod(method: TMapBaseMethod): void {
    //switch (method.typeMethod) {
    // case MapMethodsEnum.addEllipseToLayer:
    //   this.addObjectToLayer(
    //     // @ts-expect-error
    //     L.ellipse({
    //       center: L.latLng(method.params.center),
    //       semiMajor: method.params.semiMajor,
    //       semiMinor: method.params.semiMinor,
    //       bearing: method.params.tilt,
    //       numberOfPoints: method.params.numberOfPoints,
    //       ...method.options,
    //     }),
    //     method.params,
    //     method.popup,
    //     method.tooltip,
    //   );
    //   break;
    // case MapMethodsEnum.addGeoJSONToLayer:
    //   this.addObjectToLayer(
    //     L.geoJSON(method.params.geoJSON, {
    //       style() {
    //         return method.options!;
    //       },
    //     }),
    //     method.params,
    //     method.popup,
    //     method.tooltip,
    //   );
    //   break;
    // case MapMethodsEnum.manualTakeScreen:
    //   if (!this.simpleMapScreenshot) {
    //     this.initScreenshot(method.params.options!);
    //   }
    //   this.simpleMapScreenshot!.takeScreen(method.params?.format || 'blob', method.params.options)
    //     // eslint-disable-next-line no-underscore-dangle
    //     .then(() => this.simpleMapScreenshot!._onScreenBtn())
    //     .catch((error: any) => console.error(error));
    //   break;
    //}
  }

  /**
   * Изменить источник подложки базового слоя карты
   */
  private changeTileSource(options: MapBaseTileSourceOptions): void {
    const baseLayer = this.baseLayer[0] as L.TileLayer | undefined;
    if (baseLayer) {
      if (options.subdomains) {
        baseLayer.options.subdomains = options.subdomains;
      }
      baseLayer.setUrl(options.url);
    }
  }

  /**
   * Добавляет в mapLayers объект в слой, а так же popup и  tooltip к объекту
   * @param object объект TMapObject
   * @param options параметры добавляемого объекта
   */
  private addObjectToLayer(object: L.Layer, options: IMapBaseAddObjectToLayerOptions): MapObject {
    if (this.mapLayersQuery.hasEntity(options.layerId)) {
      if (this.mapObjectsQuery.hasEntity(options.objectId)) {
        /** удаляем обработчики event */
        this.deleteLayerObject(object.id);
        // this.mapObjectsService.delete(options.objectId);
      }
    } else {
      /** если нет слоя, то добавляем слой */
      this.mapLayersService.add(new MapLayer(options.layerId, false, true));
    }

    if (options.popup) {
      object.bindPopup(options.popup.text, options.popup);
    }
    if (options.tooltip) {
      object.bindTooltip(options.tooltip.text, options.tooltip);
    }
    if (options.eventsConfig?.onClick || options.eventsConfig?.eventClick) {
      object.on('click', this.onObjectClick.bind(this, options));
    }

    if (options.eventsConfig?.eventDblClick || options.eventsConfig?.onDblClick) {
      object.on('dblclick', this.onObjectDblClick.bind(this, options));
    }

    if (options.eventsConfig?.onContextMenu) {
      object.on('contextmenu', this.onContextMenu.bind(this, options));
    }

    if (options.eventsConfig?.eventRemoveObject || options.eventsConfig?.onRemoveObject) {
      object.on('remove', this.onRemoveObject.bind(this, options));
    }

    if (options.eventsConfig?.onAddObject) {
      object.on('addObject', this.onAddObject.bind(this, options));
    }

    object.on('popupopen', this.onObjectEvent.bind(this));
    object.on('popupclose', this.onObjectEvent.bind(this));

    if (options.popup) {
      object.bindPopup(options.popup.text, options.popup);
    }
    if (options.tooltip) {
      object.bindTooltip(options.tooltip.text, options.tooltip);
    }

    this.mapObjects[options.objectId] = object;
    this.mapObjectsIcon[options.objectId] = options.icon;
    return new MapObject(options.objectId, options.layerId, true, options.cluster, options.coordinates);
  }

  /** Обрабатываем click */
  public onObjectClick(options: IMapBaseAddObjectToLayerOptions, event: L.LeafletMouseEvent): void {
    if (!options['dblClickTimeout']) {
      options['dblClickTimeout'] = setTimeout(() => {
        clearTimeout(options['dblClickTimeout']);
        delete options['dblClickTimeout'];
        const active = this.mapObjectsQuery.getActive() as MapObject;
        if (options.eventsConfig?.eventClick) {
          this.mapService.sendObjectEvent(<IMapBaseObjectBaseEvent>{
            mapId: this.mapId,
            type: 'click',
            layerId: options.layerId,
            objectId: options.objectId,
            coordinates: event.latlng ? [event.latlng.lat, event.latlng.lng] : undefined,
          });
        }

        if (options.eventsConfig?.onClick) {
          options.eventsConfig.onClick(<IMapBaseObjectBaseEvent>{
            mapId: this.mapId,
            type: 'click',
            layerId: options.layerId,
            objectId: options.objectId,
            coordinates: event.latlng ? [event.latlng.lat, event.latlng.lng] : undefined,
          });
        }

        if (active?.id !== options.objectId) {
          if (active?.id) {
            this.setMainIcon(active?.id, this.mapObjectsIcon[active.id]);

            this.mapService.sendObjectEvent(<IMapBaseObjectSelectEvent>{
              mapId: this.mapId,
              type: 'selectObject',
              layerId: active.layerId,
              objectId: active.id,
              coordinates: event.latlng ? [event.latlng.lat, event.latlng.lng] : undefined,
              selected: false,
            });
          }
          this.mapObjectsService.setActive(options.objectId);
          if (options.icon) {
            this.setActiveIcon(options.objectId);
          }

          this.mapService.sendObjectEvent(<IMapBaseObjectSelectEvent>{
            mapId: this.mapId,
            type: 'selectObject',
            layerId: options.layerId,
            objectId: options.objectId,
            coordinates: event.latlng ? [event.latlng.lat, event.latlng.lng] : undefined,
            selected: true,
          });
        } else {
          this.mapObjectsService.setActive(null);
          this.setMainIcon(options.objectId, options.icon);
          this.mapService.sendObjectEvent(<IMapBaseObjectSelectEvent>{
            mapId: this.mapId,
            type: 'selectObject',
            layerId: options.layerId,
            objectId: options.objectId,
            coordinates: event.latlng ? [event.latlng.lat, event.latlng.lng] : undefined,
            selected: false,
          });
        }
      }, 200);
    }
  }

  /** Обработка dblClick */
  private onObjectDblClick(options: IMapBaseAddObjectToLayerOptions, event: L.LeafletMouseEvent): void {
    if (options['dblClickTimeout']) {
      clearTimeout(options['dblClickTimeout']);
    }
    if (options.eventsConfig?.onDblClick) {
      this.mapService.sendObjectEvent({
        mapId: this.mapId,
        type: 'dblClick',
        layerId: options.layerId,
        objectId: options.objectId,
        coordinates: event.latlng ? [event.latlng.lat, event.latlng.lng] : undefined,
      });
    }

    if (options.eventsConfig?.onDblClick) {
      options.eventsConfig.onDblClick({
        mapId: this.mapId,
        type: 'dblClick',
        layerId: options.layerId,
        objectId: options.objectId,
        coordinates: event.latlng ? [event.latlng.lat, event.latlng.lng] : undefined,
      });
    }
  }

  /** Обрабатываем contextmenu */
  public onContextMenu(options: IMapBaseAddObjectToLayerOptions, event: L.LeafletMouseEvent): void {
    this.mapService.sendObjectEvent({
      mapId: this.mapId,
      type: 'contextmenu',
      layerId: options.layerId,
      objectId: options.objectId,
      coordinates: event.latlng ? [event.latlng.lat, event.latlng.lng] : undefined,
    });
  }

  /** Обрабатываем onAddObject */
  public onAddObject(options: IMapBaseAddObjectToLayerOptions, event: L.LayerEvent): void {
    this.mapService.sendObjectEvent({
      mapId: this.mapId,
      type: 'addObject',
      layerId: options.layerId,
      objectId: options.objectId,
      coordinates: event.target?.latlng ? [event.target.latlng.lat, event.target.latlng.lng] : undefined,
    });
  }

  /** Обрабатываем onAddObject */
  public onRemoveObject(options: IMapBaseAddObjectToLayerOptions, event: L.LayerEvent): void {
    if (options.eventsConfig.eventRemoveObject) {
      this.mapService.sendObjectEvent({
        mapId: this.mapId,
        type: 'remove',
        layerId: options.layerId,
        objectId: options.objectId,
        coordinates: event.target?.latlng ? [event.target.latlng.lat, event.target.latlng.lng] : undefined,
      });
    }

    if (options.eventsConfig.onRemoveObject) {
      options.eventsConfig.onRemoveObject({
        mapId: this.mapId,
        type: 'remove',
        layerId: options.layerId,
        objectId: options.objectId,
        coordinates: event.target?.latlng ? [event.target.latlng.lat, event.target.latlng.lng] : undefined,
      });
    }
  }

  /**
   * Обработчик событий на объекте
   * @param event Событие
   */
  private onObjectEvent(event: L.LeafletMouseEvent): void {
    this.mapService.eventObject(<IMapBaseObjectEvent>{
      eventName: 'MapBaseObjectEvent',
      typeEvent: event.type,
      layerId: event.target.layerId,
      objectId: event.target.objectId,
      coordinates: event.latlng ? [event.latlng.lat, event.latlng.lng] : undefined,
      mapId: this.mapId,
    });
  }

  /**
   * Инициализирует плагин leaflet-simple-map-screenshoter
   * @param options параметры инициализации плагина leaflet-simple-map-screenshoter
   */
  private initScreenshot(options: IMapBaseTakeScreenOptions): void {
    this.simpleMapScreenshot = L.simpleMapScreenshoter(options).addTo(this.lMap!);
  }

  public trackObject(index: number, item: string): string {
    return item;
  }

  /** Удаляем физически объект, иконку и отписываемся от подписок */
  private deleteLayerObject(id: string): void {
    const obj: L.Layer = this.mapObjects[id];
    if (obj) {
      obj.off();
      obj.unbindTooltip();
      obj.unbindPopup();
      delete this.mapObjects[id];
      delete this.mapObjectsIcon[id];
    }
  }

  /** Устанавливаем иконку в активное состояние */
  private setActiveIcon(objectId: string) {
    const item = this.mapObjectsQuery.getEntity(objectId);
    const layer = this.mapLayersQuery.getEntity(item.layerId);
    if (layer.selectIconConfig) {
    } else {
      const icon = new L.Icon({
        iconSize: [56, 76],
        iconAnchor: [28, 66],
        popupAnchor: [0, -66],
        tooltipAnchor: [0, -66],
        iconUrl: '/assets/icons/click-icon.png',
        shadowUrl: '/assets/icons/marker-shadow.png',
      });
      (this.mapObjects[objectId] as L.Marker).setIcon(icon);
    }
  }

  /** Устанавливаем иконку в активное состояние */
  private setMainIcon(objectId: string, options: L.IconOptions) {
    (this.mapObjects[objectId] as L.Marker).setIcon(L.icon(options));
  }

  private isPointCoordinate(coordinates: TMapBaseCoordinates | TMapBaseCoordinates[]): boolean {
    return Array.isArray(coordinates) && coordinates.length === 2 && typeof coordinates[0] === 'number';
  }

  private isPolygonCoordinates(coordinates: TMapBaseCoordinates | TMapBaseCoordinates[]): boolean {
    return Array.isArray(coordinates) && coordinates.length >= 2 && Array.isArray(coordinates[0]);
  }
}
