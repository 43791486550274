import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ScComponentsModule } from '@smart-city/core/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule, NzIconService } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { RnisModule } from '../rnis/rnis.module';
import {
  AqiLayerControlComponent,
  BuildingRouteControlComponent,
  ChemicalMonitoringObjectsLayerControlComponent,
  EcoMonitoringObjectsLayerControlComponent,
  ElectricitySupplyFacilityLayerControlComponent,
  EosLayerControlComponent,
  FireMonitoringObjectsLayerControlComponent,
  FireRiskObjectsLayerControlComponent,
  HeatSupplyFacilitiesLayerControlComponent,
  IncidentsLayerControlComponent,
  KseonLayerControlComponent,
  LayerControlButtonComponent,
  MapAqiFilterComponent,
  MapBuildingRouteComponent,
  MapBuildingRouteV2Component,
  MapChemicalMonitoringObjectsFilterComponent,
  MapEmergencyFilterComponent,
  MapFireMonitoringObjectFilterComponent,
  MapOrganizationFilterComponent,
  MapPlannedWorkFilterComponent,
  MapSignificantObjectFilterComponent,
  MapVehicleFilterComponent,
  MapVideoDeviceFilterComponent,
  OrdersLayerControlComponent,
  PlannedWorksLayerControlComponent,
  RegionControlCenterLayerControlComponent,
  RnisLayerControlContainerComponent,
  SignificantObjectsLayerControlComponent,
  ThermopointsLayerControlComponent,
  VideoDevicesLayerControlComponent,
  VideoIncidentsLayerControlComponent,
  WaterSourceLayerControlComponent,
  WebCamFaultsLayerControlComponent,
  WlcsMonitoringObjectsLayerControlComponent,
  YardstickControlComponent,
} from './components';
import { ADD_ROAD_SVG, RULER_SVG } from './models/icons';
import { MapControlsService } from './services';

@NgModule({
  declarations: [
    AqiLayerControlComponent,
    BuildingRouteControlComponent,
    ChemicalMonitoringObjectsLayerControlComponent,
    EcoMonitoringObjectsLayerControlComponent,
    ElectricitySupplyFacilityLayerControlComponent,
    EosLayerControlComponent,
    FireMonitoringObjectsLayerControlComponent,
    FireRiskObjectsLayerControlComponent,
    HeatSupplyFacilitiesLayerControlComponent,
    IncidentsLayerControlComponent,
    KseonLayerControlComponent,
    LayerControlButtonComponent,
    MapAqiFilterComponent,
    MapBuildingRouteComponent,
    MapBuildingRouteV2Component,
    MapChemicalMonitoringObjectsFilterComponent,
    MapEmergencyFilterComponent,
    MapFireMonitoringObjectFilterComponent,
    MapOrganizationFilterComponent,
    MapPlannedWorkFilterComponent,
    MapSignificantObjectFilterComponent,
    MapVehicleFilterComponent,
    MapVideoDeviceFilterComponent,
    OrdersLayerControlComponent,
    PlannedWorksLayerControlComponent,
    RegionControlCenterLayerControlComponent,
    RnisLayerControlContainerComponent,
    SignificantObjectsLayerControlComponent,
    ThermopointsLayerControlComponent,
    VideoDevicesLayerControlComponent,
    WaterSourceLayerControlComponent,
    WebCamFaultsLayerControlComponent,
    WlcsMonitoringObjectsLayerControlComponent,
    YardstickControlComponent,
    VideoIncidentsLayerControlComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzDatePickerModule,
    NzDividerModule,
    NzDropDownModule,
    NzDropDownModule,
    NzFormModule,
    NzGridModule,
    NzIconModule,
    NzInputModule,
    NzModalModule,
    NzSelectModule,
    NzToolTipModule,
    ScComponentsModule,
    RnisModule,
  ],
  exports: [
    AqiLayerControlComponent,
    BuildingRouteControlComponent,
    ChemicalMonitoringObjectsLayerControlComponent,
    EcoMonitoringObjectsLayerControlComponent,
    EosLayerControlComponent,
    FireMonitoringObjectsLayerControlComponent,
    FireRiskObjectsLayerControlComponent,
    IncidentsLayerControlComponent,
    KseonLayerControlComponent,
    LayerControlButtonComponent,
    MapAqiFilterComponent,
    MapBuildingRouteComponent,
    MapBuildingRouteV2Component,
    MapVehicleFilterComponent,
    OrdersLayerControlComponent,
    PlannedWorksLayerControlComponent,
    RnisLayerControlContainerComponent,
    SignificantObjectsLayerControlComponent,
    ThermopointsLayerControlComponent,
    VideoDevicesLayerControlComponent,
    WaterSourceLayerControlComponent,
    WebCamFaultsLayerControlComponent,
    WlcsMonitoringObjectsLayerControlComponent,
    YardstickControlComponent,
  ],
  providers: [MapControlsService],
})
export class MapControlsModule {
  constructor(private readonly iconService: NzIconService) {
    this.iconService.addIconLiteral('ng-zorro:add-road', ADD_ROAD_SVG);
    this.iconService.addIconLiteral('ng-zorro:ruler', RULER_SVG);
  }
}
