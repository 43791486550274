<button
  nz-button
  nzType="default"
  nzGhost
  [nzTooltipTitle]="layer.name"
  nzTooltipPlacement="top"
  nz-tooltip
  (click)="changeState(true)"
  [nzLoading]="isLoading"
  [class.layer-loading]="isLoading"
  nz-dropdown
  [nzDropdownMenu]="menu"
  [attr.nameForTest]="layer.name"
  [attr.selectedForTest]="!!active"
>
  <i nz-icon>
    <img [src]="active ? activeIcon : disabledIcon" [alt]="layer.name" />
  </i>
</button>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="changeState(false)">Выключить</li>
    <li nz-menu-item (click)="openFilterDialog()">Фильтр</li>
  </ul>
</nz-dropdown-menu>
