import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayersDataService, OperationsService } from '@bg-front/core/services';
import { IMapBaseObjectBaseEvent, IMapBaseObjectSelectEvent } from '@bg-front/map/models/interfaces';
import { MapBaseService } from '@bg-front/map/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Settings2Service } from '@smart-city/core/services';

import { BaseLayerControlComponent } from '../base-layer-control/base-layer-control.component';
import { MapControlsService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'bg-region-control-center-layer-control',
  templateUrl: './region-control-center-layer-control.component.html',
  styleUrls: ['./region-control-center-layer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegionControlCenterLayerControlComponent extends BaseLayerControlComponent implements OnInit {
  constructor(
    mapService: MapBaseService,
    cdr: ChangeDetectorRef,
    gisService: LayersDataService,
    settings: Settings2Service,
    router: Router,
    route: ActivatedRoute,
    operationsService: OperationsService,
    mapControlsService: MapControlsService,
  ) {
    super(mapService, cdr, gisService, settings, router, route, operationsService, mapControlsService);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    /** Подписка на Клик по объекту */
    this.mapService
      .getObservableObjectEvent<IMapBaseObjectSelectEvent>(this.mapId, 'selectObject', this.layer.nameOnMap)
      .pipe(untilDestroyed(this))
      .subscribe((event: IMapBaseObjectSelectEvent) => {
        if (event.selected) {
          this.showObjectInfo(event);
        }
      });
  }

  /** Обработка нажатия и вызов соответствующей навигации */
  private showObjectInfo(event: IMapBaseObjectBaseEvent): void {
    this.router.navigate([{ outlets: { leftPopup: ['emergency', event?.objectId] } }], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }
}
