<div *ngIf="!model" class="center">
  <mat-spinner strokeWidth="5"></mat-spinner>
  <div class="spinner-label">Загрузка...</div>
</div>
<div class="panel-wrapper" *ngIf="model">
  <form [formGroup]="form" errorTailor id="idForTest">
    <nw-header [options]="headerActionsOptions" (clickButton)="onClickActionsButton($event)"></nw-header>
    <div class="content">
      <mat-accordion>
        <mat-expansion-panel [expanded]="!isShowAddressBlock">
          <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
            <mat-panel-title>
              <div class="header-container">
                <div>Описание</div>
                <div class="header-container__divider">
                  <hr />
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <create-update-info
            [createTitle]="'Дата и время создания'"
            [createTime]="model.creationTime"
            [updateTitle]="'Дата и время последнего обновления'"
            [updateTime]="model.updateTime"
          ></create-update-info>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="nameOptions" formControlName="name"></sc-input>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="addressOptions" formControlName="address"></sc-input>
              </div>
            </div>
          </div>
          <div class="bg-row bg-row-align-content-center">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="coordinatesOptions" formControlName="coordinates"></sc-input>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-checkbox [options]="showOnMapOptions" formControlName="showOnMap"></sc-checkbox>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="supplyTypeIdOptions" formControlName="supplyTypeId"></sc-select>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="objectStatusOptions" formControlName="hcsObjectStatus"></sc-select>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="statusIdOptions" formControlName="statusId"></sc-select>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="hcsObjectKindIdOptions" formControlName="hcsObjectKindId"></sc-select>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="hcsObjectTypeIdOptions" formControlName="hcsObjectTypeId"></sc-select>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select
                  [options]="operatingOrganizationIdOptions"
                  formControlName="operatingOrganizationId"
                ></sc-select>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="ownerOrganizationIdOptions" formControlName="ownerOrganizationId"></sc-select>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-textarea [options]="descriptionOptions" formControlName="description"></sc-textarea>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel [expanded]="isShowAddressBlock">
          <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
            <mat-panel-title>
              <div class="header-container">
                <div>Принадлежность адресов</div>
                <div class="header-container__divider">
                  <hr />
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group mini-map-container">
                <bg-map-base [mapOptions]="mapOptions"></bg-map-base>
              </div>
              <bg-address-ownership
                #ownedAddresses
                [service]="'Admin'"
                [entity]="'MonitoringObjectAddressOwnership'"
                [query]="{ monitoringObjectId: model.id }"
                [viewOnly]="isDisabled"
                (saveEvent)="saveOwnedAddress($event)"
                (deleteEvent)="deleteOwnedAddresses($event)"
              ></bg-address-ownership>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </form>
</div>
