import { Component, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IScButtonOptions, IScTextButtonOptions, ScButtonComponent } from '@smart-city/core/common';
import { AccessService, Settings2Service, SfsService } from '@smart-city/core/services';
import * as dayjs from 'dayjs';
import { of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';
import { IFileInfoDto } from 'smart-city-types/interfaces/file-info-dto.interface';

import { ISignature } from '../../models/interfaces/signature.interface';
import { IFileInfo } from '../../models/interfaces/file-info.interface';
import { SignatureService } from '../../services/signature/signature.service';
import { BaseComponent } from '../base-component/base.component';
import { SignatureDialogComponent } from '../signature-dialog/signature-dialog.component';
import { SignatureInfoDialogComponent } from '../signature-info/signature-info-dialog.component';
import { saveAs } from 'file-saver';

/**
 * Компонент множественной загрузки файлов
 *  @example
 *  <multi-file
 *    [files]="files"
 *    [fileLimit]="2"
 *    (onFilesChange)="onFilesChange($event)"
 *  ></multi-file>
 */
@UntilDestroy()
@Component({
  selector: 'multi-file',
  templateUrl: './multi-file.component.html',
  styleUrls: ['./multi-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiFileComponent),
      multi: true,
    },
  ],
})
export class MultiFileComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  /** Счётчик для генерации id компоненты */
  public static nextId = 0;
  /** Установка id */
  @HostBinding() id = `multi-file-${MultiFileComponent.nextId}`;

  /** Список файлов */
  @Input()
  public files: IFileInfo[] = [];

  /** Флаг указывающий необходимость выводить заголовок */
  @Input()
  public showTitle: boolean = true;

  /** Ограничение количества загружаемых файлов */
  @Input()
  public fileLimit: number;

  /** Активен или неактивен по внутренним правилам, несмотря на установки формы */
  @Input()
  public selfEnabled: boolean = false;

  /** Режим работы компонента */
  @Input()
  public mode: 'edit' | 'view' = 'edit';

  /** Событие изменения значения списка файлов */
  @Output()
  public filesChangeEvent: EventEmitter<IAnyObject[]> = new EventEmitter<IAnyObject[]>();

  /** Компонент кнопки добавления файла */
  @ViewChild('fileButton', { static: false }) private fileButton: ScButtonComponent;

  /** Настройка кнопки добавления файла */
  public addButton: IScButtonOptions = {
    name: 'add',
    icon: 'add',
    isFile: true,
  };

  /** Настройка кнопки скачивания */
  public downloadButton: IScButtonOptions = {
    name: 'download',
    icon: 'arrow_downward',
  };

  /** Настройка кнопки удаления */
  public deleteButton: IScButtonOptions = {
    name: 'delete',
    icon: 'delete_outline',
  };

  /** Настройка кнопки Подписать */
  public signButton: IScTextButtonOptions = {
    name: 'sign',
    title: 'Подписать',
    color: 'primary',
  };

  /** Настройка кнопки Сведения о подписи */
  public showSignatureButton: IScTextButtonOptions = {
    name: 'showSignature',
    title: 'Сведения о подписи',
    color: 'primary',
  };

  /** Список файлов не помеченных к удалению */
  public get activeFiles(): IFileInfo[] {
    return this.files.filter((item) => !(<IAnyObject>item.file).uuid || (<IAnyObject>item.file).isActive);
  }

  /** Флаг отвечает за отображение контролов связанных с подписанием файла */
  public showFileSigning = false;

  /** @ignore */
  constructor(
    private readonly sfs: SfsService,
    private readonly settings: Settings2Service,
    private readonly dialog: MatDialog,
    private readonly signatureService: SignatureService,
    private readonly access: AccessService,
  ) {
    super();
    MultiFileComponent.nextId++;
  }

  public ngOnInit(): void {
    this.files = this.files || [];
    this.addButton.disabled = (this.fileLimit && this.activeFiles.length >= this.fileLimit) || !this.selfEnabled;
    this.showFileSigning = this.access.accessMap['MultiFileSinging']?.visible;
  }

  /**
   * Обработчик события добавления файла в список
   * @param file - информация о файле
   */
  onFileChange(file: any) {
    this.files.push({
      file: file['0'],
      userId: { id: this.settings.currentUser.id, fio: this.settings.currentUser.fio },
    });
    this.propagateChange(this.files);
    this.filesChangeEvent.emit(this.files);
    this.addButton.disabled = this.fileLimit && this.activeFiles.length >= this.fileLimit;
    // Сбросить, т.к. в случае удаления только что добавленного файла и выбора его снова, он не выбирается
    // ибо не видит изменений.
    this.fileButton.resetFileInput();
  }

  /**
   * Конвертация размера файла в строку с нужным форматом
   * @param size - размер файла в байтах
   */
  public getFileSize(size: number): string {
    // Если размер менее килобайта
    if (size < 1024) {
      return `${size} Б`;
    }
    // Если размер менее мегабайта
    if (size < 1024 * 1024) {
      return `${Math.round((size / 1024) * 10) / 10} КБ`;
    }
    // Если размер менее гигабайта
    if (size < 1024 * 1024 * 1024) {
      return `${Math.round((size / (1024 * 1024)) * 10) / 10} МБ`;
    }
    return `${Math.round((size / (1024 * 1024 * 1024)) * 10) / 10} ГБ`;
  }

  /**
   * Конвертация времени в строку с нужным форматом
   * @param time - значение времени
   */
  public formatTime(time: number): string {
    return dayjs(time).format('DD.MM.YYYY HH:mm:ss');
  }

  /**
   * Скачивание файла
   * @param item - файл инфо
   */
  public downloadFile(item: IFileInfo) {
    this.sfs.directDownload((item.file as IAnyObject)?.uuid || (item.file as IAnyObject)?.id);

    if (item?.signature?.certificate) {
      this.downloadSignature(item.signature);
    }
  }

  /**
   * Скачивание файла подписи
   * @param signature - подпись
   */
  public downloadSignature(signature: ISignature): void {
    const blob = new Blob([signature.hash], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `Подпись_${signature.ownerFio}.sig`);
  }

  /**
   * Удаление файла из списка файлов
   * @param file - файл из списка
   */
  public deleteFile(file: IFileInfoDto) {
    for (let i = 0; i < this.files.length; i++) {
      if (this.files[i] === file) {
        // Если файл из хранилища SFS то надо пометить его к удалению
        if ((<IAnyObject>this.files[i].file).uuid) {
          (<IAnyObject>this.files[i].file).isActive = false;
          // Иначе удалить его из массива
        } else {
          this.files.splice(i, 1);
        }
        this.filesChangeEvent.emit(this.files);
        this.addButton.disabled = this.fileLimit && this.activeFiles.length >= this.fileLimit;
        break;
      }
    }
    this.propagateChange(this.files);
  }

  /**
   * Формирование строки с инорфмацией о файле
   * @param fio ФИО пользователя загрузившего файл
   * @param timeCreate время загрузки файла
   * @param size размер файла
   */
  public getMetaInfo(fio: string, timeCreate: number, size: number): string {
    return `${fio ? `${fio} ` : ''}${timeCreate ? `${this.formatTime(timeCreate)} ` : ''}${
      size ? `${this.getFileSize(size)} ` : ''
    }`;
  }

  /**
   * Записать значение в компонент (из ts в html), основная функция
   */
  public writeValue(value: IFileInfo[]): void {
    if (value) {
      this.files = value;
      this.addButton.disabled = this.fileLimit && this.activeFiles.length >= this.fileLimit;
    }
  }

  /**
   * Активируем или блокируем элемент
   * @param isDisabled новое состояние элемента
   */
  public setDisabledState?(isDisabled: boolean): void {
    this.addButton.disabled =
      (!this.selfEnabled && isDisabled) || (this.fileLimit && this.activeFiles.length >= this.fileLimit);
    this.deleteButton.disabled = !this.selfEnabled && isDisabled;
    this.files = [...this.files];
  }

  /**
   * Обработка нажатия на кнопку подписания файла
   * @param fileInfo - информация о файле
   */
  public signFile(fileInfo: IFileInfo) {
    const dialogRef = this.dialog.open(SignatureDialogComponent, {
      minWidth: 750,
      autoFocus: false,
      panelClass: 'modal-dialog-gray',
      data: { typeId: fileInfo.signature?.typeId, certificate: fileInfo.signature?.certificate },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter((value) => !!value),
        switchMap((signature: ISignature) => {
          fileInfo.signature = signature;
          fileInfo.signature.entityId = (<IAnyObject>fileInfo.file).uuid;
          return signature.certificate ? this.signatureService.signFile(fileInfo) : of('');
        }),
        untilDestroyed(this),
      )
      .subscribe((hash: string) => (fileInfo.signature.hash = hash));
  }

  /**
   * Обработка на нажатие кнопки сведения о подписи
   * @param fileInfo - информация о файле
   */
  public showSignature(fileInfo: IFileInfo) {
    this.dialog.open(SignatureInfoDialogComponent, {
      minWidth: 1000,
      data: { signature: fileInfo.signature },
    });
  }

  /**
   * Получение статуса подписания файла
   * @param file - информация о файле
   */
  public getSignatureStatus(file: IFileInfo): string {
    if (file.signature?.certificate) return 'Подписан усиленной квалифицированной подписью электронной подписью';
    if (file.signature?.signingDate) return 'Подписан простой электронной подписью';
    return 'Документ не подписан';
  }

  /**
   * Обработать значение из компонента (из html в ts), основная функция fn
   */
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Обработать, когда потрогали поле (потеря фокуса)
   */
  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /** Подписка на изменения */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private propagateChange = (_: any) => {
    return;
  };

  /** Подписка на нажатия */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private propagateTouch = () => {
    return;
  };
}
