import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayersDataService, OperationsService } from '@bg-front/core/services';
import { MapBaseService } from '@bg-front/map/services';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AccessService, RestService, Settings2Service, SubscriberService } from '@smart-city/core/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { map } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';
import { MapControlsService } from '../../services';
import { IncidentsLayerControlComponent } from '../incidents-layer-control/incidents-layer-control.component';
import { Observable, of } from 'rxjs';

/** Компонента управляющая слоем "Инциденты ВА" */
@UntilDestroy()
@Component({
  selector: 'bg-video-incidents-layer-control',
  templateUrl: './video-incidents-layer-control.component.html',
  styleUrls: ['./video-incidents-layer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoIncidentsLayerControlComponent extends IncidentsLayerControlComponent implements OnInit {

  constructor(
    private readonly rest: RestService,
    mapService: MapBaseService,
    cdr: ChangeDetectorRef,
    gisService: LayersDataService,
    settings: Settings2Service,
    router: Router,
    route: ActivatedRoute,
    operationsService: OperationsService,
    mapControlsService: MapControlsService,
    accessService: AccessService,
    subs: SubscriberService,
    modalService: NzModalService,
  ) {
    super(
      mapService,
      cdr,
      gisService,
      settings,
      router,
      route,
      operationsService,
      mapControlsService,
      accessService,
      subs,
      modalService,
    );
  }

  /**
   * Функция вызывающаяся после загрузки порции данных,
   * которая может каким либо образом использован для трансформации или дополнительных фильтрующих запросов
   */
  public override afterLoadChain(items: IAnyObject[]): Observable<IAnyObject[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Emergency' },
      entity: {
        name: 'Emergency',
        attributes: [
          "id",
          "number",
          "coordinates",
          'parentEventId.vaDetail.matchedLists',
          'parentEventId.vaDetail.matchedDossier',
          'parentEventId.vaDetail.vaTypeId.sysname',
        ],
        query: {
          id: { $in: items.map((item: IAnyObject) => item.id) },
          $or: [
            { $expr: { $eq: [
              '$dossierListAccessRights.userId',
              this.settings.currentUser.id,
            ] } },
            { $expr: { $eq: [
              '$wantedPersons.creationAuthor',
              this.settings.currentUser.id,
            ] } },
          ]
        },
        sort: {
          field: 'name',
          direction: 'asc',
        },
      },
      data: {
        dossierListAccessRights: {
          $join: {
            service: 'FfsIntegration',
            entity: 'DossierListAccessRights',
            attributes: ['id', 'userId', 'dossierListId'],
            query: {
              $expr: { $contains: [
                '$parentEventId.vaDetail.matchedLists',
                { $expr: { $toJsonb: ['$dossierListAccessRights.dossierListId'] } },
              ] },
            }
          }
        },
        wantedPersons: {
          $join: {
            service: 'FfsIntegration',
            entity: 'WantedPersons',
            attributes: ['id', 'creationAuthor'],
            query: {
              $expr: { $eq: [
                  // Привожу поля т.к matchedDossier сейчас - строка, нет ссылки на FfsIntegration_WantedPersons.
                  // Предупредил аналитика, что поле в базе настроено странновато
                  { $expr: { $toJsonb: ['$parentEventId.vaDetail.matchedDossier'] } },
                  { $expr: { $toJsonb: ['$wantedPersons.id'] } },
                ] },
            }
          }
        }
      },
    }).pipe(
      map((response: IAbstractServiceData) => response.data?.items),
      untilDestroyed(this)
    );
  }
}
