import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Coordinates } from '@bg-front/core/models/classes';
import { MarkerIconFactory } from '@bg-front/map/models/classes';
import { PATH_POINT_MARKER_SVG } from '@bg-front/map/models/constants';
import {
  IMapBaseAddObjectOptions,
  IMapBaseEvent,
  IMapBaseMarkerOptions,
  IMapBasePolylineOptions,
  IMapBaseTooltip,
} from '@bg-front/map/models/interfaces';
import { MapBaseCoordinatesType } from '@bg-front/map/models/types';
import { MapBaseService } from '@bg-front/map/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import { IControlEvent } from '../../models/interfaces';

/**
 * Компонента реализует элемент "Линейка на карте"
 */
@UntilDestroy()
@Component({
  selector: 'bg-yardstick-control',
  templateUrl: './yardstick-control.component.html',
  styleUrls: ['./yardstick-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YardstickControlComponent implements OnInit, OnChanges {
  /** Состояние кнопки */
  public active: boolean = false;
  @Input() public name: string;
  /** Блокирование кнопки */
  @Input() disabled: boolean = false;
  /** Имя карты для работы */
  @Input() mapId: string;
  /** Информируем о текущем состоянии */
  @Output() changeActive: EventEmitter<IControlEvent> = new EventEmitter<IControlEvent>(true);

  /** Массив точек */
  private pointsArray: MapBaseCoordinatesType[] = [];

  /** Имя слоя */
  private layerName: string = 'yardstick';

  constructor(private readonly mapService: MapBaseService, private readonly cdr: ChangeDetectorRef) {}
  /** @ignore */
  public ngOnInit(): void {
    this.changeActive.emit({
      name: this.name,
      active: false,
    });

    const icon = MarkerIconFactory.makeIcon({
      iconSize: 12,
      iconColor: 'black',
      iconSVG: PATH_POINT_MARKER_SVG,
      shapeType: 'Круг',
      shapeSize: 16,
      shapeColor: 'black',
    });

    /** Инициализируем модель карты для отображения результатов прогнозирования */
    this.mapService
      .getObservableMapEvents(this.mapId, 'click')
      .pipe(
        filter(() => this.active === true),
        untilDestroyed(this),
      )
      .subscribe((event: IMapBaseEvent) => {
        if (this.pointsArray.length === 0) {
          this.mapService.addMarker(this.mapId, <IMapBaseAddObjectOptions<IMapBaseMarkerOptions>>{
            layerId: this.layerName,
            objectId: `${this.layerName}_start`,
            coordinates: event.coordinates,
            objectOptions: {
              icon,
            },
          });
        }

        this.pointsArray.push(event.coordinates);

        if (this.pointsArray.length >= 2) {
          const distance = Coordinates.distanceInKmBetweenEarthCoordinates(
            this.pointsArray as [[number, number], [number, number]],
          ).toFixed(3);

          this.mapService.addPolyline(this.mapId, <IMapBaseAddObjectOptions<IMapBasePolylineOptions>>{
            layerId: this.layerName,
            objectId: `${this.layerName}_path_1`,
            coordinates: this.pointsArray,
            objectOptions: {
              color: 'black',
              opacity: 1,
              weight: 6,
            },
          });

          this.mapService.addPolyline(this.mapId, <IMapBaseAddObjectOptions<IMapBasePolylineOptions>>{
            layerId: this.layerName,
            objectId: `${this.layerName}_path_2`,
            coordinates: this.pointsArray,
            objectOptions: {
              color: 'white',
              weight: 2,
              opacity: 1,
              dashArray: [5, 10],
            },
          });

          this.mapService.addMarker(this.mapId, <IMapBaseAddObjectOptions<IMapBaseMarkerOptions>>{
            layerId: this.layerName,
            objectId: `${this.layerName}_tooltip`,
            coordinates: event.coordinates,
            tooltip: <IMapBaseTooltip>{
              text: `${distance} км.`,
              permanent: true,
              className: 'yardstick-tooltip',
              offset: [0, -30],
            },
            objectOptions: {
              icon,
            },
          });
        }
      });
  }

  public ngOnChanges(): void {
    if (this.disabled) {
      this.active = false;
      this.mapService.removeLayer(this.mapId, this.layerName);
    }
  }

  /** Устанавливаем статус активности */
  public setActive(): void {
    if (!this.disabled) {
      this.active = !this.active;
      if (!this.active) {
        this.mapService.removeLayer(this.mapId, this.layerName);
        this.pointsArray = [];
      }
      this.changeActive.emit({
        name: this.name,
        active: this.active,
      });
    }

    this.cdr.detectChanges();
  }
}
