<ng-container *ngIf="!active">
  <button
    nz-button
    nzType="default"
    nzGhost
    [nzTooltipTitle]="layer.name"
    nzTooltipPlacement="top"
    nz-tooltip
    (click)="changeState(!active)"
    [nzLoading]="isLoading"
    [class.layer-loading]="isLoading"
    [attr.nameForTest]="layer.name"
    [attr.selectedForTest]="!!active"
  >
    <i nz-icon style="margin-top: -5px">
      <img [src]="disabledIcon" [alt]="layer.name" />
    </i>
  </button>
</ng-container>
<ng-container *ngIf="active">
  <button
    nz-button
    nz-dropdown
    nzType="default"
    nzGhost
    nzTooltipPlacement="top"
    nz-tooltip
    nzTooltipTrigger="hover"
    [nzTooltipTitle]="layer.name"
    [nzLoading]="isLoading"
    [class.layer-loading]="isLoading"
    [nzDropdownMenu]="menu"
    [nzPlacement]="'bottomLeft'"
    [attr.nameForTest]="layer.name"
    [attr.selectedForTest]="!!active"
  >
    <i nz-icon style="margin-top: -5px">
      <img [src]="activeIcon" [alt]="layer.name" />
    </i>
  </button>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item (click)="changeState(false)">Выключить</li>
      <li nz-menu-item (click)="openFilterDialog()">Фильтр</li>
    </ul>
  </nz-dropdown-menu>
</ng-container>
