import { Component, Input, OnInit } from '@angular/core';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { IAdminMunicipalSchemaDto } from 'smart-city-types';
import { IFireMonitoringObjectMapFilterForm } from '../../models/interfaces';

/** Компонент фильтра слоя "Объекты пожарного мониторинга" */
@Component({
  selector: 'map-fire-monitoring-object-filter',
  templateUrl: './map-fire-monitoring-object-filter.component.html',
  styleUrls: ['./map-fire-monitoring-object-filter.component.scss'],
})
export class MapFireMonitoringObjectFilterComponent implements OnInit {
  /** Значения фильтров */
  @Input()
  public mapLayerFilters: ValuesOf<ControlsOf<IFireMonitoringObjectMapFilterForm>>;

  /** Форма */
  public form: FormGroup<ControlsOf<IFireMonitoringObjectMapFilterForm>>;

  /** Системы пожарного мониторинга */
  public systems: IScSelectItem[] = this.settings.getDictForSelect('fireMonitoringSystems');

  /** Муниципальные образования */
  public municipalList: IAdminMunicipalSchemaDto[] = this.settings.allMo.sort(
    (a: IAdminMunicipalSchemaDto, b: IAdminMunicipalSchemaDto) => a.name.localeCompare(b.name),
  );

  /** Просмотр всех МО */
  private viewAllMunicipals!: boolean;

  /** @ignore */
  constructor(
    private readonly accessService: AccessService,
    private readonly modalRef: NzModalRef,
    private readonly settings: Settings2Service,
  ) {}

  /** @ignore */
  public ngOnInit(): void {
    this.viewAllMunicipals = this.accessService.accessMap['viewAllMunicipals']?.visible;
    this.form = new FormGroup<ControlsOf<IFireMonitoringObjectMapFilterForm>>({
      systemId: new FormControl(this.mapLayerFilters?.systemId),
      hasOfflineSources: new FormControl(this.mapLayerFilters?.hasOfflineSources),
      onFire: new FormControl(this.mapLayerFilters?.onFire),
      municipalId: new FormControl({
        value: this.viewAllMunicipals ? this.mapLayerFilters?.municipalId : this.settings.currentUser.organizationId.mo,
        disabled: !this.viewAllMunicipals,
      }),
    });
    this.municipalList = this.settings.allMo
      .sort((a: IAdminMunicipalSchemaDto, b: IAdminMunicipalSchemaDto) => a.name.localeCompare(b.name))
      .filter(
        (item: IAdminMunicipalSchemaDto) =>
          this.viewAllMunicipals || item.id === this.settings.currentUser.organizationId.mo,
      );
  }

  /** Очистка формы */
  public clear(): void {
    this.form.reset();
    if (!this.viewAllMunicipals) {
      this.form.controls.municipalId.patchValue(this.settings.currentUser.organizationId.mo);
    }
  }

  /** Закрытие формы */
  public close(): void {
    this.modalRef.destroy();
  }

  /** Фильтрация */
  public filtrate(): void {
    this.modalRef.destroy(this.form.getRawValue());
  }
}
