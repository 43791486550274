import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { IScrollableContainerComponent } from '@smart-city/core/common';
import { takeUntil } from 'rxjs/operators';
import { INotificationObjectsHcsView } from '../../../../bg/modules/external-interactions/modules/notifications-objects-hcs/models/interfaces';
import { NotificationsObjectsHcsService } from '../../../../bg/modules/external-interactions/modules/notifications-objects-hcs/services';

/**
 * Компонент для отображения минимальной информации об уведомлении
 * Реализует интерфейс IScrollableContainerComponent,
 * что дает возможность использовать его в динамическом
 * скроллируемом контейнере (ScrollableContainer)
 */
@Component({
  selector: 'atm-notification-mini-card',
  templateUrl: './atm-notification-mini-card.component.html',
  styleUrls: ['./atm-notification-mini-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AtmNotificationMiniCardComponent extends BaseComponent implements OnInit, IScrollableContainerComponent {
  /** Информация об уведомлении */
  public notification: INotificationObjectsHcsView;

  /** Активная карточка */
  public isActive: boolean = false;

  /** Входной параметр для инициализации полей */
  @Input()
  public set data(value: INotificationObjectsHcsView) {
    this.notification = value;
    this.cdr.detectChanges();
  }

  /** @ignore */
  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly notificationHcsService: NotificationsObjectsHcsService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    /** Подписка на выбор карточки оповещения в реестре */
    this.notificationHcsService.notificationsObjectHcsSelectItem$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((significantObjectId: string) => {
        this.isActive = significantObjectId === this.notification.id;
        if (this.isActive) {
          this.router.navigate([{ outlets: { viewForm: ['hcsNotificationDetails', this.notification.id] } }], {
            relativeTo: this.route.parent,
            queryParamsHandling: 'merge',
          });
        }
        this.cdr.detectChanges();
      });
  }

  /**
   * Обработка клика на карточку
   * @param $event - информация о событии
   */
  public onClick($event): void {
    this.notificationHcsService.selectNotificationsObjectHcs(this.isActive ? undefined : this.notification.id);
  }
}
