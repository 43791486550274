<form nz-form [formGroup]="form" nzLayout="vertical">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzFor="systemId">Система пожарного мониторинга</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="systemId" nzSize="large" nzAllowClear nzShowSearch>
            <nz-option
              [nzValue]="system.id"
              [nzLabel]="system.text"
              *ngFor="let system of systems"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzFor="hasOfflineSources">Потеря связи с объектом</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="hasOfflineSources" nzSize="large" nzAllowClear>
            <nz-option [nzValue]="true" nzLabel="Да"></nz-option>
            <nz-option [nzValue]="false" nzLabel="Нет"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzFor="onFire">Пожар на объекте</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="onFire" nzSize="large" nzAllowClear>
            <nz-option [nzValue]="true" nzLabel="Да"></nz-option>
            <nz-option [nzValue]="false" nzLabel="Нет"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-col [nzSpan]="24">
    <nz-form-item>
      <nz-form-label nzFor="municipalId">Муниципальное образование</nz-form-label>
      <nz-form-control>
        <nz-select formControlName="municipalId" nzSize="large" nzAllowClear nzShowSearch>
          <nz-option
            [nzValue]="mo.id"
            [nzLabel]="mo.name"
            *ngFor="let mo of municipalList"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>
<div *nzModalFooter>
  <div nz-row>
    <div nz-col [nzSpan]="12" class="d-flex">
      <button nz-button nzType="default"(click)="clear()">Очистить</button>
    </div>
    <div nz-col [nzSpan]="12">
      <button nz-button nzType="default" (click)="close()">Отмена</button>
      <button nz-button nzType="primary" [disabled]="!form.valid" (click)="filtrate()">Фильтровать</button>
    </div>
  </div>
</div>
