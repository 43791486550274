import { Uuid } from '@smart-city/core/utils';
import { noop } from 'rxjs';

import { IMapBaseClusterOptions, IMapObjectIconOptions } from '../interfaces';

/** Модель слоя */
export class MapLayer {
  /**
   * Идентификатор
   * @default Uuid.newUuid()
   */
  public id: string = Uuid.newUuid();
  /**
   * Кластеризация
   * @default false
   */
  public cluster: boolean = false;

  /**
   * Видимость
   * @default false
   */
  public isShow: boolean = false;

  /**
   * Настройки кластеризации
   * @default undefined
   */
  public clusterOptions?: IMapBaseClusterOptions | undefined;

  /** Настройки иконки активности */
  public selectIconConfig?: IMapObjectIconOptions;

  constructor(id?: string, cluster?: boolean, isShow?: boolean, clusterOptions?: IMapBaseClusterOptions) {
    id ? (this.id = id) : noop();
    cluster ? (this.cluster = cluster) : noop();
    isShow ? (this.isShow = isShow) : noop();
    clusterOptions ? (this.clusterOptions = clusterOptions) : noop();
  }
}
