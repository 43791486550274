import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { AppInjector, Coordinates } from '@bg-front/core/models/classes';
import {
  CLICK_MAKER_SHAPE_SVG,
  PATH_POINT_MARKER_SVG,
} from '@bg-front/core/models/constants';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import {
  IBgMapExtFilterOptions,
  IMapLayer,
  IMapLayerData,
  IMapLayerEntityFilter,
  IMiniCardIncidentSelectionEvent,
  IPictogramSettingsDto,
  IPictogramSettingsValueDto,
  IPolygonDto,
  ISubstrateDto,
} from '@bg-front/core/models/interfaces';
import {
  BgMapService,
  CommunicationService,
  LayersDataService,
  OperationsService,
  SubstratesQuery,
  SubstratesService,
} from '@bg-front/core/services';
import { RegistryPanelService } from '@bg-front/registry-panel/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { INwHeaderBarOptions } from '@smart-city/core/common';
import { IDictionaryInfo, IModifiedData } from '@smart-city/core/interfaces';
import { ScNavService, Settings2Service, SidebarContainerService, SubscriberService } from '@smart-city/core/services';
import {
  IconShapeType,
  IMapBaseEvent,
  IMapBaseObjectEvent,
  IMapObjectIconOptions,
  IMapViewObjects,
  IMapViewObjectsEvent,
  IMapViewObjectsOptions,
  IMapViewObjectsRequest,
  MapBaseCoordinatesType,
  MapBaseModel,
  MapBaseService,
  TIconShape,
} from '@smart-city/maps/sc';
import * as clone from 'clone';
import * as L from 'leaflet';
import { NzModalService } from 'ng-zorro-antd/modal';
import { forkJoin, of } from 'rxjs';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { IAdminMunicipalSchemaDto, IAnyObject, IMapObjectDto } from 'smart-city-types';

import { LayersEnum } from '@bg-front/core/models/enums';
import { IMapBaseBaseUrlsOptions } from '@bg-front/map/models/interfaces';
import { TMapBaseCoordinates } from '@bg-front/map/models/types';
import { NzDividerComponent } from 'ng-zorro-antd/divider';
import { ForecastingModalFormComponent } from '../../../forecasting/components';
import {
  AqiLayerControlComponent,
  EcoMonitoringObjectsLayerControlComponent,
  EosLayerControlComponent,
  FireMonitoringObjectsLayerControlComponent,
  FireRiskObjectsLayerControlComponent,
  IncidentsLayerControlComponent,
  KseonLayerControlComponent,
  LayerControlButtonComponent,
  OrdersLayerControlComponent,
  PlannedWorksLayerControlComponent,
  RegionControlCenterLayerControlComponent,
  RnisLayerControlContainerComponent,
  SignificantObjectsLayerControlComponent,
  ThermopointsLayerControlComponent,
  VideoDevicesLayerControlComponent,
  VideoIncidentsLayerControlComponent,
  WaterSourceLayerControlComponent,
  WebCamFaultsLayerControlComponent,
  WlcsMonitoringObjectsLayerControlComponent,
} from '../../../map-controls/components';
import { IControlEvent } from '../../../map-controls/models/interfaces';
import { RnisExternalService } from '../../../rnis/services';
import { IBgMapViewObjectsMarkerData } from '../../models/interfaces';
import { EmergencyService, MiniMapService, VideoDevicesService } from '../../services';
import { LegendDialogComponent } from '../legend-dialog/legend-dialog.component';
import { MapLayersFiltersDialogComponent } from '../map-layers-filters-dialog/map-layers-filters-dialog.component';

/**
 * Базовый класс для всех компонент рабочих столов
 * Реализует отписку от событий и базовый ngOnDestroy
 */
@UntilDestroy()
@Component({
  template: '',
})
export abstract class BaseWorkspaceComponent extends BaseComponent implements OnInit, OnDestroy {
  /**
   * Настройки заголовка
   */
  public headerOptions: INwHeaderBarOptions = {
    title: 'Рабочий стол оператора',
    name: 'header',
    margin: 'collapse',
    bgColor: 'white',
    buttons: [
      {
        type: 'button',
        options: {
          name: 'burger',
          icon: 'menu',
        },
      },
    ],
  };

  /**
   * Запрос от родительского компонента
   */
  public request: IMapViewObjectsRequest;

  /**
   * Ссылка на сервис настроек
   */
  public readonly settings: Settings2Service;

  /**c
   * Ссылка на сервис регистров
   */
  public readonly registryPanelService: RegistryPanelService;

  /**
   * Ссылка на сервис бокового меню
   */
  public readonly sidebarContainerService: SidebarContainerService;
  /** Сервис межкомпонентного взаимодействия */
  public readonly communicationService: CommunicationService;
  /**
   * Сервис прослушивания изменений
   */
  public readonly subs: SubscriberService;
  /** Сервис для общения с миникартой */
  public readonly miniMapService: MiniMapService;

  /**
   * Сервис для работы с сущностью Видеокамеры
   */
  public videoDevicesService: VideoDevicesService;

  /**
   * Ссылка на ГИС сервис
   */
  public readonly gisService: LayersDataService;
  /**
   * Ссылка на сервис работы с инцидентами
   */
  public readonly emergencyService: EmergencyService;
  /**
   * Ссылка на сервис Карты
   */
  public readonly bgMapService: BgMapService;
  /**
   * Ссылка на роутер
   */
  public readonly router: Router;

  /**
   * Ссылка на диалог с фильтрами объектов на карте
   */
  public readonly dialog: MatDialog;

  /**
   * Состояние формы инцидента
   */
  public toggleFormOpen = false;

  /**
   * Состояние формы просмотра поручения из плана реагирования
   */
  public toggleViewFormOpen = false;

  /**
   * Создание формы нового события с кнопки
   */
  public toggleFormCreateEvent = false;
  /**
   * Состояние формы события звонка
   * True определяет route-outlet реестра
   */
  public toggleFormOpenCall = false;
  /**
   * Состояние формы редактирования события
   * True определяет route-outlet реестра
   */
  public toggleEditEventFormOpen = false;
  /**
   * Состояние popup'а
   */
  public isPopupOpen = false;
  /**
   * Настройки карты
   */
  public mapViewObjectsOptions: IMapViewObjectsOptions;
  /**
   * Центр карты (координаты)
   */
  public centerMap: MapBaseCoordinatesType;
  /**
   * Зум карты
   */
  public zoomMap: number;
  /**
   * Настройки маркеров
   */
  public markersOptions: IBgMapViewObjectsMarkerData[] = [];
  /**
   * Настройки слоёв
   */
  public layersOptions: IBgMapExtFilterOptions[] = [];
  /**
   * Объект содержащий объекты на карте
   */
  public mapObjects: IMapViewObjects;
  /**
   * Хранилище слоёв и объектов на этих слоях
   */
  public mapLayersData: Map<string, IMapLayerData> = new Map();
  /** Модель карты для инициализации */
  public mapModel: MapBaseModel;
  /** Настоящая модель карты */
  public mapObjectModel: L.Map;
  /** Хранилище фильтров для слоев */
  public mapLayersFilters = {};
  public mapId: string;
  /** Состояние кнопки линейка */
  public isYardstick = false;
  /** Состояние кнопки построение маршрута */
  public isBuildingRoute = false;
  /** Массив точек для линейки */
  protected pointsArray: MapBaseCoordinatesType[] = [];
  /**
   * Объект содержащий все видимые объекты на карте на всех слоях
   */
  private allMapObjects = {};
  /**
   * Ссылка на сервис навигационной панели
   */
  private readonly scNavService: ScNavService;
  /**
   * Список слоёв
   */
  private layers: IMapLayer[];
  /** Сервис работы с картой */
  private readonly mapService: MapBaseService;
  /** Список svg иконок из конфигураций слоев для легенды */
  public svgIconMap: IAnyObject;
  /** Слои доступные для легенды */
  public availableForLegendLayers: IMapLayer[];
  /** Флаг, указывает открыта ли легенда */
  private isLegendOpen: boolean = false;

  /** Состояние кнопок управления картой */
  public controlState: {
    [key: string]: boolean;
  } = {};
  /** Контейнер */
  @ViewChild('layerControlsContainer', { read: ViewContainerRef, static: false })
  public layerControlsContainer: ViewContainerRef;
  /** Текущий зум */
  public mapZoom: number;
  /** Текущий центр карты */
  public mapCenter: TMapBaseCoordinates;

  /** @ignore */
  protected constructor(
    public readonly route: ActivatedRoute,
    public readonly operationsService: OperationsService,
    private readonly modalService: NzModalService,
    public readonly substratesQuery: SubstratesQuery,
    public readonly componentFactoryResolver: ComponentFactoryResolver,
    public readonly substratesService: SubstratesService,
    public readonly rnisExternal?: RnisExternalService,
  ) {
    super();
    const injector = AppInjector.getInjector();
    this.settings = injector.get(Settings2Service);
    this.registryPanelService = injector.get(RegistryPanelService);
    this.sidebarContainerService = injector.get(SidebarContainerService);
    this.gisService = injector.get(LayersDataService);
    this.scNavService = injector.get(ScNavService);
    this.emergencyService = injector.get(EmergencyService);
    this.subs = injector.get(SubscriberService);
    this.miniMapService = injector.get(MiniMapService);
    this.router = injector.get(Router);
    this.videoDevicesService = injector.get(VideoDevicesService);
    this.dialog = injector.get(MatDialog);
    this.mapService = injector.get(MapBaseService);
    this.bgMapService = injector.get(BgMapService);
    this.communicationService = injector.get(CommunicationService);

    this.mapId = 'baseMapWorkspace';
  }

  /**
   * Счетчик примененных фильтров
   */
  public get filtersCount(): number {
    return Object.values<IMapLayerEntityFilter[]>(this.mapLayersFilters).filter(
      (item: IMapLayerEntityFilter[]) => item.length,
    ).length;
  }

  /** Текущий пользователь, авторизованный в системе */
  public get user(): IAnyObject {
    return (this.settings || <any>{}).currentUser;
  }

  public isAdmin(): boolean {
    const moId = this.user.organizationId?.mo ?? this.settings.currentUser.mo?.id;
    const userMo = this.settings.allMo.find((mo: IAdminMunicipalSchemaDto) => {
      return mo.id === moId;
    });

    if (!userMo) {
      return false;
    }
    return !userMo.municipal;
  }

  public ngOnInit(): void {
    this.mapModel = new MapBaseModel('baseMapWorkspace', this.mapService);
    this.bgMapService.baseMapModel = this.mapModel;

    this.communicationService.runForecasting$.pipe(untilDestroyed(this)).subscribe((data: IAnyObject) => {
      this.modalService
        .create({
          nzTitle: 'Параметры прогнозирования',
          nzContent: ForecastingModalFormComponent,
          nzFooter: null,
          nzComponentParams: {
            data,
          },
          nzStyle: {
            width: '900px',
          },
        })
        .afterClose.pipe(untilDestroyed(this))
        .subscribe();
    });

    const moFilterValue = {};
    // Если МО текущего пользователя не является родительским,
    // то установить значение фильтра равное данному МО.
    if (!this.isAdmin()) {
      [
        LayersEnum.incidents,
        LayersEnum.orders,
        LayersEnum.camerasMalfunctions,
        LayersEnum.significantObjects,
        LayersEnum.kseon,
        LayersEnum.eos,
        LayersEnum.dds,
      ].forEach((key: LayersEnum) => {
        let property;
        const forOrganizationMoProperty = [LayersEnum.incidents, LayersEnum.orders, LayersEnum.camerasMalfunctions];
        if (forOrganizationMoProperty.includes(key)) {
          property = 'organization.mo';
        }
        const forMoProperty = [LayersEnum.significantObjects, LayersEnum.kseon, LayersEnum.eos, LayersEnum.dds];
        if (forMoProperty.includes(key)) {
          property = 'mo';
        }
        moFilterValue[key] = {
          property,
          value: this.user.organizationId?.mo ?? this.settings.currentUser.mo?.id,
          operation: FilterOperationEnum.equal,
        };
      });
    }
    Object.defineProperty(this.mapLayersFilters, 'mo', { value: moFilterValue, enumerable: false });
    Object.defineProperty(this.mapLayersFilters, 'responsible', { value: {}, enumerable: false });

    /** Запрос доступных слоев в конструкторе слоев */
    this.gisService
      .getLayers()
      .pipe(
        switchMap((layers: IMapLayer[]) => {
          this.availableForLegendLayers = layers?.filter((layer: IMapLayer) => layer.pictogramSettings?.length);
          return forkJoin([of(layers), this.gisService.getSvgIconsMap(this.availableForLegendLayers)]);
        }),
        untilDestroyed(this),
        catchError((err: Error) => this.catchErrorFn<[IMapLayer[], IAnyObject]>(err, 'Ошибка при загрузке слоёв')),
      )
      .subscribe(([layers, svgIconMap]: [IMapLayer[], IAnyObject]) => {
        const options = [];
        this.layers = layers;
        this.svgIconMap = svgIconMap;

        this.generateLayerControls();

        this.bgMapService.mapLayersReadyInform();

        (layers || []).reverse().forEach((layer: IMapLayer) => {
          const markerShape = ((layer.markerShape || {}) as IDictionaryInfo).sysname;
          let size = 17;
          const color = '#ffffff';
          let shapeSize = 30;
          let shapeLineRadius = null;

          // TODO: вынести настройки в конструктор слоев. тикет на анализ(BG-14190).
          switch (markerShape) {
            case 'circle':
              shapeSize = 38;
              size = 22;
              break;
            case 'rectangle':
              shapeLineRadius = 5;
              shapeSize = 34;
              break;
            case 'diamond':
              shapeSize = 32;
              size = 18;
              break;
          }
        });
      });

    const transformSubstratesUrl: (url: string) => string = (url: string): string => {
      return url.startsWith('/', 0) ? `//${window.location.host}${url}` : `//${url}`;
    };

    const substrates = this.substratesQuery.getAll();
    const activeSubstrate = (this.substratesQuery.getActive() as ISubstrateDto) || substrates?.[0];
    this.mapViewObjectsOptions = {
      mapId: 'baseMapWorkspace',
      url: substrates?.length > 1 ? undefined : transformSubstratesUrl(activeSubstrate.link),
      urls:
        substrates?.length > 1
          ? substrates.map((el: ISubstrateDto): IMapBaseBaseUrlsOptions => {
              return <IMapBaseBaseUrlsOptions>{
                url: transformSubstratesUrl(el.link),
                name: el.name,
                attribution: el.attribution,
                selected: activeSubstrate ? el.id === activeSubstrate.id : el.default,
              };
            })
          : undefined,
      center: this.getMunicipalCoordinates(),
      zoom: 16,
      maxZoom: 20,
      zoomControl: true,
      mapStyle: { width: '100%', height: '100%' },
    };

    this.mapViewObjectsOptions['attribution'] = substrates?.length > 1 ? undefined : activeSubstrate.attribution;

    /** Инициализируем модель карты для отображения результатов прогнозирования */
    this.mapModel
      .getObservableMapEvents()
      .pipe(
        filter(
          (event: IMapBaseEvent) =>
            event['mapObject']?.options?.mapId === 'baseMapWorkspace' || event.mapId === 'baseMapWorkspace',
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((event: IMapBaseEvent) => {
        switch (event.typeEvent) {
          case 'mapClick': {
            // Иконка для маркера точки пути
            const icon = this.mapService.makeIcon({
              iconSize: 8,
              iconColor: 'black',
              iconSVG: PATH_POINT_MARKER_SVG,
              shapeType: 'Круг',
              shapeSize: 10,
              shapeColor: 'black',
            });

            if (this.isYardstick && !this.isBuildingRoute) {
              this.pointsArray.push(event.coordinates);

              this.mapModel.addMarkerToLayer(
                'yardstick',
                `${this.pointsArray.length}`,
                event.coordinates,
                icon || null,
              );

              if (this.pointsArray.length >= 2) {
                const distance = Coordinates.distanceInKmBetweenEarthCoordinates(
                  this.pointsArray as [[number, number], [number, number]],
                ).toFixed(3);
                this.mapModel.removeObject('yardstick', 'path');
                this.mapModel.removeObject('yardstick', 'tooltip');
                this.mapModel.addPolylineToLayer(
                  'yardstick',
                  'path',
                  this.pointsArray,
                  { color: 'black', weight: 1 },
                  null,
                );
                this.mapModel.addMarkerToLayer('yardstick', 'tooltip', event.coordinates, icon || null, null, null, {
                  text: `${distance} км.`,
                  permanent: true,
                  className: 'yardstick-tooltip',
                });
              }
              this.mapModel.viewLayer('yardstick', false);
            }
            break;
          }
          case 'mapReady': {
            this.mapObjectModel = event.mapObject;
            this.bgMapService.mapModel = event.mapObject as L.Map;
            break;
          }
          default:
            break;
        }
      });

    this.bgMapService.positionMapOnCoordinates$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((coord: string) => {
      this.centerMap = new Coordinates(coord).toArray();
    });

    this.gisService.clickMarker$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        ({ coordinates, iconName, objectId }: { coordinates: Coordinates; iconName: string; objectId: string }) => {
          if (coordinates) {
            /** Формирую маркер как для нового инцидента по внешнему виду и добавляю в слой на мини карту */
            const markerIcon: IMapObjectIconOptions = {
              iconSVG: this.layers.find((item) => item.name === iconName).icon as string,
              iconSize: 26,
              iconColor: '#FB4B53',
              shapeColor: '#ffffff',
              shapeAnchor: [26, 76],
              shapeSize: 30,
              shapeType: 'Круг',
            };

            const markerShape: IMapObjectIconOptions = {
              shapeImgSize: [52, 76],
              iconColor: '#F3F3F3',
              shapeSVG: CLICK_MAKER_SHAPE_SVG,
              shapeAnchor: [26, 66],
              shapeImg: this.bgMapService.createClickMarkerIcon(),
            };

            this.mapModel.removeLayer(`${iconName} click marker`);
            this.mapModel.addMarkerToLayer(
              `${iconName} click marker`,
              objectId,
              coordinates.toArray(),
              this.mapService.makeClickMarker(markerShape, markerIcon, false),
            );
            this.mapModel.viewLayer(`${iconName} click marker`, false);
          } else if (objectId) {
            this.mapModel.removeObject(`${iconName} click marker`, objectId);
          } else {
            this.mapModel.removeLayer(`${iconName} click marker`);
          }
        },
      );

    /** TODO: Засинхронить с другими */
    this.bgMapService.polygon$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((coordinates: Coordinates[]) => {
      this.mapModel.removeLayer(LayersEnum.forestryFacilities);
      if (!coordinates) return;
      this.mapModel.addPolygonToLayer(
        LayersEnum.forestryFacilities,
        LayersEnum.forestryFacilities,
        coordinates.map((coordinate: Coordinates) => coordinate.toArray()),
        { color: '#0072C3', weight: 2 },
      );
      this.mapModel.viewLayer(LayersEnum.forestryFacilities, false);
    });

    // подписка на очистку слоя ЛХО/пользовательских полигонов
    this.bgMapService.forestryFacilitiesRemoved$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.mapModel.removeLayer(LayersEnum.forestryFacilities);
    });

    this.bgMapService.municipalityPolygon$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((polygon: IPolygonDto) => {
      if (!polygon) {
        this.mapModel.removeLayer(LayersEnum.municipalities);
        return;
      }
      this.mapModel.addPolygonToLayer(
        LayersEnum.municipalities,
        polygon.id,
        polygon.coordinates.map((coordinate: Coordinates) => coordinate.toArray()),
        { color: '#0072C3', weight: 2 },
      );
      this.mapModel.viewLayer(LayersEnum.municipalities, false);
    });

    this.bgMapService.districtPolygon$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((polygon: IPolygonDto) => {
      if (!polygon) {
        this.mapModel.removeLayer(LayersEnum.districts);
        return;
      }
      this.mapModel.addPolygonToLayer(
        LayersEnum.districts,
        polygon.id,
        polygon.coordinates.map((coordinate: Coordinates) => coordinate.toArray()),
        { color: '#0072C3', weight: 2 },
      );
      this.mapModel.viewLayer(LayersEnum.districts, false);
    });

    this.emergencyService.incidentSelectItem$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((incident: IMiniCardIncidentSelectionEvent) => {
        const toggle = this.toggleFormOpenCall || this.toggleFormCreateEvent || this.toggleEditEventFormOpen;
        const callForm = incident.callForm === undefined ? toggle : incident.callForm;
        this.router.navigate(
          [
            {
              outlets: {
                editForm: [incident.docType, incident.id, { callForm, view: incident.view }],
              },
            },
          ],
          {
            relativeTo: this.route,
            queryParamsHandling: 'merge',
          },
        );
      });

    this.emergencyService.eventSelectItem$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((eventId: string) => {
      const editEventForm = eventId ? ['event', eventId] : null;
      this.router.navigate([{ outlets: { editEventForm } }], {
        relativeTo: this.route,
        queryParamsHandling: 'merge',
      });
    });

    this.emergencyService.incidentAddressLinkClicked$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((coordinates: Coordinates) => {
        this.selectCoordinates(coordinates);
      });

    /**
     * Подписка на изменения в инцидентах
     * */
    this.subs
      .onTableChange('Emergency', 'Emergency')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: IModifiedData<any>) => {
        if (result.action === 'update') {
          this.emergencyService.updateIncident(result.data.id);
        } else if (result.action === 'insert') {
          this.emergencyService.addNewIncident(result.data.id);
        }
      });
    /**
     * Подписка на изменения в событиях
     * */
    this.subs
      .onTableChange('Emergency', 'Events')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: IModifiedData<any>) => {
        if (result.action === 'update') {
          this.emergencyService.updateEvent(result.data.id);
        } else if (result.action === 'insert') {
          this.emergencyService.addNewEvent(result.data.id);
        }
      });
  }

  /**
   * Обработка событий карты
   * @param $event
   */
  public onMapViewObjectsEvent($event: IMapBaseObjectEvent | IMapBaseEvent | IMapViewObjectsEvent) {
    const event = $event as IMapBaseObjectEvent;
    if (event.mapId !== 'baseMapWorkspace' || this.isYardstick || this.isBuildingRoute) {
      return;
    }

    switch (event.typeEvent) {
      case 'click':
        this.showObjectInfo(event.layerId, event.objectId as string);
        if ((this.request && this.request.typeRequest === 'newEvent') || !event.coordinates) {
          return;
        }
        break;
    }
  }

  /**
   * Отображение диалога с фильтрами слоев
   */
  public showLayerFilterDialog(hideMyIncidentsFilter?: boolean) {
    const dialogRef = this.dialog.open(MapLayersFiltersDialogComponent, {
      closeOnNavigation: true,
      panelClass: 'map-layers-filters-dialog-container',
      data: {
        mapLayersFilters: clone(this.mapLayersFilters, { includeNonEnumerable: true }),
        layersFilters: this.layers.reduce(
          (layersFilters, layer) => ({
            ...layersFilters,
            [layer.nameOnMap]: layer.entityFilters.filters,
          }),
          {},
        ),
        hideMyIncidentsFilter,
      },
    });
  }

  /**
   * Вызов меню
   */
  public onClickHeaderButton(): void {
    this.scNavService.openMenu();
  }

  /**
   * Поиск по адресу
   */
  public onSearch($event: IMapViewObjectsRequest): void {
    this.request = $event;
  }

  /**
   * Метод перехода/центрирования по координатам
   * @param coordinates координаты формата "55.0, 36.0"
   */
  public selectCoordinates(coordinates: Coordinates): void {
    if (coordinates.isValid()) {
      this.bgMapService.setPositionMapOnCoordinates(coordinates.toString());
      this.request = <IMapViewObjectsRequest>{
        typeRequest: 'addCirclesToObject',
        coordinates: coordinates.toArray(),
        layerId: 'Инциденты',
      };
    }
  }

  /**
   *  Позиционирование карты на коллекции объектов на карте
   *  @param objects объекты для позиционирования
   */
  public setPositionMapOnObjects(objects: IMapObjectDto[]): void {
    const allCoordinates = objects
      .filter((object: IMapObjectDto) => object.coordinates)
      .map((object: IMapObjectDto) => object.coordinates);

    if (!allCoordinates.length) {
      return;
    }

    // fitBounds and flyTo
    if (allCoordinates.length === 1) {
      this.mapObjectModel.flyToBounds(allCoordinates);
    }
  }

  /**
   * Обработка нажатия на кнопку линейка
   */
  public onYardstickEvent(): void {
    if (this.isBuildingRoute) {
      return;
    }
    this.isYardstick = !this.isYardstick;
    if (!this.isYardstick) {
      this.mapModel.removeLayer('yardstick');
      this.pointsArray = [];
    }
  }

  protected showObjectInfo(layerId: string, objectId: string) {
    switch (layerId) {
      case 'Неисправности видеокамер':
        this.emergencyService.selectIncident({
          id: objectId,
          callForm: null,
          docType: 'fault',
        });
        break;
      case 'Поручения':
        this.router.navigate([{ outlets: { leftPopup: ['emergency', objectId] } }], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Плановые работы':
        this.router.navigate([{ outlets: { leftPopup: ['emergency', objectId] } }], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Инциденты':
      case 'Инциденты ВА':
        this.router.navigate([{ outlets: { leftPopup: ['emergency', objectId] } }], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Видеокамеры': {
        this.router.navigate(['webCam', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      }
      case 'Важные объекты': {
        this.router.navigate(['significantObject', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      }
      case 'КСЭОН': {
        this.router.navigate(['kseon', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      }
      case 'ДДС городских служб и организаций':
      case 'Организации Экстренного реагирования':
        this.router.navigate(['eos', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Объекты теплоснабжения': {
        this.router.navigate([{ outlets: { leftPopup: ['heatSupplyFacility', objectId] } }], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      }
      case 'Объекты электроснабжения': {
        this.router.navigate([{ outlets: { leftPopup: ['electricitySupplyFacility', objectId] } }], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      }
      /**
       * Кейс "Транспортные средства" переехал в модуль РНИС {@link RnisModule}
       * Смотри {@link RnisControlLayerComponent}
       */
      case 'Объекты экомониторинга':
        this.router.navigate(['ecoMonitoringObject', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Пожароопасные объекты':
        this.router.navigate(['fireRiskObject', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Объекты пожарного мониторинга':
        this.router.navigate(['fireMonitoringObject', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Водоисточники':
        this.router.navigate(['waterSource', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Термоточки': {
        this.router.navigate(['thermopoint', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      }
    }
  }

  /**
   * Получение МО активного пользователя
   */
  private getMunicipal(): IAdminMunicipalSchemaDto {
    return this.settings.allMo.find((mo: IAdminMunicipalSchemaDto) => mo.id === this.user?.organizationId?.mo);
  }

  /**
   * Получение координат МО
   */
  private getMunicipalCoordinates(): MapBaseCoordinatesType {
    const mo = this.getMunicipal();
    if (mo && mo.coordinates) {
      return Coordinates.coordinatesToArray(mo.coordinates);
    }
    return Coordinates.coordinatesToArray(this.settings.getConfig().defaultCoordinates);
  }

  /** Открыть диалог с легендой */
  public openLegendDialog() {
    if (!this.availableForLegendLayers?.length) {
      return;
    }

    const pictDict = this.settings.getDictionaryByTypeSysName('pictogramElements');
    const markerShapeDict = this.settings.getDictionaryByTypeSysName('markerShape');

    const layersConfig = this.availableForLegendLayers
      .sort((a: IMapLayer, b: IMapLayer) => a.name.localeCompare(b.name))
      .map((layer: IMapLayer) => {
        const pictogramSettingsArray = layer.pictogramSettings.map((setting: IAnyObject) => {
          const pictogramSysName = pictDict.find((item) => item.id === setting.pictogramElement)?.sysname;

          const pictogramSettingsValuesArray = setting.pictogramSettingsValues.map((config: IAnyObject) => {
            const iconOptions: IMapObjectIconOptions = {
              iconSVG: layer.icon as string,
              iconSize: 17,
              iconColor: layer.iconColor,
              shapeType: ((layer.markerShape as IDictionaryInfo)?.name as IconShapeType) || 'Нет',
              shapeColor: layer.markerColor,
              shapeSize: 27,
              strokeColor: layer.borderColor,
            };
            switch (pictogramSysName) {
              case 'icon':
                iconOptions.iconSVG = this.svgIconMap[config.element];
                break;
              case 'iconColor':
                iconOptions.iconColor = config.element;
                break;
              case 'markerForm':
                iconOptions.shapeType = markerShapeDict.find((item) => item.id === config.element)
                  ?.name as IconShapeType;
                break;
              case 'markerColor':
                iconOptions.shapeColor = config.element;
                break;
              case 'borderColor':
                iconOptions.strokeColor = config.element;
                break;
            }

            return {
              title: config.valueAlias,
              icon: this.mapService.makeIcon(iconOptions),
            };
          });

          return {
            title: setting.alias,
            pictogramSettingsValuesArray: pictogramSettingsValuesArray,
          };
        });

        return {
          title: layer.nameOnMap,
          pictogramSettingsArray: pictogramSettingsArray,
        };
      });

    if (this.isLegendOpen) return;

    this.dialog
      .open(LegendDialogComponent, {
        minWidth: '350px',
        minHeight: '300px',
        maxHeight: '600px',
        disableClose: true,
        hasBackdrop: false,
        position: { left: '450px', bottom: '16px' },
        data: {
          configurations: layersConfig,
        },
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => (this.isLegendOpen = false));

    this.isLegendOpen = true;
  }

  /** Генерация иконки для объекта карты. Метод использовался в подписках на изменение таблиц,
   * для актуализации информации на карте. В настоящее время метод не используется, оставить его нужно, но не здесь.
   * Вынести в какой-нибудь сервис.
   */
  private generateIcon(layer: IMapLayer, element: IAnyObject): IMapObjectIconOptions {
    let icon: IMapObjectIconOptions;
    if (!this.mapLayersData.has(element.id)) {
      const markerShape = ((layer.markerShape || {}) as IDictionaryInfo).sysname;
      let size = 17;
      const color = '#ffffff';
      let shapeSize = 30;
      let shapeLineRadius = null;

      // TODO: вынести настройки в конструктор слоев. тикет на анализ(BG-14190).
      switch (markerShape) {
        case 'circle':
          shapeSize = 38;
          size = 22;
          break;
        case 'rectangle':
          shapeLineRadius = 5;
          shapeSize = 34;
          break;
        case 'diamond':
          shapeSize = 32;
          size = 18;
          break;
      }

      icon = {
        iconSVG: layer.icon as string,
        iconSize: size,
        iconColor: layer.iconColor ?? color,
        shapeType: ((layer.markerShape || {}) as IDictionaryInfo).name as TIconShape,
        shapeColor: layer.markerColor,
        shapeSize: shapeSize,
        strokeWidth: 3,
        strokeColor: layer.borderColor,
        shapeLineRadius: shapeLineRadius,
      };
    } else {
      icon = this.mapLayersData.get(element.id).icon;
    }

    if (layer.pictogramSettings) {
      const pictDict = this.settings.getDictionaryByTypeSysName('pictogramElements');
      const markerShapeDict = this.settings.getDictionaryByTypeSysName('markerShape');

      layer.pictogramSettings.map((setting: IPictogramSettingsDto) => {
        const pictogramSysName = pictDict.find(
          (item: IDictionaryInfo) => item.id === setting.pictogramElement,
        )?.sysname;
        setting.pictogramSettingsValues.map((config: IPictogramSettingsValueDto) => {
          switch (pictogramSysName) {
            case 'icon':
              if (
                this.operationsService.checkValueByOperation(
                  element[setting.indicator],
                  config.value,
                  config.property as FilterOperationEnum,
                )
              ) {
                icon.iconSVG = this.svgIconMap[config.element];
              }
              break;
            case 'iconColor':
              if (
                this.operationsService.checkValueByOperation(
                  element[setting.indicator],
                  config.value,
                  config.property as FilterOperationEnum,
                )
              ) {
                icon.iconColor = config.element;
              }
              break;
            case 'markerForm':
              if (
                this.operationsService.checkValueByOperation(
                  element[setting.indicator],
                  config.value,
                  config.property as FilterOperationEnum,
                )
              ) {
                icon.shapeType = markerShapeDict.find((item) => item.id === config.element)?.name as IconShapeType;
              }
              break;
            case 'markerColor':
              if (
                this.operationsService.checkValueByOperation(
                  element[setting.indicator],
                  config.value,
                  config.property as FilterOperationEnum,
                )
              ) {
                icon.shapeColor = config.element;
              }
              break;
            case 'borderColor':
              if (
                this.operationsService.checkValueByOperation(
                  element[setting.indicator],
                  config.value,
                  config.property as FilterOperationEnum,
                )
              ) {
                icon.strokeColor = config.element;
              }
              break;
          }
        });
      });
    }

    this.mapLayersData.set(element.id, {
      icon,
      element,
      layerId: layer.nameOnMap,
    });

    return icon;
  }

  /** Генерация элементов управления слоями */
  public generateLayerControls(): void {
    let moreThenOne = 0;
    let component: IAnyObject;

    const generateDivider: () => void = (): void => {
      component = this.layerControlsContainer.createComponent<NzDividerComponent>(
        this.componentFactoryResolver.resolveComponentFactory(NzDividerComponent),
      ).instance;

      component.nzType = 'vertical';
    };
    this.layers?.forEach((layer: IMapLayer) => {
      switch (layer.nameOnMap) {
        case LayersEnum.incidents: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<IncidentsLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(IncidentsLayerControlComponent),
          ).instance;
          break;
        }
        case LayersEnum.videoIncidents: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<VideoIncidentsLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(VideoIncidentsLayerControlComponent),
          ).instance;
          break;
        }
        case LayersEnum.kseon: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<KseonLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(KseonLayerControlComponent),
          ).instance;
          break;
        }
        case LayersEnum.orders: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<OrdersLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(OrdersLayerControlComponent),
          ).instance;
          break;
        }
        case LayersEnum.plannedWorks: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<PlannedWorksLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(PlannedWorksLayerControlComponent),
          ).instance;
          break;
        }
        case LayersEnum.significantObjects: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<SignificantObjectsLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(SignificantObjectsLayerControlComponent),
          ).instance;
          break;
        }
        case LayersEnum.dds:
        case LayersEnum.eos: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<EosLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(EosLayerControlComponent),
          ).instance;
          break;
        }
        case LayersEnum.videoDevices:
        case LayersEnum.forestGuardVideoDevices: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<VideoDevicesLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(VideoDevicesLayerControlComponent),
          ).instance;
          break;
        }
        case LayersEnum.camerasMalfunctions: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<WebCamFaultsLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(WebCamFaultsLayerControlComponent),
          ).instance;
          break;
        }

        case LayersEnum.regionControlCenter: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<RegionControlCenterLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(RegionControlCenterLayerControlComponent),
          ).instance;
          break;
        }

        case LayersEnum.electricitySupplyFacility: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<RegionControlCenterLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(RegionControlCenterLayerControlComponent),
          ).instance;
          break;
        }

        case LayersEnum.heatSupplyFacilities: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<RegionControlCenterLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(RegionControlCenterLayerControlComponent),
          ).instance;
          break;
        }

        case LayersEnum.waterSource: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<WaterSourceLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(WaterSourceLayerControlComponent),
          ).instance;
          break;
        }

        case LayersEnum.fireMonitoringObject: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<FireMonitoringObjectsLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(FireMonitoringObjectsLayerControlComponent),
          ).instance;
          break;
        }

        case LayersEnum.fireRiskObject: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<FireRiskObjectsLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(FireRiskObjectsLayerControlComponent),
          ).instance;
          break;
        }

        case LayersEnum.ecoMonitoringObject: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<EcoMonitoringObjectsLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(EcoMonitoringObjectsLayerControlComponent),
          ).instance;
          break;
        }

        case LayersEnum.rasco:
        case LayersEnum.kseon: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<KseonLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(KseonLayerControlComponent),
          ).instance;
          break;
        }
        case LayersEnum.aqi: {
          if (moreThenOne) {
            generateDivider();
          }
          component = this.layerControlsContainer.createComponent<AqiLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(AqiLayerControlComponent),
          ).instance;
          break;
        }
        case LayersEnum.wlcsMonitoringObjects: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<WlcsMonitoringObjectsLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(WlcsMonitoringObjectsLayerControlComponent),
          ).instance;
          break;
        }
        case LayersEnum.thermopoints: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<ThermopointsLayerControlComponent>(
            this.componentFactoryResolver.resolveComponentFactory(ThermopointsLayerControlComponent),
          ).instance;
          break;
        }
        case LayersEnum.rnis: {
          if (moreThenOne) {
            generateDivider();
          }

          component = this.layerControlsContainer.createComponent<RnisLayerControlContainerComponent>(
            this.componentFactoryResolver.resolveComponentFactory(RnisLayerControlContainerComponent),
          ).instance;
          break;
        }
      }

      if (component) {
        component.mapId = this.mapId;
        component.layer = layer;
        component.svgIconMap = this.svgIconMap;

        moreThenOne++;
      }
    });

    if (moreThenOne) {
      generateDivider();

      component = this.layerControlsContainer.createComponent<LayerControlButtonComponent>(
        this.componentFactoryResolver.resolveComponentFactory(LayerControlButtonComponent),
      ).instance;
      component.mapId = this.mapId;
    }
  }

  public onMapReady(map: L.Map): void {
    this.mapObjectModel = map;
  }

  /** Устанавливаем активную подложку */
  public onChangeBaseLayer(layerName: string): void {
    const entity = this.substratesQuery.getAll({
      filterBy: ({ name }: ISubstrateDto) => name === layerName,
    })[0];
    this.substratesService.setActive(entity.id);
  }

  /** Обработка события нажатия на кнопку управления */
  public onControlEvent($event: IControlEvent): void {
    Object.keys(this.controlState).forEach((key: string): void => {
      if (key !== $event.name) {
        this.controlState[key] = $event.active ? true : false;
      }
    });
    this.controlState[$event.name] = false;
  }
}
