import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MapControlsService } from '../../services';

@Component({
  selector: 'layer-control-button',
  templateUrl: './layer-control-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerControlButtonComponent {
  @Input()
  public mapId: string;
  constructor(private readonly mapControlsService: MapControlsService) {}

  /** Активируем все слои карты */
  public selectAll(): void {
    if (this.mapId) {
      this.mapControlsService.changeActiveStateForAllLayers(this.mapId, true);
    }
  }

  /** Деактивируем все слои карты */
  public deselectAll(): void {
    if (this.mapId) {
      this.mapControlsService.changeActiveStateForAllLayers(this.mapId, false);
    }
  }
}
