<form nz-form [formGroup]="form" nzLayout="vertical">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Муниципальное образование</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="moId" nzSize="large" nzAllowClear nzShowSearch>
            <nz-option
              [nzValue]="municipal.value"
              [nzLabel]="municipal.text"
              *ngFor="let municipal of municipals"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Наименование</nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="id"
            nzSize="large"
            nzAllowClear
            nzShowSearch
          >
            <nz-option [nzValue]="obj.value" [nzLabel]="obj.text" *ngFor="let obj of objects$ | async"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
<div *nzModalFooter>
  <div nz-row>
    <div nz-col [nzSpan]="12" class="d-flex">
      <button nz-button nzType="default" class="m-r" (click)="clear()">Очистить</button>
    </div>
    <div nz-col [nzSpan]="12">
      <button nz-button nzType="default" class="m-r" (click)="close()">Отмена</button>
      <button nz-button nzType="primary" [disabled]="!form.valid" (click)="filtrate()">Фильтровать</button>
    </div>
  </div>
</div>
