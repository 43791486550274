import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { ForestryFacilitiesModule } from '@bg-front/forestry-facilities';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { ScComponentsModule } from '@smart-city/core/common';
import { PltPipesModule } from '@smart-city/core/pipes';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NgxMaskModule } from 'ngx-mask';

import { ForecastingModule } from '../forecasting/forecasting.module';
import { HlsPlayerModule } from '../hls-player/hls-player.module';
import {
  AddCameraDialogComponent,
  AddressDetailsAddDialogComponent,
  AddressDetailsMapViewComponent,
  AddressDetailsViewDialogComponent,
  AddressEditDialogComponent,
  AddressOwnershipComponent,
  AlertSourceMiniCardComponent,
  AtmDeviceViewFormComponent,
  AtmNotificationMiniCardComponent,
  BaseLayoutComponent,
  BgLoosesComponent,
  BgMapExtFilterComponent,
  CallEventEditFormComponent,
  CloseWithoutReactionDialogComponent,
  CoverageAreaComponent,
  CreateEventFormComponent,
  CreateIncidentFormComponent,
  CreateInvolveStepComponent,
  CreateResponseStepComponent,
  CreateUpdateInfoComponent,
  Dds01AdditionalInfoComponent,
  Dds01FireParametersComponent,
  DeathListComponent,
  DeclarerInfoFormComponent,
  DetailOrganizationDialogComponent,
  DownloadSourcesComponent,
  EddsEventEditFormComponent,
  EditReportsDialogComponent,
  EmergenciesLinkingDialogComponent,
  EmergencyInfoComponent,
  EmergencyReportsDialogComponent,
  EosInfoComponent,
  EvacuatedListComponent,
  EventMiniCardComponent,
  ExportDialogComponent,
  ForecastingForestFireParamsFormComponent,
  ForecastingRadiationChemicallyParamsFormComponent,
  ForecastingShowFormComponent,
  ForecastingTechnologicalFireAreaIncidentFormComponent,
  ForecastingTechnologicalFireEventFormComponent,
  ForecastingTechnologicalFireIncidentFormComponent,
  HeatSupplyFacilityInfoComponent,
  HospitalizedListComponent,
  ImageVideoRecordingDialogComponent,
  IncidentBackToWorkDialogComponent,
  IncidentCuksEditFormComponent,
  IncidentDds01EditFormComponent,
  IncidentEditFormComponent,
  IncidentMiniCardComponent,
  IncidentReportsDialogComponent,
  IncidentUlkEditFormComponent,
  IncidentViewFormComponent,
  InformationStatementViewFormComponent,
  InformResponseOrgFormComponent,
  InternetPortalCommentDialogComponent,
  InterviewComponent,
  InvolvedVehiclesEditDialogComponent,
  InvolveOrgWithoutCommissionDialogComponent,
  InvolveOrgWithoutCommissionFormComponent,
  InvolveOrgWithoutInteractionDialogComponent,
  InvolveOrgWithoutInteractionFormComponent,
  LegendDialogComponent,
  LoosesDialogComponent,
  MainViewEventFormComponent,
  MapCircleLegendComponent,
  MapEcoObjectsFilterComponent,
  MapEmergencyFilterComponent,
  MapLayersFiltersDialogComponent,
  MapOrganizationFilterComponent,
  MapPlannedWorksFilterComponent,
  MapPolygonsLegendComponent,
  MapSignificantObjectFilterComponent,
  MapVehicleFilterComponent,
  MapVideoCameraFilterComponent,
  MatchImageComponent,
  MiniMapComponent,
  MissingListComponent,
  MonitoringObjectAtmEditFormComponent,
  MonitoringObjectCustomEditFormComponent,
  MonitoringObjectHcsFormComponent,
  MonitoringObjectTesEditFormComponent,
  NewInvolveOrgFormComponent,
  NewPolygonComponent,
  NewResponseOrgFormComponent,
  NotificationObjectsAtmViewFormComponent,
  NotificationObjectsFormComponent,
  NotificationObjectsTesViewFormComponent,
  VideoDeviceInfoComponent,
  OrderEditFormComponent,
  OrderViewFormComponent,
  OrgInvolveRequestDialogComponent,
  OrgInvolveRequestFormComponent,
  PlacementDetailsComponent,
  PlacementDetailsEditComponent,
  PlannedWorksEventFilterComponent,
  PlannedWorksIncidentFilterComponent,
  PlannedWorksRegistryComponent,
  RegimeInfoComponent,
  RegimesEmergenciesDialogComponent,
  RegimesMapWidgetComponent,
  RegimesMessageComponent,
  RegimesRegistryPanelComponent,
  RescuedListComponent,
  ResponseOrgFormComponent,
  ResponsePlanStepComponent,
  ResponsePlanStepListComponent,
  SignificantObjectInfoComponent,
  SignificantObjectListDialogComponent,
  SpinnerComponent,
  SurveyAnswerComponent,
  SurveyKsipComponent,
  SurveyQuestionComponent,
  TechnologicalFireAdditionalInfoComponent,
  UlkOrderViewFormComponent,
  UserPolygonInfoComponent,
  UserPolygonMiniCardComponent,
  UserPolygonRegistryComponent,
  VehicleInfoComponent,
  VideoDeviceRequestVideoDialogComponent,
  VideoEventShowFormComponent,
  VideoIncidentEditFormComponent,
  VideoResultCrowdsComponent,
  VideoResultFaceRecognitionComponent,
  VideoResultLeftThingComponent,
  VideoResultLoudSoundComponent,
  VideoResultRepeatGrzComponent,
  VideoResultSabotageComponent,
  VideoResultWantedGrzComponent,
  WebcamFailureIncidentEditFormComponent,
  RequestVideoComponent,
  KsionEventViewFormComponent,
  KsionFaultEditFormComponent,
  FireMonitoringObjectInfoComponent,
  ThermopointInfoComponent,
} from './components';
import { LifeCycleStepFormStateDirective } from './directives';
import { DetailOrgSelectorTooltipPipe, OrganizationsFilterPipe } from './pipes';
import { KseonUnitsModule } from '@bg-front/kseon-units';
import { ForecastingRadioactiveEnvironmentModule } from '@bg-front/forecasting-radioactive-environment';
import { AddressModule, BgCoreModule } from '@bg-front/core';
import { SignificantObjectsModule } from '@bg-front/significant-objects';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { RnisModule } from '../rnis/rnis.module';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzViewControlModule } from '@bg-front/nz-view-control';
import { BgMapModule } from '@bg-front/map';
import { MapControlsModule } from '../map-controls/map-controls.module';

/**
 * Все компоненты и пайпы размещенные в данном модуле.
 * Собраны в один массив для удобства обновления и экспорта
 */
const COMPONENTS = [
  AddCameraDialogComponent,
  AddressOwnershipComponent,
  AtmDeviceViewFormComponent,
  AtmNotificationMiniCardComponent,
  BaseLayoutComponent,
  BgMapExtFilterComponent,
  CallEventEditFormComponent,
  CreateEventFormComponent,
  CreateIncidentFormComponent,
  CreateInvolveStepComponent,
  CreateResponseStepComponent,
  CreateUpdateInfoComponent,
  Dds01AdditionalInfoComponent,
  Dds01FireParametersComponent,
  BgLoosesComponent,
  DeclarerInfoFormComponent,
  DownloadSourcesComponent,
  EmergencyInfoComponent,
  EmergenciesLinkingDialogComponent,
  EventMiniCardComponent,
  ForecastingRadiationChemicallyParamsFormComponent,
  HeatSupplyFacilityInfoComponent,
  IncidentCuksEditFormComponent,
  IncidentDds01EditFormComponent,
  IncidentEditFormComponent,
  IncidentMiniCardComponent,
  IncidentUlkEditFormComponent,
  IncidentViewFormComponent,
  InformResponseOrgFormComponent,
  InformationStatementViewFormComponent,
  InterviewComponent,
  InvolvedVehiclesEditDialogComponent,
  InvolveOrgWithoutCommissionDialogComponent,
  InvolveOrgWithoutCommissionFormComponent,
  InvolveOrgWithoutInteractionDialogComponent,
  InvolveOrgWithoutInteractionFormComponent,
  LifeCycleStepFormStateDirective,
  MainViewEventFormComponent,
  MapCircleLegendComponent,
  MapEcoObjectsFilterComponent,
  MapEmergencyFilterComponent,
  MapLayersFiltersDialogComponent,
  MapOrganizationFilterComponent,
  MapSignificantObjectFilterComponent,
  MapVehicleFilterComponent,
  MapVideoCameraFilterComponent,
  MonitoringObjectAtmEditFormComponent,
  MonitoringObjectCustomEditFormComponent,
  MonitoringObjectHcsFormComponent,
  MonitoringObjectTesEditFormComponent,
  MiniMapComponent,
  NewInvolveOrgFormComponent,
  NewResponseOrgFormComponent,
  NotificationObjectsAtmViewFormComponent,
  NotificationObjectsFormComponent,
  NotificationObjectsTesViewFormComponent,
  OrderEditFormComponent,
  OrderViewFormComponent,
  OrgInvolveRequestDialogComponent,
  OrgInvolveRequestFormComponent,
  UlkOrderViewFormComponent,
  OrganizationsFilterPipe,
  PlacementDetailsComponent,
  PlacementDetailsEditComponent,
  RegimesEmergenciesDialogComponent,
  RegimeInfoComponent,
  RegimesMapWidgetComponent,
  RegimesMessageComponent,
  RegimesRegistryPanelComponent,
  ResponseOrgFormComponent,
  ResponsePlanStepComponent,
  ResponsePlanStepListComponent,
  SignificantObjectInfoComponent,
  SignificantObjectListDialogComponent,
  SpinnerComponent,
  SurveyAnswerComponent,
  SurveyKsipComponent,
  SurveyQuestionComponent,
  VideoDeviceRequestVideoDialogComponent,
  VideoEventShowFormComponent,
  VideoIncidentEditFormComponent,
  VideoResultFaceRecognitionComponent,
  DetailOrgSelectorTooltipPipe,
  MapPolygonsLegendComponent,
  UserPolygonRegistryComponent,
  UserPolygonMiniCardComponent,
  UserPolygonInfoComponent,
  ForecastingTechnologicalFireEventFormComponent,
  ForecastingForestFireParamsFormComponent,
  MatchImageComponent,
  ForecastingShowFormComponent,
  ForecastingTechnologicalFireIncidentFormComponent,
  PlannedWorksRegistryComponent,
  PlannedWorksIncidentFilterComponent,
  PlannedWorksEventFilterComponent,
  DetailOrganizationDialogComponent,
  MapPlannedWorksFilterComponent,
  AddressEditDialogComponent,
  CoverageAreaComponent,
  AddressDetailsAddDialogComponent,
  AddressDetailsViewDialogComponent,
  AddressDetailsMapViewComponent,
  WebcamFailureIncidentEditFormComponent,
  ExportDialogComponent,
  LegendDialogComponent,
  VehicleInfoComponent,
  VideoDeviceInfoComponent,
  RequestVideoComponent,
  KsionEventViewFormComponent,
  KsionFaultEditFormComponent,
  FireMonitoringObjectInfoComponent,
  AlertSourceMiniCardComponent,
  ThermopointInfoComponent,
];

/**
 * Компоненты локального уровня, не экспортируются
 */
const LOCAL_COMPONENTS = [
  CallEventEditFormComponent,
  CloseWithoutReactionDialogComponent,
  DeathListComponent,
  EddsEventEditFormComponent,
  IncidentBackToWorkDialogComponent,
  EditReportsDialogComponent,
  EmergencyReportsDialogComponent,
  EosInfoComponent,
  EvacuatedListComponent,
  HospitalizedListComponent,
  ImageVideoRecordingDialogComponent,
  IncidentReportsDialogComponent,
  InternetPortalCommentDialogComponent,
  LoosesDialogComponent,
  MissingListComponent,
  RescuedListComponent,
  VideoResultCrowdsComponent,
  VideoResultLeftThingComponent,
  VideoResultLoudSoundComponent,
  VideoResultRepeatGrzComponent,
  VideoResultSabotageComponent,
  VideoResultWantedGrzComponent,
  NewPolygonComponent,
  TechnologicalFireAdditionalInfoComponent,
  ForecastingTechnologicalFireAreaIncidentFormComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),
    HlsPlayerModule,
    MatExpansionModule,
    MatDialogModule,
    MatButtonModule,
    ScComponentsModule,
    ErrorTailorModule,
    PltPipesModule,
    KseonUnitsModule,
    ForecastingRadioactiveEnvironmentModule,
    BgCoreModule,
    SignificantObjectsModule,
    NzButtonModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzPopconfirmModule,
    NzSelectModule,
    NzSwitchModule,
    NzTagModule,
    NzTypographyModule,
    ForestryFacilitiesModule,
    ForecastingModule,
    RnisModule,
    NzRadioModule,
    NzCardModule,
    NzDrawerModule,
    NzIconModule,
    NzPageHeaderModule,
    NzDatePickerModule,
    NzInputModule,
    NzInputNumberModule,
    NzModalModule,
    NzFormModule,
    NzToolTipModule,
    NzCollapseModule,
    NzTabsModule,
    AddressModule,
    NzViewControlModule,
    BgMapModule,
    MapControlsModule,
  ],
  declarations: [COMPONENTS, LOCAL_COMPONENTS],
  exports: [COMPONENTS],
})
export class AppCommonModule {}
