import { Injectable } from '@angular/core';
import { ISelectItem } from '@bg-front/core/models/interfaces';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';
import { BaseLayerControlComponent } from '../../components/base-layer-control/base-layer-control.component';

/** Сервис предназначен для этого модуля и не подлежит экспорту */
@Injectable()
export class MapControlsService {
  private layersControls: Map<string, Record<string, BaseLayerControlComponent>> = new Map<
    string,
    Record<string, BaseLayerControlComponent>
  >();
  constructor(private readonly rest: RestService, private readonly settings: Settings2Service) {}

  /** Получение объектов мониторинга АХОВ */
  public getAQIObjects(): Observable<ISelectItem[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'EdelweissIntegration' },
        entity: {
          name: 'MonitoringObjects',
          // query: {
          //   active: true,
          // },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return ((response.data?.items as IAnyObject[]) || []).map(
            (el: IAnyObject) =>
              <ISelectItem>{
                text: el.name,
                value: el.id,
              },
          );
        }),
      );
  }

  /** Получение списка ГРЗ */
  public getStateNumbersForSelect(): Observable<{ id: string; stateNumber: string }[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Vehicles' },
        entity: {
          name: 'Dictionary',
          query: {
            typeId: { $in: this.settings.currentUser.vehicleTypeIds ?? [] },
          },
          attributes: ['id', 'stateNumber'],
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => (response.data?.items as { id: string; stateNumber: string }[]) || []),
      );
  }

  /** Получение списка типов ТС */
  public getTypeIdsForSelect(): Observable<{ id: string; name: string }[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Directories' },
        entity: {
          name: 'VehicleTypes',
          query: {
            id: { $in: this.settings.currentUser.vehicleTypeIds ?? [] },
          },
          attributes: ['id', 'name'],
        },
      })
      .pipe(map((response: IAbstractServiceData) => (response.data?.items as { id: string; name: string }[]) || []));
  }

  /** Получение списка происшествий и плановых работ */
  public getEmergenciesForSelect(): Observable<{ id: string; number: string }[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          query: {
            'docType.sysname': { $in: ['incident', 'plannedWork'] },
          },
          attributes: ['id', 'number'],
        },
      })
      .pipe(map((response: IAbstractServiceData) => (response.data?.items as { id: string; number: string }[]) || []));
  }

  /** Получение списка организаций */
  public getOrganizationsForSelect(): Observable<{ id: string; name: string }[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          attributes: ['id', 'name'],
        },
      })
      .pipe(map((response: IAbstractServiceData) => (response.data?.items as { id: string; name: string }[]) || []));
  }

  /** Регистрируем компоненту */
  public registerLayerControl(control: BaseLayerControlComponent): void {
    if (this.layersControls.has(control.mapId)) {
      this.layersControls.get(control.mapId)[control.id] = control;
    } else {
      this.layersControls.set(control.mapId, { [control.id]: control });
    }
  }

  /** Удаляем компоненту перед OnDestroy */
  public unregisterLayerControl(control: BaseLayerControlComponent): void {
    if (this.layersControls.has(control.mapId)) {
      delete this.layersControls.get(control.mapId)[control.id];
    }
  }

  /** Меняем состояние активности всех слоёв */
  public changeActiveStateForAllLayers(mapId: string, value: boolean): void {
    if (this.layersControls.has(mapId)) {
      Object.values(this.layersControls.get(mapId)).forEach((control: BaseLayerControlComponent): void => {
        control.changeState(value);
      });
    }
  }
}
