import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { MapBaseComponent } from './components';
import { MapMarkerClusterDirective } from './directives';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, LeafletModule],
  declarations: [MapBaseComponent, MapMarkerClusterDirective],
  exports: [MapBaseComponent],
})
export class BgMapModule {}
