import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RnisControlLayerComponent } from './components/rnis/rnis.component';

/**
 * Модуль по работе с навигационным сервисом РНИС (взаимодействие с сервисом ItsCore)
 */
@NgModule({
  declarations: [RnisControlLayerComponent],
  imports: [CommonModule],
  exports: [RnisControlLayerComponent],
})
export class RnisModule {}
