import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DirtyGuard } from '@bg-front/core/guards';
import { AppInjector } from '@bg-front/core/models/classes';
import { RUSSIAN } from '@bg-front/core/models/constants';
import {
  BgMenuService,
  CommunicationService,
  ForecastingResultsQuery,
  ForecastingResultsService,
  ForecastingResultsStore,
} from '@bg-front/core/services';
import { RegistryPanelService, RegistryStateService } from '@bg-front/registry-panel/services';
import {
  SignificantObjectsQuery,
  SignificantObjectsService,
  SignificantObjectsStore,
} from '@bg-front/significant-objects/services';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { CallManagerService, CallsService, ScCallManagerModule } from '@smart-city/calls/call-manager';
import {
  AddressService,
  EmergencyService,
  FormService,
  GridService,
  MaterialModule,
  ScComponentsModule,
  SelectService,
  WsFiasService,
} from '@smart-city/core/common';
import { PltNotificationCarbonModule } from '@smart-city/core/plt-notification';
import {
  ACCESS_CONTROLLER,
  AccessService,
  AdminService,
  AuthGuardService,
  AuthService,
  MenuService,
  RestService,
  RightsService,
  RuleGroupsAccessController,
  ScNavService,
  SidebarContainerService,
  UsersService,
  WebsocketService,
} from '@smart-city/core/services';
import { CustomValidatorsService, ValidatorsService } from '@smart-city/core/validators';
import { FlatpickrModule } from 'angularx-flatpickr';
import { ru as DATE_LOCALE_RU } from 'date-fns/locale';
import { NZ_DATE_CONFIG, NZ_DATE_LOCALE, NZ_I18N, ru_RU } from 'ng-zorro-antd/i18n';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, AppGlobalRippleOptions } from './app.component';
import { LayoutComponent } from './components/layout/layout.component';
import { AppCommonModule } from './modules/app-common/app-common.module';
import { AppLoaderModule } from './modules/app-loader/app-loader.module';
import { SoundNotificationsService } from './modules/bg/modules/administration/modules/sound-notifications/services';
import { TasksModule } from './modules/bg/modules/pbx/modules/tasks/tasks.module';
import { defaultErrors } from './services/form-errors/form-errors.services';

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    AppLoaderModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ScCallManagerModule,
    HttpClientModule,
    InfiniteScrollModule,
    MaterialModule,
    ReactiveFormsModule,
    ScComponentsModule,
    TasksModule,
    AppCommonModule,
    NgxMaskModule.forRoot(),
    FlatpickrModule.forRoot({
      locale: RUSSIAN,
      time24hr: true,
    }),
    ErrorTailorModule.forRoot({
      errors: {
        useValue: defaultErrors,
      },
      blurPredicate: (element) => {
        switch (element.tagName) {
          case 'BG-DATETIME':
          case 'SC-INPUT':
          case 'SC-SELECT':
          case 'SC-FIAS3':
          case 'INPUT':
          case 'NZ-SELECT':
          case 'NZ-INPUT-NUMBER':
          case 'NZ-DATE-PICKER':
          case 'FILE-UPLOAD':
          case 'TEXTAREA':
            return true;
        }
        return false;
      },
    }),
    PltNotificationCarbonModule,
    NzNotificationModule,
  ],
  providers: [
    AccessService,
    AddressService,
    AdminService,
    AuthGuardService,
    AuthService,
    CallsService,
    CookieService,
    CustomValidatorsService,
    EmergencyService,
    FormService,
    GridService,
    MenuService,
    RestService,
    RightsService,
    RuleGroupsAccessController,
    ScNavService,
    SelectService,
    WsFiasService,
    UsersService,
    ValidatorsService,
    WebsocketService,
    SidebarContainerService,
    SoundNotificationsService,
    CommunicationService,
    ForecastingResultsQuery,
    ForecastingResultsStore,
    ForecastingResultsService,
    RegistryPanelService,
    RegistryStateService,
    SignificantObjectsService,
    SignificantObjectsQuery,
    SignificantObjectsStore,
    DirtyGuard,
    { provide: MenuService, useClass: BgMenuService },
    { provide: ACCESS_CONTROLLER, useExisting: RuleGroupsAccessController, multi: true },
    { provide: CallManagerService, useClass: CallManagerService },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useExisting: AppGlobalRippleOptions },
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    { provide: NZ_I18N, useValue: ru_RU },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1, // соответствует понедельнику
      },
    },
    { provide: NZ_DATE_LOCALE, useValue: DATE_LOCALE_RU },
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector.setInjector(this.injector);
  }
}
