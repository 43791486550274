import { IMapBaseIcon, IMapObjectIconOptions } from '../interfaces';
import { MapUtils } from '../utils';

export class MarkerIconFactory {
  /**
   * Возвращает иконку из подложки (круг, прямоугольник, ромб) и svg в центре подложки
   * @param iconOptions Параметры отрисовки иконки.
   */
   public static makeIcon(iconOptions: IMapObjectIconOptions): IMapBaseIcon {
    let sizeSVG = [iconOptions.iconSize, iconOptions.iconSize];
    let pathsSVG: string[] = [];
    let iconShapeSize = iconOptions.shapeSize!;
    if (iconOptions.shapeType === 'Прямоугольник') {
      iconShapeSize -= 8;
    }
    if (iconOptions.iconSVG) {
      sizeSVG = MapUtils.extractSizeFromSvg(iconOptions.iconSVG);
      pathsSVG = MapUtils.extractPathsFromSvg(iconOptions.iconSVG);
    }
    const { iconSize = 0 } = iconOptions;
    const scale = [iconSize / sizeSVG[0], iconSize / sizeSVG[1]];

    const trans = [(iconShapeSize - iconOptions.iconSize!) / 2, (iconShapeSize - iconOptions.iconSize!) / 2];

    const canvas = document.createElement('canvas');
    canvas.width = iconShapeSize;
    canvas.height = iconShapeSize;
    const ctx = canvas.getContext('2d')!;

    MarkerIconFactory.makeMarkerShape(canvas, ctx, iconOptions);

    ctx.beginPath();
    ctx.fillStyle = iconOptions.iconColor!;
    const path = new Path2D();
    if (pathsSVG?.length) {
      pathsSVG.forEach((icon) => {
        path.addPath(new Path2D(icon));
      });
    } else {
      // ctx.fillRect(12,12,100,100);
    }
    if (iconOptions.shapeType === 'Треугольник') {
      ctx.translate(trans[0]!, trans[1]! * 1.3);
    } else {
      ctx.translate(trans[0]!, trans[1]!);
    }
    ctx.scale(scale[0]!, scale[1]!);
    ctx.fill(path);

    const image = canvas.toDataURL('image/png');
    return {
      iconAnchor: [canvas.width / 2, canvas.height / 2],
      iconUrl: image,
    };
  }

  /**
   * Возвращает иконку из подложки (круг, прямоугольник, ромб) и svg в центре подложки
   * @param iconOptions Параметры отрисовки иконки.
   */
   public static async makeIconAsync(iconOptions: IMapObjectIconOptions): Promise<IMapBaseIcon> {
    let sizeSVG = [iconOptions.iconSize, iconOptions.iconSize];
    let pathsSVG: string[] = [];
    let iconShapeSize = iconOptions.shapeSize!;
    if (iconOptions.shapeType === 'Прямоугольник') {
      iconShapeSize -= 8;
    }
    if (iconOptions.iconSVG) {
      sizeSVG = MapUtils.extractSizeFromSvg(iconOptions.iconSVG);
      pathsSVG = MapUtils.extractPathsFromSvg(iconOptions.iconSVG);
    }
    const { iconSize = 0 } = iconOptions;
    const scale = [iconSize / sizeSVG[0], iconSize / sizeSVG[1]];

    const trans = [(iconShapeSize - iconOptions.iconSize!) / 2, (iconShapeSize - iconOptions.iconSize!) / 2];

    const canvas = new OffscreenCanvas(iconShapeSize, iconShapeSize);
    const ctx = canvas.getContext('2d')!;

    MarkerIconFactory.makeMarkerShape(canvas, ctx, iconOptions);

    ctx.beginPath();
    ctx.fillStyle = iconOptions.iconColor!;
    const path = new Path2D();
    pathsSVG.forEach((icon) => {
      path.addPath(new Path2D(icon));
    });
    if (iconOptions.shapeType === 'Треугольник') {
      ctx.translate(trans[0]!, trans[1]! * 1.3);
    } else {
      ctx.translate(trans[0]!, trans[1]!);
    }
    ctx.scale(scale[0]!, scale[1]!);
    ctx.fill(path);

    const blob = await canvas.convertToBlob();
    const url = (await this.toDataURL(blob)) as string;

    return {
      iconAnchor: [canvas.width / 2, canvas.height / 2],
      iconUrl: url as string,
      iconSize: [canvas.width, canvas.height],
      popupAnchor: [0, -canvas.height / 2],
      tooltipAnchor: [0, -canvas.height / 2],
    };
  }

  /**
   * Формирует подложку иконки (круг, прямоугольник, ромб, треугольник, без подложки)
   * @param canvas HTML элемент canvas.
   * @param ctx canvas 2d.
   * @param iconOptions конфигурация иконки.
   */
   private static makeMarkerShape(
    canvas: HTMLCanvasElement | OffscreenCanvas,
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    iconOptions: IMapObjectIconOptions,
  ): void {
    let radius = iconOptions.shapeLineRadius || 10;
    const width = canvas.width;
    const height = canvas.height;
    const lineWidth = iconOptions.strokeWidth || 0;

    switch (iconOptions.shapeType) {
      case 'Круг':
        radius = iconOptions.strokeWidth || 0;
        ctx.beginPath();
        ctx.fillStyle = !!iconOptions.iconSVG ? iconOptions.shapeColor : iconOptions.iconColor;
        ctx.strokeStyle = iconOptions.strokeColor || iconOptions.shapeColor!;
        ctx.lineWidth = lineWidth;
        ctx.arc(width / 2, height / 2, height / 3 - lineWidth, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.fill();
        break;
      case 'Ромб':
        radius = iconOptions.shapeLineRadius || 3;
        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = !!iconOptions.iconSVG ? iconOptions.shapeColor : iconOptions.iconColor;
        ctx.strokeStyle = iconOptions.strokeColor || iconOptions.shapeColor!;
        ctx.lineWidth = iconOptions.strokeWidth || 0;
        ctx.moveTo(width / 2 + radius, lineWidth + radius);
        ctx.lineTo(width - lineWidth - radius, height / 2 - radius);
        ctx.quadraticCurveTo(width - lineWidth, height / 2, width - radius - lineWidth, height / 2 + radius);
        ctx.lineTo(width / 2 + radius, height - lineWidth - radius);
        ctx.quadraticCurveTo(width / 2, height - lineWidth, width / 2 - radius, height - radius - lineWidth);
        ctx.lineTo(lineWidth + radius, height / 2 + radius);
        ctx.quadraticCurveTo(lineWidth, height / 2, lineWidth + radius, height / 2 - radius);
        ctx.lineTo(width / 2 - radius, lineWidth + radius);
        ctx.quadraticCurveTo(width / 2, lineWidth, width / 2 + radius, lineWidth + radius);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        ctx.restore();
        break;
      case 'Прямоугольник':
        radius = iconOptions.shapeLineRadius || 8;
        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = !!iconOptions.iconSVG ? iconOptions.shapeColor : iconOptions.iconColor;
        ctx.strokeStyle = iconOptions.strokeColor || iconOptions.shapeColor!;
        ctx.lineWidth = iconOptions.strokeWidth || 0;
        ctx.moveTo(radius + lineWidth, lineWidth as number);
        ctx.lineTo(width - radius - lineWidth, lineWidth as number);
        ctx.quadraticCurveTo(width - lineWidth, lineWidth, width - lineWidth, radius + lineWidth);
        ctx.lineTo(width - lineWidth, height - radius - lineWidth);
        ctx.quadraticCurveTo(width - lineWidth, height - lineWidth, width - radius - lineWidth, height - lineWidth);
        ctx.lineTo(radius + lineWidth, height - lineWidth);
        ctx.quadraticCurveTo(lineWidth, height - lineWidth, lineWidth, height - radius - lineWidth);
        ctx.lineTo(lineWidth, radius + lineWidth);
        ctx.quadraticCurveTo(lineWidth, lineWidth, radius + lineWidth, lineWidth as number);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        ctx.restore();
        break;
      case 'Треугольник': {
        radius = iconOptions.shapeLineRadius || 3;
        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = !!iconOptions.iconSVG ? iconOptions.shapeColor : iconOptions.iconColor;
        ctx.strokeStyle = iconOptions.strokeColor || iconOptions.shapeColor!;
        ctx.lineWidth = iconOptions.strokeWidth || 0;
        // const radius2 = Math.cbrt((radius * radius) / 2);
        const radius2 = radius / 1.5;
        ctx.moveTo(width / 2 + radius2, lineWidth + radius2);
        ctx.lineTo(width - lineWidth, height - radius - lineWidth);
        ctx.quadraticCurveTo(width - lineWidth, height - lineWidth, width - radius - lineWidth, height - lineWidth);
        ctx.lineTo(radius + lineWidth, height - lineWidth);
        ctx.quadraticCurveTo(lineWidth, height - lineWidth, lineWidth, height - radius - lineWidth);
        ctx.lineTo(width / 2 - radius2, lineWidth + radius2);
        ctx.quadraticCurveTo(width / 2, lineWidth, width / 2 + radius2, lineWidth + radius2);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        ctx.restore();
        break;
      }
      default:
        break;
    }
  }

  /** Асинхронно создаём ссылку на файл */
  private static async toDataURL(data) {
    return new Promise((ok) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => ok(reader.result));
      reader.readAsDataURL(data);
    });
  }
}
