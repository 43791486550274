import { ILimit, ISort, RestService } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';

import { ICustomSelectItem } from '../../models/interfaces';

/**
 * Абстрактный сервис, реализующий загрузку инцидентов
 */
export abstract class BaseEmergencyService {
  constructor(public readonly rest: RestService) {}
  /**
   * Получить Observable, возвращающий все инциденты с требуемыми полями.
   * @param query фильтр для запроса
   * @param sort сортировка записей запроса
   * @param limit ограничения запрашиваемых данных
   * @param additionalAttributes дополнительные атрибуты для выборки
   * @param joinedTables таблицы для объединения
   */
  public getIncidents(
    query?: IAnyObject,
    limit?: ILimit,
    sort?: ISort,
    additionalAttributes?: string[],
    joinedTables?: IAnyObject,
  ): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Emergency' },
      entity: {
        query,
        name: 'Emergency',
        attributes: [
          'id',
          'organization.mo.id',
          'number',
          'lifeCycleStepId.status.sysname',
          'lifeCycleStepId.name',
          'lifeCycleStepId.endOfCycle',
          'incidentTypeId.id',
          'incidentTypeId.name',
          'addressFact.fullText',
          'addressFact.latitude',
          'addressFact.longitude',
          'addressFact.manual',
          'timeCreate',
          'docType.id',
          'docType.sysname',
          'organization.organizationType.sysname',
          'urgently',
          'coordinates',
          ...(additionalAttributes || []),
        ],
      },
      data: {
        limit,
        sort,
        ...(joinedTables || {}),
      },
    });
  }

  /**
   * Получить Observable, возвращающий все события с требуемыми полями.
   * @param query фильтр для запроса
   * @param limit ограничения запрашиваемых данных
   * @param sort объект сортировки (имя поля, направление)
   * @param additionalAttributes дополнительные атрибуты для выборки
   * @param joinedTables таблицы для объединения
   */
  public getEvents(
    query?: any,
    limit?: ILimit,
    sort?: ISort,
    additionalAttributes?: string[],
    joinedTables?: IAnyObject,
  ): Observable<IAbstractServiceData> {
    const resultQuery = Object.assign({}, query ? query : {});
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Emergency' },
      entity: {
        attributes: [
          'state',
          'timeCreate',
          'ksipTime',
          'closeReasonId.name',
          'comment',
          'direction',
          'number',
          'isHandled',
          'comment',
          'id',
          'sourceId.name',
          ...(additionalAttributes || []),
        ],
        name: 'Events',
        distinct: ['id' && 'timeCreate'],
        query: resultQuery,
      },
      data: {
        limit,
        sort,
        ...(joinedTables || {}),
      },
    });
  }

  /** Получение списка инцидентов дл япрогнозирования согласно параметрам формы */
  public getIncidentsListForForecasting(
    query: IAnyObject,
    additionalAttributes: string[] = [],
  ): Observable<ICustomSelectItem[]> {
    return this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: 'Emergency' },
          entity: {
            query,
            name: 'Emergency',
            attributes: ['id', 'number', ...additionalAttributes],
          },
          data: {
            sort: <ISort>{
              direction: 'desc',
              field: 'timeCreate',
            },
          },
        },
        'http',
      )
      .pipe(
        map((response: IAbstractServiceData) => {
          return ((response.data?.items as []) || []).map((el: IAnyObject) => {
            const item: ICustomSelectItem = <ICustomSelectItem>{
              value: el.id,
              text: el.number,
            };

            (additionalAttributes || []).forEach((name: string) => {
              item[name] = el[name];
            });

            return item;
          });
        }),
      );
  }

  /** Получение списка событий для прогнозирования согласно параметрам формы */
  public getEventsListForForecasting(
    query: IAnyObject,
    additionalAttributes: string[] = [],
  ): Observable<ICustomSelectItem[]> {
    return this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: 'Emergency' },
          entity: {
            query,
            name: 'Events',
            attributes: ['id', 'number', ...additionalAttributes],
          },
          data: {
            sort: <ISort>{
              direction: 'desc',
              field: 'timeCreate',
            },
          },
        },
        'http',
      )
      .pipe(
        map((response: IAbstractServiceData) => {
          return ((response.data?.items as []) || []).map((el: IAnyObject) => {
            const item: ICustomSelectItem = <ICustomSelectItem>{
              value: el.id,
              text: el.number,
            };
  
            (additionalAttributes || []).forEach((name: string) => {
              item[name] = el[name];
            });
  
            return item;
          });
        }),
      );
  }

  /** Получение информации для блока "Прогнозирование"
   * @param id id идентификатор инцидента
   * */
  public getEmergencyProjectedRiskId(id: string): Observable<string | undefined> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          attributes: ['incidentTypeId.projectedRisk'],
          query: { id },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return (res.data.items[0] || {})['incidentTypeId.projectedRisk'];
        }),
      );
  }

  /** Получение информации для блока "Прогнозирование"
   * @param id id идентификатор события
   * */
  public getEventProjectedRiskId(id: string): Observable<string | undefined> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Events',
          attributes: ['ksipTypeId.projectedRisk'],
          query: { id },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return (res.data.items[0] || {})['ksipTypeId.projectedRisk'];
        }),
      );
  }
}
