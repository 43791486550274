import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  IMapBaseEvent,
  IMapBaseIcon,
  IMapBaseInitOptions,
  MapBaseCoordinatesType,
  MapBaseModel,
  MapBaseService,
} from '@smart-city/maps/sc';

import { Coordinates } from '@bg-front/core/models/classes';
import { POLYGON_MARKER_SVG } from '@bg-front/core/models/constants';
import { IPolygonDto, ISubstrateDto } from '@bg-front/core/models/interfaces';
import { PolygonsService, SubstratesQuery } from '@bg-front/core/services';
import { IMapBaseBaseUrlsOptions } from '@bg-front/map/models/interfaces';

@UntilDestroy()
@Component({
  selector: 'bg-polygon-edit',
  templateUrl: './polygon-edit.component.html',
  styleUrls: ['./polygon-edit.component.scss'],
})
export class PolygonEditComponent implements OnInit {
  /** Идентификатор полигона */
  @Input() distictId: string | undefined;

  /** Координаты полигона */
  @Input() set polygonCoordinates(value: Coordinates[]) {
    this._polygonCoordinates = value;
    this.reDrawPolygon();
  }

  /** Координаты полигона */
  private _polygonCoordinates: Coordinates[] = [];
  /** Заголовок */
  @Input() title?: string;

  /** Возможность редактирвоания полигона */
  @Input() canEdit: boolean = true;

  /** Отображать поле координат */
  @Input() showCoordinates: boolean = true;

  /** Дефолтное значение приближения */
  @Input() zoom: number = 15;

  /** Центрировать карту по координатам */
  @Input() set centerCoordinates(value: MapBaseCoordinatesType | undefined) {
    this._centerCoordinates = value;
    if (value) {
      this.centerOnCoordinates(value);
    }
  }

  private _centerCoordinates: MapBaseCoordinatesType | undefined;

  /** Событие формирования полигона */
  @Output() polygonSave = new EventEmitter<Coordinates[]>();

   /** Событие готовности карты */
  @Output() mapReady = new EventEmitter();

  /** Настройка карты */
  public mapOptions: IMapBaseInitOptions = <IMapBaseInitOptions>{};

  /** Модель карты */
  private mapModel: MapBaseModel;

  /** Флаг редактирования полигона */
  public editingPolygon: boolean = false;

  /** Этап сохранения полигона */
  public savingPolygon: boolean = false;

  /** Иконка для точки полигона */
  private pointIcon?: IMapBaseIcon = this.mapService.makeIcon({
    iconSize: 8,
    iconColor: 'black',
    iconSVG: POLYGON_MARKER_SVG,
    shapeType: 'Круг',
    shapeSize: 10,
    shapeColor: 'black',
  });

  /** Иконка для последней точки полигона */
  private lastPointIcon?: IMapBaseIcon = this.mapService.makeIcon({
    iconSize: 8,
    iconColor: 'blue',
    iconSVG: POLYGON_MARKER_SVG,
    shapeType: 'Круг',
    shapeSize: 12,
    shapeColor: 'blue',
  });

  /** Идентификатор карты */
  private mapId: string = 'editPolygon';

  /** Модель инпута координат под картой */
  public coordinates: string = '';

  constructor(
    // private readonly bgMapService: BgMapService,
    private readonly mapService: MapBaseService,
    private readonly polygonsService: PolygonsService,
    private readonly substratesQuery: SubstratesQuery,
  ) {}

  public ngOnInit(): void {
    this.getPolygon().subscribe(() => this.initMap());
  }

  /** @ignore */
  public initMap(): void {
    const transformSubstratesUrl: (url: string) => string = (url: string): string => {
      return url.startsWith('/', 0) ? `//${window.location.host}${url}` : `//${url}`;
    };

    const substrates = this.substratesQuery.getAll();
    const activeSubstrate = (this.substratesQuery.getActive() as ISubstrateDto) || substrates?.[0];

    this.mapModel = new MapBaseModel(this.mapId, this.mapService);
    this.mapOptions.mapId = this.mapId;
    this.mapOptions.url = substrates?.length > 1 ? undefined : transformSubstratesUrl(activeSubstrate.link);
    this.mapOptions.urls =
      substrates?.length > 1
        ? substrates.map((el: ISubstrateDto): IMapBaseBaseUrlsOptions => {
            return <IMapBaseBaseUrlsOptions>{
              url: transformSubstratesUrl(el.link),
              name: el.name,
              attribution: el.attribution,
              selected: activeSubstrate ? el.id === activeSubstrate.id : el.default,
            };
          })
        : undefined;
    this.mapOptions.maxZoom = 18;
    this.mapOptions.mapStyle = { width: '100%', height: '100%' };
    this.mapOptions['attribution'] = substrates?.length > 1 ? undefined : activeSubstrate.attribution;
    this.mapOptions.zoom = this.zoom;
    this.mapOptions.center = this._centerCoordinates;

    this.mapModel
      .getObservableMapEvents()
      .pipe(untilDestroyed(this))
      .subscribe((event: IMapBaseEvent) => {
        switch (event.typeEvent) {
          case 'mapClick': {
            if (event?.coordinates && this.editingPolygon) {
              this._polygonCoordinates.push(new Coordinates(event.coordinates[0], event.coordinates[1]));
              this.reDrawPolygon();
            }
            if (this._polygonCoordinates.length >= 3) {
              this.coordinates = this._polygonCoordinates
                .map((coordinate: Coordinates) => coordinate.toString())
                .join('; ');
            }
            break;
          }
          case 'mapReady':
            this.mapReady.emit();
            this.reDrawPolygon();
            this.mapOptions = { ...this.mapOptions };
            if (this._centerCoordinates) {
              setTimeout(() => this.centerOnCoordinates(this._centerCoordinates!));
            }
            break;
          default:
            break;
        }
      });
  }

  /** Получение данных полигона */
  private getPolygon(): Observable<IPolygonDto> {
    return this.distictId && !this._polygonCoordinates
      ? this.polygonsService.getById(this.distictId!).pipe(
          tap((polygon: IPolygonDto) => {
            if (!polygon?.coordinates) return;
            this._polygonCoordinates = polygon.coordinates;
            this.coordinates = this._polygonCoordinates
              .map((coordinate: Coordinates) => coordinate.toString())
              .join('; ');
            this.reDrawPolygon();
            if (this._centerCoordinates) {
              this.centerOnCoordinates(this._centerCoordinates);
              return;
            }
            if (this._polygonCoordinates?.length) {
              this.centerOnCoordinates(this.getCenter(this._polygonCoordinates));
            }
          }),
        )
      : of(null);
  }

  /** Редактирование полигона */
  public editPolygon(): void {
    this.editingPolygon = true;
    this.reDrawPolygon();
  }

  /** Отмена редактирования полигона */
  public cancelEditPolygon(): void {
    if (!this._polygonCoordinates.length) {
      this.editingPolygon = false;
      this._polygonCoordinates = [];
      this.coordinates = '';
      this.reDrawPolygon();
    } else {
      this._polygonCoordinates.pop();
      this.reDrawPolygon();
    }
  }

  /** Сохранение полигона */
  public savePolygon(): void {
    this.editingPolygon = false;
    this.polygonSave.emit(this._polygonCoordinates);
  }

  /** Перерисовка полигона */
  public reDrawPolygon(): void {
    if (this.mapOptions.mapId) {
      this.mapModel.removeLayer('polygon-points');
      this.mapModel.removeLayer('polygon');
      this.mapModel.addPolygonToLayer(
        'polygon',
        'polygon',
        this._polygonCoordinates.map((point: Coordinates) => point.toArray()),
        {
          color: 'black',
          weight: 2,
        },
      );
      this._polygonCoordinates.map((point: Coordinates, index: number) => {
        this.mapModel.addMarkerToLayer(
          'polygon-points',
          `${index}`,
          point.toArray(),
          index === (this.editingPolygon && this._polygonCoordinates.length - 1) ? this.lastPointIcon : this.pointIcon,
        );
      });
      this.mapModel.viewLayer('polygon', false);
      this.mapModel.viewLayer('polygon-points', false);
    }
  }

  /** Центрирование карты на координатах */
  private centerOnCoordinates(coordinates: MapBaseCoordinatesType): void {
    this.mapModel.setCenter(coordinates);
  }

  /** Удалить полигон */
  public clearPolygon(): void {
    this._polygonCoordinates = [];
    this.coordinates = '';
    this.mapModel.removeLayer('polygon-points');
    this.mapModel.removeLayer('polygon');
  }

  /** Редактирование полигона */
  public onCoordinatesChange(coordinates: string): void {
    if (coordinates) {
      this._polygonCoordinates = coordinates
        .split(';')
        .map((str: string) => new Coordinates(str))
        .filter((coord) => coord.isValid());

      if (this._polygonCoordinates.length > 1) {
        this.mapModel.addPolygonToLayer(
          'polygon',
          'polygon',

          this._polygonCoordinates.map((point: Coordinates) => point.toArray() as [number, number]),
          {
            color: 'black',
            weight: 2,
          },
        );
      }
    } else {
      this.mapModel.removeLayer('polygon-points');
      this.mapModel.removeLayer('polygon');
    }
  }

  /**
   * Получение центра в массиве координат
   * @param coordinates - координаты вершин полигона
   */
  private getCenter(coordinates: Coordinates[]): MapBaseCoordinatesType {
    let maxLat = -Infinity;
    let maxLon = -Infinity;
    let minLat = Infinity;
    let minLon = Infinity;
    for (let i = 0; i < coordinates.length; i += 1) {
      if (!coordinates[i].lat || !coordinates[i].lon) continue;

      if (coordinates[i].lat! > maxLat) {
        maxLat = coordinates[i].lat!;
      }
      if (coordinates[i].lon! > maxLon) {
        maxLon = coordinates[i].lon!;
      }
      if (coordinates[i].lat! < minLat) {
        minLat = coordinates[i].lat!;
      }
      if (coordinates[i].lon! < minLon) {
        minLon = coordinates[i].lon!;
      }
    }
    return [+((maxLat + minLat) / 2).toFixed(7), +((maxLon + minLon) / 2).toFixed(7)];
  }
}
