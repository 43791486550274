import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { CommunicationService } from '@bg-front/core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { INwHeaderBarOptions } from '@smart-city/core/common';
import { AccessService, IAccessAction, ScNavService } from '@smart-city/core/services';
import { distinctUntilChanged, map, skipWhile, takeUntil } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';

import { IRegistryPanelDto } from '../../models/interfaces';
import { RegistryPanelService } from '../../services';

/**
 * Панель с набором оперативных данных. Включает в себя реестр происшествий и реестры объектов
 */
@UntilDestroy()
@Component({
  selector: 'bg-registries-panel',
  templateUrl: './registry-panel.component.html',
  styleUrls: ['./registry-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistryPanelComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Доступность контрола Организация */
  public canForecastingRun: IAccessAction = { visible: false, enabled: false, name: 'CanForecastingRun' };

  /**
   * Настройки заголовка
   */
  public headerOptions: INwHeaderBarOptions = {
    title: 'Рабочий стол оператора',
    name: 'header',
    margin: 'collapse',
    bgColor: 'white',
    buttons: [
      {
        type: 'button',
        options: {
          name: 'burger',
          icon: 'menu',
        },
      },
    ],
  };

  /** Массив объектов для отображения на панели */
  public registries: IRegistryPanelDto[] = [];

  /** Массив объектов для отображения на панели */
  @Input() public components: IAnyObject[] = [];

  /** Контейнер */
  @ViewChild('panelContainer', { read: ViewContainerRef, static: true })
  public panelContainer: ViewContainerRef;

  /** Выбранный реестр */
  public selectRegistry = false;

  constructor(
    private readonly registryPanelService: RegistryPanelService,
    private readonly scNavService: ScNavService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly accessService: AccessService,
    private readonly communicationService: CommunicationService,
    private readonly componentFactoryResolver: ComponentFactoryResolver,

    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.canForecastingRun = this.accessService.accessMap[this.canForecastingRun.name];
    this.components = this.route.snapshot.data.registries;

    this.registryPanelService
      .getRegistries()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((registries: IRegistryPanelDto[]) => {
        // Получение доступных реестров из конструктора панели реестров (Admin_RegistriesPanel) по указанным клеймам
        this.registries = registries || [];

        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params: IAnyObject) => {
          if (params?.openForecastingForm === 'true') {
            this.runForecasting(params);
          }
        });

        this.route.queryParams
          .pipe(
            skipWhile(() => !this.registries?.length),
            map((params: Params) => {
              return params['registry'] as string;
            }),
            distinctUntilChanged(),
            untilDestroyed(this),
          )
          .subscribe((id: string) => {
            this.panelContainer.clear();
            this.selectRegistry = !!id;
            if (id) {
              let sysName = this.registries.find((reg: IRegistryPanelDto) => reg.id === id)?.sysName;
              sysName = `${sysName.split('')[0].toLowerCase()}${sysName.split('').splice(1).join('')}`;
              const component = this.panelContainer.createComponent(
                this.componentFactoryResolver.resolveComponentFactory(this.components[sysName]),
              ).instance as IAnyObject;

              component.registry = this.registries.find((reg: IRegistryPanelDto) => reg.id === id);
            }

            this.cdr.detectChanges();
          });
      });
  }

  /**
   * Обработчик, вызываемый при клике на одну из кнопок.
   * @param registry - реестр
   */
  public onClickButton(registry: IRegistryPanelDto): void {
    let sysName = registry.sysName;
    sysName = `${sysName.split('')[0].toLowerCase()}${sysName.split('').splice(1).join('')}`;
    if (this.components && this.components[sysName]) {
      const urlTree = this.router.parseUrl(this.router.url);
      const params = { ...urlTree.queryParams };
      params['registry'] = registry.id;

      this.router.navigate([], {
        relativeTo: this.route.parent,
        queryParams: params,
      });
    } else {
      const link = `${registry.sysName.split('')[0].toLowerCase()}${registry.sysName.split('').splice(1).join('')}`;
      this.router.navigate([{ outlets: { leftSidebar: [link, registry.id.toString()] } }], {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge',
      });
    }
  }

  /**
   * Вызов меню
   */
  public onClickHeaderButton($event: any): void {
    this.scNavService.openMenu();
  }

  /**
   * Запуск прогнозирования
   */
  public runForecasting(params: IAnyObject): void {
    if (this.canForecastingRun?.enabled) {
      this.resetQueryParams();
      this.communicationService.runForecasting({
        route: this.route,
        emergencyId: params.emergencyId,
        eventId: params.eventId,
        projectedRiskId: params.projectedRiskId,
        coordinates: params.coordinates,
      });
    }
  }

  /** Убирает queryParams из URL */
  private resetQueryParams() {
    const urlTree = this.router.createUrlTree([], {
      queryParams: { openForecastingForm: null, emergencyId: null },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
    this.router.navigateByUrl(urlTree);
  }
}
