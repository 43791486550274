import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import { IMapLayerEntityFilter, ISelectItem } from '@bg-front/core/models/interfaces';
import { SignificantObjectsQuery } from '@bg-front/significant-objects/services';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAdminMunicipalSchemaDto, ISignificantObjectDto } from 'smart-city-types';

import { ISignificantObjectMapFilterForm } from '../../models/interfaces';

/**
 * Компонент фильтрации важных объектов на карте
 */
@UntilDestroy()
@Component({
  selector: 'map-significant-object-filter',
  templateUrl: './map-significant-object-filter.component.html',
  styleUrls: ['./map-significant-object-filter.component.scss'],
})
export class MapSignificantObjectFilterComponent implements OnInit {
  /** Значения фильтров */
  @Input()
  public mapLayerFilters: ValuesOf<ControlsOf<ISignificantObjectMapFilterForm>>;

  /** Оповещение о изменении значений фильтров */
  @Output()
  public changedFilters: EventEmitter<IMapLayerEntityFilter[] | 'delete'> = new EventEmitter<
    IMapLayerEntityFilter[] | 'delete'
  >();

  /** Форма */
  public form: FormGroup<ControlsOf<ISignificantObjectMapFilterForm>>;

  /** Настройка компоненты Тип */
  public types: ISelectItem[] = this.settings.getDictForSelect('significantObjectTypes').map(
    (el: IScSelectItem) =>
      <ISelectItem>{
        text: el.text,
        value: el.id,
      },
  );

  /** Настройка компоненты Наименование */
  public significantObjects$: Observable<ISelectItem[]> = this.significantObjectsQuery.selectAll().pipe(
    map((values: ISignificantObjectDto[]): ISelectItem[] =>
      values.map(
        (el: ISignificantObjectDto): ISelectItem =>
          <ISelectItem>{
            text: el.name,
            value: el.id,
          },
      ),
    ),
    untilDestroyed(this),
  );

  /** Настройка компоненты Состояние */
  public statuses: ISelectItem[] = this.settings.getDictForSelect('significantObjectStatus').map(
    (el: IScSelectItem) =>
      <ISelectItem>{
        text: el.text,
        value: el.id,
      },
  );

  /** Настройка компоненты Муниципальное образование */
  public municipals!: ISelectItem[];

  /** Просмотр всех МО */
  private viewAllMunicipals!: boolean;

  /** @ignore */
  constructor(
    private readonly accessService: AccessService,
    private readonly settings: Settings2Service,
    private readonly significantObjectsQuery: SignificantObjectsQuery,
    private readonly modalRef: NzModalRef,
  ) {}

  /** @ignore */
  public ngOnInit(): void {
    this.viewAllMunicipals = this.accessService.accessMap['viewAllMunicipals']?.visible;
    this.form = new FormGroup<ControlsOf<ISignificantObjectMapFilterForm>>({
      moId: new FormControl({
        value: this.viewAllMunicipals ? this.mapLayerFilters?.moId : this.settings.currentUser.organizationId.mo,
        disabled: !this.viewAllMunicipals,
      }),
      typeId: new FormControl(this.mapLayerFilters?.typeId),
      id: new FormControl(this.mapLayerFilters?.id),
      stateId: new FormControl(this.mapLayerFilters?.stateId),
    });
    this.municipals = this.settings.allMo
      .map((item: IAdminMunicipalSchemaDto) => {
        return <ISelectItem>{
          text: item.name,
          value: item.id,
        };
      })
      .filter((item: ISelectItem) => this.viewAllMunicipals
        || item.value === this.settings.currentUser.organizationId.mo);
  }
  /**
   * Формирование фильтров на основании значения формы
   * @param value значение формы
   */
  private getFilters(): IMapLayerEntityFilter[] {
    const value: ValuesOf<ControlsOf<ISignificantObjectMapFilterForm>> = this.form.value;

    const result: IMapLayerEntityFilter[] = [];
    if (value.moId) {
      result.push({
        property: 'mo',
        value: value.moId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.typeId) {
      result.push({
        property: 'type',
        value: value.typeId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.id) {
      result.push({
        property: 'id',
        value: value.id,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.stateId) {
      result.push({
        property: 'state',
        value: value.stateId,
        operation: FilterOperationEnum.equal,
      });
    }
    return result;
  }

  /** Очистка формы */
  public clear(): void {
    this.form.reset();
    if (!this.viewAllMunicipals) {
      this.form.controls.moId.patchValue(this.settings.currentUser.organizationId.mo);
    }
  }

  /** Закрытие формы */
  public close(): void {
    this.modalRef.destroy();
  }

  /** Фильтрация */
  public filtrate(): void {
    this.modalRef.destroy(this.form.getRawValue());
  }
}
