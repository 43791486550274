import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BaseComponent, DeletedDialogComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { LayersEnum } from '@bg-front/core/models/enums';
import { IPolygonDto } from '@bg-front/core/models/interfaces';
import { BgMapService } from '@bg-front/core/services';
import { IElementButton, INwHeaderBarOptions, IScrollableContainerLoadDataFnResult } from '@smart-city/core/common';
import { NotificationService, ScNavService } from '@smart-city/core/services';
import { MapBaseModel, MapBaseService } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { of } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { IEmergencyDto, IUserInfoDto } from 'smart-city-types';
import { BgAdminService, EmergencyService, PolygonsService } from '../../services';

/**
 * Компонента отображающая данные о пользовательском полигоне
 */
@Component({
  selector: 'bg-user-polygon-info',
  templateUrl: './user-polygon-info.component.html',
  styleUrls: ['./user-polygon-info.component.scss'],
})
export class UserPolygonInfoComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions;
  /** Данные выбранного полигона */
  public polygon: IPolygonDto = undefined;
  /** Координаты */
  public coordinates: string = undefined;
  /** Список связанных происшевствий */
  public emergencies: IEmergencyDto[] = undefined;
  /** Активная карточка */
  public isActive: boolean = false;
  /** Событие закрытия формы */
  @Output()
  public closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  /** Время создания записи */
  public creationTime: string;
  /** Автор записи */
  public creationAuthor: string;
  /** Модель карты */
  private mapModel: MapBaseModel;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly polygonsService: PolygonsService,
    private readonly adminService: BgAdminService,
    private readonly scNavService: ScNavService,
    private readonly gisService: BgMapService,
    private readonly emergencyService: EmergencyService,
    private dialog: MatDialog,
    private note: NotificationService,
    private readonly mapService: MapBaseService,
  ) {
    super();
  }

  /** Получаем информацию о полигоне по id */
  @Input()
  public set userPolygonId(polygonId: string) {
    if (polygonId) {
      this.polygonsService
        .getById(polygonId)
        .pipe(
          switchMap((polygon: IPolygonDto) => {
            this.polygon = polygon as IPolygonDto;
            this.creationTime = dayjs(Number(polygon.creationTime)).format('DD.MM.YYYY HH:mm:ss');

            return this.adminService.getUserById(polygon.creationAuthor);
          }),
          switchMap((user: IUserInfoDto) => {
            this.creationAuthor = user?.fio;
            return of(null);
          }),
          switchMap(() => {
            return this.emergencyService.getIncidents({
              'docType.sysname': 'incident',
              $or: [{ 'lifeCycleStepId.status.sysname': 'new' }, { 'lifeCycleStepId.status.sysname': 'inWork' }],
              point: {
                $polygon: {
                  $points: [
                    ...this.polygon.coordinates.map((item: Coordinates) => item.toArray()),
                    ...[this.polygon.coordinates[0].toArray()],
                  ],
                },
              },
            });
          }),
          catchError((err: Error) =>
            this.catchErrorFn<IScrollableContainerLoadDataFnResult>(err, 'Ошибка при загрузке полигонов'),
          ),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(({ data: { items: emergencies } }) => (this.emergencies = emergencies));
    }
  }

  /** @ignore */
  public ngOnInit(): void {
    this.mapModel = new MapBaseModel('baseMapWorkspace', this.mapService);

    /** Подписка на выбор карточки пользовательского полигона в реестре */
    this.polygonsService.userPolygonSelectItem$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userPolygonId: string) => {
        this.isActive = userPolygonId === this.polygon.id;

        if (this.isActive) {
          this.router.navigate([{ outlets: { leftPopup: ['userPolygon', this.polygon.id] } }], {
            relativeTo: this.route.parent,
            queryParamsHandling: 'merge',
          });
        }
      });

    /** Подписываемся на параметры */
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params: Params) => {
      if (params['id']) {
        return (this.userPolygonId = params['id']);
      }
    });

    this.headerOptions = {
      margin: 'collapse',
      title: ' ',
      buttons: [
        {
          type: 'button',
          options: {
            name: 'burger',
            icon: 'menu',
            callback: () => {
              this.scNavService.openMenu();
              return of({ actionStream: 'donothing' });
            },
          },
        },
        {
          type: 'button',
          options: {
            name: 'close',
            icon: 'close',
            position: 'suffix',
            callback: () => {
              this.mapModel.removeLayer(LayersEnum.clickMarker);

              this.router.navigate(
                [
                  {
                    outlets: { leftPopup: null },
                  },
                ],
                {
                  relativeTo: this.route.parent,
                  queryParamsHandling: 'merge',
                },
              );
              return of();
            },
          },
        },
      ],
    };
  }

  /** @ignore */
  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.gisService.removeForestryFacilities();
    this.mapModel.removeLayer(LayersEnum.clickMarker);
  }

  /**
   * Обработчик, вызываемый при клике на кнопке удалить полигон.
   */
  public clickDeletePolygon($event: Event) {
    const dialogRef = this.dialog.open(DeletedDialogComponent, {
      disableClose: false,
      minWidth: 450,
    });

    dialogRef.componentInstance.message = 'Запись будет удалена. Вы действительно хотите продолжить?';
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.polygonsService
          .delete(this.polygon.id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            this.closeEvent.emit(true);
            this.note.pushInfo('Запись успешно удалена!');

            this.router.navigate(
              [
                {
                  outlets: { leftPopup: null },
                },
              ],
              {
                relativeTo: this.route.parent,
                queryParamsHandling: 'merge',
              },
            );
          });
      }
    });
  }

  /**
   * Обработка событий из заголовка
   * @param $event событие
   */
  public clickHeaderButton($event: IElementButton): void {
    ($event.options.callback as any)();
  }

  /**
   * Обработчик, вызываемый при клике на кнопке показать на карте.
   * @param event - информация о событии
   */
  public showOnMap(event: Event): void {
    event.preventDefault();
    this.polygonsService
      .getById(this.polygon.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((polygon: IPolygonDto): void => {
        const polygonCenter: string = this.gisService.getCenter(polygon.coordinates);
        this.gisService.drawPolygon(polygon.coordinates);
        this.gisService.setPositionMapOnCoordinates(polygonCenter);
      });
  }
}
