import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Coordinates } from '@bg-front/core/models/classes';
import { MultiFileService } from '@bg-front/core/services';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  DialogService,
  IScCheckboxOptions,
  IScInputOptions,
  IScSelectOptions,
  IScTextareaOptions,
} from '@smart-city/core/common';
import { AccessService, INotificationMessage, Settings2Service } from '@smart-city/core/services';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { IEmergencyDto, IEmergencyResolutionDto, ILifeCycleStepDto } from 'smart-city-types';
import { BgLoosesComponent } from '../../../../../app-common/components';
import { BaseIncidentFormComponent } from '../../../../../app-common/components/base-incident-form/base-incident-form.component';
import { IMiniMapMarkerPositionEvent } from '../../../../../app-common/models/interfaces';
import {
  AtmIntegrationService,
  BgAdminService,
  EmergencyService,
  MiniMapService,
  OrganizationsService,
  ResponsePlanStepService,
} from '../../../../../app-common/services';
import { ReportingService } from '../../../reporting/services';

@UntilDestroy()
@Component({
  selector: 'bg-ulk-order-edit-form',
  templateUrl: './ulk-order-edit-form.component.html',
  styleUrls: ['./ulk-order-edit-form.component.scss'],
})
export class UlkOrderEditFormComponent extends BaseIncidentFormComponent implements OnInit, AfterViewInit {
  /** Новая служба должна привлекаться через ЕДДС */
  protected readonly isInvolveThroughEdds: boolean = true;

  /** Список сообщений об ошибке */
  public errors: INotificationMessage[] = [];

  /** Настройка компоненты Комментарий */
  public optionsComment: IScTextareaOptions = {
    label: 'Комментарий оператора',
    maxLength: 1000,
    rows: 6,
  };
  /** Настройка компоненты Комментарий */
  public optionsUlkComment: IScTextareaOptions = {
    label: 'Комментарий исполнителя',
    rows: 2,
  };
  /** Настройка компоненты Описание */
  public optionsDescription: IScTextareaOptions = {
    label: 'Описание',
    maxLength: 1000,
    rows: 6,
  };
  /** Настройка компоненты Ответсвенный пользователь */
  public optionsResponsibleUser: IScSelectOptions = {
    title: 'Ответственный пользователь',
    clearable: true,
    service: 'Admin',
    entity: 'Users',
    modern: true,
    fieldId: 'id',
    fieldName: 'fio',
  };
  /** Настройка компоненты Угроза населению */
  public optionsThreatPopulation: IScCheckboxOptions = {
    title: 'Угроза населению',
  };
  /** Настройка компоненты Угроза организации */
  public optionsThreatOrganization: IScCheckboxOptions = {
    title: 'Угроза организации',
  };
  /** Настройка компоненты Фактического адреса */
  public coordinatesOption: IScInputOptions = {
    label: 'Координаты фактического адреса',
    maxLength: 100,
  };
  /** Настройка компоненты Доп информация о месте КСиП */
  public placeDescriptionOptions: IScTextareaOptions = {
    label: 'Дополнительная информация о месте КСиП',
    maxLength: 1000,
    rows: 8,
  };
  /** Количество граждан, зарегистрированных в жилом доме */
  public totalRegistryPeopleHouseOptions: IScInputOptions = {
    label: 'Количество граждан, зарегистрированных в жилом доме',
    type: 'number',
  };
  /** Индивидуальное домовладение */
  public individualOwnershipOptions: IScCheckboxOptions = {
    title: 'Индивидуальное домовладение',
  };
  /** Объект ЖКХ */
  public jkhObjectOptions: IScSelectOptions = {
    title: 'Объект ЖКХ',
    clearable: true,
    modern: true,
    service: 'AtmIntegration',
    entity: 'MonitoringObject',
    fieldName: 'name',
    query: { active: true },
  };
  /** Отправить в "Реформу ЖКХ"  */
  public sendingToJkhReformOptions: IScCheckboxOptions = {
    title: 'Отправить в "Реформу ЖКХ"',
  };
  /** Тип ограничения ресурса  */
  public resourceConstraintTypeOptions: IScSelectOptions = {
    title: 'Тип ограничения ресурса',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'Dictionary',
    query: { 'typeid.sysname': 'resourceLimitationType' },
    fieldName: 'name',
  };
  /** Связанные ограничения  */
  public relatedRestrictionsOptions: IScCheckboxOptions = {
    title: 'Связанные ограничения',
  };
  /** Cфера ЖКХ */
  public jkhDirectionOptions: IScSelectOptions = {
    title: 'Сфера ЖКХ',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'IncidentCategories',
    width: '100%',
    isMultiple: true,
    fieldName: 'name',
    query: { 'sectionId.sysname': 'zhkh' },
  };
  /**
   * Объекты первой категории, затронутые ограничением
   */
  public favorableRestrictionObjectsOptions: IScTextareaOptions = {
    label: 'Объекты первой категории, затронутые ограничением',
    maxLength: 1000,
    rows: 8,
  };
  /**
   * Иные объекты, затронутые ограничением
   */
  public affectedRestrictionObjectsOptions: IScTextareaOptions = {
    label: 'Иные объекты, затронутые ограничением',
    maxLength: 1000,
    rows: 8,
  };
  /** Категория электроснабжения потребителей  */
  public consumerPowerCategoryIdOptions: IScSelectOptions = {
    title: 'Категория электроснабжения потребителей',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'Dictionary',
    query: { 'typeid.sysname': 'powerSupplyConsumerCategory' },
    fieldName: 'name',
  };
  /** Количество граждан, проживающих в жилом доме временно */
  public totalTemporalPeopleHouseOptions: IScInputOptions = {
    label: 'Количество граждан, проживающих в жилом доме временно',
    type: 'number',
  };
  /** Флаг для отображения контролов жкх */
  public isJhk: boolean = false;
  /** флаг на значение 'Эксплуатация жилищного фонда' */
  public isHousingService: boolean;
  /** Видимость Отправить в "Реформу ЖКХ" */
  public isSendingReformToJkhVisible: boolean;
  /** Центр миникарты */
  public miniMapCenter: MapBaseCoordinatesType;
  /** Зум для миникарты */
  public miniMapZoom: number;
  /** Координаты по умолчанию для мини карты */
  public defaultMarkerCoordinate: [number, number];
  /** Активность блока Привлекаемые службы */
  public orderInvolvedServiceEnabled: boolean = false;

  /** @ignore */
  constructor(
    accessService: AccessService,
    atmIntegrationService: AtmIntegrationService,
    bgAdminService: BgAdminService,
    dialog: MatDialog,
    dialogService: DialogService,
    emergencyService: EmergencyService,
    fb: FormBuilder,
    ksipCategoriesQuery: KsipCategoriesQuery,
    ksipDetailsQuery: KsipDetailsQuery,
    ksipSectionsQuery: KsipSectionsQuery,
    ksipTypesQuery: KsipTypesQuery,
    miniMapService: MiniMapService,
    multiFileService: MultiFileService,
    organizationsService: OrganizationsService,
    reportingService: ReportingService,
    route: ActivatedRoute,
    router: Router,
    settings: Settings2Service,
    significantObjectService: SignificantObjectsService,
    responsePlanStepService: ResponsePlanStepService,
  ) {
    super(
      accessService,
      atmIntegrationService,
      bgAdminService,
      dialog,
      dialogService,
      emergencyService,
      fb,
      ksipCategoriesQuery,
      ksipDetailsQuery,
      ksipSectionsQuery,
      ksipTypesQuery,
      miniMapService,
      multiFileService,
      organizationsService,
      reportingService,
      route,
      router,
      settings,
      significantObjectService,
      responsePlanStepService,
    );
  }

  /** Свойство рассчитывающее общее количество задействованных машин */
  public get summaryCars(): number {
    if (this.model.resolution) {
      return (
        (this.model.resolution.fireCar || 0) +
        (this.model.resolution.ambulance || 0) +
        (this.model.resolution.policeCar || 0)
      );
    }
    return 0;
  }

  /** @ignore */
  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data: Data) => {
      this.model = data.model || this.model;
      // Активность блока Привлекаемые службы
      this.orderInvolvedServiceEnabled =
        this.accessService.accessMap['OrderInvolvedServiceEnabled']?.visible &&
        this.accessService.accessMap['OrderInvolvedServiceEnabled']?.enabled;
      this.incidentForm = new FormGroup({
        /** ----- MAIN ----- **/
        byCoordinates: new FormControl(this.model.byCoordinates),
        addressFact: new FormControl(this.model.addressFact, [Validators.required]),
        coordinatesAddress: new FormControl(
          this.model.byCoordinates ? this.model.addressFact.fullText || this.model.address.fullText : null,
        ),
        moId: new FormControl(this.model.moId),
        districtId: new FormControl({ value: this.model.districtId, disabled: true }),
        detailsFact: new FormControl(this.model.detailsFact),
        incidentTypeId: new FormControl(
          {
            value: this.model.incidentTypeId,
            disabled: true,
          },
          [Validators.required],
        ),
        ksipDetailsId: new FormControl({
          value: this.model.ksipDetailsId,
          disabled: true,
        }),
        comment: new FormControl({
          value: this.model.comment,
          disabled: true,
        }),
        description: new FormControl({
          value: this.model.description,
          disabled: true,
        }),
        responsibleUser: new FormControl(this.model.responsible || this.settings.currentUser.id, [Validators.required]),
        deadline: new FormControl(this.model.deadline),
        organization: new FormControl(this.model.organization),
        urgently: new FormControl(this.model.urgently),
        threatPopulation: new FormControl(this.model.resolution?.danger),
        threatOrganization: new FormControl(this.model.resolution?.dangerOrg),
        /** ----- LOOSES ----- **/
        resolution: BgLoosesComponent.generateFormGroup(this.fb, {
          ...this.model.resolution,
          peopleInTheZoneOnDate: this.model.resolution?.peopleInTheZoneOnDate || this.model.timeCreate,
          deathOnDate: this.model.resolution?.deathOnDate || this.model.timeCreate,
          rescuedOnDate: this.model.resolution?.rescuedOnDate || this.model.timeCreate,
          victimOnDate: this.model.resolution?.victimOnDate || this.model.timeCreate,
          hospitalizedOnDate: this.model.resolution?.hospitalizedOnDate || this.model.timeCreate,
          evacuatedOnDate: this.model.resolution?.evacuatedOnDate || this.model.timeCreate,
          missingOnDate: this.model.resolution?.missingOnDate || this.model.timeCreate,
        }),
        /** ----- PLACEMENT ----- **/
        coordinates: new FormControl(this.model.coordinates),
        ksipPlaceDescription: new FormControl(this.model.ksipPlaceDescription),
        /** ----- ADDITIONAL ----- **/
        ulkAdditionalInfo: new FormControl(this.model.ulkAdditionalInfo),
        totalCars: new FormControl({ value: this.summaryCars, disabled: true }),
        ulkComment: new FormControl({
          value: this.model.ulkAdditionalInfo?.comment || undefined,
          disabled: false,
        }),
        /** ----- DOCS ----- **/
        documents: new FormControl(this.model.documents),
        totalRegistryPeopleHouse: new FormControl(this.model?.totalRegistryPeopleHouse),
        totalTemporalPeopleHouse: new FormControl(this.model?.totalTemporalPeopleHouse),
        individualOwnership: new FormControl(this.model?.individualOwnership),

        jkhObject: new FormControl(this.model?.jkhObject),
        favorableRestrictionObjects: new FormControl(this.model?.favorableRestrictionObjects),
        affectedRestrictionObjects: new FormControl(this.model?.affectedRestrictionObjects),
        resourceConstraintType: new FormControl(this.model?.resourceConstraintType),
        relatedRestrictions: new FormControl(this.model?.relatedRestrictions),
        jkhDirection: new FormControl(this.model?.jkhDirection),
        consumerPowerCategoryId: new FormControl(this.model?.consumerPowerCategoryId, [Validators.required]),
        isSendingToJkhReform: new FormControl(this.model?.isSendingToJkhReform),
        workTermFrom: new FormControl(this.model?.workTermFrom),
        workTermTo: new FormControl(this.model?.workTermTo),
      });

      this.eventsGridQuery = { incidentId: (<IEmergencyDto>this.model.parentId).id };

      super.ngOnInit();

      this.optionsResponsibleUser.query = { isActive: true, organizationId: this.model.organization };

      /** Получение координат и зума для МО */
      this.miniMapCenter = this.getMunicipalCoordinates();
      this.miniMapZoom = parseInt(this.getMunicipal().zoom, 10);

      // this.getModelData();

      const markerCoordinates = new Coordinates(this.model.mapMarkerCoordinate || this.model.coordinates);
      if (markerCoordinates.isValid()) {
        this.defaultMarkerCoordinate = markerCoordinates.toArray();
      }

      this.initReactPlan();
      this.updateForm();
      super.setViewedField();
    });
  }

  /** @ignore */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.route.snapshot.data['disabled']) {
      this.incidentForm.disable();
    }
  }

  /**
   * Установка маркера
   * @param miniMapEvent событие
   */
  public updateCoordinates(miniMapEvent: IMiniMapMarkerPositionEvent) {
    const markerCoordinates = new Coordinates(this.incidentForm.controls['mapMarkerCoordinate'].value);
    const newMarkerCoordinates = new Coordinates(miniMapEvent?.coordinates[0], miniMapEvent?.coordinates[1]);
    if (!markerCoordinates.equal(newMarkerCoordinates)) {
      this.incidentForm.controls['mapMarkerCoordinate'].setValue(newMarkerCoordinates.toString());
      this.redrawEventMarker(this.incidentForm.controls['mapMarkerCoordinate'].value);
      this.model.mapMarkerCoordinate = this.incidentForm.controls['mapMarkerCoordinate'].value;
    }
  }

  /** Получение данных из формы и сохранение в модель */
  public getModelData() {
    if (!this.model.id) {
      this.model.timeCreate = dayjs().unix() * 1000;
      this.model.number = `o-${dayjs(this.model.timeCreate).format('YYYYMMDD-HHmmss')}`;
      this.model.organization = this.settings.currentUser.organizationId.id;
      this.model.createdBy = this.settings.currentUser.id;
    }
    /** ----- MAIN ----- **/
    this.model.byCoordinates = this.incidentForm.controls['byCoordinates'].value;
    this.model.addressFact = this.model.byCoordinates
      ? this.addressByCoordinates
      : this.incidentForm.controls['addressFact'].value;
    this.model.moId = <string>this.moIdComponent.value;
    this.model.districtId = <string>this.districtIdComponent.value;
    this.model.detailsFact = this.incidentForm.controls['detailsFact'].value;
    this.model.ksipDetailsId = this.incidentForm.controls['ksipDetailsId'].value;
    this.model.incidentTypeId = this.incidentForm.controls['incidentTypeId'].value;
    this.model.comment = this.incidentForm.controls['comment'].value;
    this.model.description = this.incidentForm.controls['description'].value;
    this.model.responsible = this.incidentForm.controls['responsibleUser'].value;
    this.model.resolution = this.model.resolution || <IEmergencyResolutionDto>{};
    this.model.urgently = this.incidentForm.controls['urgently'].value;
    this.model.resolution.danger = this.incidentForm.controls['threatPopulation'].value;
    this.model.resolution.dangerOrg = this.incidentForm.controls['threatOrganization'].value;
    /** ----- PLACEMENT ----- **/
    this.model.coordinates = this.incidentForm.controls['coordinates'].value;
    this.model.ksipPlaceDescription = this.incidentForm.controls['ksipPlaceDescription'].value;
    /** ----- ADDITIONAL ----- **/
    this.model.ulkAdditionalInfo = this.incidentForm.controls['ulkAdditionalInfo'].value || {};
    this.model.ulkAdditionalInfo['comment'] = this.incidentForm.controls['ulkComment'].value;
    /** ----- DOCS ----- **/
    this.model.documents = this.incidentForm.controls['documents'].value;
    this.model.totalRegistryPeopleHouse = Number(this.incidentForm.controls['totalRegistryPeopleHouse'].value) || 0;
    this.model.totalTemporalPeopleHouse = Number(this.incidentForm.controls['totalTemporalPeopleHouse'].value) || 0;
    this.model.individualOwnership = this.incidentForm.controls['individualOwnership'].value;

    this.model.jkhObject = this.incidentForm.controls['jkhObject'].value;
    this.model.favorableRestrictionObjects = this.incidentForm.controls['favorableRestrictionObjects'].value;
    this.model.affectedRestrictionObjects = this.incidentForm.controls['affectedRestrictionObjects'].value;
    this.model.resourceConstraintType = this.incidentForm.controls['resourceConstraintType'].value;
    this.model.relatedRestrictions = this.incidentForm.controls['relatedRestrictions'].value;
    this.model.jkhDirection = this.incidentForm.controls['jkhDirection'].value;
    this.model.consumerPowerCategoryId = this.incidentForm.controls['consumerPowerCategoryId'].value
      ? this.incidentForm.controls['consumerPowerCategoryId'].value
      : undefined;
    this.model.isSendingToJkhReform = this.incidentForm.controls['isSendingToJkhReform'].value;
    this.model.workTermFrom = this.incidentForm.controls['workTermFrom'].value;
    this.model.workTermTo = this.incidentForm.controls['workTermTo'].value;

    /** ----- LOOSES ----- **/
    this.model.resolution = {
      ...this.model.resolution,
      ...(this.incidentForm.controls['resolution'].value || <IEmergencyResolutionDto>{}),
    };
  }

  /** Обновление формы */
  public updateForm() {
    this.actions = (this.model?.lifeCycleStepId as ILifeCycleStepDto)?.actions || [];
    this.generateHeaderBar();
    // TODO: Строчка ниже переопределяет, то что было присвоено в функции строчкой выше.
    // Подобное есть например еще в поручениях ДДС 01.
    // Подумать и сделать корректное определение заголовка в generateHeaderBar
    this.headerActionsOptions.title = `Поручение ${this.model.number ?? ''}`;
    this.attributes = (this.model.lifeCycleStepId as ILifeCycleStepDto).params;
  }

  /** Закрытие формы */
  public closeForm() {
    if (this.workspace === 'consolidated-registries') {
      this.router.navigate(['consolidated-registries/order-register']);
    } else {
      this.emergencyService.selectIncident({ id: undefined, docType: 'incident' });
      const navigateParams = this.route.snapshot.data['disabled']
        ? [{ outlets: { viewForm: null } }]
        : [{ outlets: { editForm: null, editEventForm: null } }];
      this.router.navigate(navigateParams, { relativeTo: this.route.parent, queryParamsHandling: 'merge' });
    }
  }

  /** Раскрытие блока Зона действия */
  public openCoverageArea(): void {
    this.emergencyService.openCoverageArea({ ...this.model, id: this.model.parentId['id'] });
  }
}
