import 'leaflet.markercluster';

import { Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange } from '@angular/core';
import { LeafletDirective, LeafletDirectiveWrapper } from '@asymmetrik/ngx-leaflet';
import * as L from 'leaflet';

import { IMapClustersRefresh } from '../../models/interfaces';

/**
 * Директива для шаблона отображающая объекты в кластере на базовой карте
 * Используется модуль leaflet MarkerCluster.
 *
 * @example
 * <map-base [mapOptions]="options"></map-base>
 */
@Directive({
  selector: '[mapMarkerCluster]',
})
export class MapMarkerClusterDirective implements OnDestroy, OnChanges, OnInit {
  /** Директива leaflet */
  private leafletDirective: LeafletDirectiveWrapper;
  /** Leaflet cluster group. см. описание leaflet */
  private markerClusterGroup!: L.MarkerClusterGroup;
  /** Параметры объектов карты */
  @Input('mapMarkerCluster') public markersData!: L.Layer[];
  /** Указывает на изменение объектов в кластере  */
  @Input() public mapMarkerClusterRefresh!: IMapClustersRefresh;
  /** Параметры кластера объектов */
  @Input() public mapMarkerClusterOptions!: L.MarkerClusterGroupOptions;
  /** Событие готовности кластера */
  @Output()
  public mapMarkerClusterReady: EventEmitter<L.MarkerClusterGroup> = new EventEmitter<L.MarkerClusterGroup>();

  constructor(leafletDirective: LeafletDirective) {
    this.leafletDirective = new LeafletDirectiveWrapper(leafletDirective);
  }

  /**
   * Инициализация кластера.
   */
  public ngOnInit(): void {
    this.leafletDirective.init();

    const map = this.leafletDirective.getMap();
    const options =
      this.mapMarkerClusterOptions ??
      <L.MarkerClusterGroupOptions>{
        disableClusteringAtZoom: 17,
      };

    this.markerClusterGroup = L.markerClusterGroup(options);

    // Add the marker cluster group to the map
    this.markerClusterGroup.addTo(map);

    // Set the data now that the markerClusterGroup exists
    this.setData(this.markersData);

    // Fire the ready event
    this.mapMarkerClusterReady.emit(this.markerClusterGroup);
  }

  /**
   * Обработчик изменения объектов кластера.
   * Если изменилось значение mapMarkerClusterRefresh, то кластер пересобирается.
   * @param changes объект на карте
   */
  public ngOnChanges(): void {
    this.setData(this.markersData);
  }

  /**
   * Завершение работы.
   */
  public ngOnDestroy(): void {
    this.markerClusterGroup.clearLayers();
    const map = this.leafletDirective.getMap();
    map.removeLayer(this.markerClusterGroup);
  }

  /**
   * Создает кластер из объектов слоя
   * @param objects Объекты IMapObjects
   */
  private setData(objects: L.Layer[]): void {
    // Ignore until the markerClusterGroup exists
    if (this.markerClusterGroup) {
      this.markerClusterGroup.clearLayers();
      this.markerClusterGroup.addLayers(objects);
    }
  }
}
