import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayersDataService, OperationsService } from '@bg-front/core/services';
import { IMapBaseObjectBaseEvent, IMapBaseObjectSelectEvent } from '@bg-front/map/models/interfaces';
import { MapBaseService } from '@bg-front/map/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Settings2Service } from '@smart-city/core/services';
import { Coordinates } from '@bg-front/core/models/classes';
import { BaseLayerControlComponent } from '../base-layer-control/base-layer-control.component';
import { IAnyObject, IMapObjectDto } from 'smart-city-types';
import { ControlsOf, ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { IBaseMapFilterForm } from '../../models/interfaces';
import { MapControlsService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'bg-thermopoints-layer-control',
  templateUrl: './thermopoints-layer-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThermopointsLayerControlComponent extends BaseLayerControlComponent implements OnInit {
  public override attributes: string[] = ['id', 'extId', 'centerCoordinates'];
  constructor(
    mapService: MapBaseService,
    cdr: ChangeDetectorRef,
    gisService: LayersDataService,
    settings: Settings2Service,
    router: Router,
    route: ActivatedRoute,
    operationsService: OperationsService,
    mapControlsService: MapControlsService,
  ) {
    super(mapService, cdr, gisService, settings, router, route, operationsService, mapControlsService);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    /** Подписка на Клик по объекту */
    this.mapService
      .getObservableObjectEvent<IMapBaseObjectSelectEvent>(this.mapId, 'selectObject', this.layer.nameOnMap)
      .pipe(untilDestroyed(this))
      .subscribe((event: IMapBaseObjectSelectEvent) => {
        if (event.selected) {
          this.showObjectInfo(event);
        }
      });
  }

  /** Обработка нажатия и вызов соответствующей навигации */
  private showObjectInfo(event: IMapBaseObjectBaseEvent): void {
    this.router.navigate(['thermopoint', event?.objectId], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }

  /** Преобразование входных данных */
  public override transformResponseData(data: IAnyObject[]): IMapObjectDto[] {
    return data.map(
      (el: IAnyObject) =>
        <IMapObjectDto>{
          ...el,
          name: el.extId,
          coordinates: new Coordinates(el.centerCoordinates).toArray(),
        },
    );
  }

  /** Формирование условий */
  public override getLayerLoadDataQuery(mapFilter: ValuesOf<ControlsOf<IBaseMapFilterForm>>): IAnyObject[] {
    return [
      { centerCoordinates: { $ne: null } },
      ...this.gisService.getEntityFilters(this.layer.entityFilters.filters),
      ...this.gisService.getEntityFilters(this.getFilterQuery(mapFilter)),
    ];
  }
}
