import { Component, OnInit } from '@angular/core';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import { IMapLayerEntityFilter, ISelectItem } from '@bg-front/core/models/interfaces';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Settings2Service } from '@smart-city/core/services';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { IAdminMunicipalSchemaDto, IKsipTypeDto } from 'smart-city-types';

import { IPlannedWorkMapFilterForm } from '../../models/interfaces';

dayjs.extend(isSameOrBefore);

/**
 * Общая компонента для фильтров разных происшествий
 *  @example
 *  <map-emergency-filter
 *    [title]="'Поручения'"
 *    [mapLayerFilters]="data.mapLayersFilters['Поручения']"
 *    (changedFilters)="onFiltersChange($event)"
 *  ></map-emergency-filter>
 */
@UntilDestroy()
@Component({
  selector: 'map-planned-work-filter',
  templateUrl: './map-planned-work-filter.component.html',
  styleUrls: ['./map-planned-work-filter.component.scss'],
})
export class MapPlannedWorkFilterComponent implements OnInit {
  /** Значения фильтров */
  public mapLayerFilters: ValuesOf<ControlsOf<IPlannedWorkMapFilterForm>> | undefined;

  /** Форма */
  public form: FormGroup<ControlsOf<IPlannedWorkMapFilterForm>>;

  /** Настройка компоненты Муниципальное образование */
  public municipals: ISelectItem[] = this.settings.allMo.map((item: IAdminMunicipalSchemaDto) => {
    return <ISelectItem>{
      text: item.name,
      value: item.id,
    };
  });

  /** Настройка компоненты Тип КСиП */
  public ksipTypes$: Observable<IKsipTypeDto[]> = this.ksipTypesQuery.getAllKsipTypes();

  constructor(
    private readonly ksipTypesQuery: KsipTypesQuery,
    private readonly settings: Settings2Service,
    private readonly modalRef: NzModalRef,
  ) {}

  /** @ignore */
  public ngOnInit(): void {
    this.form = new FormGroup<ControlsOf<IPlannedWorkMapFilterForm>>({
      moId: new FormControl(this.mapLayerFilters?.moId),
      incidentTypeId: new FormControl(this.mapLayerFilters?.incidentTypeId),
      state: new FormControl(this.mapLayerFilters?.state),
      timeCreateFrom: new FormControl(this.mapLayerFilters?.timeCreateFrom),
      timeCreateTo: new FormControl(this.mapLayerFilters?.timeCreateTo),
    });
  }

  /**
   * Формирование фильтров на основании значения формы
   */
  public getFilters(): IMapLayerEntityFilter[] {
    const value: ValuesOf<ControlsOf<IPlannedWorkMapFilterForm>> = this.form.value;

    const result: IMapLayerEntityFilter[] = [];
    if (value.moId) {
      result.push({
        property: 'moId',
        value: value.moId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.incidentTypeId) {
      result.push({
        property: 'incidentTypeId',
        value: value.incidentTypeId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.state) {
      result.push({
        property: 'lifeCycleStepId.status.sysname',
        value: value.state,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.timeCreateFrom) {
      result.push({
        property: 'timeCreate',
        value: value.timeCreateFrom,
        operation: FilterOperationEnum.greatThenOrEqual,
      });
    }
    if (value.timeCreateTo) {
      result.push({
        property: 'timeCreate',
        value: value.timeCreateTo,
        operation: FilterOperationEnum.lessThenOrEqual,
      });
    }
    return result;
  }

  /** Блокируем даты */
  public disabledStartDate = (): boolean => {
    const start = this.form.get('timeCreateFrom')?.value;
    const end = this.form.get('timeCreateTo')?.value;
    if (!start || !end) {
      return false;
    }
    return dayjs(start).isAfter(end);
  };

  /** Блокируем даты */
  public disabledEndDate = (endValue: Date): boolean => {
    const start = this.form.get('timeCreateFrom')?.value;
    if (!start) {
      return false;
    }
    if (dayjs(endValue).isSame(start, 'd')) {
      return false;
    }
    return dayjs(endValue).isSameOrBefore(start);
  };

  /** Очистка формы */
  public clear(): void {
    this.form.reset();
  }

  /** Закрытие формы */
  public close(): void {
    this.modalRef.destroy();
  }

  /** Фильтрация */
  public filtrate(): void {
    this.modalRef.destroy(this.getFilters());
  }
}
