import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Settings2Service } from '@smart-city/core/services';
import { Observable } from 'rxjs';

import { IGraphHopperDto, IGraphHopperPathDto } from '../../models/interfaces';

/** Сервис для построения маршрутов с помощью GraphHopper */
@Injectable({
  providedIn: 'root',
})
export class BuildRouteService {
  /** Ссылка на сервис */
  public readonly serviceUrl: string;

  /** Сообщение об изменении точек маршрута */
  private updateRoutePointsEvent: EventEmitter<string[]> = new EventEmitter<string[]>(true);
  public updateRoutePointsEvent$: Observable<string[]> = this.updateRoutePointsEvent.asObservable();

  /** Сообщение об изменении точек маршрута */
  private addRoutePointEvent: EventEmitter<string> = new EventEmitter<string>(true);
  public addRoutePointEvent$: Observable<string> = this.addRoutePointEvent.asObservable();

  /** Сообщенение о построении маршрута */
  private createRouteEvent: EventEmitter<IGraphHopperPathDto[]> = new EventEmitter(true);
  public createRouteEvent$: Observable<IGraphHopperPathDto[]> = this.createRouteEvent.asObservable();

  constructor(private settings: Settings2Service, private readonly service: HttpClient) {
    const graph: string = this.settings.getConfig().graphHopperUrl;
    const url = !graph || graph.startsWith('/', 0)
      ? `//${window.location.host}${graph}`
      : `//${graph}`;
    this.serviceUrl = `${url}/route`;
  }

  /** Построить маршрут */
  public getRoute(points: string[], vehicle: string = 'car'): Observable<IGraphHopperDto> {
    let params = new HttpParams();
    points.forEach((point: string) => (params = params.append('point', point)));
    params = params.set('vehicle', vehicle);
    params = params.set('points_encoded', 'false');

    return this.service.get<IGraphHopperDto>(this.serviceUrl, {
      params,
      responseType: 'json',
    });
  }

  /** Построить маршрут на карте */
  public createRoute(pathes: IGraphHopperPathDto[]): void {
    this.createRouteEvent.next(pathes);
  }

  /** Обновление маркеров */
  public updateRoutePoints(points: string[]): void {
    this.updateRoutePointsEvent.emit(points);
  }

  /** Добавление маркера */
  public addRoutePoint(point: string): void {
    this.addRoutePointEvent.emit(point);
  }
}
