import { Uuid } from '@smart-city/core/utils';
import { noop } from 'rxjs';

import { TMapBaseCoordinates, TMapObject } from '../types';

/** Объект карты */
export class MapObject {
  /**
   * Идентификатор
   * @default Uuid.newUuid()
   */
  public readonly id: string = Uuid.newUuid();
  /** Объект */
  public object!: TMapObject;
  /**
   * Показываем или нет
   * @default true
   */
  public isShow: boolean = true;
  /** Идентификатор слоя, на котором расположен объект */
  public layerId: string;
  /** Координаты */
  public coordinates: TMapBaseCoordinates | TMapBaseCoordinates[];
  /** Кластеризовывать объект или нет */
  public cluster: boolean = undefined;

  constructor(
    id?: string,
    layerId?: string,
    isShow?: boolean,
    cluster?: boolean,
    coordinates?: TMapBaseCoordinates | TMapBaseCoordinates[],
  ) {
    id ? (this.id = id) : noop();
    isShow ? (this.isShow = isShow) : noop();
    layerId ? (this.layerId = layerId) : noop();
    cluster !== undefined || cluster !== null ? (this.cluster = cluster) : noop();
    coordinates ? (this.coordinates = coordinates) : noop();
  }
}
