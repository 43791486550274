import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { LAYER_DATA_LOAD_LIMIT } from '@bg-front/core/models/constants';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import {
  IMapLayer,
  IMapLayerClusterSettings,
  IMapLayerClusterSettingValue,
  IMapLayerData,
  IMapLayerEntityFilter,
  IPictogramSettingsDto,
  IWorkerCreateIconsParams,
} from '@bg-front/core/models/interfaces';
import { LayersDataService, OperationsService } from '@bg-front/core/services';
import { MapLayer, MarkerIconFactory } from '@bg-front/map/models/classes';
import {
  IMapBaseAddObjectOptions,
  IMapBaseIcon,
  IMapBaseMarkerOptions,
  IMapBaseObjectEventsConfig,
  IMapObjectIconOptions,
} from '@bg-front/map/models/interfaces';
import { TIconShape } from '@bg-front/map/models/types';
import { MapBaseService } from '@bg-front/map/services';
import { ControlsOf, ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { ScConsole, Uuid } from '@smart-city/core/utils';
import { fromWorker } from 'observable-webworker';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, mergeMap, repeat, switchMap, takeWhile } from 'rxjs/operators';
import { IAnyObject, IMapObjectDto } from 'smart-city-types';

import { ColorHelper } from '@bg-front/core/models/helpers';
import * as L from 'leaflet';
import { IBaseMapFilterForm, IControlEvent } from '../../models/interfaces';
import { MapControlsService } from '../../services';

/** Компонента управляющая слоем */
@UntilDestroy()
@Component({
  template: '',
})
export class BaseLayerControlComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Состояние кнопки */
  @Input()
  public active: boolean = true;
  /** Список svg иконок из конфигураций слоев для легенды */
  @Input()
  public svgIconMap: IAnyObject;
  /** Модель слоя */
  @Input()
  public layer: IMapLayer;
  /** Блокирование кнопки */
  @Input()
  public disabled: boolean = false;
  /** Имя карты для работы */
  @Input()
  public mapId: string;
  /** Информируем о текущем состоянии */
  @Output()
  public changeActive: EventEmitter<IControlEvent> = new EventEmitter<IControlEvent>(true);
  /** Фильтры с карты */
  @Input()
  public mapLayerFilters: ValuesOf<ControlsOf<IBaseMapFilterForm>> = {};
  /**
   * Наличие подписки на изменение
   * @property {boolean}  subscribeChanges
   */
  @Input()
  public subscribeChanges: boolean = true;
  /** Скрытый режим. Кнопка не отображается */
  @Input()
  public hidden: boolean = false;
  /** Состояние загрузки слоя */
  public isLoading: boolean = false;
  /** Активная иконка */
  public activeIcon: string;
  /** Неактивная иконка */
  public disabledIcon: string;
  /** Запрашиваемые аттрибуты */
  public attributes: string[] = ['id', 'name', 'coordinates'];
  /** Набор уникальных столбцов */
  public distinct: string[] | undefined = undefined;
  /** Уникальный идентификатор */
  public id: string = Uuid.newUuid();

  /** @ignore */
  constructor(
    public readonly mapService: MapBaseService,
    public readonly cdr: ChangeDetectorRef,
    public readonly gisService: LayersDataService,
    public readonly settings: Settings2Service,
    public readonly router: Router,
    public readonly route: ActivatedRoute,
    public readonly operationsService: OperationsService,
    public readonly mapControlsService: MapControlsService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    const markerShapeSysname = ((this.layer.markerShape || {}) as IDictionaryInfo).sysname;
    let size = 17;
    const color = '#ffffff';
    let shapeSize = 30;
    let shapeLineRadius = null;

    // TODO: вынести настройки в конструктор слоев. тикет на анализ(BG-14190).
    switch (markerShapeSysname) {
      case 'circle':
        shapeSize = 38;
        size = 22;
        break;
      case 'rectangle':
        shapeLineRadius = 5;
        shapeSize = 34;
        break;
      case 'diamond':
        shapeSize = 32;
        size = 18;
        break;
    }
    const iconOptions: IMapObjectIconOptions = {
      iconSVG: this.layer.icon as string,
      iconSize: size,
      iconColor: this.layer.iconColor ?? color,
      shapeType: ((this.layer.markerShape || {}) as IDictionaryInfo).name as TIconShape,
      shapeColor: this.layer.markerColor,
      shapeSize: shapeSize,
      strokeWidth: 3,
      strokeColor: this.layer.borderColor,
      shapeLineRadius: shapeLineRadius,
    };

    this.activeIcon = MarkerIconFactory.makeIcon(iconOptions).iconUrl;

    this.disabledIcon = MarkerIconFactory.makeIcon({
      ...iconOptions,
      iconColor: '#FFFFFF',
      shapeColor: '#8C8C8C',
    }).iconUrl;

    const attributes = [
      ...(this.layer.pictogramSettings?.map((settings: IPictogramSettingsDto): string => settings.indicator) ?? []),
      ...(this.layer.clusterSettings?.map((settings: IMapLayerClusterSettings): string => settings.indicator) ?? []),
    ];

    this.attributes = [...this.attributes, ...attributes];

    this.active = this.layer.isDefaultActive;

    const mapLayer = new MapLayer(this.layer.nameOnMap, this.layer.isClustering ?? true, this.active);
    if (mapLayer.cluster) {
      mapLayer.clusterOptions = {
        iconCreateFunction: (cluster) => {
          const childCount = cluster.getChildCount();
          const allChildren: IAnyObject[] = cluster.getAllChildMarkers();

          let className: string = ' marker-cluster-';
          if (childCount < 10) {
            className += 'small';
          } else if (childCount < 100) {
            className += 'medium';
          } else {
            className += 'large';
          }

          let color: string | undefined;
          this.layer.clusterSettings?.forEach((setting: IMapLayerClusterSettings) => {
            setting.clusterSettingsValues?.forEach((value: IMapLayerClusterSettingValue) => {
              const check: boolean = allChildren.some((el: IAnyObject) => {
                return this.operationsService.checkValueByOperation(
                  el?.options?.properties[setting.indicator],
                  value.value,
                  value.property as FilterOperationEnum,
                );
              });

              if (check) {
                color = value.color;
              }
            });
          });

          const html = `<div ${
            color ? `style="background-color: ${color ? color : 'inherit'}"` : ''
          }><span>${childCount}<span aria-label="markers"></span></span></div>`;
          return this.generateClusterIcon(
            `<span style="background-color: ${color ? ColorHelper.lighter(color, 0.2) : 'inherit'};
            width: 40px;
            height: 40px;
            display: grid;
            border-radius: 40px;">${html}</span>`,
            'marker-cluster' + className + ` marker-cluster-level-${this.layer.clusterOrder ?? 0}`,
          );
        },
        disableClusteringAtZoom: 17,
      };
    }
    this.mapService.addLayer(this.mapId, mapLayer);

    this.mapControlsService.registerLayerControl(this);

    this.getLayerData(this.mapLayerFilters, false, true);
  }

  /** Включаем/выключаем слой */
  public changeState(newState: boolean): void {
    this.active = newState;

    this.mapService.showLayer(this.mapId, {
      id: this.layer.nameOnMap,
      isShow: this.active,
    });

    this.cdr.detectChanges();
  }

  /**
   * Запрос данных по слоям
   * @param mapLayerFilters информация о фильтрах настроенных пользователем на карте
   * @param positioningMapOnObjects позиционирование в середине всех элементов
   * @param restore сбросить состояние хранилища
   */
  public getLayerData(
    mapLayerFilters?: ValuesOf<ControlsOf<IBaseMapFilterForm>> | undefined,
    positioningMapOnObjects?: boolean | undefined,
    restore: boolean = false,
  ): void {
    this.isLoading = true;
    let countWorkers = 0;
    const newElementsIds = new Set<string>();

    /** Ограничение загрузки */
    const limit = {
      paNumber: 0,
      paSize: LAYER_DATA_LOAD_LIMIT,
    };

    if (restore) {
      this.mapService.removeObject(this.mapId, this.layer.nameOnMap);
    }

    of({})
      .pipe(
        switchMap(() => {
          return this.gisService.getLayerItems(
            this.layer.entityFilters.service,
            this.layer.entityFilters.entity,
            this.attributes,
            this.getLayerLoadDataQuery(mapLayerFilters),
            limit,
            this.getJoinedTables(mapLayerFilters),
            this.distinct,
          );
        }),
        mergeMap((items: IAnyObject[]): Observable<IAnyObject[]> => this.afterLoadChain(items)),
        map((items: IAnyObject[]): IMapObjectDto[] => this.transformResponseData(items)),
        map((items: IMapObjectDto[]): IMapObjectDto[] => {
          if (restore) {
            items.forEach((e: IMapObjectDto) => newElementsIds.add(e.id));
          }
          if ((items || []).length) {
            const input$ = of(<IWorkerCreateIconsParams>{
              data: [...items],
              settings: {
                layer: this.layer,
                pictogramElements: this.settings.getDictionaryByTypeSysName('pictogramElements'),
                markerShapes: this.settings.getDictionaryByTypeSysName('markerShape'),
                userId: this.settings.currentUser.id,
                svgIconMap: this.svgIconMap,
              },
            });

            countWorkers++;
            if (countWorkers > 0 && !this.isLoading) {
              this.isLoading = true;
            }

            const worker: Subscription = fromWorker<
              IWorkerCreateIconsParams,
              Map<string, IMapLayerData & { resultIcon: IMapBaseIcon }>
            >(
              () =>
                new Worker(new URL('../../../../../workers/initialize-map-objects.worker.ts', import.meta.url), {
                  type: 'module',
                }),
              input$,
            ).subscribe((response: Map<string, IMapLayerData & { resultIcon: IMapBaseIcon }>) => {
              let woCoords = 0;
              countWorkers--;
              const itemsTOAdd = [];
              items.forEach((item: IMapObjectDto) => {
                if (item) {
                  if (item.coordinates) {
                    if ((item.coordinates[0] ?? 0) > 0 && (item.coordinates[1] ?? 0) > 0) {
                      itemsTOAdd.push(this.createMarkerOnMap(item, response));
                    } else {
                      woCoords++;
                    }
                  }
                }
              });
              this.mapService.addMarkers(this.mapId, itemsTOAdd);

              if (woCoords > 0) {
                ScConsole.warning(`Map. Слой ${this.layer.nameOnMap} объектов без координат ${woCoords}`);
              }

              if (countWorkers === 0) {
                this.isLoading = false;
                this.cdr.detectChanges();
              }

              worker.unsubscribe();
            });

            if (positioningMapOnObjects) {
              this.mapService.flyToBounds(this.mapId);
            }
          } else {
            if (countWorkers === 0) {
              this.isLoading = false;
              if (restore) {
                this.mapService.removeLayer(this.mapId, this.layer.nameOnMap);
              }
              this.cdr.detectChanges();
            }
          }

          /** Переходим к следующей странице */
          limit.paNumber++;

          return items;
        }),
        repeat(),
        takeWhile((objects: IMapObjectDto[]) => {
          return objects.length === LAYER_DATA_LOAD_LIMIT;
        }),
        untilDestroyed(this),
        catchError((err: Error) =>
          this.catchErrorFn<IMapObjectDto[]>(err, `Ошибка при загрузке слоя "${this.layer.nameOnMap}"`),
        ),
      )
      .subscribe();
  }

  /** Формирование условий */
  public getLayerLoadDataQuery(mapFilter: ValuesOf<ControlsOf<IBaseMapFilterForm>>): IAnyObject[] {
    return [
      { coordinates: { $ne: null } },
      ...this.gisService.getEntityFilters(this.layer.entityFilters.filters),
      ...this.gisService.getEntityFilters(this.getFilterQuery(mapFilter)),
    ];
  }

  /** Формируем из объекта фильтра массив условий */
  public getFilterQuery(value: ValuesOf<ControlsOf<IBaseMapFilterForm>> | undefined): IMapLayerEntityFilter[] {
    if (!value) {
      return [];
    }
    const result: IMapLayerEntityFilter[] = [];
    if (value.moId) {
      result.push({
        property: 'moId',
        value: value.moId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.ids?.length) {
      result.push({
        property: 'id',
        value: value.ids,
        operation: FilterOperationEnum.in,
      });
    }
    // Запросить только данные по обновленным записям
    return result;
  }

  /** Преобразование входных данных */
  public transformResponseData(data: IAnyObject[]): IMapObjectDto[] {
    return data.map(
      (el: IAnyObject) =>
        <IMapObjectDto>{
          ...el,
          coordinates: new Coordinates(el.coordinates).toArray(),
        },
    );
  }

  /** Создание маркера на карте */
  public createMarkerOnMap(
    item: IMapObjectDto,
    response: Map<string, IMapLayerData & { resultIcon: IMapBaseIcon }>,
  ): IMapBaseAddObjectOptions<IMapBaseMarkerOptions> {
    const icon = response.get(item.id).resultIcon;
    return <IMapBaseAddObjectOptions<IMapBaseMarkerOptions>>{
      layerId: this.layer.nameOnMap,
      objectId: item.id,
      coordinates: item.coordinates,
      tooltip: {
        text: item.name,
        direction: 'top',
      },
      objectOptions: {
        icon,
      },
      eventsConfig: <IMapBaseObjectEventsConfig>{
        eventClick: true,
        eventDblClick: true,
      },
      properties: {
        ...item,
      },
    };
  }

  /** Функция открытия диалогового окна фильтрации */
  public openFilterDialog(): void {
    console.warn(`Фильтр для слоя ${this.layer.name} не реализован`);
  }

  /** Получаем дополнительные таблицы */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getJoinedTables(mapFilter: ValuesOf<ControlsOf<IBaseMapFilterForm>> | undefined): IAnyObject {
    return {};
  }

  public generateClusterIcon(html: string, className: string): IAnyObject {
    return new L.DivIcon({
      html,
      className,
      iconSize: new L.Point(40, 40),
    }) as IAnyObject;
  }

  /**
   * Функция вызывающаяся после загрузки порции данных,
   * которая может каким либо образом использован для трансформации или дополнительных фильтрующих запросов
   */
  public afterLoadChain(items: IAnyObject[]): Observable<IAnyObject[]> {
    return of(items);
  }

  /** Деструктор */
  public override ngOnDestroy(): void {
    this.mapService.removeLayer(this.mapId, this.layer.nameOnMap);
    this.mapControlsService.unregisterLayerControl(this);
  }
}
