import { Component, Input, OnInit } from '@angular/core';
import { ISelectItem } from '@bg-front/core/models/interfaces';
import { VideoDevicesStoreQuery } from '@bg-front/core/services';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDictionaryInfo, IScSelectItem } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAdminMunicipalSchemaDto, IAnyObject } from 'smart-city-types';

import { IVideoDeviceMapFilterForm } from '../../models/interfaces';

/**
 * Компонент фильтрации видеокамер на карте
 */
@UntilDestroy()
@Component({
  selector: 'map-video-device-filter',
  templateUrl: './map-video-device-filter.component.html',
  styleUrls: ['./map-video-device-filter.component.scss'],
})
export class MapVideoDeviceFilterComponent implements OnInit {
  /** Значения фильтров */
  @Input()
  public mapLayerFilters: ValuesOf<ControlsOf<IVideoDeviceMapFilterForm>> | undefined;

  /** Форма */
  public form: FormGroup<ControlsOf<IVideoDeviceMapFilterForm>>;

  /** Настройка компоненты Наименование */
  public devices$: Observable<ISelectItem[]> = this.videoDevicesQuery.selectAll().pipe(
    map((values: IAnyObject[]): ISelectItem[] =>
      values.map(
        (el: IAnyObject): ISelectItem =>
          <ISelectItem>{
            text: el.name,
            value: el.id,
          },
      ),
    ),
    untilDestroyed(this),
  );

  /** Настройка компоненты Тип видеокамеры */
  public videoCameraSignIds: ISelectItem[] = this.settings.getDictForSelect('videocameraSign').map(
    (el: IScSelectItem) =>
      <ISelectItem>{
        text: el.text,
        value: el.id,
      },
  );

  // /** Настройка компоненты Тип видеокамеры */
  // public videoCameraSignIdOptions: IScSelectOptions = {
  //   title: 'Тип видеокамеры',
  //   clearable: true,
  //   modern: true,
  //   fieldName: 'videoCameraSignId',
  //   data: this.settings.getDictForSelect('videocameraSign'),
  // };

  /** Настройка компоненты Категория */
  public categoryIds$: Observable<ISelectItem[]> = of([]).pipe(
    map((values: IAnyObject[]): ISelectItem[] =>
      values.map(
        (el: IAnyObject): ISelectItem =>
          <ISelectItem>{
            text: el.name,
            value: el.id,
          },
      ),
    ),
    untilDestroyed(this),
  );

  /** Настройка компоненты Категория */
  // public categoryIdsOptions: IScSelectOptions = {
  //   title: 'Категория',
  //   clearable: true,
  //   modern: true,
  //   fieldName: 'name',
  //   service: 'Admin',
  //   entity: 'VideoDeviceCategories',
  //   isMultiple: true,
  // };

  /** Настройка компоненты Состояние */
  public statuses: ISelectItem[] = this.settings.getDictForSelect('devicesState').map(
    (el: IScSelectItem) =>
      <ISelectItem>{
        text: el.text,
        value: el.id,
      },
  );

  /** @ignore */
  constructor(
    private readonly settings: Settings2Service,
    private readonly videoDevicesQuery: VideoDevicesStoreQuery,
    private readonly modalRef: NzModalRef,
  ) {}

  /** @ignore */
  public ngOnInit(): void {
    this.form = new FormGroup<ControlsOf<IVideoDeviceMapFilterForm>>({
      id: new FormControl(this.mapLayerFilters?.id),
      videoCameraSignId: new FormControl(this.mapLayerFilters?.videoCameraSignId),
      categoryIds: new FormControl<string[]>(this.mapLayerFilters?.categoryIds),
      isNoCategory: new FormControl(this.mapLayerFilters?.isNoCategory),
      // Т.к. в таблице хранится sysname записи из справочника,
      // то нужно в фильтр отдавать значение системного наименования, а при инициализации формы брать id.
      // UPD. Не переделывать на getDictionaryByTypeAndSysName.
      // Так как sysname является числом, поиск отрабатывает неверно.
      status: new FormControl(this.mapLayerFilters?.status),
    });
  }

  /** Очистка формы */
  public clear(): void {
    this.form.reset();
  }

  /** Закрытие формы */
  public close(): void {
    this.modalRef.destroy();
  }

  /** Фильтрация */
  public filtrate(): void {
    this.modalRef.destroy(this.form.getRawValue());
  }
}
