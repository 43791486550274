import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Coordinates } from '@bg-front/core/models/classes';
import { FilterOperationEnum, LayersEnum } from '@bg-front/core/models/enums';
import { IMapLayerData, IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { LayersDataService, OperationsService } from '@bg-front/core/services';
import {
  IMapBaseAddObjectOptions,
  IMapBaseCircleOptions,
  IMapBaseIcon,
  IMapBaseMarkerOptions,
  IMapBaseObjectBaseEvent,
  IMapBaseObjectEventsConfig,
  IMapBaseObjectSelectEvent,
  IMapBaseOptions,
  IMapBasePositionOnCoordinateOptions,
} from '@bg-front/map/models/interfaces';
import { MapBaseService } from '@bg-front/map/services';
import { ControlsOf, ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { untilDestroyed } from '@ngneat/until-destroy';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { AccessService, Settings2Service, SubscriberService } from '@smart-city/core/services';
import * as clone from 'clone';
import { NzModalService } from 'ng-zorro-antd/modal';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { IAnyObject, IEmergencyDto, IMapObjectDto } from 'smart-city-types';

import { IEmergencyMapFilterForm } from '../../models/interfaces';
import { BaseLayerControlComponent } from '../base-layer-control/base-layer-control.component';
import { MapEmergencyFilterComponent } from '../map-emergency-filter/map-emergency-filter.component';
import { MapControlsService } from '../../services';

/** Компонента управляющая слоем "Инциденты" */
@Component({
  selector: 'bg-incidents-layer-control',
  templateUrl: './incidents-layer-control.component.html',
  styleUrls: ['./incidents-layer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentsLayerControlComponent extends BaseLayerControlComponent implements OnInit {
  /** Специфические аттрибуты для слоя */
  public override attributes: string[] = ['id', 'number', 'coordinates'];

  public override mapLayerFilters: ValuesOf<ControlsOf<IEmergencyMapFilterForm>> = this.accessService.accessMap[
    'viewAllMunicipals'
  ]?.visible
    ? {}
    : { moId: this.settings.currentUser.organizationId.mo };

  constructor(
    mapService: MapBaseService,
    cdr: ChangeDetectorRef,
    gisService: LayersDataService,
    settings: Settings2Service,
    router: Router,
    route: ActivatedRoute,
    operationsService: OperationsService,
    mapControlsService: MapControlsService,
    private readonly accessService: AccessService,
    private readonly subs: SubscriberService,
    private readonly modalService: NzModalService,
  ) {
    super(mapService, cdr, gisService, settings, router, route, operationsService, mapControlsService);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    /** Подписка на Клик по объекту */
    this.mapService
      .getObservableObjectEvent<IMapBaseObjectSelectEvent>(this.mapId, 'selectObject', this.layer.nameOnMap)
      .pipe(
        filter(() => this.active),
        untilDestroyed(this),
      )
      .subscribe((event: IMapBaseObjectSelectEvent) => {
        if (event.selected) {
          this.mapService.setPositionOnCoordinate(this.mapId, {
            coordinate: event.coordinates,
          });
          this.mapService.addCircle(this.mapId, <IMapBaseAddObjectOptions<IMapBaseCircleOptions>>{
            layerId: 'circle',
            objectId: `circleBig_${event.objectId}`,
            coordinates: event.coordinates,
            objectOptions: {
              stroke: false,
              fillColor: '#FDD13A',
              fillOpacity: 0.3,
              radius: 500,
              center: event.coordinates,
            },
          });
          this.mapService.addCircle(this.mapId, <IMapBaseAddObjectOptions<IMapBaseCircleOptions>>{
            layerId: 'circle',
            objectId: `circleMin_${event.objectId}`,
            coordinates: event.coordinates,
            objectOptions: {
              stroke: false,
              fillColor: '#DA1E28',
              fillOpacity: 0.4,
              radius: 50,
              center: event.coordinates,
            },
          });

          this.showObjectInfo(event);
        }
        if (!event.selected) {
          this.mapService.removeObject(this.mapId, 'circle', [
            `circleBig_${event.objectId}`,
            `circleMin_${event.objectId}`,
          ]);
        }
      });

    this.mapService.setPositionOnCoordinateSub$
      .pipe(
        filter(() => this.active),
        filter(
          (params: Required<IMapBaseOptions<IMapBasePositionOnCoordinateOptions>>) =>
            params.options.layerId === LayersEnum.incidents,
        ),
        map((params: Required<IMapBaseOptions<IMapBasePositionOnCoordinateOptions>>) => params.options),
        untilDestroyed(this),
      )
      .subscribe((options: IMapBasePositionOnCoordinateOptions) => {
        this.mapService.removeLayer(this.mapId, 'circle');
        this.mapService.addCircle(this.mapId, <IMapBaseAddObjectOptions<IMapBaseCircleOptions>>{
          layerId: 'circle',
          objectId: 'circleBig',
          coordinates: options.coordinate,
          objectOptions: {
            stroke: false,
            fillColor: '#FDD13A',
            fillOpacity: 0.3,
            radius: 500,
            center: options.coordinate,
          },
        });
        this.mapService.addCircle(this.mapId, <IMapBaseAddObjectOptions<IMapBaseCircleOptions>>{
          layerId: 'circle',
          objectId: 'circleMin',
          coordinates: options.coordinate,
          objectOptions: {
            stroke: false,
            fillColor: '#DA1E28',
            fillOpacity: 0.4,
            radius: 50,
            center: options.coordinate,
          },
        });
      });

    this.initSubscribeOnChanges();
  }

  /** Обработка нажатия и вызов соответствующей навигации */
  private showObjectInfo(event: IMapBaseObjectBaseEvent): void {
    this.router.navigate([{ outlets: { leftPopup: ['emergency', event?.objectId] } }], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }

  /** Включаем/выключаем слой */
  public override changeState(newState: boolean): void {
    this.active = newState;

    this.mapService.showLayer(this.mapId, {
      id: this.layer.nameOnMap,
      isShow: this.active,
    });

    if (!this.active) {
      this.mapService.removeLayer(this.mapId, 'circle');
    }

    this.cdr.detectChanges();
  }

  /** Создание маркера на карте */
  public override createMarkerOnMap(
    item: IMapObjectDto,
    response: Map<string, IMapLayerData & { resultIcon: IMapBaseIcon }>,
  ): IMapBaseAddObjectOptions<IMapBaseMarkerOptions> {
    const icon = response.get(item.id).resultIcon;

    return <IMapBaseAddObjectOptions<IMapBaseMarkerOptions>>{
      layerId: this.layer.nameOnMap,
      objectId: item.id,
      coordinates: item.coordinates,
      tooltip: {
        text: item['number'],
        direction: 'top',
      },
      objectOptions: {
        icon,
      },
      eventsConfig: <IMapBaseObjectEventsConfig>{
        eventClick: true,
        eventDblClick: true,
      },
    };
  }

  /**
   * Инициализация подписки на изменения сущностей на карте
   */
  private initSubscribeOnChanges() {
    // Массив для накопления ID записей которые изменились за секунду
    let ids = [];
    /** Подписка на поток изменений в таблице происшествий */
    this.subs
      .onTableChange<IEmergencyDto>('Emergency', 'Emergency')
      .pipe(
        tap((response) => {
          const id: string | IAnyObject = response.data.id;
          if (typeof id === 'string') {
            ids.push(id);
          } else if (((id as Record<string, unknown>)?.['$in'] as [])?.length) {
            ((id as Record<string, unknown>)?.['$in'] as []).forEach((el: string) => ids.push(el));
          }
        }),
        debounceTime(1000),
        filter(() => ids.length > 0),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.mapService.removeObject(this.mapId, this.layer.nameOnMap, ids);

        const mapLayerFilters = clone(this.mapLayerFilters, { includeNonEnumerable: true });
        if (ids?.length) {
          mapLayerFilters.ids = [...ids];
        }
        // Очистка массива изменявшихся ID
        ids = [];

        this.getLayerData(mapLayerFilters, false);
      });
  }

  /** Открытие модального окна фильтрации */
  public override openFilterDialog(): void {
    this.modalService
      .create({
        nzTitle: 'Фильтрация объектов на карте',
        nzContent: MapEmergencyFilterComponent,
        nzComponentParams: {
          mapLayerFilters: this.mapLayerFilters,
        },
        nzFooter: null,
      })
      .afterClose.pipe(untilDestroyed(this))
      .subscribe((value: ValuesOf<ControlsOf<IEmergencyMapFilterForm>>) => {
        if (value) {
          this.mapLayerFilters = value;
          this.getLayerData(value, true, true);
        }
      });
  }

  /** Преобразование входных данных */
  public override transformResponseData(data: IAnyObject[]): IMapObjectDto[] {
    return data.map(
      (el: IAnyObject) =>
        <IMapObjectDto>{
          ...el,
          name: el.number,
          coordinates: new Coordinates(el.coordinates).toArray(),
        },
    );
  }

  public override getLayerLoadDataQuery(
    mapFilter: ValuesOf<ControlsOf<IEmergencyMapFilterForm>> | undefined,
  ): IAnyObject[] {
    const statusIds: string[] = this.settings
      .getDictionaryByTypeSysName('statusLifeCycleStep')
      .filter((item: IDictionaryInfo) => {
        return ['new', 'inWork', 'onControl'].includes(item.sysname);
      })
      .map((item: IDictionaryInfo) => item.id);

    const query = {
      coordinates: { $ne: null },
      lifeCycleStepId: { status: { id: { $in: statusIds } } },
    };

    return [
      query,
      ...this.gisService.getEntityFilters(this.layer.entityFilters.filters),
      ...this.gisService.getEntityFilters(this.getFilterQuery(mapFilter)),
    ];
  }

  public override getFilterQuery(
    value: ValuesOf<ControlsOf<IEmergencyMapFilterForm>> | undefined,
  ): IMapLayerEntityFilter[] {
    if (!value) {
      return [];
    }
    const result: IMapLayerEntityFilter[] = [];
    if (value.moId) {
      result.push({
        property: 'moId',
        value: value.moId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.incidentTypeId) {
      result.push({
        property: 'incidentTypeId',
        value: value.incidentTypeId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.state) {
      result.push({
        property: 'lifeCycleStepId.status.sysname',
        value: value.state,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.urgently) {
      result.push({
        property: 'urgently',
        value: value.urgently ? 'true' : 'false, null',
        operation: FilterOperationEnum.or,
      });
    }
    if (value.timeCreateFrom) {
      result.push({
        property: 'timeCreate',
        value: value.timeCreateFrom,
        operation: FilterOperationEnum.greatThenOrEqual,
      });
    }
    if (value.timeCreateTo) {
      result.push({
        property: 'timeCreate',
        value: value.timeCreateTo,
        operation: FilterOperationEnum.lessThenOrEqual,
      });
    }
    if (value.ids) {
      result.push({
        property: 'id',
        value: value.ids,
        operation: FilterOperationEnum.in,
      });
    }
    // Запросить только данные по обновленным записям
    return result;
  }
}
