import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import { IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { LayersDataService, OperationsService } from '@bg-front/core/services';
import { IMapBaseObjectBaseEvent, IMapBaseObjectSelectEvent } from '@bg-front/map/models/interfaces';
import { MapBaseService } from '@bg-front/map/services';
import { ControlsOf } from '@ngneat/reactive-forms';
import { ValuesOf } from '@ngneat/reactive-forms/lib/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import { NzModalService } from 'ng-zorro-antd/modal';

import { ISignificantObjectMapFilterForm } from '../../models/interfaces';
import { BaseLayerControlComponent } from '../base-layer-control/base-layer-control.component';
import { MapSignificantObjectFilterComponent } from '../map-significant-object-filter/map-significant-object-filter.component';
import { MapControlsService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'bg-significant-objects-layer-control',
  templateUrl: './significant-objects-layer-control.component.html',
  styleUrls: ['./significant-objects-layer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignificantObjectsLayerControlComponent extends BaseLayerControlComponent implements OnInit {
  public override attributes: string[] = ['id', 'name', 'coordinates', 'statusId'];

  public override mapLayerFilters: ValuesOf<ControlsOf<ISignificantObjectMapFilterForm>> = this.accessService.accessMap[
    'viewAllMunicipals'
  ]?.visible
    ? {}
    : { moId: this.settings.currentUser.organizationId.mo };

  constructor(
    mapService: MapBaseService,
    cdr: ChangeDetectorRef,
    gisService: LayersDataService,
    settings: Settings2Service,
    router: Router,
    route: ActivatedRoute,
    operationsService: OperationsService,
    mapControlsService: MapControlsService,
    private readonly accessService: AccessService,
    private readonly modalService: NzModalService,
  ) {
    super(mapService, cdr, gisService, settings, router, route, operationsService, mapControlsService);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    /** Подписка на Клик по объекту */
    this.mapService
      .getObservableObjectEvent<IMapBaseObjectSelectEvent>(this.mapId, 'selectObject', this.layer.nameOnMap)
      .pipe(untilDestroyed(this))
      .subscribe((event: IMapBaseObjectSelectEvent) => {
        if (event.selected) {
          this.showObjectInfo(event);
        }
      });
  }

  /** Обработка нажатия и вызов соответствующей навигации */
  private showObjectInfo(event: IMapBaseObjectBaseEvent): void {
    this.router.navigate(['significantObject', event?.objectId], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }

  /** Открытие диалогово окна фильтрации */
  public override openFilterDialog(): void {
    this.modalService
      .create({
        nzTitle: 'Фильтрация объектов на карте',
        nzContent: MapSignificantObjectFilterComponent,
        nzComponentParams: {
          mapLayerFilters: this.mapLayerFilters,
        },
        nzFooter: null,
      })
      .afterClose.pipe(untilDestroyed(this))
      .subscribe((value: ValuesOf<ControlsOf<ISignificantObjectMapFilterForm>>) => {
        if (value) {
          this.mapLayerFilters = value;
          this.getLayerData(this.mapLayerFilters, false, true);
        }
      });
  }

  public override getFilterQuery(
    value: ValuesOf<ControlsOf<ISignificantObjectMapFilterForm>> | undefined,
  ): IMapLayerEntityFilter[] {
    if (!value) {
      return [];
    }
    const result: IMapLayerEntityFilter[] = [];

    if (value.moId) {
      result.push({
        property: 'mo',
        value: value.moId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.typeId) {
      result.push({
        property: 'type',
        value: value.typeId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.id || value.ids?.length) {
      if (value.id) {
        result.push({
          property: 'id',
          value: value.id,
          operation: FilterOperationEnum.equal,
        });
      } else {
        if (value.ids?.length) {
          result.push({
            property: 'id',
            value: value.ids,
            operation: FilterOperationEnum.in,
          });
        }
      }
    }
    if (value.stateId) {
      result.push({
        property: 'state',
        value: value.stateId,
        operation: FilterOperationEnum.equal,
      });
    }

    return result;
  }
}
