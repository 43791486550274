import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of, throwError } from 'rxjs';
import { catchError, debounceTime, finalize } from 'rxjs/operators';

import { IElementButton, INwHeaderBarOptions } from '@smart-city/core/common';

import { BaseComponent } from '@bg-front/core/components';
import { IGraphHopperDto } from '../../models/interfaces';
import { BuildRouteService } from '../../services';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@UntilDestroy()
@Component({
  selector: 'bg-map-building-route',
  templateUrl: './map-building-route.component.html',
  styleUrls: ['./map-building-route.component.scss'],
})
export class MapBuildingRouteComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions;

  /** Форма */
  public form: FormGroup;

  /** Ссылка на массив координат */
  public pointsArray: FormArray;

  /** Режим постройки маршрута */
  public isBuildingRoute = false;

  /** @ignore */
  constructor(
    notificationService: NzNotificationService,
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly buildRoute: BuildRouteService,
  ) {
    super(notificationService);
  }

  /** @ignore */
  public ngOnInit(): void {
    this.headerOptions = {
      margin: 'collapse',
      title: 'Построение маршрута',
      buttons: [
        {
          type: 'button',
          options: {
            name: 'close',
            icon: 'close',
            position: 'suffix',
            callback: () => {
              this.router.navigate(
                [
                  {
                    outlets: { leftPopup: null },
                  },
                ],
                {
                  relativeTo: this.route.parent,
                  queryParamsHandling: 'merge',
                },
              );
              return of();
            },
          },
        },
      ],
    };

    this.form = this.fb.group({
      points: this.fb.array([]),
    });

    this.pointsArray = this.form.controls.points as FormArray;

    this.buildRoute.addRoutePointEvent$
      .pipe(untilDestroyed(this))
      .subscribe((point: string) => {
        const control = new FormControl(point, Validators.required);
        control.valueChanges.pipe(debounceTime(300), untilDestroyed(this))
          .subscribe(() => {
            this.buildRoute.updateRoutePoints(this.form.value.points);
          });
        this.pointsArray.push(control);
      });
  }

  /**
   * Обработка событий из заголовка
   * @param $event событие
   */
  public clickHeaderButton($event: IElementButton): void {
    if (typeof $event.options.callback === 'function') {
      $event.options.callback(undefined);
    }
  }

  /** Удаляем точку */
  public removePoint(idx: number): void {
    this.pointsArray.removeAt(idx);
    this.buildRoute.updateRoutePoints(this.form.value.points);
  }

  /** Построение маршрута */
  public startBuilding(): void {
    this.form.setControl('points', this.fb.array([]));
    this.pointsArray = this.form.controls.points as FormArray;
  }

  public finishBuilding(): void {
    if (this.form.valid) {
      const { points }: { points: string[] } = this.form.value;

      this.isBuildingRoute = true;
      this.buildRoute.getRoute(points)
        .pipe(
          catchError((error: Error) => {
            this.notificationService.error('Ошибка', 'Не удалось получить данные для маршрута');
            return throwError(error);
          }),
          finalize(() => this.isBuildingRoute = false),
          untilDestroyed(this),
        )
        .subscribe((res: IGraphHopperDto) => {
          this.buildRoute.createRoute(res.paths);
          this.form.setControl('points', this.fb.array([]));
          this.pointsArray = this.form.controls.points as FormArray;
        });
    }
  }
}
